import { useEffect, useState } from "react";
import getInstance from "../AxiosRequest";
import { gmtToDateString } from "../../../js/HelperFunctions";
import store from "../../../redux/store";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";



export const options = {
    title: "ANALYZED DOCUMENTS PER MODULE",
};
export const UsageComponent = () => {

    const [user_usage, setUserUsage] = useState({});

  useEffect(() => {
      const fetchData = async () => {
          try {
              const axiosInstance = getInstance(localStorage.getItem("token"));
              await axiosInstance
                  .get(
                      "/user/getexistingplandata/" +
                          store.getState().login?.logInState?.user_id,
                      {
                          headers: {
                              "Content-Type": "multipart/form-data",
                          },
                          timeout: 300000,
                      }
                  )
                  .then((response) => {
                      console.log(response?.data);
                      setUserUsage(response?.data);
                  })
                  .catch((error) => {
                      console.log("From API: ", error.response.status);
                  });
          } catch (error) {
              console.log(error);
          } finally {
          }
      };

      fetchData();
  }, []);


    return (
        <>
            <ul className="ul__cards">
                <li className="li__cards__item">
                    <div className="div__card">
                        <div className="div__card__content">
                            <div className="div__card__title">
                                <span>DOCUMENTS</span>
                                <span></span>
                            </div>
                            <div className="div__card__usage__content">
                                <ul className="ul">
                                    <li>
                                        <span>
                                            ANALYZED: <b>{user_usage?.docs}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            REMAINING:{" "}
                                            <b>{user_usage?.remaining_days}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            {" "}
                                            START DATE:{" "}
                                            <b>
                                                {user_usage?.start_date
                                                    ? gmtToDateString(
                                                          user_usage?.start_date
                                                      )
                                                    : "-"}
                                            </b>
                                        </span>{" "}
                                        {"  "} &nbsp;&nbsp;&nbsp;
                                    </li>
                                    <li>
                                        <span>
                                            END DATE:
                                            {"  "}
                                            <b>
                                                {user_usage?.end_date
                                                    ? gmtToDateString(
                                                          user_usage?.end_date
                                                      )
                                                    : "-"}
                                            </b>
                                        </span>
                                    </li>
                                </ul>
                                {user_usage?.documents &&
                                    user_usage?.documents?.length > 0 && (
                                        <AreaChart
                                            width={400}
                                            height={100}
                                            data={user_usage?.documents}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <XAxis dataKey="created_at" />
                                            <YAxis hide />
                                            <Tooltip dataKey="count" />
                                            <Area
                                                type="monotone"
                                                dataKey="count"
                                                stroke="#fff"
                                                fill="#ccc"
                                            />
                                        </AreaChart>
                                    )}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
}


