import axios from "axios";
import { isMobile,isTablet, browserName, osName } from 'react-device-detect';
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx"
import SiteIds from "../../../Helpers/SiteIds.js";

const UseUserLocation = async(data) => {
    const token =localStorage.getItem("token")
    let axiosInstance = getAxiosInstance(token,false)
    
    await axiosInstance.post("/log_user_data", data, {timeout:180000}).then((res)=>{
        if(res?.status === 200){
            sessionStorage.removeItem("isApiCalled")
            localStorage.setItem('manage_id', res?.data?.login_id)
        }
        
    }) 
};

export const UpdateLogoutActivity = ()=>{
    const token =localStorage.getItem("token")
    let id = localStorage.getItem("manage_id")
    
    if(id){
        let axiosInstance = getAxiosInstance(token,false)
        axiosInstance.post("/update_user_data", {id:parseInt(id)}, {timeout:180000}).then((res)=>{
            if(res?.status === 200){
                localStorage.removeItem('manage_id')
            }
            
        })
    }
}

export const UserLogActivity = async(userId, loginStatus)=>{

    const userAgent = navigator?.userAgent;
    const deviceType = isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop');

    // Get IP Address using ipify API
    const ipAddress = await axios.get('https://api.ipify.org?format=json')
                                                .then(response => {return response?.data?.ip;})
                                                .catch((error) => {console.error("Error fetching IP address:", error)});
    
    let data={
        userId:userId, 
        loginStatus:loginStatus,
        ipAddress :ipAddress,
        deviceType : deviceType?.toUpperCase(),
        operatingSystem : osName?.toUpperCase(),
        browser : browserName?.toUpperCase(),
        location : JSON.stringify({latitude:"", longitude:""}),
        authMethod : "PASSWORD",
        failedAttempts:0,
        sessionToken:"",
        userAgent: userAgent,
        isFirstTimeLogin:false,
        logoutTimestamp:"",
        sessionDuration:"",
        domain:window.location.origin,
        site_id: SiteIds.PERSONAL_INJURY
    }
    navigator?.permissions.query({name:"geolocation"}).then(function(permissionStatus){
        if(permissionStatus?.state === "granted"){
            navigator?.geolocation?.getCurrentPosition(
                (position) => {
                  if (position) {
                    data["location"] = JSON.stringify({latitude:position?.coords?.latitude, longitude:position?.coords?.longitude})
                    UseUserLocation(data)
                }
                },
                (error) => {
                    data["location"] = JSON.stringify({latitude:error?.message, longitude:error?.message})
                    UseUserLocation(data)
                }
              );
        }
        else{
            data["location"] = JSON.stringify({latitude:"No permission", longitude:"No permission"})
            UseUserLocation(data)
        }
    })
  
}

