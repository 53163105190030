import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { CustomTabPanel } from "../../MainComponents/Admin/AdminOperations";
import { CustomDocViewer } from "../DocumentComponents/DocViewer";

export default function ScrollableTabsButtonForce({ updatedfiles }) {
    const [value, setValue] = React.useState(0);
    const [files, setFiles] = React.useState(updatedfiles);
    const handleChange = (event, newValue) => {
        console.log(newValue, event);
        setValue(newValue);
    };
    React.useEffect(() => {
        setFiles(updatedfiles);
    }, [updatedfiles]);

    return (
        <Box
            sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
            >
                {files?.map((val, index) => {
                    return (
                        <Tab key={index} label={val?.file?.name}>
                            {val?.file?.name}
                        </Tab>
                    );
                })}
            </Tabs>
            <>
                {files?.map((val, index) => {
                    return (
                        <CustomTabPanel value={value} key={index} index={index}>
                            <CustomDocViewer
                                url={val?.url}
                                opendocdate={""}
                                name={val?.file?.name}
                                mimetype={val?.file?.type}
                                file={val?.file}
                            />
                        </CustomTabPanel>
                    );
                })}
            </>
        </Box>
    );
}
