import React, { useEffect } from 'react';

const UnknownPathHandler = ({ onUnknownPath }) => {
  useEffect(() => {
    onUnknownPath();
  }, [onUnknownPath]);

  return (
    <div>
     
    </div>
  );
};

export default UnknownPathHandler;
