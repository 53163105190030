const initialState = {
    generateDocState: {
        selectedDocType: "",
        selectedLabel: "",
        isBottomPanelOpen: true,
        generateDocumentResult: "",
        timetaken: "",
        isGenerated: false,
        dd_details: {
            Client_Full_Name: "",
            Client_Address: "",
            Client_Contact_Info: "",
            Party_Full_Name: "",
            Party_Address: "",
            Party_Contact_Info: "",
            Accident_Type: "",
            Accident_Date: "",
            Accident_Loc: "",
            Brief_Summary: "",
            Injury: "",
            Treatment: "",
            Current_Condition: "",
            Medical_Treatment: "",
            Medical_Cost: "",
            Future_Medical_Costs: "",
            Lost_Income: "",
            Projected_Loss: "",
            Other_Damages: "",
            Settlement_Demand: "",
        },
        medical_details: {
            Client_Full_Name: "",
            dob: "",
            address: "",
            contact_info: "",
            healthcare_provider: "",
            dept: "",
            hospital_address: "",
            hos_contact_info: "",
            Accident_Type: "",
            Accident_Date: "",
            Brief_Summary: "",
            specific_details: "",
            record_type: "",
            record_time: "",
            req_purpose:
            "For the purpose of legal representation in a personal injury case",
            client_consent: false,
            consent_date: "",
            expiration_date: "",
            delivery_pref: "",
        },
        complaints_details: {
            p_Full_Name: "",
            p_Address: "",
            p_Contact_Info: "",
            d_Full_Name: "",
            d_Address: "",
            d_Contact_Info: "",
            court: "",
            reason: "",
            Accident_Type: "",
            Accident_Date: "",
            Accident_Loc: "",
            Brief_Summary: "",
            injuries_description: "",
            property_damage: "",
            medical_treatment: "",
            medical_expense: "",
            lost_wage: "",
            pain_suffering: "",
            claims: "",
            demand: "",
        },
        discovery_details: {
            req_Full_Name: "",
            o_Full_name: "",
            Name: "",
            number: "",
            jurisdiction: "",
            doc_req: "",
            discovery_date: "",
            Client_Full_Name: "",
            address: "",
            contact_info: "",
            discoveryType: "",
        },
        validation_errors: {
            description: "Description",
            plaintiff: "Plaintiff",
            defendant: "Defendant",
            jurisdiction: "Jurisdiction",
            case_number: "Case Number",
            date: "Date",
            location: "Location",
            legal_issue: "Legal Issue",
            status: "Status",
            witness: "Witness",
            relevant_documents: "Relevant Documents",
            Nature_of_Incident: "Nature of Incident",
            Injury: "Injury",
            Treatment_Received: "Medical Treatment",
            Medical_Expense: "Medical Expenses",
            Lost_Wages: "Lost Wages",
            Downtime: "Downtime",
            Pain_Suffering: "Pain Suffering",
            Parties_Behavior: "Parties Behavior",
            Laws_Regulations: "Laws Regulations",
            Expert_Testimony: "Expert Testimony",
            Analysis_Questions: "Questions for Analysis",
            Liability: "Liability",
            Insurance_Coverage: "Insurance Coverage",
            Plaintiff_Req: "Plaintiff's Requirements",
            Defendent_Position: "Defense's Position",
            Precedents: "Precedents",
            Claims: "Claims and Counterclaims",
            Evidence: "Evidence",
            Laws_Precedents: "Laws and Precedents",
            Parties: "Parties",
            Legal_Team: "Legal Team",
            Evidentiary: "Evidentiary",
            Legal_Precedents: "Legal Precedents",
            Opposing_Party: "Opposing Party",
            Plaintiff_Position: "Plaintiff's Position",
        },
        isLoading: false,
        delivery_option: ["Mail", "E-Mail", "Pickup"],
        discovery_req_types: [
            "Interrogatories",
            "Requests for Production of Documents",
            "Requests for Admissions",
            "Depositions",
        ],
    }, 
};

export const generateDocReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_GENERATEDOC_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, generateDocState: action.payload.data };
        // Add other cases for different components' states
    case 'RESET_GENERATEDOC_STATE':
        return initialState;
    default:
        return state;
    }
};
