import HelperMessages from "../../Helpers/HelperMessages";

const initialState = {
    reviewState: {
        isBottomPanelOpen: false,
        selectedDocType: "",
        selectedEngine: 2,
        selectedFile: "",
        selectedCanned_Ques: [],
        reviewResult: "",
        isUploadFileOpen: false,
        CannedQuestionsList: [],
        promptDisplayed:HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        isLoading: false,
        isSocketRequestProgress: false,
        loaderText: "Analysis in progress...",
        fileName: "",
        reviewTimer: 0,
        favoriteFiles: [],
        activeTab: 0,
        isHandWritten: false,
        historyList: [],
        fromDashBoard: false,
        reqStartTime: new Date().getTime(),
        blobFilesList: [], // List used to open doc in the bottom bar
        docActiveTab: "", // state variable used to maintain active tab of document
        isDocumentOpen: true,
        favdisplayval: false,
        hisdisplayval: false,
        isContentExpanded: true,
        openCreateWorkSpacePopUp: false,
        index_path: "",
        inputValue: "",
        oldMessages: [],
        workspace_id: 0,
        workspace_item_id: 0,
        isAddDoc: false,
        module_id: 1,
        isIndexCreated: false,
        disableAddToWorkspace: false,
        req_id: "",
        uploadFileId: undefined,
        fromDashBoardLinkType: "",
        isFromWorkspace: true,
        reviewTime: "",
        review_by_name: "",
        isSharedWorkspace: false,
        selectedWorkSpaces:[],
        showAllWorkspaces:false,
        workspace_created_by:0,
        ws_shared_users: [],
        ws_count:0,
        workspace_name: "",
        doc_help_questions: [],
        moveToAnotherWorkspace:false,
        addToAnotherWorkspace:false,
        closeKebabMenu:false
    }, // Initial state for the ReviewComponent

};
export const ReviewInitialState = initialState.reviewState;
export const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_REVIEW_STATE':
            // console.log("Redux payload",action.payload.data)
            return { ...state, reviewState: action.payload.data };
        // Add other cases for different components' states
        case "RESET_REVIEW_STATE":
            return initialState;
        default:
            return state;
    }
};

