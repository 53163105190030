import React, { useEffect, useState } from "react";
import { ExternalLinkIcon } from "./FontAwesomeComponents/heart/filledheart";

function TruncateText({ text, maxLength, fileURL, isBatch = false, onClick }) {
    const [text_, setText] = useState(text);
    const [maxLength_, setMaxLength] = useState(maxLength);
    const [fileURL_, setFileURL] = useState(fileURL);

    useEffect(() => {
        setText(text);
    }, [text]);
    useEffect(() => {
        setMaxLength(maxLength);
    }, [maxLength]);
    useEffect(() => {
        setFileURL(fileURL);
    }, [fileURL]);
    return (
        <label
            data-toggle="tooltip"
            data-placement="top"
            title={text_?.length > maxLength_ ? text_ : null}
        >
            {isBatch ? (
                <div
                    style={{
                        color: "#2eb1e3",
                        cursor: "pointer",
                    }}
                    onClick={onClick}
                >
                    {" "}
                    {renderTruncatedText(text_, maxLength_)}{" "}
                    <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                </div>
            ) : (
                <a href={fileURL_} rel="noreferrer" target="_blank">
                    {renderTruncatedText(text_, maxLength_)}{" "}
                    <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                </a>
            )}
        </label>
    );
}
export const renderTruncatedText = (text, maxLength) => {
    if (text?.length <= maxLength) {
        return text;
    }

    const startLength = Math.floor((maxLength - 3) / 2);
    const endLength = maxLength - startLength;

    const truncatedText = `${text?.substring(0, startLength)}...${text?.substring(
        text?.length - endLength
    )}`;

    return truncatedText;
};

export const docxfileTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
/*
export function CustomTruncatedText({
    text,
    maxLength,
    fileURL,
    isBatch = false,
    callbackfn,
    updatestatecallbackfn,
    file,
}) {
    return (
        <label
            data-toggle="tooltip"
            data-placement="top"
            title={text.length > maxLength ? text : null}
        >
            {isBatch ? (
                <div
                    style={{
                        color: "#2eb1e3",
                        cursor: "pointer",
                    }}
                    onClick={() => onClick(true)}
                >
                    {" "}
                    {renderTruncatedText(text, maxLength)}{" "}
                    <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                </div>
            ) : (
                <a href={fileURL} rel="noreferrer" target="_blank">
                    {renderTruncatedText(text, maxLength)}{" "}
                    <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                </a>
            )}
        </label>
    );
}
*/
export function CustomTruncatedText({
    text,
    maxLength,
    fileURL,
    isBatch = false,
    callbackfn,
    updatestatecallbackfn,
    file,
    openInNewTab = true,
}) {
    return (
        <label
            data-toggle="tooltip"
            data-placement="top"
            title={text?.length > maxLength ? text : null}
        >
            {isBatch ? (
                <div
                    style={{
                        color: "#2eb1e3",
                        cursor: "pointer",
                    }}
                    onClick={() => {}}
                >
                    {" "}
                    {renderTruncatedText(text, maxLength)}{" "}
                    <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                </div>
            ) : (
                <>
                    {docxfileTypes.includes(file?.type) || openInNewTab ? (
                        <>
                            <a href={fileURL} target="_blank" rel="noreferrer">
                                {" "}
                                {renderTruncatedText(text, maxLength)}{" "}
                                <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>{" "}
                            </a>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => callbackfn()}
                                className="simulate-link"
                            >
                                {renderTruncatedText(text, maxLength)}{" "}
                                <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                            </button>
                        </>
                    )}
                </>
            )}
        </label>
    );
}
export default TruncateText;
