import React, {
    useState,
    useEffect,
    Fragment,
    forwardRef,
    useImperativeHandle,
    useRef,
    useCallback,
} from "react";
//import { PropTypes } from "prop-types";
import JSZip from "jszip";
import {
    FolderIcon,
    TrashCanIcon,
} from "../SupportiveComponents/FontAwesomeComponents/heart/filledheart";
import { CustomTable } from "./AgGridTemplates/CustomAgGrid.jsx";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import getAxiosInstance from "./AxiosRequest.jsx";
import { ExternalLinkIcon } from "./FontAwesomeComponents/heart/filledheart";
import { AddBlobToBlobList } from "./DocumentComponents/DocViewer.jsx";
import { renderTruncatedText, docxfileTypes } from "./TruncateFileName.jsx";

const FavoritesComponent = forwardRef((props, ref) => {
    const [favoriteFiles, SetFavFiles] = useState([]);
    const [msg, setMsgText] = useState("No Favorite's Found...");
    const [favdisplayvals, setfavdisplayval] = useState(props.favdisplayval);
    const gridRef = useRef();
    useEffect(() => {
        setfavdisplayval(props.favdisplayval);
    }, [props.favdisplayval]);
    //Assigning FetchAllFavoritesStateCallback to state
    const [handleFetchFavoriteFilesStateCallBack] = useState(
        () => props.handleFetchFavoriteFilesState
    );

    //This Hook Exposes Methods when using ref
    useImperativeHandle(ref, () => ({
        RemoveFavorite,
        handleFavoriteFile,
        RetrieveFileFromFavorites,
        gridRef,
    }));

    //Removes Favorites
    const RemoveFavorite = (
        filename,
        moduleId,
        batchId,
        handleRemoveFavoriteStateCallBack
    ) => {
        try {
            handleRemoveFavoriteStateCallBack(
                {
                    isLoading: true,
                    loaderText: "Removing favorite in progress...",
                    favdisplayval: false,
                },
                false
            );
            const formData = new FormData();
            formData.append("file_name", filename);
            formData.append("module_id", moduleId);
            formData.append("batch_id", batchId);

            const axiosInstance = getAxiosInstance(props.token);
            axiosInstance
                .post("/removefavourite", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    crossDomain: true,
                    timeout: 300000,
                })
                .then((response) => {
                    //  console.log(response, "Fetch Favourite File");

                    if (response.data.error_Msg) {
                        handleRemoveFavoriteStateCallBack(
                            { isLoading: false },
                            true
                        );
                        Swal.fire({
                            text: "Not able to Retrieve the favorite file, please try again",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        //   console.log("Response", response.headers);

                        handleRemoveFavoriteStateCallBack(
                            { isUploadFileOpen: false, isLoading: false },
                            false
                        );

                        FetchAllFavoriteList(
                            moduleId,
                            handleFetchFavoriteFilesStateCallBack
                        );

                        if (response.data.success) {
                            toast.success("File Removed from favorites");
                        } else {
                            toast.error("Unable to Remove File From Favorites");
                        }
                    }
                })
                .catch((error) => {
                    handleRemoveFavoriteStateCallBack(
                        { isLoading: false },
                        true
                    );
                    // console.error("Error:", error);
                    Swal.fire({
                        text: "Not able to Remove Favorite, please try again",
                        icon: "error",
                        confirmButtonText: "OK",
                    });

                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        props.removeToken();
                    }
                });
        } catch (error) {
            //empty
        }
    };

    //Favoritise a File
    /**
     *
     * @param {Object} obj
     * @param {callback} handleFavoriteFileStateCallback
     */
    const handleFavoriteFile = (obj, handleFavoriteFileStateCallback) => {
        try {
            handleFavoriteFileStateCallback(
                {
                    isLoading: true,
                    loaderText: "Favoriting in progress...",
                },
                false
            );

            let fileDetail = obj.selectedFile;
            const formData = new FormData();
            formData.append("file", fileDetail.file);
            formData.append("document_type", obj.selectedDocType);
            formData.append("is_hand_written", obj.isHandWritten);
            formData.append("module_id", obj.module_id);
            formData.append("selected_engine_id", obj.selected_engine_id);
            console.info("Save To Favorites", formData);
            if (obj.isBatchFile) {
                //  console.log("In Favoriting the batch ", obj.isBatchFile);
                let files = obj.batchFiles;
                formData.append("isBatchFile", true);
                formData.append("batchName", obj.batchName);
                files.forEach((file, index) => {
                    formData.append(`file${index}`, file);
                });
            }

            const axiosInstance = getAxiosInstance(props.token);
            axiosInstance
                .post("/FavoriteFile", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 300000,
                })
                .then((response) => {
                    if (response.data.error_Msg) {
                        handleFavoriteFileStateCallback(
                            { isLoading: false, loaderText: "" },
                            true
                        );
                        Swal.fire({
                            text: "Not able to favorite the file, please try again",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        //   console.log("Response", response.data);
                        handleFavoriteFileStateCallback(
                            { isLoading: false, isUploadFileOpen: false },
                            false
                        );
                        FetchAllFavoriteList(
                            props.module,
                            handleFetchFavoriteFilesStateCallBack
                        );
                        toast.success(response.data.success);
                    }
                })
                .catch((error) => {
                    handleFavoriteFileStateCallback(
                        { isLoading: false, loaderText: "" },
                        true
                    );
                    console.error("Error:", error);
                    Swal.fire({
                        text: "Not able to favorite the file, please try again",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        props.removeToken();
                    }
                });
        } catch (error) {
            //empty
        }
    };

    //Get Favorites
    const FetchAllFavoriteList = (
        moduleId,
        handleFetchFavoriteFilesStateCallBack
    ) => {
        try {
            setMsgText("Loading Favorites...");
            const formData = new FormData();
            formData.append("module_id", moduleId);
            new Date().getTime();
            // FavoriteFile
            const axiosInstance = getAxiosInstance(props.token);
            axiosInstance
                .get("/FetchFavoritesFile", {
                    headers: {
                        "x-moduleId": `${formData.get("module_id")}`,
                    },
                })
                .then((response) => {
                    // console.log("response", response);
                    if (response.data.error_Msg) {
                        SetFavFiles([]);
                        handleFetchFavoriteFilesStateCallBack(
                            { favoriteFiles: [] },
                            true
                        );
                    } else {
                        SetFavFiles(response.data.result);
                        handleFetchFavoriteFilesStateCallBack(
                            { favoriteFiles: response.data.result },
                            false
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    SetFavFiles([]);
                    handleFetchFavoriteFilesStateCallBack(
                        { favoriteFiles: [] },
                        true
                    );
                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        props.removeToken();
                    }
                });
        } catch (error) {
            SetFavFiles([]);
            console.error("Error:", error);
            handleFetchFavoriteFilesStateCallBack({ favoriteFiles: [] }, true);
        } finally {
            setMsgText("No Favorite's Found...");
        }
    };

    /*Fetches Favorites List Only Once*/
    useEffect(() => {
        if (
            Array.isArray(props.favoriteFiles)
                ? props.favoriteFiles.length > 0
                : false
        ) {
            console.log(props.favoriteFiles);
            SetFavFiles(props.favoriteFiles);
        } else {
            FetchAllFavoriteList(
                props.module,
                handleFetchFavoriteFilesStateCallBack
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Retrieves File From Favorites
    const RetrieveFileFromFavorites = (
        filename,
        moduleId,
        batchId,
        isBatch,
        handleRetrieveFileStateCallback,
    ) => {
        console.log(`triggered Retrieve file from Favorites ${filename}`);
        try {
            handleRetrieveFileStateCallback(
                {
                    isLoading: true,
                    loaderText: "Retrieving favorited file in progress...",
                    fileName: "",
                    uploadFileId: undefined,
                    selectedFile: "",
                    favdisplayval: false,
                    isFromFavorites:true,
                    isFromHistory:false
                },
                true
            );

            const formData = new FormData();
            formData.append("fileName", filename);
            formData.append("module_id", moduleId);
            formData.append("batch_id", batchId);
            formData.append("isBatch", isBatch);

            const axiosInstance = getAxiosInstance(props.token);
            axiosInstance
                .post("/FetchFavoriteFile", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    crossDomain: true,
                    timeout: 300000,
                    responseType: "blob",
                })
                .then((response) => {
                    console.log("favorites response", response);
                    if (response.data.error_Msg) {
                        handleRetrieveFileStateCallback(
                            {
                                isLoading: false,
                                selectedDocType: "",
                                selectedFile: "",
                                fileName: "",
                            },
                            true
                        );

                        Swal.fire({
                            text: "Not able to Retrieve the favorite file, please try again",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    } else {
                        if (isBatch) {
                            const headers = response.headers;

                            // Access specific headers by their names
                            const doctypeheader = headers["x-document-type"];

                            // Access specific headers by their names
                            const ishandwrittenheader =
                                headers["x-is-hand-written"];

                            // const mimeType = headers["x-mime-type"];
                            const engine_id = headers["x-engine_id"];
                            // You can access other response data like the response body
                            let docType = doctypeheader;
                            let is_hand_written =
                                parseInt(ishandwrittenheader) === 1
                                    ? true
                                    : false;

                            const zip = new JSZip();

                            const zipBlob = response.data;
                            let extractedFiles = [];
                            zip.loadAsync(zipBlob).then(function (loadedZip) {
                                //  console.log("loadedZip", loadedZip);
                                let promises = Object.keys(loadedZip.files).map(
                                    function (relativePath) {
                                        let zipEntry =
                                            loadedZip.files[relativePath];
                                        // console.log("zipEntry", zipEntry);
                                        let mimeType = zipEntry.comment;
                                        let filename = zipEntry.name;
                                        return zipEntry
                                            .async("blob")
                                            .then(function (contentBlob) {
                                                contentBlob = contentBlob.slice(
                                                    0,
                                                    contentBlob.size,
                                                    "application/pdf"
                                                );
                                                console.log(
                                                    "before Push:*******************",
                                                    contentBlob
                                                );
                                                console.log(
                                                    mimeType,
                                                    filename,
                                                    "mimetype, filename",
                                                    contentBlob,
                                                    URL.createObjectURL(
                                                        new File(
                                                            [contentBlob],
                                                            filename,
                                                            { type: mimeType }
                                                        )
                                                    )
                                                );

                                                extractedFiles.push(
                                                    new File(
                                                        [contentBlob],
                                                        filename,
                                                        { type: mimeType }
                                                    )
                                                );
                                            });
                                    }
                                );

                                Promise.all(promises).then(function () {
                                    let selectedFile = {
                                        name: filename,
                                        file: extractedFiles,
                                        error: null,
                                    };

                                    handleRetrieveFileStateCallback(
                                        {
                                            isUploadFileOpen: false,
                                            isLoading: false,
                                            batchFiles: extractedFiles,
                                            batch_id: batchId,
                                            selectedDocType: docType,
                                            isHandWritten: is_hand_written,
                                            fileName: filename,
                                            selectedFile,
                                            selectedEngine:
                                                engine_id === null
                                                    ? props.selectedEngine
                                                    : engine_id,
                                            batchId: batchId,
                                        },
                                        false
                                    );
                                });
                            });
                        } else {
                            const headers = response.headers;

                            // Access specific headers by their names
                            const doctypeheader = headers["x-document-type"];

                            // Access specific headers by their names
                            const ishandwrittenheader =
                                headers["x-is-hand-written"];

                            const mimeType = headers["x-mime-type"];
                            const engine_id = headers["x-engine_id"];
                            // You can access other response data like the response body
                            const docType = doctypeheader;
                            const is_hand_written =
                                parseInt(ishandwrittenheader) === 1
                                    ? true
                                    : false;
                            const file1 = new File([response.data], filename, {
                                type: mimeType,
                            });
                            console.log("doctype from favorite file", docType);
                            handleRetrieveFileStateCallback(
                                {
                                    isUploadFileOpen: false,
                                    isLoading: false,
                                    selectedFile: {
                                        file: file1,
                                        error: null,
                                    },
                                    batchFiles: [],
                                    batch_id: batchId,
                                    selectedDocType: docType,
                                    isHandWritten: is_hand_written,
                                    fileName: filename,
                                    selectedEngine:
                                        engine_id === null
                                            ? props.selectedEngine
                                            : engine_id,
                                },
                                false
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleRetrieveFileStateCallback(
                        {
                            isLoading: false,
                            selectedDocType: "",
                            selectedFile: "",
                            fileName: "",
                        },
                        true
                    );
                    console.error("Error:", error);
                    Swal.fire({
                        text: "Not able to Retrieve the favorite file, please try again",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        props.removeToken();
                    }
                });
        } catch (error) {
            //empty
        }
    };

    return (
        <Fragment>
            {favoriteFiles && favoriteFiles.length !== 0 ? (
                <CustomTable
                    ref={gridRef}
                    favdisplayval={favdisplayvals}
                    rowdata={favoriteFiles.map((element, index) => ({
                        ...element,
                        serial_id: index + 1,
                        localdatereccreate: new Date(
                            element.datereccreate
                        ).toLocaleString(),
                        doc_type_name: docTypes_ddl[props.module_name].filter(
                            (ele) => {
                                return ele.documentID === element.document_type;
                            }
                        ).length > 0 && docTypes_ddl[props.module_name].filter(
                            (ele) => {
                                return ele.documentID === element.document_type;
                            }
                        )[0]["documentName"],
                    }))}
                    defaultcoldefs={{ autoHeight: true }}
                    activeTab={props.activeTab}
                    columndefs={[
                        {
                            field: "serial_id",
                            headerName: "",
                            suppressSizeToFit: true,
                            headerClass: "text-center",
                            width: 50,
                            cellStyle: {
                                textAlign: "center",
                            },
                        },
                        {
                            field: "is_hand_written",
                            headerName: "",
                            suppressSizeToFit: true,
                            width: 50,
                            headerClass: "text-center",

                            cellRenderer: (params) => {
                                return (
                                    <Fragment>
                                        {params.value === "1" ? (
                                            <span
                                                className="material-symbols-outlined"
                                                title="Indicates handwritten document"
                                            >
                                                stylus_note
                                            </span>
                                        ) : (
                                            ""
                                        )}{" "}
                                        {params.data?.batch_id && (
                                            <span title="Batch of Files">
                                                <FolderIcon></FolderIcon>
                                            </span>
                                        )}
                                    </Fragment>
                                );
                            },
                        },
                        {
                            field: "engine_id",
                            headerName: "Engine",
                            suppressSizeToFit: true,
                            sortable: true,
                            width: 50,
                            cellStyle: {
                                textAlign: "center",
                            },
                            cellRenderer: (params) => {
                                //   console.log("params", params);
                                return (
                                    <p>
                                        {params.value !== null ? (
                                            params.value === 1 ? (
                                                <span
                                                    className="aicon icon-square-o"
                                                    title="Open AI Engine"
                                                ></span>
                                            ) : (
                                                <span
                                                    className="aicon icon-square-m"
                                                    title="LawBotica Engine"
                                                ></span>
                                            )
                                        ) : (
                                            ""
                                        )}{" "}
                                    </p>
                                );
                            },
                        },
                        {
                            field: "file_name",
                            headerName: "File name",
                            sortable: true,

                            resizable: true,
                            filter: "agTextColumnFilter",
                            cellRenderer: (params) => {
                                return (
                                    <>
                                        <a
                                            href="/"
                                            target="_blank"
                                            title={`Click to ${params.data.module_display_name}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                RetrieveFileFromFavorites(
                                                    params.value,
                                                    props.module,
                                                    params.data?.batch_id,
                                                    params.data?.is_batch,
                                                    props.handleRetrieveFileState
                                                );
                                            }}
                                        >
                                            {renderTruncatedText(
                                                params.value,
                                                21
                                            )}
                                        </a>{" "}
                                        <>
                                            <ViewFileInBrower
                                                key={params.rowIndex}
                                                token={props.token}
                                                removeToken={props.removeToken}
                                                fileinfo={{
                                                    filename:
                                                        params.data?.file_name,
                                                    moduleId:
                                                        params.data?.module_id,
                                                    batchId:
                                                        params.data?.batch_id,
                                                    isBatch:
                                                        params.data?.is_batch,
                                                }}
                                                handleFileState={
                                                    props.handleRetrieveFileState
                                                }
                                                handleFetchFavoriteFilesStateCallBack={
                                                    handleFetchFavoriteFilesStateCallBack
                                                }
                                                blobFilesList={
                                                    props.blobFilesList
                                                }
                                                handleUpdateBlobFilesState={
                                                    props.handleUpdateBlobFilesState
                                                }
                                            ></ViewFileInBrower>
                                        </>
                                    </>
                                );
                            },
                            cellStyle: {
                                // width:"fit-content"
                            },
                            suppressSizeToFit: false,
                        }, //document_type
                        {
                            field: "doc_type_name",
                            headerName: "Document type",
                            sortable: true,
                            resizable: true,
                            filter: "agTextColumnFilter",

                            cellStyle: {
                                // width:"fit-content"
                            },
                            suppressSizeToFit: false,
                        },
                        {
                            field: "localdatereccreate",
                            headerName: "Favorited",
                            sortable: true,
                            resizable: true,
                            filter: "agDateColumnFilter",
                            suppressSizeToFit: false,
                        },
                        {
                            field: "favorite_id",
                            headerName: "",
                            suppressSizeToFit: true,
                            width: 50,
                            cellStyle: {
                                textAlign: "center",
                            },
                            cellRenderer: (params) => {
                                return (
                                    <button
                                        title="Remove from Favorites"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            RemoveFavorite(
                                                params.data.file_name,
                                                props.module,
                                                params.data?.batch_id,
                                                props.handleRemoveFavoriteState
                                            );
                                        }}
                                    >
                                        <TrashCanIcon />
                                    </button>
                                );
                            },
                        },
                    ]}
                />
            ) : (
                <h2 className="popup_content">{msg}</h2>
            )}
        </Fragment>
    );
});

FavoritesComponent.displayName = "FavoritesComponent";
/*
FavoritesComponent.propTypes = {
    module: PropTypes.number,
    aciveTab: PropTypes.string,
    module_name: PropTypes.string,
    handleRemoveFavoriteState: PropTypes.Function,
    handleFavoriteFile: PropTypes.Function,
    handleFetchFavoriteFilesState: PropTypes.Function,
    handleRetrieveFileState: PropTypes.Function,
    favoriteFiles: PropTypes.array,
    token: PropTypes.string,
    removetoken: PropTypes.Function,
    selectedEngine: PropTypes.number,
    isBatchFile: PropTypes.bool,
    blobFilesList: PropTypes.array, //passing blob files list to push data on select
    handleUpdateBlobFilesState: PropTypes.Function,
};*/
export default FavoritesComponent;

/**
 * 
//Remove Favorites callback after a warning

 * @param {string} file_name 
 * @param {Int} module_id 
 * @param {int} batch_id 
 * @param {Function} callback 
 * @param {Function} stateCallback 
 */
export function handleWarnRemoveFavorite(
    file_name,
    module_id,
    batch_id,
    callback,
    stateCallback
) {
    Swal.fire({
        html: `<label>Remove <strong>${file_name}</strong> from favorites?</label>`,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonText: "Yes",
        confirmButtonTextColor: "#2eaadb",
    }).then((result) => {

        if (result.isConfirmed) {
            callback(file_name, module_id, batch_id, stateCallback); // Remove favorite callback
        }
    });
}

const ViewFileInBrower = (props) => {
    /**
     * setting prop to state to identify if the file type is a batch
     */

    const [isBatch, setIsBatch] = useState(props.fileinfo.isBatch);

    //Assigning FetchAllFavoritesStateCallback to state
    const [handleFetchFavoriteFilesStateCallBack] = useState(
        () => props.handleFetchFavoriteFilesStateCallBack
    );

    /**const [isLoading, setIsLoading] = useState(false);
    
     *
     */
    const getFile = useCallback(
        (fileInfo, openInNewTab = true) => {
            try {
                if (
                    !props.blobFilesList.some((val, idx) => {
                        return val?.name === fileInfo.filename;
                    })
                ) {
                    const formData = new FormData();
                    formData.append("fileName", fileInfo.filename);
                    formData.append("module_id", fileInfo.moduleId);
                    formData.append("batch_id", fileInfo.batchId);
                    formData.append("isBatch", fileInfo.isBatch);
                    setIsBatch(fileInfo.isBatch ? true : false);
                    const axiosInstance = getAxiosInstance(props.token);
                    axiosInstance
                        .post("/FetchFavoriteFile", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            crossDomain: true,
                            timeout: 300000,
                            responseType: "blob",
                        })
                        .then((response) => {
                            if (response.data.error_Msg) {
                            } else {
                                if (fileInfo.isBatch) {
                                    const headers = response.headers;

                                    console.log(headers);
                                    const zip = new JSZip();

                                    const zipBlob = response.data;
                                    let extractedFiles = [];

                                    zip.loadAsync(zipBlob).then(
                                        function (loadedZip) {
                                            console.log("loadedZip", loadedZip);
                                            var promises = Object.keys(
                                                loadedZip.files
                                            ).map((relativePath) => {
                                                let zipEntry =
                                                    loadedZip.files[
                                                    relativePath
                                                    ];
                                                console.log(
                                                    "zipEntry",
                                                    zipEntry
                                                );
                                                let mimeType = zipEntry.comment;
                                                let filename = zipEntry.name;
                                                return zipEntry
                                                    .async("blob")
                                                    .then(
                                                        function (contentBlob) {
                                                            contentBlob =
                                                                contentBlob.slice(
                                                                    0,
                                                                    contentBlob.size,
                                                                    mimeType
                                                                );
                                                            /* console.log(
                                                            "before Push:*******************",
                                                            contentBlob
                                                        );*/
                                                            extractedFiles.push(
                                                                new File(
                                                                    [
                                                                        contentBlob,
                                                                    ],
                                                                    filename,
                                                                    {
                                                                        type: mimeType,
                                                                    }
                                                                )
                                                            );
                                                        }
                                                    );
                                            });

                                            Promise.all(promises).then(
                                                function () {
                                                    //  setBatchFiles(extractedFiles);
                                                }
                                            );
                                            props.handleFileState(
                                                {
                                                    isPopupVisible: true,
                                                    isRenaming: true
                                                        ? false
                                                        : true,
                                                    popupActivebatchFiles:
                                                        extractedFiles,
                                                    popupActivebatchName:
                                                        fileInfo.filename,
                                                },
                                                false
                                            );
                                        }
                                    );
                                } else {
                                    const headers = response.headers;

                                    const mimeType = headers["x-mime-type"];
                                    const file1 = new File(
                                        [response.data],
                                        fileInfo.filename,
                                        {
                                            type: mimeType,
                                        }
                                    );
                                    let bloburl = URL.createObjectURL(file1);

                                    if (
                                        docxfileTypes.includes(mimeType) ||
                                        openInNewTab
                                    ) {
                                        window.open(bloburl, "_blank");
                                    } else {
                                        AddBlobToBlobList({
                                            blobFilesList: props.blobFilesList,
                                            blobobj: {
                                                name: fileInfo.filename,
                                                url: bloburl,
                                            },
                                            updatestatecallbackfn:
                                                props.handleUpdateBlobFilesState,
                                            closecallback: () => {
                                                console.info(
                                                    "close event triggered"
                                                );
                                            },
                                        });
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                            let status = error.response
                                ? error.response.status
                                : 200;
                            if (status === 401 || status === 422) {
                                props.removeToken();
                            }
                        });
                } else {
                    AddBlobToBlobList({
                        blobFilesList: props.blobFilesList,
                        blobobj: {
                            name: fileInfo.filename,
                            url: "",
                        },
                        updatestatecallbackfn: props.handleUpdateBlobFilesState,
                        closecallback: () => {
                            console.info("close event triggered");
                        },
                    });
                }
            } catch (e) {
                //error
            }
        },
        [props]
    );
    const getFileandOpenInNewWindow = (
        fileinfo,
        handleFetchFavoriteFilesStateCallBack
    ) => {
        handleFetchFavoriteFilesStateCallBack(
            { isLoading: true, loaderText: "downloading File..." },
            true
        );

        getFile(fileinfo);
        handleFetchFavoriteFilesStateCallBack(
            { isLoading: false, loaderText: "Analysis in progress..." },
            true
        );
    };

    /**
     *
     * @param {*} value
     * @param {*} fileinfo
     */
    const handleBatchPopUp = (value, fileinfo) => {
        // console.log("***** Batch Files", batchFiles);
        getFile(fileinfo);
    };

    return isBatch ? (
        <div
            style={{
                color: "#2eb1e3",
                cursor: "pointer",
                display: "inline-block",
            }}
            onClick={() => handleBatchPopUp(true, props.fileinfo)}
        >
            <ExternalLinkIcon></ExternalLinkIcon>
        </div>
    ) : (
        <div
            style={{
                color: "#2eb1e3",
                cursor: "pointer",
                display: "inline-block",
            }}
            onClick={() =>
                getFileandOpenInNewWindow(
                    props.fileinfo,
                    handleFetchFavoriteFilesStateCallBack
                )
            }
        >
            <ExternalLinkIcon></ExternalLinkIcon>
        </div>
    );
};