import { Resizable } from "re-resizable";
import React, { forwardRef, useEffect, useState } from "react";
import useSocket from "../../contexts/socket";
import toparrow from "../../images/toparrow.png";
import store from "../../redux/store";
import { getCurrentTime } from "./CustomAlerts/ToastAlert";
import { HandleHelpQuestionSComponent } from "./HelpQuestionsComponent";

const ChatComponent = forwardRef(({ uploadFileId, ...props }, _ref) => {
    /***chat Types Allowed */
    //const fileChatTypes = ["review", "docchat"];
    //const allowedChatTypes = ["internal", ...fileChatTypes];


    const [token, setToken] = useState(props.token);
    /**
     *
     * Resizable Div's initial Width and height state data
     */
    const [resizableDivState, setResizableDivState] = useState({
        width: "100%",
        height: 200,
    });

    /**
     * BottonPanel States
     */
    const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(
        props.isBottomPanelOpen
    );

    const [tabID_, setTabId] = useState(props.tabID);
    const [index_path_, setIndexPath] = useState(props.index_path);
    const [index_list_, setIndexList] = useState(props.index_list);
    const [source, setSource] = useState(props.source);
    const [topK, settopK] = useState(props.topK);
    const [isCannedDdl, setIsCannedDDl] = useState(props.isCannedDdl);
    const [socket] = useState(useSocket());
    /**states important for socket communication */
    const [chatType, setChatType] = useState(props.chatType);
    const [docType, setSelectedDocType] = useState(props.selectedDocType);
    const [engine, setSelectedEngine] = useState(props.selectedEngine);
    const [hwFlag, setHWFlag] = useState(props.hwFlag);
    const [uploadFileId_, setUploadFileId] = useState(uploadFileId);
    const [isBatchFile, setIsBatchFile] = useState(props.isBatchFile);
    const [activeTab, setActiveTab] = useState(props.activeTab);
    const [fileName, setIsFileName] = useState(props.fileName);
    const [isLoading, setIsLoading] = useState(props.isLoading);
    const [selectedCanned_Ques, setSelectedCanned_Ques] = useState(
        props.selectedCanned_Ques
    );
    const [promptDisplayed, setPromptDisplayed] = useState(
        props.promptDisplayed
    );

    const [displayPromptsBox, setDisplayPromptsBox] = useState(false);


    useEffect(() => {
        setTabId(props.tabID);
    }, [props.tabID]);
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);
    useEffect(() => {
        settopK(props.topK);
    }, [props.topK]);

    useEffect(() => {
        setIndexList(props.index_list);
    }, [props.index_list]);
    useEffect(() => {
        setIndexPath(props.index_path);
    }, [props.index_path]);
    useEffect(() => {
        setToken(props.token);
    }, [props.token]);
    useEffect(() => {
        setSource(props.source);
    }, [props.source]);
    useEffect(() => {
        setIsFileName(props.fileName);
    }, [props.fileName]);
    useEffect(() => {
        setPromptDisplayed(props.promptDisplayed);
    }, [props.promptDisplayed]);
    useEffect(() => {
        setSelectedCanned_Ques(props.selectedCanned_Ques);
    }, [props.selectedCanned_Ques]);
    const [inputValue, setInputValue] = useState(
        props.inputValue ? props.inputValue : ""
    );

    const [doc_help_questions_v, setDocHelpQuestions] = useState(props.doc_help_questions);


    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        setChatType(props.chatType);
    }, [props.chatType]);
    useEffect(() => {
        setDocHelpQuestions(props.doc_help_questions);
    }, [props.doc_help_questions])
    useEffect(() => {
        setSelectedDocType(props.selectedDocType);
    }, [props.selectedDocType]);
    useEffect(() => {
        setSelectedEngine(props.selectedEngine);
    }, [props.selectedEngine]);
    useEffect(() => {
        setHWFlag(props.hwFlag);
    }, [props.hwFlag]);
    useEffect(() => {
        setUploadFileId(uploadFileId);
    }, [uploadFileId]);
    useEffect(() => {
        setIsBatchFile(props.isBatchFile);
    }, [props.isBatchFile]);
    useEffect(() => {
        setIsBottomPanelOpen(props.isBottomPanelOpen);
    }, [props.isBottomPanelOpen]);

    useEffect(() => {
        setActiveTab(props.activeTab);
    }, [props.activeTab]);

    useEffect(() => {
        setIsCannedDDl(props.isCannedDdl);
    }, [props.isCannedDdl]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    //SetInputValue state
    useEffect(() => {
        setInputValue(props.inputValue);
    }, [props.inputValue]);

    useEffect(() => {
        setIsDisabled(
            docType === "" || chatType === "docchat" || chatType === "review"
                ? uploadFileId_ === undefined
                : false
        );
    }, [docType, chatType, uploadFileId_]);

    //ws request being sent to api
    const handleOnKeyPress = async(e) => {
        if (e.key === "Enter" && inputValue !== "") {
            // props.setReqStartTime(new Date().getTime());
            e.preventDefault();
            // setStartTime(new Date().getTime());
            let ques = {
                msg: { map: inputValue, reduce: inputValue, short: inputValue },
                doctype: docType,
                engineType: engine,
                srcname: source,
                topk: topK,
                token: token,
                uploadFileId: uploadFileId_,
                hwFlag: hwFlag,
                fileName: fileName,
                index_path: index_path_,
                index_list: index_list_,
                isBatchFile: isBatchFile ? isBatchFile : false,
                workspace_id: props.workspace_id,
                workspace_item_id: props.workspace_item_id,
                reqStartTime: new Date().getTime(),
                tabId: tabID_,
            };
            if (props.handleCannedQuestion) {
                props.handleCannedQuestion(inputValue);
            }
            props.setReqStartTime(new Date().getTime());

            if (socket !== undefined) {
                switch (chatType) {
                case "internal":
                    console.log(socket);
                    socket.emit("repo_chat", ques);
                    break;
                case "docchat":
                    // ques.reqStartTime=new Date().toLocaleString()
                    socket.emit("doc_chat", ques);
                    break;
                case "review":
                    socket.emit("review_doc_chat", ques);
                    break;
                case "summarize":
                    ques.workspace_id =
                        ques.workspace_id === 0
                        ? undefined
                        : ques.workspace_id;
                    ques.workspace_item_id =
                        ques.workspace_item_id === 0
                        ? undefined
                        : ques.workspace_item_id;
                    socket.emit("summarize_chat", ques); 
                    break;
                case "chatWithWorkspace":
                    // let ws_items = await props?.get_ws_items();
                    const userState = store.getState().login.logInState;
                    // ques["workspace_items"]=ws_items
                    ques["username"]=userState?.user_name
                    ques["chat_key"]=props?.chat_key
                    ques["reqStartDateTime"]= getCurrentTime()
                    socket.emit("chat_within_workspace", ques);
                    break;
                default:
                        console.error(`doc chat type ${chatType} not allowed`);
                    break;
                }
                console.log("ws-sending", ques);
                props.setLoaderStatus(true);
            }
        }
    };

    /**
     * handleCannedQuestion
     * @param {String} question
     */
    const handleCannedQuestion = async(question) => {
        props.setReqStartTime(new Date().getTime());
        let ques = {
            msg: question,
            doctype: docType,
            srcname: props.source,
            engineType: engine,
            topk: props.topk,
            token: token,
            uploadFileId: uploadFileId_,
            hwFlag: hwFlag,
            fileName: fileName,
            index_path: index_path_,
            index_list: index_list_,
            isBatchFile: isBatchFile ? isBatchFile : false,
            workspace_id: props.workspace_id,
            workspace_item_id: props.workspace_item_id,
            reqStartTime: new Date().getTime(),
            reqStartDateTime: new Date().toLocaleString(),
            tabId: tabID_,
        };
        
        if (socket !== undefined) {
            switch (chatType) {
            case "internal":
                console.log(socket);
                socket.emit("repo_chat", ques);
                break;
            case "docchat":
                // ques.reqStartTime=new Date().toLocaleString()
                socket.emit("doc_chat", ques);
                break;
            case "review":
                socket.emit("review_doc_chat", ques);
                break;
            case "summarize":
                ques.workspace_id =
                    ques.workspace_id === 0 ? undefined : ques.workspace_id;
                ques.workspace_item_id =
                    ques.workspace_item_id === 0
                    ? undefined
                    : ques.workspace_item_id;
                socket.emit("summarize_chat", ques);
                break;
            case "chatWithWorkspace":
                // let ws_items =await props?.get_ws_items();
                const userState = store.getState().login.logInState;
                // if(ws_items && ws_items?.length>0){
                    ques["chat_key"]=props?.chat_key
                    // ques["workspace_items"]=ws_items
                    ques["username"]=userState?.user_name
                    ques["reqStartDateTime"]= getCurrentTime()
                    socket.emit("chat_within_workspace", ques);
                // }
                break;
            default:
                    console.error(`doc chat type ${chatType} not allowed`);
                break;
            }
            props.setLoaderStatus(true);
        } else {
            //empty
        }
    };
    

    const handlehelpquestionsChange = (value) => {
        console.log(value, " help questions function");
        if (isDisabled || isLoading) {
            return;
        }
        if (props.handleCannedQuestion) {
            props.handleCannedQuestion(value);
        }
        handleCannedQuestion(value);
    };


    return (
        <>
          {!isDisabled && (
              <Resizable
                id="botbar"
                className={`${
                        !isBottomPanelOpen
                            ? "rytpanelbotpart"
                            : `rytpanelbotpartBottom ${
                                chatType === "chatWithWorkspace"
                                    ? "chat-box-ht"
                                    : ""
                            }`
                    } expandable-bar-box-shadow expandable-border-top`}
                handleClasses={{
                    top:
                    !isBottomPanelOpen || isCannedDdl
                        ? "pointer-events-none"
                        : "", // written with consciousness
                    bottom: "pointer-events-none",
                    left: "pointer-events-none",
                    topRight: "pointer-events-none",
                    bottomRight: "pointer-events-none",
                    bottomLeft: "pointer-events-none",
                    topLeft: "pointer-events-none",
                }}
                style={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    minHeight: 100,
                }}
                size={{
                    width: resizableDivState.width,
                    height: !isBottomPanelOpen
                        ? "50px"
                        : resizableDivState.height,
                }}
                onResizeStop={(e, direction, ref, d) => {
                    setResizableDivState({
                        width: resizableDivState.width,
                        height: resizableDivState.height + d.height,
                    });
                }}
                defaultSize={{
                    width: "100%",
                    height: !isBottomPanelOpen
                        ? "50px"
                        : resizableDivState.height,
                }}
              >
                {/**tab area */}
                <div className="tabarea chatbotbar">
                  <div className="tabsection">
                    <section className="accordion-tabs">
                      {!props.isSummary && (
                          <button
                            className={`accordion-tab ${
                                            activeTab === 0
                                                ? "accordion-active"
                                                : ""
                                        }`}
                            onClick={() => props.toggleTab(0)}
                          >
                            chat
                            <sup></sup>
                          </button>
                      )}
                    </section>
                    {doc_help_questions_v &&
                     doc_help_questions_v?.length > 0 && (
                         <>
                           <HandleHelpQuestionSComponent doc_help_questions_v={doc_help_questions_v} 
                                                         handlehelpquestionsChangecallback={handlehelpquestionsChange}
                           />
                         </>
                     )}
                  </div>

                  <div
                    className="updownsecfunc"
                    style={{ display: "flex" }}
                  >
                    {selectedCanned_Ques && (
                        <div
                          className={
                              !displayPromptsBox
                                  ? "plussquare"
                                  : "minusicon"
                          }
                          onClick={() => {
                              setDisplayPromptsBox(
                                  !displayPromptsBox
                              );
                          }}
                          onMouseOver={() => {
                              setDisplayPromptsBox(true);
                          }}
                          onMouseOut={() => {
                              setDisplayPromptsBox(false);
                          }}
                        >
                          Prompts
                          {displayPromptsBox &&
                           selectedCanned_Ques && (
                               <div className="promptcontsec">
                                 {selectedCanned_Ques.map(
                                     (item, i) => (
                                         <p
                                           key={i}
                                           onClick={() => {
                                               if (
                                                   isDisabled ||
                                                       isLoading
                                               ) {
                                                   return;
                                               }
                                               if (
                                                   props.handleCannedQuestion
                                               ) {
                                                   props.handleCannedQuestion(
                                                       item
                                                   );
                                               }

                                               handleCannedQuestion(
                                                   {
                                                       ...item.value,
                                                       short: item.id,
                                                   }
                                               );
                                           }}
                                         >
                                           {item.id}
                                         </p>
                                     )
                                 )}
                               </div>
                           )}
                        </div>
                    )}
                    <div>
                      <img
                        src={toparrow}
                        alt=""
                        className={`expand_collapse ${
                                        !isBottomPanelOpen
                                            ? "collapse"
                                            : "expand"
                                    }`}
                        onClick={() =>
                            props.handleBottomPanel(
                                !isBottomPanelOpen ? true : false
                            )
                        }
                      />
                    </div>
                  </div>
                </div>
                <section className="accordion-content">
                  <article
                    className={`accordion-item ${
                                activeTab === 0 ? "accordion-active" : ""
                            }`}
                  >
                    <div className="accordion-item__container">
                      <form>
                        <textarea
                          className="messagefield"
                          placeholder={
                              promptDisplayed?.map
                                  ? promptDisplayed?.map
                                  : promptDisplayed
                          }
                          value={inputValue}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                              handleOnKeyPress(e);
                          }}
                          ref={props.chat_ref}
                          disabled={isDisabled || isLoading}
                        ></textarea>
                        <button
                          type="button"
                          name="button"
                          value=""
                          className="sendbut"
                          onClick={() => {
                              if (props.handleCannedQuestion) {
                                  props.handleCannedQuestion(
                                      inputValue
                                  );
                              }
                              handleCannedQuestion(inputValue);
                          }}
                          disabled={isDisabled || isLoading}
                        />
                      </form>
                    </div>
                  </article>
                </section>
              </Resizable>
          )}
        </>
    );
});

ChatComponent.displayName = "ChatComponent";
export default React.memo(ChatComponent);
