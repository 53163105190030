import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        console.error(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className="custom_error_boundary">
                    <pre>{`
                           <div>
                            <h1>Error Occured</h1>
                            <p>Oops! Something went wrong on our end. Issue has been logged. <br/>
                            Please navigate to the dashboard or another module.
                            </p>
                           </div>`}</pre>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
