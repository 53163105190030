const initialState = {
    errorState: {
        hasError:false
    }, // Initial state for the dashBoard
    // Add other initial states as needed for different components
};

export const errorReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_ERROR_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, errorState: action.payload.data };
        // Add other cases for different components' states
        case 'RESET_ERROR_STATE':
            return initialState;
        default:
        return state;
    }
};
