import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
export const handleCustomConfirm = (params, htmlStr, callback) => {
    Swal.fire({
        html: htmlStr,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonText: "Yes",
        focusCancel: true,
        confirmButtonTextColor: "#2eaadb",
    }).then((result) => {
        if (result.isConfirmed) {
            callback(params);
        }
    });
};

export const handleCustomPrompt = ({
    confirm_button_text,
    cancel_button_text,
    callback,
    callbackparamsObj,
}) => {
    Swal.fire({
        input: "textarea",
        inputLabel: "Please enter Reason",
        inputPlaceholder: "Type your reason here...",
        inputAttributes: {
            "aria-label": "Type your reason here...",
        },

        confirmButtonText: confirm_button_text,
        cancelButtonText: cancel_button_text,
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonTextColor: "#2eaadb",
        showCancelButton: true,
        preConfirm: (value) => {
            console.log("value", value);
            if (!value) {
                Swal.showValidationMessage(" Reason for rejection is required");
            }
        },
    }).then((result) => {
        console.log(result, callbackparamsObj);
        if (result.isConfirmed) {
            callbackparamsObj["reason"] = result.value;
            callback(callbackparamsObj);
        }
    });
};



export const handleLockUserOrOrganization = (
    axiosInstance,
    loadercallback,
    id,
    lockedval,
    differentiator,
    closepopupCallback
) => {
    loadercallback(true);
    let formData = new FormData();
    formData.append("user_id__organization_id", id);
    formData.append("islocked", lockedval);
    formData.append("identifier", differentiator);
    console.log("formData=>", formData);
    axiosInstance
        .post("/admin/lockOrganizationOrUser", formData, {
            crossDomain: true,
            timeout: 300000,
        })
        .then((response) => {
            console.log(response, "lock Organization response");
            if (response.data.length > 0) {
                switch (response.data[0]["status_code"]) {
                    case 202:
                        toast.success("lock status updated successfully!");
                        closepopupCallback();
                        break;
                    case 208:
                        toast.info("request to change status made already!");
                        break;
                    case 500:
                    case 404:
                        toast.error("something went wrong!");
                        break;
                    default:
                        toast.error("something went wrong!");
                        break;
                }
            } else {
                toast.error("something went wrong!");
            }
        })
        .catch((error) => {
            toast.error("something went wrong!");
            console.error(`Something went wrong ${error}`);
        })
        .finally(() => {
            loadercallback(false);
        });
};
