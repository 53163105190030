import { Fragment } from "react";
import { useEffect, useState } from "react";
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid";
import { convertUtcToEst } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { getUserDetails } from "../Registration/useToken.jsx";
import { FolderIcon, TrashCanIcon } from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import {
  updateCaseAnalysisState,
  updateChatWithDocState,
  updateReviewState,
  updateSummaryState,
} from "../../../redux/actions.js";
import store from "../../../redux/store.js";
import { useNavigate } from "react-router-dom";
import docTypes_ddl from "../../../dropDowns/docTypes.json";
import moduleNames from "../../../dropDowns/moduleNames.json";

const WorkspaceFilter = (props) => {
  const { user_id } = getUserDetails();
  const [sortedItems, setSortedItems] = useState(
    props?.value?.data?.workspace_items?.length > 0
      ? [...props?.value.data?.workspace_items].sort((a, b) => b.id - a.id)
      : []
  );
  const [workspaces, setWorkspaces] = useState([]);
  const [users,setUsers] = useState(props.users);
  const [existDocTypes, setExistDocTypes] = useState(props?.existDocTypes);
  const [userId] = useState(user_id);
  const [filterTex, setFilterText] = useState("");
  const nav = useNavigate();

  useEffect(() => {
    setExistDocTypes(props?.existDocTypes);
    setUsers(props?.users)
  }, [props?.existDocTypes,props?.users]);

  useEffect(() => {
    if (sortedItems?.length > 0) {
      const updatedItems = sortedItems?.map((item) => {
        return {
          ...item,
          convertedTime: convertUtcToEst(item.modified_at),
          displayDocType: [
            ...docTypes_ddl.SummarizeDocType,
            ...docTypes_ddl.ReviewdocType,
            ...docTypes_ddl.ChatwithDocType,
            ...docTypes_ddl.CaseAnalysis,
          ].find((x) => x.documentID === item?.document_type)?.documentName,
        };
      });
      setWorkspaces(updatedItems);
    } else {
      setWorkspaces([]);
    }
  }, [sortedItems]);

  useEffect(() => {
    if (props?.value?.data?.workspace_items?.length !== sortedItems?.length) {
      setSortedItems(
        props.value.data?.workspace_items?.length > 0
          ? [...props.value.data?.workspace_items].sort((a, b) => b.id - a.id)
          : props.value.data?.workspace_items
      );
    }
  }, [props?.value.data?.workspace_items, sortedItems?.length]);

  const RedirectToReview = (data) => {
    switch (data?.module_id) {
      case 1:
        let currentState = store.getState().review.reviewState;
        store.dispatch(
          updateReviewState({
            ...currentState,
            workspace_item_id: data.id,
            module_id: data.module_id,
            isfromWorkspce: true,
            users: users,
            workspace_id: props?.value.workspace_id,
            workspace_name: props?.value.workspace_name,
            ws_count:0,
          })
        );
        nav("/Review");
        break;
      case 2:
        let summaryState = store.getState().summary.summarizeState;

        store.dispatch(
            updateSummaryState({
                ...summaryState,
                workspace_item_id: data.id,
                module_id: data.module_id,
                workspace_id: props?.value?.workspace_id,
                workspace_name: props?.value?.workspace_name,
                ws_count: 0,
            })
        );
        nav("/Summarize");
        break;
      case 3:
        let chatwithDocState = store.getState().chatWithDoc.chatWithDocState;
        store.dispatch(
            updateChatWithDocState({
                ...chatwithDocState,
                workspace_item_id: data.id,
                module_id: 3,
                workspace_id: props?.value?.workspace_id,
                workspace_name: props?.value?.workspace_name,
                ws_count: 0,
            })
        );
        nav("/ChatWithDocument");
        break;
      case 6:
        let caseAnalysisState = store.getState().caseAnalysis.caseAnalysisState;
        store.dispatch(
            updateCaseAnalysisState({
                ...caseAnalysisState,
                workspace_item_id: data.id,
                module_id: 6,
                workspace_id: props?.value?.workspace_id,
                workspace_name: props?.value?.workspace_name,
                ws_count: 0,
            })
        );
        nav("/Case_Analysis");
        break;
      default:
        nav("/Workspaces");
    }
  };

  return (
    <Fragment>
      <div className="filter-area">
        <div className="filter-activity d-flex">
          <h4 className="space">Quick Filter by Doc Type: </h4>
          {filterTex && filterTex?.docId && filterTex?.docName ? (
            <div className="d-flex">
              <p className="text-link-filter space">
                {filterTex?.docName +
                  " (" +
                  workspaces.filter((x) => x.document_type === filterTex?.docId)
                    .length +
                  ")"}
              </p>
              <p className="space">
                {" "}
                <p style={{ fontSize: "1rem" }}>&#183;</p>
              </p>
              <h4
                className="clear-filter space"
                onClick={() => {
                  setFilterText({});
                }}
              >
                Clear all filters{" "}
              </h4>
            </div>
          ) : (
            existDocTypes.map((i, j) => { 
              return   <div className=" d-flex">
                  <p
                    className="text-link-filter space"
                    onClick={() =>
                      setFilterText({
                        docName: i?.documentName,
                        docId: i?.documentID,
                      })
                    }
                  >
                    {i?.documentName + " (" + workspaces.filter((x) => x.document_type === i.documentID).length+ ")"}
                  </p>
                  <p className="space">
                    {existDocTypes?.length - 1 !== j ? (
                      <p style={{ fontSize: "1rem" }}>&#183;</p>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
            })
          )}
        </div>
        <div className="ag-theme-quartz" style={{ height: 560, width: "100%" }}>
          <CustomTable
            defaultcoldefs={{
              // flex: 1,
              resizable: true,
              editable: false,
              autoHeight: true,
              sortable: true,
            }}
            columndefs={[
              {
                            field: "is_hand_written",
                            headerName: "",
                            suppressSizeToFit: true,
                            width: 50,
                            headerClass: "text-center",

                            cellRenderer: (params) => {
                                return (
                                    <Fragment>
                                        {params.data?.docs?.length>1 && (
                                            <span title="Batch of Files">
                                                <FolderIcon></FolderIcon>
                                            </span>
                                        )}
                                    </Fragment>
                                );
                            },
                        },
              {
                headerName: "Name",
                field: "batchName",
                cellRenderer: (params) => { 
                  return (
                    <p
                      className="text-link"
                      onClick={() => RedirectToReview(params.data)}
                    >
                      {params.data?.batchName ? params.data?.batchName : params.data?.document_name}
                    </p>
                  );
                },
                sortable: true,
                resizable: true,
                supressSizeToFit: true,
                filter: true,
                // tooltipComponent: "customToolTip(params.data?.batchName, params.data?.document_name)",
                tooltipValueGetter: (params) => `${params.data?.batchName ? params.data?.batchName : params.data?.document_name}`,
                headerTooltip: "Document Name",
              },
              {
                headerName: "Document Type",
                field: "displayDocType",
                sortable: true,
                sort: "asc",
                resizable: true,
                supressSizeToFit: true,
                filter: true,
                tooltipField: "displayDocType",
                headerTooltip: "Document Type",
              },
              {
                headerName: "Module",
                field: "module_id",
                cellRenderer: (params) => {
                  return (
                    <p>
                      {
                        moduleNames?.find(
                          (x) => x.Id === params.data?.module_id
                        )?.loadingName
                      }
                    </p>
                  );
                },
                sortable: true,
                resizable: true,
                supressSizeToFit: true,
                filter: true,
                tooltipField: "module_id",
                headerTooltip: "Module",
              },
              {
                sortable: true,
                filter: true,
                supressSizeToFit: true,
                field: "owner",
                cellRenderer: (params) => {
                  return params.data.created_by === parseInt(userId)
                    ? "You"
                    : users?.find((x) => x.user_id === params.data.created_by)
                        ?.full_name;
                },
                headerName: "Owner",
                resizable: true,
                tooltipField: "owner",
                headerTooltip: "Owner",
              },
              {
                sortable: true,
                supressSizeToFit: true,
                filter: true,
                field: "convertedTime",
                // cellRenderer: (params) => { return convertUtcToEst(params.data.modified_at) },
                headerName: "Last Modified On",
                resizable: true,
                tooltipField: "convertedTime",
                headerTooltip: "Last Modified On",
              },
              {
                field: "delete",
                headerName: "",
                suppressSizeToFit: true,
                width: 50,
                cellStyle: {
                  textAlign: "center",
                },
                tooltipField: "Delete from workspace",
                headerTooltip: "Delete from workspace",
                cellRenderer: (params) => {
                  return parseInt(userId) === params.data?.created_by ? (
                    <button
                      title="Delete from workspace"
                      onClick={(e) => {
                        props.DeleteWorkspaceItem(params.data);
                      }}
                    >
                      <TrashCanIcon />
                    </button>
                  ) : (
                    ""
                  );
                },
              },
            ]}
            rowdata={workspaces?.length > 0 ? workspaces : []}
            activeTab={"01"}
            pageSize={20}
            noItemsMessg={"No Docs"}
            selectedFilter={filterTex?.docName}
            fieldName={"displayDocType"}
          ></CustomTable>
        </div>
      </div>
    </Fragment>
  );
};
export default WorkspaceFilter;
