import HelperMessages from "../../Helpers/HelperMessages";

export const initialState = {
    chatWithDocState: {
        isBottomPanelOpen: true,
        selectedDocType: "",
        selectedEngine: 2,
        selectedFile: "",
        prevSelectedFile: "",
        isSocketRequestProgress: false,
        chatResult: "",
        isUploadFileOpen: true,
        promptDisplayed: HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        activeTab: 0,
        isLoading: false,
        loaderText: "Analysis in progress...",
        fileName: "",
        chatTimer: "",
        Chats: {},
        inputValue: "",
        selectedCanned_Ques: [],
        unique_id: "",
        favoriteFiles: [],
        prevResponse: "",
        prevQuestion: "",
        summary: {},
        unique_ids_list: [],
        isHandWritten: false,
        historyList: [],
        batchFiles: [],
        invalidFiles: [],
        isRenaming: false,
        reqStartTime: new Date().getTime(),
        batchName: "",
        batchId: null,
        blobFilesList: [],
        popupActivebatchFiles: [],
        popupActivebatchName: "",
        isDocumentOpen: true,
        favdisplayval: false,
        hisdisplayval: false,
        isExpanded: true,
        index_list: [],
        openCreateWorkSpacePopUp: false,
        baseContent: "",
        chatResponseTimer: "",
        chatResponse: "",
        isIndexCreated: false,
        disableAddToWorkspace: false,
        workspace_id: 0,
        workspace_item_id: 0,
        workspace_name: "",
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        ws_shared_users: [],
        addToAnotherWorkspace: false,
        moveToAnotherWorkspace: false,
        oldBatchName: "",
        doc_help_questions: [],
        isFromFavorites: false,
        isFromHistory: false,
        closeKebabMenu: false,
        ws_count: 0,
    },
};
export const ChatWithDocsInitialState = initialState.chatWithDocState;
export const chatWithDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CHATWITHDOC_STATE":
      // console.log("Redux payload",action.payload.data)
      return { ...state, chatWithDocState: action.payload.data };
    // Add other cases for different components' states
    case "RESET_CHATWITHDOC_STATE":
      return initialState;
    default:
      return state;
  }
};
