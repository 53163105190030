import React, { Component, Fragment, createRef } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { isEqual } from "lodash";
import { socket } from "../../contexts/socket.js";
import legal_Doc_ddl from "../../dropDowns/docTypes.json";
import docTypes_ddl from "../../dropDowns/documentReviewTypes.json";
import { setTitle } from "../../js/setTitle.js";
import { updateDashBoardState, updateReviewState } from "../../redux/actions";
import getAxiosInstance, {
  Createaccesslog,
} from "../SupportiveComponents/AxiosRequest";
import { convertUtcToLocal } from "../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import {
  ContentReviewSection,
  ContentReviewSectionComponent,
  TitleOfComponent,
} from "../SupportiveComponents/CustomContentOperations/CustomContentViewer.jsx";
import ErrorBoundary from "../SupportiveComponents/CustomErrorBoundary.jsx";
import {
  CustomDocViewer,
  CustomExpandableDocComponent,
} from "../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import FavoritesComponent, {
  handleWarnRemoveFavorite,
} from "../SupportiveComponents/FavoriteComponent";
import CustomWindowPopupComponent from "../SupportiveComponents/FavouritesPopupContent.jsx";
import FileProcessing from "../SupportiveComponents/FileProcessing";
import { HistoryComponent } from "../SupportiveComponents/HistoryComponent";
import { CustomHistoryFavoriteLinks } from "../SupportiveComponents/HistoryandFavoritesLinks.jsx";
import //LoadingSpinner,
{ LoadingSpinnerSticky } from "../SupportiveComponents/LoadingSpinner";
import {
    HandleCancelRequestButtonComponent,
  HandleDocTypeChangeComponent,
  HandleEngineComponent,
  HandleFileNameComponent,
  HandleNewButtonComponent,
  HandleRefreshButtonComponent,
  HandwrittenComponent,
} from "../SupportiveComponents/ModuleHelpers.jsx";
import { LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT } from "../../js/constants.js";
import HelperMessages from "../../Helpers/HelperMessages.js";
import { ReviewInitialState, reviewReducer } from "../../redux/Reducers/reviewReducer.js";
import ChatComponent from "../SupportiveComponents/ChatComponent.jsx";
import ShowAllWorkspacePopUp from "../SupportiveComponents/PopUps/ShowAllSelectedWorkspacePopUp.js";
import WorkSpaceListPopUp from "../SupportiveComponents/PopUps/WorkspaceListPopUp.jsx";
import store from "../../redux/store.js";
class ReviewComponent extends Component {
    state = {
        isBottomPanelOpen: false,
        selectedDocType: "",
        selectedEngine: 1,
        selectedFile: "",
        selectedCanned_Ques: [],
        reviewResult: "",
        isUploadFileOpen: false,
        CannedQuestionsList: [],
        promptDisplayed: HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        isLoading: false,
        isSocketRequestProgress: false,
        loaderText: "",
        fileName: "",
        reviewTimer: 0,
        favoriteFiles: [],
        activeTab: 0,
        isHandWritten: false,
        req_id: "",
        historyList: [],
        uploadFileId: undefined,
        fromDashBoard: false,
        fromDashBoardLinkType: "",
        reqStartTime: new Date().getTime(),
        blobFilesList: [], // List used to open doc in the bottom bar
        docActiveTab: "", // state used for maintaining doc active tab
        isDocumentOpen: false, // state used to expand or collapse document tab
        favdisplayval: false, 
        hisdisplayval: false,
        isContentExpanded: true,
        inputValue: "",
        openCreateWorkSpacePopUp: false,
        oldMessages: [],
        disableAddToWorkspace: false,
        workspace_id: 0,
        workspace_item_id: 0,
        workspace_name: "",
        ws_count:0,
        module_id: 1,
        isFromWorkspace: true,
        index_path: undefined,
        isIndexCreated: false,
        reviewTime: "",
        review_by_name: "",
        isSharedWorkspace: false,
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        ws_shared_users: [],
        doc_help_questions: [],
        addToAnotherWorkspace:false,
        moveToAnotherWorkspace:false,
        closeKebabMenu:false
    };
    module_id = legal_Doc_ddl.Modules["ReviewdocType"].moduleId;
    module_name = legal_Doc_ddl.Modules["ReviewdocType"].moduleName;
    HistoryComponentRef = React.createRef();
    FavoriteComponentRef = React.createRef();
    /**
     *componentDidMount: Lifecycle method which triggers when components html is built
     */
    componentDidMount() {
        let dashBoardState = this.props.dashBoardState;
        if (dashBoardState && dashBoardState.source) {
            this.removeWorkspaceDetails();
            this.handleLinkFromDashBoard(dashBoardState);
        } else {
            console.log(this.props.reviewState, "<=== Before ")
            this.setState({...store.getState().review.reviewState, fromDashBoard: false }, () => {
                this.props.updateReviewState(this.state);
            });
            console.log(this.props.reviewState);
            this.getMessages();
        }
        setTitle("Review");
        this?.props?.setPosition({document:this.state?.isDocumentOpen, chatComponent:this.state.isBottomPanelOpen})
        this.scrollToBottom();
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevProps.reviewState, this.props.reviewState) ||
            !isEqual()
        ) {
            // State has changed, trigger re-render
            this.setState({ ...this.props.reviewState });
            this.scrollToBottom();

        }
    }

    scrollToBottom = () => {
        this?.messagesEnd?.scrollIntoView({ behavior: "smooth" });
    };
    handleToggleDocumentViewer = (val) => {
        this.setState({ isDocumentOpen: val }, () => {
            this.props.updateReviewState(this.state);
        });
        this?.props?.setPosition({document:val, chatComponent : this.state.isBottomPanelOpen})
    };

    handleUpdateHistoryList = (hlist) => {
        this.setState({ historyList: hlist }, () => {
            this.props.updateReviewState(this.state);
        });
    };
    /**
     * handleBottomPanel => updates the state of the Bottompanel i.e Chat section
     * @param {Boolean} isOpen
     */
    handleBottomPanel = (isOpen) => {
        this.setState(
            {
                isBottomPanelOpen: isOpen,
            },
            () => {
                this.props.updateReviewState(this.state);
                this?.props?.setPosition({document:this.state?.isDocumentOpen, chatComponent:isOpen})
            }
        );
    };
    updateHistoryFilesState = (historyFiles) => {
        this.setState({ historyFiles: historyFiles }, () => {
            this.props.updateReviewState(this.state);
        });
    };

    /**
     * toggleTab updates the state of the active tab of bottom panel
     * @param {Number} tabId
     */
    toggleTab = (tabId) => {
        this.setState({ activeTab: tabId }, () => {
            this.props.updateReviewState(this.state);
            if (tabId === 1) {
                this.chatComponentRef.current
                    .getFavComponentRef()
                    .current.gridRef.current?.onFirstDataRendered();
            }
        });
        this.handleBottomPanel(true);
    };

    /**
     * toggleDocTab updates the state of the active tab of documents
     * @param {*} tabId
     */
    toggleDocTab = (tabId) => {
        this.setState({ docActiveTab: tabId }, () => {
            this.props.updateReviewState(this.state);
        });
    };

    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.chatComponentRef = createRef();
        this.secondDivRef = createRef();
        this.state = props.reviewState;
    }

    /**
     * handleLinkFromDashBoard updates state of the module when navigated from dashboard
     * @param {Object} dashBoardState
     */
    handleLinkFromDashBoard(dashBoardState) {
        this.setState(
            {
                reviewResult: "",
                CannedQuestionsList: [],
                fileName: "",
                workspace_id: 0,
                workspace_item_id: 0,
                module_id: 1,
                isAddDoc: false,
                workspace_name: "",
                selectedFile: {},
                disableAddToWorkspace: false,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );

        let stateObj = {};
        if (dashBoardState.linkType.length > 0) {
            stateObj = { fromDashBoardLinkType: dashBoardState.linkType };
        } else {
            stateObj = {
                fileName: dashBoardState.fileName,
                selectedDocType: dashBoardState?.selectedDocType.trim(),
                selectedEngine: dashBoardState?.selectedEngine
                    ? dashBoardState.selectedEngine
                    : 1,
            };
        }
        switch (dashBoardState.source) {
            case "favorites":
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        favdisplayval:
                            stateObj.hasOwnProperty("fromDashBoardLinkType") > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                    },
                    () => {
                        dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateReviewState(this.state);

                        if (this.state.fileName) {
                            this.FavoriteComponentRef.current.RetrieveFileFromFavorites(
                                this.state.fileName,
                                this.module_id,
                                null, //batchid assuming it as null as there are multi files upload here
                                0, // isBatch is 0
                                this.handleRetrieveFileState
                            );
                        }
                    }
                );
                break;
            case "history":
                console.log("From History");
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        hisdisplayval:
                            stateObj.hasOwnProperty("fromDashBoardLinkType") > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateReviewState(this.state);
                        if (this.state?.fileName) {
                            this.HistoryComponentRef?.current?.handleRetrieveHistoryFile(
                                this.state?.fileName,
                                null, //batchid assuming it as null as there are multi files upload here
                                0,
                                this.handleFavoriteFileState
                            );
                        }
                    }
                );
                break;
            default:
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: false,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateReviewState(this.state);
                    }
                );
                break;
        }
    }

    handleFetchFavoriteFilesState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateReviewState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateReviewState(this.state);
            });
        }
    };

    handleFavoriteFileState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateReviewState(this.state);
            });
        } else
            this.setState(
                {
                    ...stateobj,
                    selectedCanned_Ques:
                        docTypes_ddl[
                            this.state.selectedDocType + "_Canned_Ques"
                        ],
                },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
    };

    handleRetrieveFileState = (stateobj, error) => {
        this.handleUploadBar(false)
        try {
            let docType = stateobj?.selectedDocType
                ? stateobj?.selectedDocType
                : null;
            let selectedCanned_Ques = [];
            if (docType) {
                selectedCanned_Ques = docTypes_ddl[docType + "_Canned_Ques"];
            }
            if (error) {
                this.setState({ ...stateobj }, () => {
                    this.props.updateReviewState(this.state);
                });
            } else {
                this.setState(
                    {
                        ...stateobj,
                        selectedCanned_Ques: selectedCanned_Ques,
                    },
                    () => {
                        this.removeWorkspaceDetails();
                        this.props.updateReviewState(this.state);
                        if (this.state.selectedFile !== "") {
                            this.handleChatFile(this.state.selectedFile);
                        }
                    }
                );
            }
        } catch (error) {
            //empty
        }
    };

    handleRemoveFavoriteState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateReviewState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateReviewState(this.state);
            });
        }
    };

    /**
     * setLoaderStatus updates isLoading state data
     * @param {Boolean} flag
     * @param {Boolean} fireSwal
     */
    setLoaderStatus = (flag, fireSwal) => {
        try {
            this.setState({ isLoading: flag }, () => {
                this.props.updateReviewState(this.state);
            });
            if (fireSwal) {
                Swal.fire({
                    text: "We are unable to process this file at this time. May be try another file",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.log("ErrorTriggerMsgSet:", error);
        }
    };

    setReqStartTime = (time) => {
        try {
            this.setState(
                { reqStartTime: time, isSocketRequestProgress: true },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * resetState => updates the state of the object to the initial state
     * @param {Boolean} fireSwal
     * @param {String} swalMsg
     * @param {Number} status
     */
    resetState = (fireSwal, swalMsg, status = 200) => {
        this.setState(
            {
                reviewResult: "",
                isLoading: false,
                isUploadFileOpen: true,
                selectedFile: "",
                fileName: "",
                isHandWritten: false,
                selectedDocType: "",
                selectedEngine: 1,
                fromDashBoard: false,
                uploadFileId: undefined,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
        if (fireSwal) {
            if (status === 401 || status === 422) {
                this.props.removeToken();
            }
            Swal.fire({
                text: swalMsg,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    handleResponse = (response) => {
        try {
            let now = new Date().getTime();
            let processtimeinsec = (
                (now - this.state.reqStartTime) /
                1000
            ).toFixed(2);
            let formDataForAccessLog = new FormData();
            formDataForAccessLog.append("module_id", this.module_id);
            formDataForAccessLog.append("engine_id", this.state.selectedEngine);
            formDataForAccessLog.append(
                "document_type",
                this.state.selectedDocType
            );
            formDataForAccessLog.append(
                "is_hand_written",
                this.state.isHandWritten
            );
            formDataForAccessLog.append("doc_name", this.state?.fileName);

            Createaccesslog(formDataForAccessLog, processtimeinsec);

            if (response) {
                let res = "";
                if (Array.isArray(response.msg) && response.msg?.length > 1) {
                    response.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = response.msg;
                }

                const { CannedQuestionsList } = this.state;

                if (response.q) {
                    let obj = {
                        question: this.state.selectedPrompt.id,
                        response: res,
                        time: new Date().toLocaleString(),
                        timetaken: processtimeinsec + "s",
                    };

                    this.setState(
                        {
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                        },
                        () => {
                            this.props.updateReviewState(this.state);
                            this.scrollToBottom();
                        }
                    );
                } else {
                    let obj = {};
                    if (
                        this.context &&
                        this.context?.length > 0 &&
                        this.state.isAddDoc &&
                        this.state.module_id === 1
                    ) {
                        obj = {
                            disableAddToWorkspace: true,
                            openCreateWorkSpacePopUp: false,
                        };
                    }
                    this.setState(
                        {
                            ...obj,
                            reviewResult: res,
                            isUploadFileOpen: false,
                            isLoading: false,
                            reviewTimer: processtimeinsec + "s",
                        },
                        () => {
                            this.props.updateReviewState(this.state);
                            this.scrollToBottom();
                        }
                    );
                }
            } else {
                this.resetState(
                    true,
                    "We are unable to process this file at this time. May be try another file"
                );
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
            this.resetState(
                true,
                "We are unable to process this file at this time. May be try another file"
            );
        }
    };

    handleChatFile = (fileDetail) => {
        try {
            if (
                this.state.selectedFile !== "" ||
                (fileDetail.file && fileDetail.error === null)
            ) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({ baseContent: reader.result }, () => {
                        this.props.updateReviewState(this.state);
                    });
                };
                reader.readAsDataURL(fileDetail.file);
                this.setState(
                    {
                        selectedFile: fileDetail,
                        CannedQuestionsList:
                            this.state.module_id !== 0 &&
                            this.state.workspace_id !== 0
                                ? this.state.CannedQuestionsList
                                : [],
                        fileName: fileDetail.file?.name,
                        isSocketRequestProgress: true,
                    },
                    () => {
                        this.props.updateReviewState(this.state);
                    }
                );

                if (this.state.selectedDocType !== "") {
                    if (
                        this.state.isAddDoc ||
                        (this.state.workspace_id === 0 &&
                            this.state.workspace_item_id === 0)
                    ) {
                        this.setState(
                            {
                                isLoading: true,
                                loaderText: "Analysis in progress...",
                            },
                            () => {
                                this.props.updateReviewState(this.state);
                            }
                        );
                        const formData = new FormData();
                        formData.append("file0", fileDetail.file);
                        formData.append("module", this.module_name);
                        const axiosInstance = getAxiosInstance(
                            this.props.token,
                            false,
                            this.props?.history
                        );

                        axiosInstance
                            .post("/upload", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                                timeout: 300000,
                            })
                            .then((response) => {
                                if (response.data.fid === undefined) {
                                    this.setLoaderStatus(false, true);
                                } else {
                                    console.log(response.data.fid, "fid");
                                    this.setState(
                                        {
                                            uploadFileId: response.data.fid,
                                        },
                                        () => {
                                            this.props.updateReviewState(
                                                this.state
                                            );
                                        }
                                    );

                                    const formData = new FormData();
                                    console.log(
                                        "Document Type ",
                                        this.state.selectedDocType
                                    );
                                    formData.append(
                                        "file",
                                        this.state.selectedFile.file
                                    );
                                    formData.append(
                                        "document_type",
                                        this.state.selectedDocType
                                    );
                                    formData.append(
                                        "is_hand_written",
                                        this.state.isHandWritten
                                    );
                                    formData.append(
                                        "module_id",
                                        this.module_id
                                    );
                                    formData.append(
                                        "engine_id",
                                        this.state.selectedEngine
                                    );
                                    let ques = {
                                        msg: {
                                            map: this.state.inputValue,
                                            reduce: this.state.inputValue,
                                        },
                                        doctype: this.state.selectedDocType,
                                        engineType: this.state.selectedEngine,
                                        srcname: undefined,
                                        topk: undefined,
                                        token: this.props.token,
                                        uploadFileId: response.data.fid,
                                        hwFlag: this.state.isHandWritten
                                            ? "1"
                                            : "0",
                                        fileName: this.state.fileName,
                                        isBatchFile: false,
                                        index_path: this.state.index_path,
                                        workspace_item_id:
                                            this.state.workspace_item_id,
                                        workspace_id: this.state.workspace_id,
                                    };
                                    console.log(ques);
                                    this.setReqStartTime(new Date().getTime());

                                    socket.emit("review_doc_chat", ques);
                                    this.HistoryComponentRef?.current?.handleSaveFileToHistory(
                                        {
                                            module_id: this.module_id,
                                            selectedFile:
                                                this.state.selectedFile,
                                            selectedDocType:
                                                this.state.selectedDocType,
                                            isHandWritten:
                                                this.state.isHandWritten,
                                            selected_engine_id:
                                                this.state.selectedEngine,
                                            isBatchFile: false, //false as there are no multi doc uploads in this
                                        },
                                        this.props.token
                                    );
                                    let formDataForAccessLog = formData;
                                    formDataForAccessLog.delete("file");
                                    formDataForAccessLog.append(
                                        "doc_name",
                                        this.state?.fileName
                                    );
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                let status = error.response?.status;
                                if (status === 401 || status === 422)
                                    this.resetState(
                                        true,
                                        "Session timedout",
                                        401
                                    );
                                else
                                    this.resetState(
                                        true,
                                        "We are unable to process this file at this time. May be try another file"
                                    );
                                console.log("Error=>", error);
                            });
                    } else {
                    }
                } else {
                    this.resetState(true, "Please select a document type");
                }
            } else {
                this.resetState(true, fileDetail.error);
            }
        } catch (error) {
            this.resetState(false, "");
            console.log("Error in handleSummarize", error);
        }
    };

    handleCannedQuestion = (question) => {
        try {
            // this.setReqStartTime(new Date().getTime());
            if (this.state.selectedFile?.file !== "") {
                this.setState(
                    {
                        ...this.state,
                        promptDisplayed: question.value
                            ? question.value
                            : question,
                        selectedPrompt: question.value
                            ? question
                            : { id: question, value: question },
                        isLoading: true,
                        isBottomPanelOpen: true,
                    },
                    () => {
                        this?.props?.setPosition({document:this.state?.isDocumentOpen, chatComponent:true})
                        this.props.updateReviewState(this.state);
                    }
                );
            }
        } catch (error) {
            this.setLoaderStatus(false, true);
            console.error("Error:", error);
        }
    };

    /**
     * handleDocTypeChange => updates the Document Type and other changes based on doc type
     * @param {React.ChangeEvent<HTMLSelectElement>} event
     */
    handleDocTypeChange = (event, docTypes) => {
        const { value } = event.target;

        if (docTypes && docTypes?.length > 0) {
            legal_Doc_ddl.ReviewdocTyp = docTypes;
        }

        this.setState(
            {
                ...ReviewInitialState,
                isUploadFileOpen: true,
                selectedDocType: value,
                ws_count:0,
                selectedCanned_Ques: docTypes_ddl[value + "_Canned_Ques"]
            },
            () => this.props.updateReviewState(this.state)
        );
    };

    /**
     * handleEngineChange => updates the state of the selectedEngine value
     * @param {React.ChangeEvent<HTMLSelectElement>} event
     */
    handleEngineChange = (event) => {
        const { value } = event.target;
        this.setState(
            {
                ...this.state,
                selectedEngine: value,
            },
            () => this.props.updateReviewState(this.state)
        );
    };
    getUploadFileIdBool = () => this.state.uploadFileId ? this.state.uploadFileId?.length > 0 : false
    
    handleUploadBar = (value) => {
        this.removeURLParams();
        this.setState(
            {
                ...ReviewInitialState,
                isUploadFileOpen: value,
                fileName: "",
                selectedDocType: "",
                reviewResult: "",
                isHandWritten: false,
                selectedFile: "",
                uploadFileId: undefined,
                disableAddToWorkspace: false,
                index_path: "",
                inputValue: "",
                isIndexCreated: false,
                isLoading: false,
                isSharedWorkspace: false,
                isContentExpanded: true,
                selectedPrompt: {},
                doc_help_questions: [],
                isSocketRequestProgress: false
            },
            () => {
                this.removeWorkspaceDetails();
                this.props.updateReviewState(this.state);
            }
        );
    };

    handleUpdateBlobFilesState = (blobFilesList, activeTab) => {
        this.setState(
            {
                docActiveTab: activeTab,
                blobFilesList: blobFilesList,
            },
            () => {
                console.log(this.state);
                this.props.updateReviewState(this.state);
            }
        );
    };

    handleHWSwitchChange = (isHandWritten) => {
        this.setState(
            {
                isHandWritten,
            },
            () => {
                this.props.updateReviewState(this.state);
                if (this.state.selectedFile !== "") {
                    this.handleChatFile(this.state.selectedFile);
                }
            }
        );
    };

    handleHistoryFile = (file, docType, is_hand_written, engine_id) => {
        this.removeURLParams();
        try {
            this.setState(
                {
                    isUploadFileOpen: false,
                    isLoading: false,
                    selectedFile: file,
                    selectedEngine: engine_id,
                    selectedDocType: docType,
                    isHandWritten: is_hand_written,
                    selectedCanned_Ques: docTypes_ddl[docType + "_Canned_Ques"],
                    fromDashBoard: false,
                    hisdisplayval: false,
                },
                () => {
                    this.props.updateReviewState(this.state);
                    let fileDetail = {};
                    fileDetail.file = file;
                    fileDetail.error = null;
                    this.handleChatFile(fileDetail);
                }
            );

            // toast.success("File Retrieved from favorites");
        } catch (e) {
            //empty
        }
    };

    handleLoadingFromHistory = (value, text, error) => {
        if (error) {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
            Swal.fire({
                text: "Not able to fetch file from history, please try after some time",
                icon: "error",
                confirmButtonText: "OK",
            });
        } else
            this.setState(
                {
                    isLoading: value,
                    loaderText: text,
                },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
    };
    handleToggleFavourites = () => {
        this.setState(
            {
                favdisplayval: !this.state.favdisplayval,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    };
    handleToggleHistory = () => {
        this.setState(
            {
                hisdisplayval: !this.state.hisdisplayval,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateReviewState(this.state);
            }
        );
    };
    handleToggleExpndableBox = () => {
        this.setState(
            {
                isContentExpanded: !this.state.isContentExpanded,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    };

    dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    sortByIdAsc = (items) => {
        const sortedItems = [...items].sort((a, b) => {
            return a.id - b.id;
        });
        return sortedItems;
    };
    removeWorkspaceDetails = () => {
        this.setState(
            {
                ...this.state,
                workspace_item_id: 0,
                module_id: 3,
                workspace_id: 0,
                workspace_name: "",
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    };

    getMessages = () => {
            if (
                this.state.module_id === 1 &&
                this.state.workspace_id > 0 &&
                this.state.workspace_item_id > 0 &&
                ((this.state.workspace_id !==
                    store.getState().review.reviewState.workspace_id ||
                    this.state.workspace_item_id !==
                        store.getState().review.reviewState.workspace_item_id) ||
                    store.getState().review.reviewState.ws_count <= 0)
            ) {
                try {
                    reviewReducer(this.state, { type: "RESET_REVIEW_STATE" });
                    this.setState(
                        {
                            isLoading: true,
                            disableAddToWorkspace: true,
                            isUploadFileOpen: false,
                            openCreateWorkSpacePopUp: false,
                            reviewResult: "",
                            fileName: "",
                            uploadFileId: "",
                            selectedFile: {},
                            reviewTimer: 0 + "s",
                            CannedQuestionsList: [],
                            selectedDocType: "",
                        },
                        () => {
                            this.props.updateReviewState(this.state);
                        }
                    );
                    const axiosInstance = getAxiosInstance(
                        this.props.token,
                        false,
                        this.props?.history
                    );
                    axiosInstance
                        .get(
                            `/workspace/item?ws_id=${this.state.workspace_id}&ws_item_id=${this.state.workspace_item_id}`,
                            { timeout: 180000 }
                        )
                        .then((res) => {
                            if (res?.data) {
                                const base64String =
                                    res?.data?.documents[0].docresponse;
                                const filename =
                                    res?.data?.documents.length > 0 &&
                                    res.data.documents[0]?.name; // Specify desired file name
                                var sortedData = this.sortByIdAsc(
                                    res.data.messages
                                );
                                var queryContent = [];
                                sortedData.forEach((element, index) => {
                                    if (index > 0) {
                                        queryContent.push({
                                            question: element.query,
                                            embeddings:
                                                element.embeddings?.length > 0
                                                    ? JSON.parse(
                                                          element.embeddings
                                                      )
                                                    : "",
                                            response: element.messages,
                                            time: convertUtcToLocal(
                                                element.created_at
                                            ),
                                            timetaken: element.timeTaken + "s",
                                            review_by_name:
                                                res.data?.shared_users?.length >
                                                1
                                                    ? this.state?.users?.find(
                                                          (x) =>
                                                              x.user_id ===
                                                              element.created_by
                                                      )?.full_name
                                                    : "",
                                        });
                                    }
                                });
                                const file = this.dataURLtoFile(
                                    base64String,
                                    filename
                                );

                                let data = {
                                    isUploadFileOpen: false,
                                    reviewResult:
                                        sortedData?.length > 0
                                            ? sortedData[0].messages
                                            : "",
                                    reviewTime:
                                        sortedData?.length > 0
                                            ? convertUtcToLocal(
                                                  sortedData[0]?.created_at
                                              )
                                            : "",
                                    review_by_name:
                                        sortedData?.length > 0
                                            ? this.state?.users?.find(
                                                  (x) =>
                                                      x.user_id ===
                                                      sortedData[0]?.created_by
                                              )?.full_name
                                            : "",
                                    fileName:
                                        res.data?.documents?.length > 0 &&
                                        res.data.documents[0]?.name,
                                    index_path:
                                        res.data?.documents?.length > 0 &&
                                        res.data?.documents[0].index_path,
                                    selectedFile: { file: file, error: "" },
                                    isLoading: false,
                                    reviewTimer:
                                        res.data.messages[0].timeTaken + "s",
                                    oldMessages: queryContent,
                                    CannedQuestionsList: queryContent,
                                    selectedDocType:
                                        res.data?.documents?.length > 0 &&
                                        res.data.documents[0].documentType,
                                    ws_shared_users: res.data?.shared_users,
                                    isSharedWorkspace:
                                        res.data?.shared_users?.length > 1
                                            ? true
                                            : false,
                                    workspace_created_by:
                                        res.data?.shared_users?.find(
                                            (x) => x.permissions === "owner"
                                        )?.user_id,
                                    selectedEngine: res.data?.engine_type,
                                    baseContent: base64String,
                                };

                                let docType =
                                    res.data?.documents?.length > 0 &&
                                    res.data.documents[0].documentType;
                                let selectedCanned_Ques = [];
                                if (docType) {
                                    selectedCanned_Ques =
                                        docTypes_ddl[docType + "_Canned_Ques"];
                                }

                                this.setState(
                                    {
                                        ...data,
                                        selectedCanned_Ques:
                                            selectedCanned_Ques,
                                        ws_count: 1,
                                    },
                                    () => {
                                        this.props.updateReviewState(
                                            this.state
                                        );
                                        let ques = {
                                            msg: {
                                                map: LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT,
                                                reduce: LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT,
                                            },
                                            ishelpquestionrequest: true,
                                            doctype:
                                                store.getState().review
                                                    .reviewState
                                                    .selectedDocType,
                                            engineType:
                                                store.getState().review
                                                    .reviewState.selectedEngine,
                                            srcname: undefined,
                                            topk: undefined,
                                            uploadFileId:
                                                store.getState().review
                                                    .reviewState.uploadFileId,
                                            hwFlag: store.getState().review
                                                .reviewState.isHandWritten
                                                ? "1"
                                                : "0",
                                            fileName:
                                                store.getState().review
                                                    .reviewState.fileName,
                                            isBatchFile: false,
                                            index_path: data?.index_path,
                                            workspace_item_id:
                                                store.getState().review
                                                    .reviewState
                                                    .workspace_item_id,
                                            workspace_id:
                                                store.getState().review
                                                    .reviewState.workspace_id,
                                        };
                                        socket.emit("review_doc_chat", ques);
                                    }
                                );
                            }
                        });
                } catch (ex) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.updateReviewState(this.state);
                        }
                    );
                }
            }
    };

    removeURLParams = () => {
        this.setState(
            {
                workspace_id: 0,
                workspace_item_id: 0,
                module_id: 1,
                workspace_name: "",
                disableAddToWorkspace: false,
                isSharedWorkspace: false,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    };

    closeShowAllWorkspacesPopup = (data) => {
        if (data?.length > 0) {
            if(!this.state.moveToAnotherWorkspace){
            let ob = {
                rid: this.state.uploadFileId,
                workspace_item_id: data[0].workspace_item_id,
                workspace_id: data[0].id,
            };
            socket.emit("set_review_doc_chat_session", ob);
        }

            if(this.state.moveToAnotherWorkspace){
                let obj = {
                    msg: {
                        map: this.state.inputValue,
                        reduce: this.state.inputValue,
                    },
                    doctype: this.state.selectedDocType,
                    engineType: this.state.selectedEngine,
                    srcname: undefined,
                    topk: undefined,
                    token: this.props.token,
                    uploadFileId: undefined,
                    index_path: this.state.index_path,
                    hwFlag: this.state.isHandWritten
                        ? "1"
                        : "0",
                    fileName: this.state.fileName,
                    isBatchFile: false,
                    workspace_item_id:data[0].workspace_item_id,
                    workspace_id: data[0].id,
                };
                socket.emit("review_doc_chat", obj);
            }

            this.setState(
                {
                    ...this.state,
                    showAllWorkspaces: false,
                    workspace_name: data[0].name,
                    workspace_id: data[0].id,
                    workspace_item_id: data[0].workspace_item_id,
                    openCreateWorkSpacePopUp: false,
                    disableAddToWorkspace: true,
                    moveToAnotherWorkspace:false,
                    closeKebabMenu:true,
                    workspace_created_by: parseInt(
                        store?.getState()?.login?.logInState?.user_id
                    ),
                    isSharedWorkspace:
                        parseInt(data[0]?.shared_users) > 1 ? true : false,
                },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
        }
    };

    closeAllWorkspacesPopup = () => {
        this.setState(
            {
                ...this.state,
                showAllWorkspaces: false,
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    };

    CloseWorkspaceListPopUp = (data, res) => {
        if(this.state.addToAnotherWorkspace){
            this.setState(
                {
                    addToAnotherWorkspace: false,
                    openCreateWorkSpacePopUp: false,
                    closeKebabMenu:true
                },
                () => {
                    this.props.updateReviewState(this.state);
                }
            );
        }else{
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                disableAddToWorkspace: false,
                selectedWorkSpaces: data,
                showAllWorkspaces: data?.length > 1 ? true : false,
            },
            () => {
                this.props.updateReviewState(this.state);
                if (data?.length === 1 && res?.added?.length === 1) {
                    this.closeShowAllWorkspacesPopup(data);
                }
            }
        );
    }
    };
    addToAnotherWorkspace=()=>{
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                addToAnotherWorkspace:true,
                moveToAnotherWorkspace:false
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    }
    moveToAnotherWorkspace=()=>{
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                moveToAnotherWorkspace:true,
                addToAnotherWorkspace:false
            },
            () => {
                this.props.updateReviewState(this.state);
            }
        );
    }

    render() {
        let reviewResult = this.state.reviewResult;
        return (
            <Fragment>
                {this.state.showAllWorkspaces && (
                    <ShowAllWorkspacePopUp
                        selectedWorkspaces={this.state.selectedWorkSpaces}
                        closeShowAllWorkspacesPopup={
                            this.closeShowAllWorkspacesPopup
                        }
                        closepopup={this.closeAllWorkspacesPopup}
                        document_name={this.state.selectedFile?.file?.name}
                    />
                )}

                {this.state.openCreateWorkSpacePopUp && (
                    <WorkSpaceListPopUp
                        title={"Create new WorkSpace"}
                        workSpace={""}
                        module_id={1}
                        engine_type={this.state.selectedEngine}
                        buttonName={"Add"}
                        workSpaceDisabled={false}
                        state={this.state}
                        file={this.state.selectedFile?.file}
                        content={this.state.reviewResult}
                        baseContent={this.state.baseContent}
                        docsContent={[
                            {
                                content: this.state.baseContent,
                                fileName: this.state.selectedFile.file.name,
                                fileType: this.state.selectedFile.file.type,
                                index_path: this.state.index_path,
                            },
                        ]}
                        document_type={this.state.selectedDocType}
                        document_name={this.state.selectedFile?.file?.name}
                        handwritten_text_flag={this.state.isHandWritten}
                        timeTaken={this.state.reviewTimer.replace("s", "")}
                        CannedQuestionsList={this.state.CannedQuestionsList}
                        index_path={this.state.index_path}
                        closepopup={(data, res) =>
                            this.CloseWorkspaceListPopUp(data, res)
                        }
                        closeWsListpopup={() => {
                            this.setState({
                                ...this.state,
                                openCreateWorkSpacePopUp:
                                    !this.state.openCreateWorkSpacePopUp,
                                disableAddToWorkspace:
                                    this.state.addToAnotherWorkspace ||
                                    this.state.moveToAnotherWorkspace
                                        ? this.state.disableAddToWorkspace
                                        : false,
                            });
                        }}
                        workspace_id={this.state.workspace_id}
                        moveToAnotherWorkspace={
                            this.state.moveToAnotherWorkspace
                        }
                        fromWorkspaceId={this.state.workspace_id}
                        fromWorkspaceItemId={this.state.workspace_item_id}
                    />
                )}

                <CustomWindowPopupComponent
                    window_name={"Favorites"}
                    module_name={this.module_name}
                    togglepopup={this.handleToggleFavourites}
                    favdisplayval={this.state.favdisplayval}
                    content={
                        <FavoritesComponent
                            ref={this.FavoriteComponentRef}
                            module={this.module_id}
                            favdisplayval={this.state.favdisplayval}
                            aciveTab={this.state.activeTab}
                            module_name="ReviewdocType"
                            handleToggleFavoritesCallback={
                                this.handleToggleFavourites
                            }
                            handleRemoveFavoriteState={
                                this.handleRemoveFavoriteState
                            }
                            handleFavoriteFile={this.handleFavoriteFileState}
                            handleFetchFavoriteFilesState={
                                this.handleFetchFavoriteFilesState
                            }
                            handleRetrieveFileState={
                                this.handleRetrieveFileState
                            }
                            favoriteFiles={this.state.favoriteFiles}
                            token={this.props.token}
                            removetoken={this.props.removetoken}
                            selectedEngine={this.state.selectedEngine}
                            isBatchFile={this.state.isBatchFile}
                            blobFilesList={this.state.blobFilesList} //passing blob files list to push data on select
                            handleUpdateBlobFilesState={
                                this.handleUpdateBlobFilesState
                            }
                        />
                    }
                />
                <CustomWindowPopupComponent
                    module_name={this.module_name}
                    window_name={"History"}
                    togglepopup={this.handleToggleHistory}
                    favdisplayval={this.state.hisdisplayval}
                    content={
                        <HistoryComponent
                            module={this.module_id}
                            module_name={"ReviewdocType"}
                            hisdisplayval={this.state.hisdisplayval}
                            isProcessed={reviewResult !== "" ? true : false}
                            aciveTab={this.state.activeTab}
                            ref={this.HistoryComponentRef}
                            fileName={this.state.fileName}
                            fileDetails={this.state.selectedFile}
                            docType={this.state.selectedDocType}
                            isHandWritten={this.state.isHandWritten}
                            historyList={this.state.historyList}
                            isLoading={this.handleLoadingFromHistory}
                            handleHistoryFile={this.handleHistoryFile}
                            handleFileResetCallback={this.handleUploadBar}
                            fromDashBoard={this.state.fromDashBoard}
                            token={this.props.token}
                            removeToken={this.props.removeToken}
                            selectedEngine={this.state.selectedEngine}
                            handleRetrieveFileStateCallback={
                                this.handleRetrieveFileState
                            }
                            handleFavoriteFileStateCallback={
                                this.handleFavoriteFileState
                            }
                            updateStateCallback={this.handleUpdateHistoryList}
                            toggleFavorites={this.handleToggleFavourites}
                        />
                    }
                ></CustomWindowPopupComponent>

                <div className="rytpaneltoppart flex-flow-wrap">
                    <div className="reviewfirstdiv">
                        <h2>review document </h2>
                        {this.state.fileName !== "" && (
                            <HandleNewButtonComponent
                                title="Review a new document"
                                selectedDocType={this.state.selectedDocType}
                                handleUploadBarCallback={this.handleUploadBar}
                            />
                        )}
                    </div>
                    <div className="reviewdescriptionsec">
                        <div className="reviewdescription">
                            <HandleDocTypeChangeComponent
                                selectedDocType={this.state.selectedDocType}
                                handleDocTypeChangeCallback={
                                    this.handleDocTypeChange
                                }
                                documentTypes={legal_Doc_ddl.ReviewdocType}
                                fileName={this.state.fileName}
                            />
                            <HandleEngineComponent
                                selectedEngine={this.state.selectedEngine}
                                handleEngineChangeCallback={
                                    this.handleEngineChange
                                }
                                fileName={this.state.fileName}
                            />
                            {this.state.selectedDocType !== "" && (
                                <HandwrittenComponent
                                    isHandWritten={this.state.isHandWritten}
                                    handleHWSwitchChangeCallback={
                                        this.handleHWSwitchChange
                                    }
                                />
                            )}
                            {this.state.fileName !== "" && (
                                <>
                                    <HandleFileNameComponent
                                        fileName={this.state.fileName}
                                        selectedFile={this.state.selectedFile}
                                        maxLengthOfFileName={15}
                                        blobFilesList={this.state.blobFilesList}
                                        favoriteFiles={this.state.favoriteFiles}
                                        module_id={this.module_id}
                                        handleUpdateBlobFilesStateCallback={
                                            this.handleUpdateBlobFilesState
                                        }
                                        handleWarnRemoveFavoriteCallback={
                                            handleWarnRemoveFavorite
                                        }
                                        handleRemoveFavoriteStateCallback={
                                            this.handleRemoveFavoriteState
                                        }
                                        selectedDocType={
                                            this.state.selectedDocType
                                        }
                                        isHandWritten={this.state.isHandWritten}
                                        selectedEngine={
                                            this.state.selectedEngine
                                        }
                                        RemoveFavouriteCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.RemoveFavorite
                                        }
                                        handleFavoriteFileCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.handleFavoriteFile
                                        }
                                        handleFavoriteFileStateCallback={
                                            this.handleFavoriteFileState
                                        }
                                    />
                                </>
                            )}
                            {this.state?.fileName?.length > 0 &&
                                (!this.state.isSocketRequestProgress ||
                                    this.state.reviewResult?.length <= 0) && (
                                    <>
                                        <HandleRefreshButtonComponent
                                            handleRefreshFunc={() => {
                                                this.setState(
                                                    {
                                                        reviewResult: "",
                                                        uploadFileId: undefined,
                                                        index_path:
                                                            this.state
                                                                .module_id ===
                                                                1 &&
                                                            this.state
                                                                .workspace_id >
                                                                0 &&
                                                            this.state
                                                                .workspace_item_id >
                                                                0
                                                                ? this.state
                                                                      .index_path
                                                                : undefined,
                                                    },
                                                    () => {
                                                        this.props.updateReviewState(
                                                            this.state
                                                        );
                                                    }
                                                );
                                                this.getMessages();
                                                this.handleChatFile(
                                                    this.state.selectedFile
                                                );
                                            }}
                                        />
                                    </>
                                )}

                            {this.state?.fileName?.length > 0 &&
                                this.state.isSocketRequestProgress &&
                                this.state.reviewResult?.length > 0 && (
                                    <>
                                        <HandleCancelRequestButtonComponent
                                            handleRefreshFunc={() => {
                                                console.log(
                                                    `stoping the request made...`
                                            );
                                            let ques = {
                                                msg: {
                                                    map: `cancel request`,
                                                    reduce: `cancel request`,
                                                },
                                                reqStartTime:
                                                    new Date().getTime(),
                                                doctype:
                                                    this.state.selectedDocType,
                                                cancel_req: true,
                                                engineType:
                                                    this.state.selectedEngine,
                                                srcname: undefined,
                                                topk: undefined,
                                                token: this.props.token,
                                                uploadFileId:
                                                    this.state.uploadFileId,
                                                hwFlag: this.state.isHandWritten
                                                    ? "1"
                                                    : "0",
                                                fileName: this.state.fileName,
                                                isBatchFile: false,
                                                index_path:
                                                    this.state.index_path,
                                                workspace_item_id:
                                                    this.state
                                                        .workspace_item_id,
                                                workspace_id:
                                                    this.state.workspace_id,
                                            };
                                            console.log(`${ques} cancel ques request`);
                                            socket.emit(
                                                "review_doc_chat",
                                                ques
                                            );
                                            }}
                                        />
                                    </>
                                )}
                        </div>
                    </div>
                    <CustomHistoryFavoriteLinks
                        toggleFavoritescallback={this.handleToggleFavourites}
                        toggleHistoryCallback={this.handleToggleHistory}
                    />
                </div>

                <div
                    ref={this.secondDivRef}
                    id="midPanel"
                    className={
                        !this.state.isBottomPanelOpen
                            ? "rytpanelmidpart"
                            : "rytpanelmidpartWithBottom"
                    }
                >
                    <div className="midPanel_Container">
                        <div data-scroll className="midPanel_Item1">
                            {this.state.selectedDocType !== "" &&
                                this.state.isUploadFileOpen && (
                                    <FileProcessing
                                        handleFile={this.handleChatFile}
                                        inProgress={
                                            reviewResult !== "" ? false : true
                                        }
                                    ></FileProcessing>
                                )}

                            {reviewResult !== "" ? (
                                <>
                                    {" "}
                                    <TitleOfComponent
                                        buttonTitle={
                                            this.state.isAddDoc &&
                                            this.state.disableAddToWorkspace
                                                ? "Document auto saved in workspace "
                                                : " Add to workspace"
                                        }
                                        title={""}
                                        description={null}
                                        isExpanded={
                                            this.state.isContentExpanded
                                        }
                                        exapandfncallback={
                                            this.handleToggleExpndableBox
                                        }
                                        fileName={this.state.fileName}
                                        CannedQuestionsList={[
                                            {
                                                question: "Review Notes",
                                                response:
                                                    this.state.reviewResult,
                                                time: new Date().toLocaleString(),
                                                timetaken:
                                                    this.state.reviewTimer +
                                                    "s",
                                            },
                                            ...this.state.CannedQuestionsList,
                                        ]}
                                        componenttitle="ReviewDocs"
                                        setStateCallback={() => {
                                            this.setState(
                                                {
                                                    openCreateWorkSpacePopUp: true,
                                                },
                                                () =>
                                                    this.props.updateReviewState(
                                                        this.state
                                                    )
                                            );
                                        }}
                                        disableAddToWorkspace={
                                            this.state.disableAddToWorkspace
                                        }
                                        workspace_name={
                                            this.state.workspace_name
                                        }
                                        workspace_id={this.state.workspace_id}
                                        workspace_created_by={
                                            this.state.workspace_created_by
                                        }
                                        users={this.state.ws_shared_users}
                                        AddToAnotherWorkspace={
                                            this.addToAnotherWorkspace
                                        }
                                        MoveToAnotherWorkspace={
                                            this.moveToAnotherWorkspace
                                        }
                                        closeKebabMenu={
                                            this.state.closeKebabMenu
                                        }
                                    />
                                    <div className="reviewdatasec">
                                        <ContentReviewSection
                                            title="Notes"
                                            timer={this.state.reviewTimer}
                                            response={this.state.reviewResult}
                                            filename={this.state.fileName}
                                            CannedQuestionsList={
                                                this.state.CannedQuestionsList
                                            }
                                            question={null}
                                            componenttitle="ReviewDocs"
                                            isExpanded={
                                                this.state.isContentExpanded
                                            }
                                            reviewTime={this.state.reviewTime}
                                            review_by_name={
                                                this.state.isSharedWorkspace
                                                    ? this.state.review_by_name
                                                    : ""
                                            }
                                            engine_id={
                                                +this.state.selectedEngine
                                            }
                                        />

                                        {this.state.CannedQuestionsList
                                            ?.length !== 0 &&
                                            this.state.CannedQuestionsList.map(
                                                (item, i) => (
                                                    <>
                                                        <ContentReviewSectionComponent
                                                            key={i}
                                                            question={
                                                                item.question
                                                            }
                                                            time={item.time}
                                                            timetaken={
                                                                item.timetaken
                                                            }
                                                            response={
                                                                item.response
                                                            }
                                                            fileName={
                                                                this.state
                                                                    .fileName
                                                            }
                                                            componenttitle="ReviewDocs"
                                                            isExpanded={
                                                                this.state
                                                                    .isContentExpanded
                                                            }
                                                            created_by_name={
                                                                this.state
                                                                    .isSharedWorkspace
                                                                    ? item?.review_by_name
                                                                    : ""
                                                            }
                                                            embeddings={
                                                                item?.embeddings
                                                            }
                                                            engine_id={
                                                                +this.state
                                                                    .selectedEngine
                                                            }
                                                        />
                                                    </>
                                                )
                                            )}
                                        {this.state.CannedQuestionsList
                                            ?.length > 0 && (
                                            <div
                                                style={{
                                                    float: "left",
                                                    clear: "both",
                                                }}
                                                ref={(el) => {
                                                    this.messagesEnd = el;
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            {this.state.isLoading ? (
                                <LoadingSpinnerSticky
                                    text={this.state.loaderText}
                                />
                            ) : (
                                <></>
                            )}
                        </div>

                        {/**Expandable Document Component Start */}
                        {this.state.fileName?.length > 0 &&
                            this.state.selectedFile?.file && (
                                <>
                                    <CustomExpandableDocComponent
                                        isDocumentOpen={
                                            this.state.isDocumentOpen
                                        }
                                        toggleDocumentVisibleStateCallback={
                                            this.handleToggleDocumentViewer
                                        }
                                        customclass="midPanel_Item2"
                                        childComponent={
                                            <>
                                                <CustomDocViewer
                                                    name={
                                                        this.state.selectedFile
                                                            ?.file?.name
                                                    }
                                                    opendocdate={""}
                                                    mimetype={
                                                        this.state.selectedFile
                                                            ?.file?.type
                                                    }
                                                    file={
                                                        this.state.selectedFile
                                                            ?.file
                                                    }
                                                ></CustomDocViewer>
                                            </>
                                        }
                                        activedocname={this.state.fileName}
                                    ></CustomExpandableDocComponent>
                                </>
                            )}

                        {/**Expandable Document Component End */}
                    </div>
                </div>
                <ErrorBoundary>
                    {(this.state.isIndexCreated ||
                        this.state.index_path?.length > 0) && (
                        <ChatComponent
                            ref={this.chatComponentRef}
                            module={this.module_id}
                            module_name="ReviewdocType"
                            activeTab={this.state.activeTab}
                            toggleTab={this.toggleTab}
                            selectedDocType={this.state.selectedDocType}
                            handleCannedQuestion={this.handleCannedQuestion}
                            handleBottomPanel={this.handleBottomPanel}
                            isBottomPanelOpen={this.state.isBottomPanelOpen}
                            selectedCanned_Ques={this.state.selectedCanned_Ques}
                            isReview={true}
                            promptDisplayed={this.state.promptDisplayed}
                            isShowFavorites={true}
                            uploadFileId={this.state.uploadFileId}
                            chatType="review"
                            hwFlag={this.state.isHandWritten}
                            handleResponse={this.handleResponse}
                            setLoaderStatus={this.setLoaderStatus}
                            isLoading={this.state.isLoading}
                            token={this.props.token}
                            removeToken={this.props.removeToken}
                            selectedEngine={this.state.selectedEngine}
                            selectedFile={this.state.selectedFile}
                            setReqStartTime={this.setReqStartTime}
                            blobFilesList={this.state.blobFilesList}
                            handleUpdateBlobFilesState={
                                this.handleUpdateBlobFilesState
                            }
                            inputValue={this.state.selectedPrompt.id}
                            fileName={this.state.selectedFile.file?.name}
                            workspace_item_id={this.state.workspace_item_id}
                            workspace_id={this.state.workspace_id}
                            index_path={this.state.index_path}
                            reqStartTime={this.state.reqStartTime}
                            doc_help_questions={this.state.doc_help_questions}
                            isSocketRequestProgress={
                                this.state.isSocketRequestProgress
                            }
                        />
                    )}
                </ErrorBoundary>
                <Toaster position="top-center" />
            </Fragment>
        );
    }
}

// ReviewComponent.contextType = WorkspaceContext
const mapStateToProps = (state) => ({
  reviewState: state.review.reviewState, // Use the appropriate reducer key
  dashBoardState: state.dashBoard.dashBoardState,
});

const mapDispatchToProps = {
  updateReviewState, // This maps your action directly to props
  updateDashBoardState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewComponent);
