import { createRef, Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../../contexts/socket.js";
import chatWithDocsCanned from "../../../dropDowns/ChatWithDocTypes.json";
import reviewCannedQues from "../../../dropDowns/documentReviewTypes.json";
import moduleNames from "../../../dropDowns/moduleNames.json";
import summarizeDocTypes from "../../../dropDowns/summarizeDocTypes.json";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import ChatComponent from "../../SupportiveComponents/ChatComponent";
import { ContentReviewSectionComponent } from "../../SupportiveComponents/CustomContentOperations/CustomContentViewer";
import { Spinning } from "../../SupportiveComponents/FontAwesomeComponents/HelperIcons";
import {
    HandleDocTypeChangeComponent,
    HandleEngineComponent,
} from "../../SupportiveComponents/ModuleHelpers";
import ChatSidebar from "./ChatSideBar";

import { Sidenav } from "rsuite/";
// import "rsuite/dist/rsuite.min.css";
import { base64ToBlob } from "../../../Helpers/UtilMethods.js";
import {
    generateDocumentForCannedListUsingMD,
    generateDocumentForWorkspaceChat,
} from "../../SupportiveComponents/CustomContentOperations/WordDocGenerationOperations.jsx";
import {
    BackArrow,
    Collapse,
    Expand,
    Export,
    ExternalLinkIcon,
} from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";
import { isMobile } from "../../SupportiveComponents/PureHelpers.jsx";
import { isEqual } from "lodash";

const WorkspaceChat = ({ WorkspaceItems, docTypes, locState }) => {
    const [state, setState] = useState({
        view: false,
        isLoading: false,
        selectedDocType: "",
        selectedEngine: 2,
        isBottomPanelOpen: true,
        totalDocs: 0,
        currentDocNo: 0,
        existDocTypes: docTypes,
        chat_key: uuidv4(),
        hasError: false,
        chatName: "",
        isChatLoading:false
    });
    const [workspaceItems, setWorkspaceItems] = useState(
        docTypes ? docTypes : WorkspaceItems ?? []
    );
    const loc = useLocation();
    const history = useNavigate();
    if (!loc.state && workspaceItems) {
        history("/WorkSpace-Chat", {
            state: { workspaceItems: workspaceItems, ...locState },
        });
    }
    const [messges, setMessages] = useState([]);
    const [previousChats, setPreviousChats] = useState([]);
    const [token] = useState(localStorage.getItem("token"));
    const [loading, setLoding] = useState(false);
    const [isExpanded, setIsExpanded] = useState([{ status: true, index: 0 }]);
    const [isDocExpanded] = useState(true);
    const [workspace_id] = useState(loc?.state?.workspace_id);
    const [expand, setExpand] = useState(true);
    const [selectedPrompt, setSelectedPrompt] = useState({id:""});

    const ref = createRef();
    useEffect(() => {
        ref.current?.focus();
    }, [ref]);

    const handleNewchat = () => {
        setMessages([]);
        setExpand(!expand);
        getChats();
        setState((prevState) => {
            return {
                ...prevState,
                currentDocNo: 0,
                chat_key: uuidv4(),
                chatName: "",
            };
        });
    };

    const handleSelectChat = (data) => {
        ref.current?.focus();
        setExpand(!expand);
        Object.entries(data.response).map(([chat, questions]) => {
            setState({ ...state, chat_key: chat, chatName: data?.display_name });
            return setMessages(questions);
        });
    };

    const handleCannedQuestion = (question) => {
        try {
            setSelectedPrompt({id:question?.id});
        } catch (error) {
            setLoaderStatus(false, true);
            console.error("Error:", error);
        }
    };


    const handleQuestion = (data, chat, questinNo) => {
        setExpand(!expand);

        setState({ ...state, chat_key: chat });
        data["questionNo"] = questinNo;
        setMessages([data]);
    };

    const getChats = useCallback(() => {
        const axiosInstance = getAxiosInstance(token);
        setLoding(true);
        axiosInstance
            .get(`/workspace/chats/all?ws_id=${workspace_id}`)
            .then((res) => {
                let msgs = [];
                res?.data?.forEach((element) => {
                    msgs.push({
                        response: JSON.parse(element?.response),
                        id: element?.id,
                        display_name: element?.display_name,
                    });
                });
                setLoding(false);
                setPreviousChats(msgs);
            });
    }, [token, workspace_id]);

    const mergeObjects = (obj1, obj2) => {
        const merged = { ...obj1 };
        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (merged[key]) {
                    merged[key] = [...merged[key], ...obj2[key]];
                } else {
                    merged[key] = obj2[key];
                }
            }
        }
        return merged;
    };

    useEffect(() => {
        if (!loc?.state || loc.state?.workspace_id === 0) {
        } else if (loc?.state && loc.state?.workspace_id !== 0) {
            getChats();
        }

        if (WorkspaceItems) {
            setWorkspaceItems(WorkspaceItems);
        } else {
            setWorkspaceItems(loc?.state?.workspaceItems);
        }
        let existDocTypes = [];
        if (loc?.state?.docTypes) {
            existDocTypes = loc.state.docTypes;
        } else {
            existDocTypes = docTypes;
        }
        setState((prevState) => {
            return {
                ...prevState,
                existDocTypes: existDocTypes,
                selectedDocType:
                    existDocTypes?.length > 0 ? existDocTypes[0].documentID : "",
                selectedCanned_Ques:
                    existDocTypes?.length > 0
                        ? {
                            ...summarizeDocTypes,
                            ...reviewCannedQues,
                            ...chatWithDocsCanned,
                        }[existDocTypes[0].documentID + "_Canned_Ques"]
                        : "",
            };
        });
    }, [loc?.state, getChats, workspaceItems, docTypes, WorkspaceItems]);

    function isKeyInArray(key, array) {
        return array.find(item => item[Object.keys(item)[0]].find(x=>x.question_id === key))
    }
    function getArrayLengthByKey(key, arr) {
        // Find the object in the array that has the key
        const objWithKey = arr.find(item => item[Object.keys(item)[0]].find(x=>x.question_id === key));
        // If the object is found, return the length of the array corresponding to the key
        if (objWithKey) {
          return objWithKey[Object.keys(objWithKey)[0]].length;
        } else {
          return 0; // Return 0 if the key is not found
        }
      }

   // Function to filter out duplicate objects based on question_id and docId
function filterDuplicates(chats) {
    let arr =[]
     chats.forEach(chat => {
      const keys = Object.keys(chat.response); // Get the keys inside response object
      let filteredArr =[]
      chat?.response[keys[0]]?.forEach((e)=>{
        if(filteredArr.filter(x=>x[Object.keys(x)[0]][0]?.question_id === e[Object.keys(e)[0]][0]?.question_id)?.length===0){
            filteredArr.push({[e[Object.keys(e)[0]][0].query]:e[Object.keys(e)[0]]})
        }
       
      })
      arr.push({response:{[keys[0]]:filteredArr},display_name:chat?.display_name, id:chat?.id})
    });
    return arr
  }
   

    useEffect(() => {
        const chatListener = (responses) => {
            if (responses["workspace_id"] === loc?.state?.workspace_id) {
                if (responses?.error) {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        isChatLoading:false,
                        hasError: true,
                    }));
                    Swal.fire({
                        text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        isChatLoading:false,
                    }));
                    let data = responses["message"];
                    let key = data?.query;
                    
                    data = { [key]: [data], id: data?.id };
                    let updatedObj = messges;
                    let currentDoc = getArrayLengthByKey(responses?.message?.question_id, updatedObj)

                    if(messges?.length ===0 || (messges?.length>0 && messges[0][Object.keys(messges[0])[0]][0].chat_key === responses.message?.chat_key )){
                        //For showing loader and processing documents
                        setState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                            isChatLoading:false,
                            currentDocNo: responses?.message?.id === 0 ? (currentDoc + 1) : 0,
                            totalDocs:responses?.message?.total_docs,
                            chatName: Object.keys(data)?.length > 0 ? Object.keys(data)[0] : "",
                        }));
                        if (Object.entries(updatedObj)?.length > 0 && isKeyInArray(responses?.message?.question_id, updatedObj)) {
                            updatedObj.forEach((item) => {
                                if (Object.keys(data).length > 0) {
                                    // Check if the key exists in the array item
                                    
                                    if (item[Object.keys(item)[0]]?.filter(x=>x.question_id === responses.message.question_id)?.length>0) {
                                        const key = Object.keys(data)[0];
                                        item[key] = item[key]?.filter(x=>x.question_id===responses?.message?.question_id)
                                        item[key].push(data[key][0]);
                                    }
                                }
                            });
                        } else {
                            if(previousChats.filter(x=>x.response[responses.message.chat_key])?.length>0
                            && previousChats.filter(x=>x.response[responses.message.chat_key])[0].response[responses.message.chat_key]?.length !== messges?.length){
                                data["questionNo"]=previousChats.filter(x=>x.response[responses.message.chat_key])[0].response[responses.message.chat_key]?.length+1
                            }

                            updatedObj.push(data);
                        }
                        setMessages(updatedObj);
                    }else{
                        setState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                            currentDocNo: 0,
                            totalDocs:0,
                            chatName:  "",
                        }));
                    }

                    if(responses?.message?.id !== 0){
                    let exist = previousChats.some(
                        (item) => item.response && item.response[responses?.message?.chat_key]
                    );
                    
                    //For updating all question responses in left side chat
                    let msgs =[]
                    messges.forEach((e,i)=>{
                        if(e[Object.keys(e)[0]]?.filter(x=>x.question_id===responses?.message?.question_id)?.length>0){
                            msgs=e[Object.keys(e)[0]]?.filter(x=>x.question_id===responses?.message?.question_id)
                        }
                    })
                    if(msgs.length === 0){
                        msgs= [responses?.message]
                    }
                    //updating chats in left side
                    let previoudsChats= {id:responses?.message?.id, display_name: responses?.message?.query,
                         response:{[responses?.message?.chat_key]:[{[responses?.message?.query]:msgs}]}}
                         //for new chat
                        if(!exist && responses?.message?.id !== 0) {
                                setPreviousChats([previoudsChats, ...previousChats.filter(x=>x.id !==0)]);
                            }
                            //for existing chat append question
                        else {
                            let updatedChats = previousChats.map((e) => {
                                if (Object.keys(e?.response)[0] === Object.keys(previoudsChats?.response)[0] && previoudsChats?.id !== 0) {
                                    return {...e, response: mergeObjects(e?.response, previoudsChats?.response)};
                                }
                                return e;
                            });
                            if(!isEqual(updatedChats, previousChats) && previoudsChats?.id !== 0){
                                let chats = filterDuplicates(updatedChats)
                                setPreviousChats(chats);
                            }

                        }
                    }
                }
            }
        };

        socket.on("chat_within_workspace_response", chatListener);

        return () => {
            socket.off("chat_within_workspace_response", chatListener);
        };
    }, [messges, previousChats, loc?.state?.workspace_id, state.selectedDocType, workspaceItems]);

    useEffect(()=>{
        const chatResponseListener =(responses)=>{
            if (responses?.length>0 && (responses[0]["workspace_id"] === loc?.state?.workspace_id)) {
            let exist = previousChats.some((item) => item.response && item.response[responses[responses?.length-1].chat_key]);
            let previoudsChats= {id:responses[responses?.length-1].id, display_name: responses[responses?.length-1].query,
                response:{[responses[responses?.length-1].chat_key]:[{[responses[responses?.length-1].query]:responses}]}}
                if (!exist) {
                    setPreviousChats([previoudsChats, ...previousChats]);
                }
                else {
                    let updatedChats = previousChats.map((e) => {
                        if (Object.keys(e?.response)[0] === Object.keys(previoudsChats?.response)[0] && previoudsChats?.id !== 0) {
                            return {...e, response: mergeObjects(e?.response, previoudsChats?.response)};
                        }
                        return e;
                    });

                    if (updatedChats?.length === previousChats?.length) {
                        setPreviousChats(updatedChats);
                    }
                }
                if(messges?.length>0 && messges[0][Object.keys(messges[0])[0]][0].chat_key === responses[responses?.length-1]?.chat_key){
                    let data = undefined
                    if(responses?.length>0){
                        let key = responses[responses?.length-1]?.question_id;
                        data = { [key]: responses, id: responses[responses?.length-1]?.id }; 
                    }
                    
                    if(previousChats.filter(x=>x.response[responses[responses?.length-1]?.chat_key])?.length>0
                            && previousChats.filter(x=>x.response[responses[responses?.length-1]?.chat_key])[0].response[responses[responses?.length-1]?.chat_key]?.length !== messges?.length){   
                                data["questionNo"]=previousChats.filter(x=>x.response[responses[responses?.length-1]?.chat_key])[0].response[responses[responses?.length-1]?.chat_key]?.length+1
                            }
                    setMessages([...messges, data])
                }
            }
        }
        socket.on("chat_within_workspace_response_for_shared_user", chatResponseListener);
        return ()=>{socket.off("chat_within_workspace_response_for_shared_user", chatResponseListener)}
    })

    const handleDocTypeChange = (event, docTypes) => {
        const { value } = event.target;
        setState({
            ...state,
            selectedDocType: value,
            selectedCanned_Ques: {
                ...summarizeDocTypes,
                ...reviewCannedQues,
                ...chatWithDocsCanned,
            }[value + "_Canned_Ques"],
            view: state.view,
        });
        console.log(value);
    };
    const toggleTab = (tabId) => {
        setState({ ...state, activeTab: tabId, isBottomPanelOpen: true });
    };

    const handleBottomPanel = (isOpen) => {
        setState({ ...state, isBottomPanelOpen: isOpen });
    };

    const setLoaderStatus = (flag, fireSwal) => {
        try {
            setState({ ...state, isChatLoading: flag });

            if (fireSwal) {
                Swal.fire({
                    text: "We are unable to process this file at this time. May be try another file",

                    icon: "error",

                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.log("ErrorTriggerMsgSet:", error);
        }
    };

    const setReqStartTime = (startTime) => {
        setState({ ...state, startTime: startTime });
    };

    const openDocument = (id) => {
        const allDocs =
            workspaceItems?.length > 0 &&
            workspaceItems?.flatMap((item) => item.docs);
        let base64String = allDocs?.find((x) => x.doc_id === id).content;
        // Extracting the Base64 part from the string (assuming the format is "data:<mimeType>;base64,<data>")
        const base64Data =
            base64String.split(",")?.length > 0 && base64String.split(",")[1];
        const mimeType = base64String.split(",")[0].split(":")[1].split(";")[0];

        const blob = base64ToBlob(base64Data, mimeType);
        const url = URL.createObjectURL(blob);

        // Open the new tab and write the document into an iframe
        const newTab = window.open(url, "_blank");
        if (newTab) {
            return newTab.document.write(
                `<iframe src="${url}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
            );
        }

        // Clean up the URL when the component unmounts
        return () => {
            // URL.revokeObjectURL(url);
            if (newTab) {
                newTab.open();
            }
        };
    };

    const handleUpdatedName = (name) => {
        setState({ ...state, chatName: name });
    };

    return (
        <Fragment>
            {(loading || state?.isChatLoading) ? <LoadingSpinner />:""}
            <div className={`${!isMobile() ? "app-main" : ""}`}>
                {isMobile() ? (
                    <Sidenav
                        expanded={expand}
                        defaultOpenKeys={["1", "4"]}
                        style={{ height: "50%" }}
                    >
                        <Sidenav.Header
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "90%",
                            }}
                        >
                            <BackArrow
                                style={{
                                    color: "#2eb1e3",
                                    padding: "10px",
                                    VerticalAlign: "center",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                }}
                                title="BACK TO LIST"
                                onClick={() => history(-1)}
                            />
                            <Sidenav.Toggle
                                expanded={expand}
                                onToggle={(expanded) => setExpand(!expand)}
                                style={{ fontSize: "24px" }}
                            // className="icon-container"
                            />
                        </Sidenav.Header>

                        <Sidenav.Body style={{ height: "50%" }}>
                            {expand && (
                                <ChatSidebar
                                    previousChat={previousChats}
                                    onSelectChat={handleSelectChat}
                                    getChats={handleNewchat}
                                    messages={[...messges].reverse()}
                                    chat_key={state.chat_key}
                                    handleQuestion={handleQuestion}
                                    workspace_id={loc?.state?.workspace_id}
                                    workspace_name={loc.state.workspace_name}
                                    handleUpdatedName={handleUpdatedName}
                                />
                            )}
                        </Sidenav.Body>
                    </Sidenav>
                ) : (
                    <ChatSidebar
                        previousChat={previousChats}
                        onSelectChat={handleSelectChat}
                        getChats={handleNewchat}
                        messages={[...messges].reverse()}
                        chat_key={state.chat_key}
                        handleQuestion={handleQuestion}
                        workspace_id={loc?.state?.workspace_id}
                        workspace_name={loc.state.workspace_name}
                        handleUpdatedName={handleUpdatedName}
                    />
                )}

                {((isMobile() && !expand) || !isMobile()) && (
                    <div className="chat-window">
                        <div
                            style={{ height: "100%" }}
                            id="botbar"
                            className="chat-dropdowns"
                        >
                            <div style={{ display: "flex", margin: "20px" }}>
                                <HandleDocTypeChangeComponent
                                    selectedDocType={state.selectedDocType}
                                    handleDocTypeChangeCallback={(e) => handleDocTypeChange(e)}
                                    documentTypes={state.existDocTypes ?? []}
                                    fileName={state.fileName}
                                />
                                <HandleEngineComponent
                                    selectedEngine={state?.selectedEngine}
                                    handleEngineChangeCallback={(e) => {
                                        setState({
                                            ...state,
                                            selectedEngine: parseInt(e?.target?.value),
                                        });
                                    }}
                                    fileName={state.fileName}
                                />
                            </div>
                            <div>
                                <div className="text-center">
                                    {state.currentDocNo !== 0 && ((state.currentDocNo + 1) !== (state.totalDocs + 1)) ? (
                                        <>
                                            <Spinning />
                                            {state.hasError === false ? `Processing ${state.currentDocNo + 1} of ${state.totalDocs} `: ""}
                                        </>
                                    ) : (
                                        ``
                                    )}
                                </div>
                            </div>
                            <div data-scroll className="chat-container">
                                <div
                                    className={`${messges.length > 0 && "messages"}`}
                                    style={{ padding: "10px !important" }}
                                >
                                    {messges?.length > 0 && (
                                        <button
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                width: "100%",
                                                padding: "10px",
                                            }}
                                        >
                                            <Export
                                                title={"Export chat to word"}
                                                onClick={(e) => {
                                                    generateDocumentForWorkspaceChat(
                                                        messges,
                                                        loc?.state?.workspace_name + " Chat",
                                                        "chatwithworkspace",
                                                        loc?.state?.data?.users,
                                                        state.existDocTypes,
                                                        state?.chatName
                                                    );
                                                }}
                                            />
                                        </button>
                                    )}
                                    {messges?.length > 0 &&
                                        messges?.map((msg, index) => {
                                            return (
                                                Object.entries(msg)?.length > 0 &&
                                                [
                                                    Object.entries(
                                                        messges[messges.length - (index + 1)]
                                                    )[0],
                                                ].map(([chat, value]) => {
                                                    return (
                                                        <div className="chat-item">
                                                            <div
                                                                className={`chat-title`}
                                                                style={{ fontWeight: "bold", display: "flex" }}
                                                            >
                                                                <div style={{ width: "90%" }}>
                                                                    {chat === undefined ? "NO question"
                                                                        : `${Object.entries(msg)?.length > 1 &&
                                                                            Object.keys(messges[messges.length - (index + 1)])?.includes("questionNo")
                                                                                ? messges[messges.length - (index + 1)]["questionNo"]
                                                                                : messges.length - index}. ${value[0]?.query}`}
                                                                </div>
                                                                <span
                                                                    className="mr-3"
                                                                    onClick={(e) =>
                                                                        generateDocumentForCannedListUsingMD(
                                                                            value,
                                                                            chat,
                                                                            "chatwithworkspace",
                                                                            loc
                                                                        )
                                                                    }
                                                                >
                                                                    <Export title={"Export question to word"} />
                                                                </span>

                                                                <span
                                                                    className="vertical_align_m"
                                                                    style={{ float: "inline-end" }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        let arr = isExpanded.filter(
                                                                            (x) => x.index !== index
                                                                        );
                                                                        arr.push({
                                                                            status: !isExpanded?.find(
                                                                                (x) => x.index === index
                                                                            )?.status,
                                                                            index: index,
                                                                        });
                                                                        setIsExpanded(arr);
                                                                    }}
                                                                >
                                                                    {isExpanded?.find((x) => x.index === index)
                                                                        ?.status ? (
                                                                        <Collapse title={"Collapse question"} />
                                                                    ) : (
                                                                        <Expand title={"Expand question"} />
                                                                    )}
                                                                </span>
                                                            </div>

                                                            {value?.length > 0 &&
                                                                value?.map((e, i) => {
                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            className={`chat-view ${isExpanded?.find(
                                                                                (x) => x.index === index
                                                                            )?.status
                                                                                    ? "expanded"
                                                                                    : "collapse"
                                                                                }`}
                                                                        >
                                                                            <ContentReviewSectionComponent
                                                                                key={i}
                                                                                chat_quesion={chat}
                                                                                question={
                                                                                    <p className="doc-color d-flex">
                                                                                        {e?.document_name +
                                                                                            " (" +
                                                                                            moduleNames?.find(
                                                                                                (x) => x.Id === e?.module_id
                                                                                            )?.loadingName +
                                                                                            (e?.batchName?.length > 0
                                                                                                ? "-" + e?.batchName
                                                                                                : "") +
                                                                                            ")"}
                                                                                        <p
                                                                                            onClick={() =>
                                                                                                openDocument(e?.doc_id)
                                                                                            }
                                                                                        >
                                                                                            <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                                                                                        </p>
                                                                                    </p>
                                                                                }
                                                                                time={e?.time}
                                                                                timetaken={parseInt(e?.timetaken) + "s"}
                                                                                response={e.response}
                                                                                fileName={e?.document_name}
                                                                                componenttitle="chatwithworkspace"
                                                                                isExpanded={isDocExpanded}
                                                                                created_by_name={e?.created_by_name}
                                                                                embeddings={""}
                                                                                engine_id={state.selectedEngine}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    );
                                                })
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {state.selectedDocType && (
                <div className="input-container">
                    <ChatComponent
                        module_name="chatWithWorkspace"
                        activeTab={0}
                        toggleTab={toggleTab}
                        selectedDocType={state.selectedDocType}
                        handleCannedQuestion={handleCannedQuestion}
                        handleBottomPanel={handleBottomPanel}
                        isBottomPanelOpen={true}
                        selectedCanned_Ques={state.selectedCanned_Ques}
                        promptDisplayed={state.promptDisplayed}
                        isShowFavorites={true}
                        chatType="chatWithWorkspace"
                        setLoaderStatus={setLoaderStatus}
                        isLoading={state.currentDocNo !== 0 && ((state.currentDocNo + 1) !== (state.totalDocs + 1))} //for chat disability if any question is processing
                        token={token}
                        selectedEngine={state.selectedEngine}
                        setReqStartTime={setReqStartTime}
                        inputValue={selectedPrompt?.id}
                        workspace_item_id={state.workspace_item_id}
                        workspace_id={loc.state.workspace_id}
                        workspace_name={loc?.state?.workspace_name}
                        chat_key={state?.chat_key}
                        chat_ref={ref}
                    />
                </div>
            )}
        </Fragment>
    );
};
export default WorkspaceChat;
