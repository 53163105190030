// plugins/disableOrderedLists.js
module.exports = () => (tree) => {
    visit(tree, "list", (node) => {
        if (node.ordered) {
            node.ordered = false;
        }
        node.data = node.data || {};
        
    });

};

const visit = (tree, type, callback) => {
    if (tree.type === type) {
        callback(tree);
    }
    if (tree.children) {
        tree.children.forEach((child) => visit(child, type, callback));
    }
};
