export function setTitle(title) {
    
    if (typeof title !== "string") {
        title="LawBotica Custom Trained AI Research Tool";

    }else{
        title=`${title}: LawBotica Custom Trained AI Research Tool`
    }
    document.title = title;
}





