import {
  faArrowLeft,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Resizable } from "re-resizable";
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { allCannedQuestions} from "../../../Helpers/UtilMethods.js";
import { socket } from "../../../contexts/socket.js";
import "../../../css/workspace.css";
import docTypes_ddl from "../../../dropDowns/docTypes.json";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { ToastAlert } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { SvgIconButton } from "../../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";
import ShareWorkSpacePopUp from "../../SupportiveComponents/PopUps/ShareWorkSpacePopUp.jsx";
import { isMobile } from "../../SupportiveComponents/PureHelpers.jsx";
import CaseAssessmentWorkspace from "./CaseAssessmentWorkspace.jsx";
import ChatWithDocWorkspace from "./ChatWithDocWorkspace.jsx";
import { BoxesGroup } from "./DraggableBoxex.jsx";
import ReviewWorkspace from "./ReviewWorkspace.jsx";
import SummarizeWorkspace from "./SummarizeWorkspace.jsx";
import WorkspaceChat from "./WorkspaceChat.jsx";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { a11yProps, CustomTabPanel } from "../Admin/AdminOperations.jsx";
import WorkspaceFilter from "./WorkspaceFilter.jsx";
import { EditIcon, SharIcon } from "../../SupportiveComponents/FontAwesomeComponents/HelperIcons.jsx";
import CreateWorkSpacePopUp from "../../SupportiveComponents/PopUps/CreateWorkSpacePopUp.jsx";

const WorkSpaceComponent = (props) => {
  const params = useParams();
  const [value, setValue] = useState([]);
  const [state, setState] = useState({
    view: false,
    isLoading: false,
    selectedDocType: "",
    selectedEngine: 2,
    isBottomPanelOpen: true,
    totalDocs: 0,
    currentDocNo: 0,
    existDocTypes: [],
    chat_key: uuidv4(),
    Docs:[],
    cannedQuestions:allCannedQuestions()
  });
  const nav = useNavigate();
  const [users, setUsers] = useState([]);
  const [userId] = useState(localStorage.getItem("user_id"));
  const [loading, setLoding] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [openSharePopUp, setOpenSharePopUp] = useState(false);
  const [docTypes] = useState(docTypes_ddl);
  const[tabValue, setTabValue]=useState(0)
  const [token] = useState(localStorage.getItem("token"));
  const[editWorkspace, setEditWorkspace]=useState(false)

  const ref = React.createRef()
  useEffect(()=>{
  ref.current?.focus()
  },[ref])

  const closeSharedWorkspacesPopup = (loader,userDetails) => {
    setOpenSharePopUp(false);
    if(loader){
      let updatedData=loc.state;
      let newUsers=[...loc.state?.data?.users];
      userDetails.forEach(element => {
        newUsers?.push({
          full_name:element?.full_name,
          organization_id: element?.organization_id,
          user_id:element?.user_id,
          user_name:element?.user_name})
      });
      updatedData.data.users=newUsers
      nav('/Workspaces', { state: updatedData});
    }
  };

  const loc = useLocation();

  useEffect(() => {
    if (params?.workspace_id) {
      loc.state = {};
      loc.state.workspace_id = params?.workspace_id;
    }
  }, [loc, params?.workspace_id]);

  const getWorkspaceItems = useCallback(
    (stateLoc) => {
      const axiosInstance = getAxiosInstance(token);

      axiosInstance
        .get(`/workspaceitems/all?id=${stateLoc}`, { timeout: 180000 })
        .then((res) => {
          setValue({
            isSharedWorkspace:
              res?.data?.shared_users?.length > 0 ? true : false,
            workspace_name: loc?.state?.workspace_name,
            workspace_id: stateLoc,
            data: { workspace_items: res?.data?.workspace_items },
          });

          setLoding(false);
          let existDocTypes = [];
          let totalDocTypes=[...docTypes_ddl.SummarizeDocType, ...docTypes_ddl.ReviewdocType,...docTypes_ddl.ChatwithDocType]
          totalDocTypes.forEach((element, index) => {
            if (
              res?.data?.workspace_items?.length > 0 &&
              res?.data?.workspace_items?.filter(
                (x) => x.document_type === element.documentID
              )?.length > 0 &&
              existDocTypes.filter((x) => x.documentID === element.documentID)
                .length === 0
            ) {
              existDocTypes.push(element);
            } else if (
              res?.data?.workspace_items?.length > 0 &&
              res?.data?.workspace_items?.filter(
                (x) => x.document_type === "medical_Reports_Records"
              )?.length > 0 &&
              element.documentID === "medical_Records" &&
              existDocTypes.filter((x) => x.documentID === element.documentID)
                .length === 0
            ) {
              existDocTypes.push({
                documentName: "Medical Records",
                documentID: "medical_Records",
              });
            }
          });

          setState((prevState) => {
            if (prevState.existDocTypes.length !== existDocTypes.length) {
              return {
                ...prevState,
                existDocTypes: existDocTypes,
                selectedDocType:
                  existDocTypes.length > 0 ? existDocTypes[0].documentID : "",
                selectedCanned_Ques: existDocTypes.length > 0
                    ? state.cannedQuestions[existDocTypes[0].documentID + "_Canned_Ques"]
                    : "",
              };
            }
            return prevState;
          });
        });
    },
    [token, setValue, loc.state?.workspace_name, setState,state.cannedQuestions]
  );

  const getUsers = useCallback(() => {
    if(token){
    const axiosInstance = getAxiosInstance(token);
    axiosInstance
      .post("/admin/getOrgData", {}, { timeout: 180000 })
      .then((res) => {
        setUsers(res.data);
      }).catch((error) => {
        let status = error.response.status;
        let error_text =
            "Error while Logging you out, please try after some time.";
        if (status === 401 || status === 422) {
            props.removeToken();
            nav("/Login");
        } else
            Swal.fire({
                text: error_text,
                icon: "error",
                confirmButtonText: "OK",
            });
    });
    }
  }, [token,nav,props]);

  useEffect(() => {
    socket.on("workspaceitemliveupdates", (data) => {
      if (loc.state?.workspace_id === data) {
        getUsers();
        const axiosInstance = getAxiosInstance(token);
        axiosInstance
          .get(`/workspaceitems/all?id=${loc.state.workspace_id}`, {
            timeout: 180000,
          })
          .then((res) => {
            setValue({
              isSharedWorkspace:
                res.data?.shared_users?.length > 0 ? true : false,
              workspace_name: loc?.state?.workspace_name,
              workspace_id: loc?.state?.workspace_id,
              data: {
                workspace_items: res.data?.workspace_items,
              },
            });
          });
      }
    });
    return () => socket.off("workspaceitemliveupdates");
  }, [getUsers, token, loc?.state?.workspace_name, loc.state?.workspace_id]);

  useEffect(() => {
    if (loc?.state?.workspace_id === 0) {
    } else if (loc?.state?.workspace_id !== 0) {
      setLoadingText("Loading workspace items...");
      setLoding(true);
      // getUsers();
      getWorkspaceItems(loc?.state?.workspace_id);
    }
  }, [nav, loc?.state?.workspace_id, getWorkspaceItems]);

  useEffect(() => {
    getUsers(); // This effect will run once on mount
  }, [getUsers]); // Empty dependency array ensures it runs only once on mount

  const closePopUp = () => {
    nav("/Workspacelist", {
      state: {
        activeTab: loc?.state?.activeTab ? loc?.state?.activeTab : 0,
      },
    });
  };

  const DeleteWorkspaceItem = async (data) => {
    const axiosInstance = getAxiosInstance(token);
    try {
      const result = await Swal.fire({
        html: `Are you sure you want to delete ${data.document_name}?`,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonText: "Yes",
        confirmButtonTextColor: "#2eaadb",
        focusCancel: true,
        customClass: {
          confirmButton: "swal-button",
          cancelButton: "swal-button",
        },
      });

      if (result.isConfirmed) {
        setLoadingText(`Deleting ${data.document_name}`);
        setLoding(true);
        axiosInstance
          .delete(
            `/workspace/item?ws_id=${data.workspace_id}&ws_item_id=${data.id}`,
            { timeout: 180000 }
          )
          .then((res) => {
            getWorkspaceItems(data.workspace_id);
            ToastAlert("Workspace item deleted successfully");
          });
      }
    } catch (exception) {
      setLoding(false);
    }
  };

  const DeleteWorkspace = async () => {
    const axiosInstance = getAxiosInstance(token);
    try {
      const result = await Swal.fire({
        html: `Are you sure to want to delete ${loc?.state?.workspace_name} workspace and the documents & any chat that's contained in the workspace?`,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonText: "Yes",
        confirmButtonTextColor: "#2eaadb",
        focusDeny: true,
      });

      if (result.isConfirmed) {
        setLoadingText(`Deleting ${loc.state.workspace_name} workspace`);
        setLoding(true);
        axiosInstance
          .delete(`/workspace?ws_id=${loc.state.workspace_id}&ws_name=${loc?.state?.workspace_name}`, {
            timeout: 180000,
          })
          .then((res) => {
            setLoding(false);
            ToastAlert("Workspace deleted successfully");
            setTimeout(() => {
              nav("/Workspacelist");
            }, 1000);
          });
      }
    } catch (exception) {
      setLoding(false);
    }
  };

  const handleToggleDocumentViewer = () => {
    var mobile = isMobile();
    setState({
      ...state,
      view: !state.view,
      isMobile: mobile,
    });
    props.scrollUpdate(!state.view)
  };
  
  const handleChange = (event, newValue) => {
    // GetWorkspaces();
    setTabValue(newValue);
  };

  return (
    <Fragment>
      {openSharePopUp && (
        <ShareWorkSpacePopUp
          closepopup={(loader,userDetails) => closeSharedWorkspacesPopup(loader,userDetails)}
          workSpaceDisabled={true}
          data={loc.state?.data}
          users={users}
        />
      )}
      {editWorkspace && 
        <CreateWorkSpacePopUp
          closepopup={()=>{return setEditWorkspace(false)}}
          content={""}
          workSpace={loc?.state?.workspace_name}
          workspaceId={loc?.state?.workspace_id}
          baseContent={""}
          document_type={""}
          document_name={""}
          mime_type={""}
          timeTaken={0}
          CannedQuestionsList={[]}
          workspaces={loc?.state?.allWorkSpaces}
          getNameBack={(values) => {
            
          }}
          handwritten_text_flag={false}
          closeworkspacelist={(res) => {
            setEditWorkspace(false);
            nav('/Workspaces', {
            state: {
                workspace_id: loc?.state?.workspace_id, workspace_name: res.name,data:{...loc?.state?.data, name:res?.name},
                isSharedWorkspace: loc?.state?.isSharedWorkspace,
            }
        })
          }}
        />
      }
     
      <div className=" full_screen midPanel_Container midPanel_Container_gap">
        <div className="midPanel_ca_Item1 width-0">
          <div >
            <h2
              className="rytpaneltoppart pt-1 bg-color"
              style={{ position: "sticky", zIndex: 8, top: 0 }}
            >
              <button
                style={{
                  color: "#2eb1e3",
                  marginRight: "1%",
                  marginLeft: "1%",
                  fontSize: "20px",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  title="BACK TO LIST"
                  onClick={() => closePopUp()}
                />
              </button>
              <div className="w-100">
               <div className="d-flex flex-wrap" style={{width:'100%'}}> <div style={{color:'#999'}} className="space"> WORKSPACE: </div> 
               <div> {loc?.state?.workspace_name}</div>
               {loc?.state?.data?.created_by === parseInt(userId) && 
               <div  style={{marginLeft:'1%'}}><EditIcon style={{cursor:'pointer',color:'#999',marginLeft:'2%'}} onClick={()=>{setEditWorkspace(true,params?.data)}}/></div>
               }
               </div>
               
              </div>
          
                <div className="align-icons">
                {value?.data?.workspace_items?.length>0 && isMobile() &&
                  <button
                   onClick={()=>{nav("/WorkSpace-Chat",{state:{docTypes:state.existDocTypes, workspaceItems:value?.data?.workspace_items,...loc?.state}})}}
                    style={{
                      color: "#2eb1e3",
                      marginLeft: "60%",
                      fontSize: "20px",
                    }}
                  >
                   Chat
                  </button>
                }
                {loc?.state?.data?.created_by === parseInt(userId) && <>   <button
                    style={{
                      color: "#2eb1e3",
                      marginLeft: "3%",
                      fontSize: "20px",
                    }}
                  >
                    <FontAwesomeIcon
                      title="DELETE"
                      icon={faTrashCan}
                      onClick={() => DeleteWorkspace()}
                    />
                  </button>
                  <button
                    style={{
                      color: "#2eb1e3",
                      marginRight: "8%",
                      fontSize: "20px",
                      paddingLeft:'2%'
                    }}
                  >
                  <SharIcon  onClick={() => {
                        setOpenSharePopUp(!openSharePopUp);
                      }}/>
                  
                    
                  </button>
                  </>}
                </div>
              
            </h2>
       <Box  sx={{ width: "99%", marginLeft: "1%",borderBottom:'1px solid var(--clr-border)'}}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="By Type of Document"
              sx={
                tabValue === 0
                  ? {
                      backgroundColor: "var(--clr-sidebar-buttons-background)",
                      borderRadius: "10px",
                      color: "#fff !important",
                    }
                  : {}
              }
              {...a11yProps(0)}
            />

            <Tab
              label="By Module"
              sx={
                tabValue === 1
                  ? {
                      backgroundColor: "var(--clr-sidebar-buttons-background)",
                      borderRadius: "10px",
                      color: "#fff !important",
                    }
                  : {}
              }
              {...a11yProps(1)}
            />
           
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
        {loading &&  <LoadingSpinner text={loadingText}></LoadingSpinner> } 
        <WorkspaceFilter
            value={value}
            existDocTypes={value?.data?.workspace_items?.filter(x=>x.module_id === 6).length>0?[...state.existDocTypes,...docTypes_ddl.CaseAnalysis]:[...state.existDocTypes]}
                    users={users}
                    docTypes={docTypes}
                    DeleteWorkspaceItem={DeleteWorkspaceItem}
                    />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
            {loading &&  <LoadingSpinner text={loadingText}></LoadingSpinner> } 
             <div className="ws-module-ht">
              <BoxesGroup
                element={
                  <ReviewWorkspace
                    value={value}
                    users={users}
                    docTypes={docTypes}
                    DeleteWorkspaceItem={DeleteWorkspaceItem}
                  />
                }
                element2={
                  <SummarizeWorkspace
                    value={value}
                    users={users}
                    docTypes={docTypes}
                    DeleteWorkspaceItem={DeleteWorkspaceItem}
                  />
                }
                element3={
                  <ChatWithDocWorkspace
                    value={value}
                    users={users}
                    docTypes={docTypes}
                    DeleteWorkspaceItem={DeleteWorkspaceItem}
                  />
                }
                element4={
                  <CaseAssessmentWorkspace
                    value={value}
                    users={users}
                    docTypes={docTypes}
                    DeleteWorkspaceItem={DeleteWorkspaceItem}
                  />
                }
              >
                {" "}
              </BoxesGroup>
              </div>
          
            </CustomTabPanel>
          </div>
        </div>
        {value?.data?.workspace_items?.length>0 && !isMobile() &&
        <Resizable
        enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
          className={`midPanel_Item2 flex-grow-0 z-index-8 ${
            state.view
              ? "expandable-border-left  expandable-bar-box-shadow"
              : "collapse"
          }`}
        
          defaultSize={{ width: "50%", height: "auto" }}
          key={"test1"}
        >
          <SvgIconButton
            isexpanded={state.view}
            togglecallback={handleToggleDocumentViewer}
          />
          <div data-scroll>
          <WorkspaceChat
           WorkspaceItems={value?.data?.workspace_items} 
           docTypes={state.existDocTypes}
            locState={{...loc?.state}}/>
            </div>
         

          <p
            onClick={() => handleToggleDocumentViewer()}
            className=" active_side_doc_name text-center"
            style={{ height: "100%",width:"100%",cursor:'pointer' }}
          >
            Chat With All Docs
          </p>
    
        </Resizable>
        }
      </div>
      <Toaster />
    </Fragment>
  );
};
export default WorkSpaceComponent;
