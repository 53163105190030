import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@rsuite/icons/Close";
import React, { createRef, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import Input from "rsuite/Input";
import "rsuite/Input/styles/index.css";
import InputGroup from "rsuite/InputGroup";
import "rsuite/InputGroup/styles/index.css";
import Loader from "rsuite/Loader";
import "rsuite/Loader/styles/index.css";
// import getInstance from "../../SupportiveComponents/AxiosRequest";
import {
  CloseSubItems,
  ShowSubItems,
} from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart";
import KebabMenu from "./KebabMenu";
import { socket } from "../../../contexts/socket.js";
import store from "../../../redux/store.js";

const ChatSidebar = ({
  onSelectChat,
  previousChat,
  getChats,
  messages,
  chat_key,
  handleQuestion,
  workspace_id,
  workspace_name,
  handleUpdatedName
}) => {
  // const [token] = useState(localStorage.getItem("token"));
  const [isSearch, setIsSearch] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [previousChats, setPreviousChats] = useState([]);
  const [state, setState] = useState({
    openTextBox: false,
    openTextBoxId: 0,
    chatInput: "",
    isLoading: false,
    searchInput: "",
    showSubList: [],
    oldChatInput:""
    
  });
  // const nav = useNavigate();

  const toggleMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };
  const inputRef = createRef(null);
  useEffect(()=>{
    inputRef.current?.focus()
    },[inputRef])

  const handleFocus = () => {
    inputRef.current?.select();
  };

  const updateChat = (id, chat) => {
    setState({
      ...state,
      openTextBox: true,
      openTextBoxId: id,
      chatInput: chat,
      oldChatInput:chat
    });
    toggleMenu(id);
    inputRef.current?.focus()
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement?.addEventListener("focus", handleFocus);

      return () => {
        inputElement?.removeEventListener("focus", handleFocus);
      };
    }
  };
  React.useEffect(() => {
    socket.on("workspace_chat_rename_response", (data)=>{
      previousChat.find(x=>x.id === data?.res?.id).display_name = data?.res?.updated_name
      if (handleUpdatedName) {
      handleUpdatedName(data?.res?.updated_name)
      }
      // getChats();
      setState((prevState) => {
        return {
          ...prevState,
         openTextBox: false,
        openTextBoxId: 0,
        isLoading: false,
        };
      });
      })
    return () => socket.off("workspace_chat_rename_response");
  }, [getChats, previousChat, handleUpdatedName]);



  const updateChatInput = (obj) => {
    setState({ ...state, isLoading: true });
    let new_response = "";
    Object.entries(obj?.response).map(
      ([key, value]) =>
        (new_response = JSON.stringify({ [Object.keys(obj?.response)?.length > 0? Object.keys(obj?.response)[0] : state.chatInput]: value }))
    );
    let data = {
      chat_id: obj?.id,
      new_response: new_response,
      dispalyName: state.chatInput,
      workspace_id: workspace_id,
      workspace_name: workspace_name,
      old_chat_name:state.oldChatInput,
      user_name: store?.getState()?.login?.logInState?.user_name,
      obj:obj
    }
    socket.emit("workspace_chat_rename", data)
  };

  useEffect(() => {
    setPreviousChats(previousChat);
  }, [previousChat]);

  const searchChats = (searchInput) => {
    let searchData = [];
    previousChat?.map((obj, index) =>
      Object.keys(obj?.response).map((chat) => {
        if (obj?.display_name &&
          obj?.display_name
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        ) {
          searchData.push(obj);
        }
        return null;
      })
    );
    setPreviousChats(searchData);
  };

  const handleSubList = (obj) => {
    if (state.showSubList.filter((x) => x.id === obj?.id).length > 0) {
      setState({
        ...state,
        showSubList: [...state.showSubList.filter((x) => x.id !== obj?.id)],
      });
    } else {
      setState({ ...state, showSubList: [obj, ...state.showSubList] });
    }
  };
  const handleKeyDown = (event, obj) => {
    if (event.key === "Enter") {
      updateChatInput(obj);
      // Perform the desired action here, e.g., submitting the input value
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // setState({...state,    openTextBox: false, openTextBoxId: 0,})
        setState((prevState) => {
          return { ...prevState, openTextBox: false, openTextBoxId: 0 };
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="sidebar">
      <div className="input-container bt-none">
        <h4>Previous Chats</h4>
        {!isSearch && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="icon-color cursor-pointer"
            fill="none"
            onClick={() => {
              setIsSearch(true);
            }}
          >
            <mask
              id="mask0_1155_7487"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1155_7487)">
              <path
                stroke="currentColor"
                d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
        )}
        <svg
          className="icon-color cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={() => getChats()}
        >
          <path
            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isSearch && (
        <div className="d-flex">
          <InputGroup>
            <Input
              value={state.searchInput}
              onChange={(e) => {
                if (e === "") {
                  setPreviousChats(previousChat);
                }
                if (e?.length > 3) {
                  searchChats(e);
                  // setPreviousChats(previousChat)
                }
                setState({ ...state, searchInput: e });
              }}
            />
          </InputGroup>
          <CloseIcon
            className="ml-3"
            width={30}
            height={40}
            onClick={() => {
              setIsSearch(false);
              setPreviousChats(previousChat);
            }}
            title="Close"
          />
        </div>
      )}
      <div className="chat-list">
        {previousChats?.map((obj, index) => {
          return (
            <li key={index}>
              {obj?.response && 
                Object.keys(obj?.response).map((chat) => {
                  return (
                    <div
                      className={`chat-item  ${
                        chat_key === chat ? "selected-question" : ""
                      }`}
                    >
                      <div className={`chat-title chat-header `}>
                        {state.openTextBox &&
                        state.openTextBoxId === obj?.id ? (
                          <InputGroup inside>
                            <Input
                            ref={inputRef}
                            onFocus={()=>handleFocus()}
                              value={state.chatInput}
                              onKeyDown={(e) => handleKeyDown(e, obj)}
                              onChange={(e) => {
                                setState({ ...state, chatInput: e });
                              }}
                            />
                            <InputGroup.Addon>
                              <Whisper
                                placement="top"
                                speaker={<Tooltip> Edit chat</Tooltip>}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  title="EDIT"
                                  onClick={() => updateChatInput(obj)}
                                  color="#666"
                                  style={{ cursor: "pointer" }}
                                />
                              </Whisper>
                              <Whisper
                                placement="top"
                                speaker={<Tooltip> Cancel</Tooltip>}
                              >
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  title="CANCEL"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      openTextBox: false,
                                      openTextBoxId: 0,
                                    });
                                  }}
                                  color="#666"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "15%",
                                  }}
                                />
                              </Whisper>
                            </InputGroup.Addon>
                          </InputGroup>
                        ) : (
                          <>
                            <div
                              style={{ width: "85%" }}
                              onClick={() => onSelectChat(obj)}
                            >
                              {" "}
                              {obj?.display_name
                                ? obj?.display_name
                                : obj?.response &&
                                  obj?.response[chat]?.length > 0 &&
                                  Object.keys(obj?.response[chat][0])[0]}{" "}
                            </div>
                          </>
                        )}
                        {state.openTextBox &&
                        state.openTextBoxId === obj?.id &&
                        state.isLoading ? (
                          <Loader />
                        ) : state.openTextBox &&
                          state.openTextBoxId === obj?.id ? (
                          <></>
                        ) : (
                          <div style={{ width: "15%" }}>
                            {state.showSubList.filter((x) => x.id === obj?.id)
                              .length > 0 ? (
                              <CloseSubItems
                                onClick={() => handleSubList(obj)}
                                style={{
                                  color: "#2eb1e3",
                                  fontSize: "24px",
                                  paddingRight: "10px",
                                }}
                              />
                            ) : (
                              <ShowSubItems
                                onClick={() => handleSubList(obj)}
                                style={{
                                  color: "#2eb1e3",
                                  fontSize: "24px",
                                  paddingRight: "10px",
                                }}
                              />
                            )}
                            <KebabMenu
                              getChats={getChats}
                              id={obj?.id}
                              chat={
                                obj?.display_name
                                  ? obj?.display_name
                                  : obj?.response &&
                                    obj?.response[chat]?.length > 0 &&
                                    Object.keys(obj?.response[chat][0])[0]
                              }
                              isOpen={openMenuId === index}
                              toggleMenu={() => toggleMenu(index)}
                              updateChat={updateChat}
                              workspace_name={workspace_name}
                            />
                          </div>
                        )}
                      </div>
                      <ul>
                        {state?.showSubList?.length > 0 &&
                          obj.response[Object.keys(obj.response)[0]] &&
                          obj.response[Object.keys(obj.response)[0]]?.map(
                            (element, index) => {
                              return state?.showSubList?.map((e, i) => {
                                if (Object.keys(e?.response)[0] === Object.keys(obj.response)[0]){
                                  return (
                                    <li
                                      style={{
                                        paddingLeft: "10%",
                                        listStyleType: "disc !important",
                                      }}
                                      onClick={() =>
                                        handleQuestion(element, chat, index + 1)
                                      }
                                    >
                                      {index + 1}. {element[Object.keys(element)[0]]?.length>0 ?element[Object.keys(element)[0]][0]?.query:""}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              });
                            }
                          )}
                      </ul>
                    </div>
                  );
                })}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default ChatSidebar;
