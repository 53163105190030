import { Fragment, useEffect, useState } from "react";
import docTypes_ddl from "../../../dropDowns/docTypes.json";
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid.jsx";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { convertGMTToLocal } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";
import ShowLogsPopUp from "../../SupportiveComponents/PopUps/ShowLogsPopUp.jsx";
import modules from "../../../dropDowns/moduleNames.json"

const ResponseLogs = (props) => {
  const [responses, setRespones] = useState();
  const [loading, setLoading] = useState(false);
  // const [openPopUp, setOpenPopUp] = useState(false);
  const[state,setState]=useState({openPopUp:false,overViewData:{}})
  const[docTypes] =useState([...docTypes_ddl.SummarizeDocType, ...docTypes_ddl.ReviewdocType,...docTypes_ddl.ChatwithDocType])

  useEffect(() => {
    const axiosInstance = getAxiosInstance(props.token, false, props?.history);
    setLoading(true);
    try{
        axiosInstance.get("/logs/get", { timeout: 180000 }).then((res) => {
            if (res?.status === 200) {
              setRespones(res?.data?.response);
              setLoading(false);
            }
          });
    }
    catch{
        setLoading(false)
    }
   
  }, [props.token, props?.history]);
  return (
    <Fragment>
    {state.openPopUp && <ShowLogsPopUp closeWsListpopup={()=>{setState({openPopUp:!state.openPopUp})}} data={state.overViewData}/>}
    {loading && <LoadingSpinner/>}
    <div className="rytpaneltoppart  flex-flow-wrap">
    <div className="reviewfirstdiv">
    <h2>Response Logs</h2>
    </div>
    </div>
      <div
        className="ag-theme-quartz"
        style={{ height: "100%", width: "100%",padding:'1% 2% 4% 2%' }}
      >
        <CustomTable
          defaultcoldefs={{
            // flex: 1,
            resizable: true,
            editable: false,
            autoHeight: true,
            sortable: true,
          }}
          columndefs={[
            {
              headerName: "Prompt",
              field: "prompt",
              // cellRenderer: (params) => {
              //     return <p className="text-link" onClick={() => RedirectToReview(params.data)}>{params.data.document_name}</p>
              // },
              sortable: true,
              resizable: true,
              supressSizeToFit: true,
              filter: true,
              tooltipField: "prompt",
              headerTooltip: "Prompt",
            },
            {
              headerName: "RESPONSE",
              field: "response",
              // cellRenderer: (params) => { return <p>{docTypes_ddl.ReviewdocType?.find(x => x.documentID === params.data.RESPONSE_PART)?.documentName}</p> },
              sortable: true,
              resizable: true,
              supressSizeToFit: true,
              filter: true,
              tooltipField: "response",
              headerTooltip: "RESPONSE",
            },
            {
              headerName: "Doc Type",
              field: "doc_type",
              cellRenderer: (params) => { return <p>{docTypes?.filter(x => x.documentID === params.data?.doc_type)?.length>0 && docTypes?.filter(x => x.documentID === params.data?.doc_type)[0]?.documentName}</p> },
              sortable: true,
              resizable: true,
              supressSizeToFit: true,
              filter: true,
              tooltipField: "doc_type",
              headerTooltip: "Doc Type",
              maxWidth:250,
            },
            {
              headerName: "Module",
              field: "module_id",
              cellRenderer: (params) => { return <p>{modules?.filter(x => x.Id === params.data?.module_id)?.length>0 && modules?.filter(x => x.Id === params.data?.module_id)[0]?.loadingName}</p> },
              sortable: true,
              resizable: true,
              supressSizeToFit: true,
              filter: true,
              tooltipField: "module_id",
              headerTooltip: "Module",
              maxWidth:250,
            },
            // {
            //   headerName: "Module",
            //   field: "module_id",
            //   // cellRenderer: (params) => { return <p>{docTypes_ddl.ReviewdocType?.find(x => x.documentID === params.data.RESPONSE_PART)?.documentName}</p> },
            //   sortable: true,
            //   resizable: true,
            //   supressSizeToFit: true,
            //   filter: true,
            //   tooltipField: "module_id",
            //   headerTooltip: "Module",
            // },
            {
              headerName: "Create At",
              field: "datereccreate",
              cellRenderer: (params) => { return <p>{convertGMTToLocal(params?.data?.datereccreate)}</p> },
              sortable: true,
              resizable: true,
              supressSizeToFit: true,
              filter: true,
              maxWidth:250,
              tooltipField: "datereccreate",
              headerTooltip: "Create At",
            },
            // {
              // headerName: "Create At",
              // field: "datereccreate",
            //   cellRenderer: (params) => { return  <ShowPassword className="show-prompts" style={{alignContent:'center',position:'initial',right:'0px' }}
            //    title="Show Response"
            //    onClick={{}}/>},
            //   sortable: true,
            //   resizable: true,
            //   supressSizeToFit: true,
            //   filter: true,
            //   maxWidth:50,
            //   tooltipField: "datereccreate",
            //   headerTooltip: "Create At",
            // },
          ]}
          rowdata={responses}
          activeTab={"01"}
          pageSize={20}
          noItemsMessg={"No Docs"}
          onRowClicked={(params)=>{setState({openPopUp:!state.openPopUp, overViewData:params?.data})}}
        ></CustomTable>
      </div>
    </Fragment>
  );
};
export default ResponseLogs;
