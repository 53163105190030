import { isEqual } from "lodash";
import React, { Component, Fragment, createRef } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { socket } from "../../contexts/socket.js";
import prompts_ddl from "../../dropDowns/ChatWithDocTypes.json";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import { setTitle } from "../../js/setTitle.js";
import { ChatWithDocsInitialState, initialState } from "../../redux/Reducers/chatWithDocReducer.js";
import {
  updateChatWithDocState,
  updateDashBoardState,
} from "../../redux/actions";
import getAxiosInstance, {
  Createaccesslog,
} from "../SupportiveComponents/AxiosRequest.jsx";
import ChatComponent from "../SupportiveComponents/ChatComponent.jsx";
import { convertUtcToLocal } from "../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { TitleOfComponent } from "../SupportiveComponents/CustomContentOperations/CustomContentViewer.jsx";
import ErrorBoundary from "../SupportiveComponents/CustomErrorBoundary.jsx";
import { BatchComponent } from "../SupportiveComponents/CustomPopup.jsx";
import {
  CustomDocViewer,
  CustomExpandableDocComponent,
  CustomMultiDOcViewer,
} from "../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import FavoritesComponent, {
  handleWarnRemoveFavorite,
} from "../SupportiveComponents/FavoriteComponent";
import CustomWindowPopupComponent from "../SupportiveComponents/FavouritesPopupContent.jsx";
import FileProcessing from "../SupportiveComponents/FileProcessing";
import { Spinning } from "../SupportiveComponents/FontAwesomeComponents/HelperIcons.jsx";
import { HistoryComponent } from "../SupportiveComponents/HistoryComponent.jsx";
import { CustomHistoryFavoriteLinks } from "../SupportiveComponents/HistoryandFavoritesLinks.jsx";
import  { LoadingSpinnerSticky } from "../SupportiveComponents/LoadingSpinner";
import {
  CWDReviewTextComponent,
  DocumentUploadedHelperDisplayComponent,
  HandleBatchFileNameComponent,
  HandleCancelRequestButtonComponent,
  HandleDocTypeChangeComponent,
  HandleEngineComponent,
  HandleFileNameComponent,
  HandleNewButtonComponent,
  HandleRefreshButtonComponent,
  HandwrittenComponent,
} from "../SupportiveComponents/ModuleHelpers.jsx";
import WorkSpaceListPopUp from "../SupportiveComponents/PopUps/WorkspaceListPopUp.jsx";
import { dataURLtoFile } from "../SupportiveComponents/PureHelpers.jsx";
import ShowAllWorkspacePopUp from "../SupportiveComponents/PopUps/ShowAllSelectedWorkspacePopUp.js";
import HelperMessages from "../../Helpers/HelperMessages.js";
import store from "../../redux/store.js";
class ChatWithDocComponent extends Component {
    module_id = docTypes_ddl.Modules["ChatwithDocType"].moduleId;
    module_name = docTypes_ddl.Modules["ChatwithDocType"].moduleName;
    HistoryComponentRef = React.createRef();
    FavoriteComponentRef = React.createRef();
    TextComponentRef = React.createRef();
    state = {
        showMsgBoxAfterUpload: false,
        isBottomPanelOpen: true, // isBottomPanelOpen to detect if ChatComponent is in open/closed state by default it is open
        selectedDocType: "",
        selectedEngine: 2,
        selectedFile: "",
        chatResult: "",
        isUploadFileOpen: true,
        promptDisplayed: HelperMessages.PROMPT_PLACEHOLDER,
        activeTab: 0,
        favoriteFiles: [],
        isLoading: false,
        loaderText: "Analysis in progress...",
        fileName: "",
        chatTimer: "",
        Chats: {},
        isSocketRequestProgress: false,
        inputValue: "",
        selectedCanned_Ques: [],
        historyList: [],
        isHandWritten: false, // isHandWritten flag detects if the file is handwritten
        uploadFileId: undefined,
        fromDashBoard: false,
        fromDashBoardLinkType: "",
        batchFiles: [],
        invalidFiles: [],
        isRenaming: false,
        selectedPrompt: {},
        blobFilesList: [], // List used to open doc in the bottom bar
        reqStartTime: new Date().getTime(), // state variable used to calculate response time
        batchId: null,
        popupActivebatchFiles: [],
        popupActivebatchName: "",
        isDocumentOpen: false,
        favdisplayval: false,
        hisdisplayval: false,
        isExpanded: true,
        openCreateWorkSpacePopUp: false,
        baseContent: "",
        chatResponseTimer: "",
        chatResponse: "",
        chats_list: [],
        docsContent: [],
        index_list: [],
        isIndexCreated: false,
        disableAddToWorkspace: false,
        isSharedWorkspace: false,
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        workspace_id: 0,
        workspace_item_id: 0,
        ws_count: 0,
        workspace_name: "",
        ws_shared_users: [],
        addToAnotherWorkspace: false,
        moveToAnotherWorkspace: false,
        doc_help_questions: [],
        isFromFavorites: false,
        isFromHistory: false,
        closeKebabMenu: false,
    };
    setReqStartTime = (time) => {
        try {
            this.setState(
                { reqStartTime: time, isSocketRequestProgress: true },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    handleUpdateHistoryList = (hlist) => {
        this.setState({ historyList: hlist }, () => {
            this.props.updateChatWithDocState(this.state);
        });
    };

    handleUpdateLoader = (obj) => {
        this.setState({ ...obj }, () => {
            this.props.updateChatWithDocState(this.state);
        });
    };
    handleToggleDocumentViewer = (val) => {
        this.setState({ isDocumentOpen: val }, () => {
            this.props.updateChatWithDocState(this.state);
        });
        this?.props?.setPosition({
            document: val,
            chatComponent: this.state.isBottomPanelOpen,
        });
    };
    handleCannedQuestion = (question) => {
        try {
            if (this.state.selectedFile !== "") {
                let selectedPrompt = question.id
                    ? question
                    : { id: question, value: question };

                this.setState(
                    {
                        ...this.state,
                        promptDisplayed: question.id ? question.id : question,
                        selectedPrompt,
                        isLoading: true,
                        isBottomPanelOpen: true,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                        this?.props?.setPosition({
                            document: this?.state?.isDocumentOpen,
                            chatComponent: true,
                        });
                    }
                );
            }
        } catch (error) {
            this.setLoaderStatus(false, true);
            console.error("Error:", error);
        }
    };

    componentDidMount() {
        let dashBoardState = this.props.dashBoardState;
        if (dashBoardState && dashBoardState.source) {
            this.removeWorkspaceDetails();
            this.handleLinkFromDashBoard(dashBoardState);
        } else {
            this.setState({ fromDashBoard: false }, () => {
                this.props.updateChatWithDocState(this.state);
            });
            this.getMessages();
        }
        window.addEventListener("beforeunload", this.handleClearTheDictionary);
        setTitle("Chat with Docs");
        this?.props?.setPosition({
            document: this.state?.isDocumentOpen,
            chatComponent: this.state.isBottomPanelOpen,
        });
        this.scrollToBottom();
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevProps.chatWithDocState, this.props.chatWithDocState) ||
            !isEqual(this.state.Chats, this.props.chatWithDocState.Chats)
        ) {
            // State has changed, trigger re-render
            console.log("state has been triggered - chat with docs ");
            this.setState({ ...this.props.chatWithDocState });
            this.scrollToBottom();
            
        }
    }
    componentWillUnmount() {
        window.removeEventListener(
            "beforeunload",
            this.handleClearTheDictionary
        );
    }
    scrollToBottom = () => {
        this?.messagesEnd?.scrollIntoView({ behavior: "smooth" });
    };
    getUploadFileIdBool = () =>
        this.state.uploadFileId ? this.state.uploadFileId?.length > 0 : false;
    handleLinkFromDashBoard(dashBoardState) {
        let stateObj = {};
        console.log("Handle Link from Dashboard", dashBoardState);
        if (dashBoardState.linkType?.length > 0) {
            stateObj = { fromDashBoardLinkType: dashBoardState.linkType };
        } else {
            stateObj = {
                fileName: dashBoardState.fileName,
                selectedDocType: dashBoardState.selectedDocType,
                batchId: dashBoardState?.batchId,
                selectedEngine: dashBoardState.selectedEngine
                    ? dashBoardState.selectedEngine
                    : 1,
            };
        }

        switch (dashBoardState.source) {
            case "favorites":
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        batchId: dashBoardState.batchId,
                        favdisplayval:
                            Object.prototype.hasOwnProperty.call(
                                stateObj,
                                "fromDashBoardLinkType"
                            ) > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                    },
                    () => {
                        console.log(this.state.fileName);
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                            disableAddToWorkspace: false,
                        };

                        this.props.updateDashBoardState(dashBoardState);

                        console.log(
                            "Chat With a Doc Component State",
                            this.state
                        );
                        if (this.state.fileName) {
                            this.FavoriteComponentRef?.current?.RetrieveFileFromFavorites(
                                this.state.fileName,
                                this.module_id,
                                this.state.batchId, //batchid assuming it as null as there are multi files upload here
                                this.state.batchId === null ? 0 : 1, // isBatch is 0
                                this.handleRetrieveFileState
                            );
                        }
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                break;
            case "history":
                console.log("From History");
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        hisdisplayval:
                            Object.prototype.hasOwnProperty.call(
                                stateObj,
                                "fromDashBoardLinkType"
                            ) > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                        batchId: dashBoardState.batchId,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };

                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateChatWithDocState(this.state);
                        if (this.state?.fileName) {
                            this.HistoryComponentRef?.current?.handleRetrieveHistoryFile(
                                this.state.fileName,
                                this.state.batchId, //batchid assuming it as null as there are multi files upload here
                                this.state.batchId === null ? 0 : 1,
                                this.handleFavoriteFileState
                            );
                        }
                    }
                );
                break;
            default:
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: false,
                    },
                    () => {
                        console.log("fromDashBoard", this.state.fromDashBoard);
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                break;
        }
    }
    handleToggleHistory = () => {
        this.setState(
            {
                hisdisplayval: !this.state.hisdisplayval,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateChatWithDocState(this.state);
            }
        );
    };
    updateHistoryFilesState = (historyFiles) => {
        this.setState(
            {
                historyFiles: historyFiles,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleBottomPanel = (isOpen) => {
        this.setState(
            {
                isBottomPanelOpen: isOpen,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
                this?.props?.setPosition({
                    document: this.state?.isDocumentOpen,
                    chatComponent: isOpen,
                });
            }
        );
    };

    toggleTab = (tabId) => {
        this.setState(
            {
                activeTab: tabId,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
                if (tabId === 1) {
                    this.chatComponentRef.current
                        .getFavComponentRef()
                        .current.gridRef.current?.onFirstDataRendered();
                }
            }
        );
        this.handleBottomPanel(true);
    };

    constructor(props) {
        super(props);
        this.chatComponentRef = createRef();
        this.secondDivRef = React.createRef();
        this.state = this.props.chatWithDocState;
        this.module_id = docTypes_ddl.Modules["ChatwithDocType"].moduleId;
    }

    setLoaderStatus = (flag) => {
        try {
            this.setState({ isLoading: flag }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        } catch (error) {
            console.log("ErrorTriggerMsgSet:", error);
        }
    };

    handleResponse = (response) => {
        try {
            console.log("Response:", response);
            if (response === undefined) {
                this.setState(
                    {
                        isUploadFileOpen: true,
                        isLoading: false,
                        selectedFile: "",
                        fileName: "",
                        selectedDocType: "",
                        showMsgBoxAfterUpload: false,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                Swal.fire({
                    text: "We are unable to process the chat questions. Maybe try another file.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            } else {
                let now = new Date().getTime();
                let startTime = this.state.reqStartTime;
                let processtimeinsec = ((now - startTime) / 1000).toFixed(2);
                let formDataForAccessLog = new FormData();
                formDataForAccessLog.append("module_id", this.module_id);
                formDataForAccessLog.append(
                    "engine_id",
                    this.state.selectedEngine
                );
                formDataForAccessLog.append(
                    "document_type",
                    this.state.selectedDocType
                );
                formDataForAccessLog.append(
                    "is_hand_written",
                    this.state.isHandWritten
                );
                formDataForAccessLog.append("doc_name", this.state.fileName);
                Createaccesslog(formDataForAccessLog, processtimeinsec);
                let res = "";

                if (Array.isArray(response.msg) && response.msg.length > 1) {
                    response.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = response.msg;
                }

                let obj = {
                    question: this.state.selectedPrompt.id,
                    response: res,
                    time: new Date().toLocaleString(),
                    timetaken: processtimeinsec + "s",
                    qid: response?.qid,
                    fname: response["fname"],
                    tresp: response?.tresp,
                };

                const { Chats } = this.state;
                Chats[obj.qid] = Chats.hasOwnProperty(obj.qid)
                    ? [...Chats[obj.qid], obj]
                    : [obj];

                this.setState(
                    {
                        Chats: { ...Chats },
                        isUploadFileOpen: false,
                        isLoading: false,
                        inputValue: "",
                        showMsgBoxAfterUpload: false,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                this.scrollToBottom();
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
            this.setState(
                {
                    isUploadFileOpen: true,
                    isLoading: false,
                    selectedFile: "",
                    fileName: "",
                    selectedDocType: "",
                    showMsgBoxAfterUpload: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
            Swal.fire({
                text: "We are unable to process the chat questions. Maybe try another file.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    handleBatch = (selectedFile, validFiles, invalidFiles) => {
        try {
            if (invalidFiles && invalidFiles.length > 0) {
                let files = "";

                invalidFiles.forEach((file) => {
                    files = files + file.name + ", ";
                });

                Swal.fire({
                    text:
                        "Error While uploading " +
                        files +
                        " files. Plese make sure they are of acceptable format and are in size limit.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }

            if (this.state.selectedFile !== "" || validFiles) {
                let docs = [];
                for (let i = 0; i < validFiles.length; i++) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        docs.push({
                            content: reader.result,
                            fileName: validFiles[i].name,
                            fileType: validFiles[i].type,
                        });
                        // this.setState({ ...this.state, baseContent: reader.result }, () => { this.props.updateChatWithDocState(this.state); });
                    };
                    reader.readAsDataURL(validFiles[i]);
                }

                this.setState(
                    {
                        selectedFile: selectedFile,
                        Chats: {},
                        chatResult: "",
                        isLoading: true,
                        fileName: selectedFile.name,
                        batchFiles: validFiles,
                        invalidFiles: invalidFiles,
                        docsContent: docs,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                if (this.state.selectedDocType !== "") {
                    this.setState(
                        {
                            isLoading: true,
                            loaderText: "Analysis in progress...",
                        },
                        () => {
                            this.props.updateChatWithDocState(this.state);
                        }
                    );
                    const formData = new FormData();
                    formData.append("isBatchFile", true);
                    formData.append("module", this.module_name);
                    validFiles &&
                        validFiles.forEach((file, index) => {
                            formData.append(`file${index}`, file);
                        });

                    const axiosInstance = getAxiosInstance(
                        this.props.token,
                        false,
                        this.props?.history
                    );
                    axiosInstance
                        .post("/upload", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            timeout: 300000,
                        })
                        .then((response) => {
                            if (response.data.fid === undefined) {
                                this.setState(
                                    {
                                        showMsgBoxAfterUpload: false,
                                        isUploadFileOpen: false,
                                        isLoading: false,
                                        selectedFile: "",
                                        selectedDocType: "",
                                        fileName: "",
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                                Swal.fire({
                                    text: "We are unable to process the chat questions. Maybe try another file.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            } else {
                                this.setState(
                                    {
                                        isLoading: false,
                                        uploadFileId: response.data.fid,
                                        showMsgBoxAfterUpload: true,
                                        isUploadFileOpen: false,
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                                socket.emit("doc_chat", {
                                    uploadFileId: response.data.fid,
                                    doctype: this.state.selectedDocType,
                                    engineType: this.state.selectedEngine,
                                    hwFlag: this.state.isHandWritten,
                                    fileName: this.state.batchId,
                                });
                                this.setLoaderStatus(true);
                                this.HistoryComponentRef?.current?.handleSaveFileToHistory(
                                    {
                                        module_id: this.module_id,
                                        selectedFile: this.state.selectedFile,
                                        selectedDocType:
                                            this.state.selectedDocType,
                                        isHandWritten: this.state.isHandWritten,
                                        selected_engine_id:
                                            this.state.selectedEngine,
                                        isBatchFile:
                                            this.state.batchFiles.length > 0
                                                ? true
                                                : false,
                                        batchName: selectedFile.name,
                                        batchFiles: this.state.batchFiles,
                                    },
                                    this.props.token
                                );
                            }
                        })
                        .catch((error) => {
                            let status = error.response
                                ? error.response.status
                                : 200;
                            this.setState(
                                {
                                    chatResult: "",
                                    isUploadFileOpen: true,
                                    isLoading: false,
                                    isHandWritten: false,
                                    showMsgBoxAfterUpload: false,
                                },
                                () => {
                                    this.props.updateChatWithDocState(
                                        this.state
                                    );

                                    if (status === 401 || status === 422) {
                                        this.props.removeToken();
                                    }
                                }
                            );
                            let swalMsg =
                                "We are unable to process the chat questions. Maybe try another file.";
                            if (status === 400) {
                                swalMsg = "No Files Provided";
                            }
                            Swal.fire({
                                text: swalMsg,
                                icon: "error",
                                confirmButtonText: "OK",
                            });
                            console.log("Error in file Upload", error);
                        });
                } else {
                    this.setState(
                        {
                            chatResult: "",
                            isUploadFileOpen: true,
                            isLoading: false,
                            isHandWritten: false,
                            showMsgBoxAfterUpload: false,
                        },
                        () => {
                            this.props.updateChatWithDocState(this.state);
                        }
                    );
                    Swal.fire({
                        text: "Please select a document type",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
            } else {
                this.setState(
                    {
                        chatResult: "",
                        isUploadFileOpen: true,
                        isLoading: false,
                        isHandWritten: false,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                Swal.fire({
                    text: "Error While uploading files",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            this.setState(
                {
                    chatResult: "",
                    isUploadFileOpen: true,
                    isLoading: false,
                    isHandWritten: false,
                    showMsgBoxAfterUpload: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
            console.log("Error in handle Chat with Doc", error);
        }
    };

    handleChatFile = (fileDetail) => {
        try {
            if (
                this.state.selectedFile !== "" ||
                (fileDetail.file && fileDetail.error === null)
            ) {
                let docs = [];
                const reader = new FileReader();
                reader.onloadend = () => {
                    docs.push({
                        content: reader.result,
                        fileName: fileDetail.file.name,
                        fileType: fileDetail.file.type,
                    });
                    // this.setState({ ...this.state, baseContent: reader.result }, () => { this.props.updateChatWithDocState(this.state); });
                };
                reader.readAsDataURL(fileDetail.file);

                this.setState(
                    {
                        selectedFile: fileDetail,
                        Chats: {},
                        chatResult: "",
                        fileName: fileDetail.file.name,
                        docsContent: docs,
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                if (this.state.selectedDocType !== "") {
                    this.setState(
                        {
                            isLoading: true,
                            loaderText: "Analysis in progress...",
                        },
                        () => {
                            this.props.updateChatWithDocState(this.state);
                        }
                    );
                    const formData = new FormData();

                    formData.append("file0", fileDetail.file);
                    formData.append("module", this.module_name);
                    const axiosInstance = getAxiosInstance(
                        this.props.token,
                        false,
                        this.props?.history
                    );
                    axiosInstance
                        .post("/upload", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            timeout: 300000,
                        })
                        .then((response) => {
                            console.info("response", response);
                            if (response.data.fid === undefined) {
                                this.setState(
                                    {
                                        showMsgBoxAfterUpload: false,
                                        isUploadFileOpen: true,
                                        isLoading: false,
                                        selectedFile: "",
                                        selectedDocType: "",
                                        fileName: "",
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                                Swal.fire({
                                    text: "We are unable to process the chat questions. Maybe try another file.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                            } else {
                                this.setState(
                                    {
                                        isLoading: false,
                                        uploadFileId: response.data.fid,
                                        showMsgBoxAfterUpload: true,
                                        isUploadFileOpen: false,
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                                console.info(
                                    "saving file to History triggered"
                                );
                                socket.emit("doc_chat", {
                                    uploadFileId: response.data.fid,
                                    doctype: this.state.selectedDocType,
                                    engineType: this.state.selectedEngine,
                                    hwFlag: this.state.isHandWritten,
                                    fileName: this.state.batchId,
                                });
                                this.setLoaderStatus(true);
                                this.HistoryComponentRef?.current?.handleSaveFileToHistory(
                                    {
                                        module_id: this.module_id,
                                        selectedFile: this.state.selectedFile,
                                        selectedDocType:
                                            this.state.selectedDocType,
                                        isHandWritten: this.state.isHandWritten,
                                        selected_engine_id:
                                            this.state.selectedEngine,
                                        isBatchFile:
                                            this.state.batchFiles.length > 0
                                                ? true
                                                : false,
                                    },
                                    this.props.token
                                );
                            }
                        })
                        .catch((error) => {
                            this.setState(
                                {
                                    chatResult: "",
                                    isUploadFileOpen: false,
                                    isLoading: false,
                                    isHandWritten: false,
                                    showMsgBoxAfterUpload: false,
                                },
                                () => {
                                    this.props.updateChatWithDocState(
                                        this.state
                                    );
                                    let status = error.response
                                        ? error.response.status
                                        : 200;
                                    if (status === 401 || status === 422) {
                                        this.props.removeToken();
                                    }
                                }
                            );
                            Swal.fire({
                                text: "We are unable to process the chat questions. Maybe try another file.",
                                icon: "error",
                                confirmButtonText: "OK",
                            });
                            console.log("Error in file Upload", error);
                        });
                } else {
                    this.setState(
                        {
                            chatResult: "",
                            isUploadFileOpen: false,
                            isLoading: false,
                            isHandWritten: false,
                            showMsgBoxAfterUpload: false,
                        },
                        () => {
                            this.props.updateChatWithDocState(this.state);
                        }
                    );
                    Swal.fire({
                        text: "Please select a document type",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
            } else {
                this.setState(
                    {
                        chatResult: "",
                        isLoading: false,
                        isHandWritten: false,
                        isUploadFileOpen: true,
                        selectedFile: "",
                        fileName: "",
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );
                Swal.fire({
                    text: fileDetail.error,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            this.setState(
                {
                    chatResult: "",
                    isUploadFileOpen: true,
                    isLoading: false,
                    isHandWritten: false,
                    showMsgBoxAfterUpload: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
            console.log("Error in handleSummarize", error);
        }
    };

    removeWorkspaceDetails = () => {
        this.setState(
            {
                ...this.state,
                workspace_item_id: 0,
                module_id: 3,
                workspace_id: 0,
                workspace_name: "",
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleUploadBar = (value) => {
        let initialstate = initialState.chatWithDocState;
        this.setState(
            {
                ...initialstate,
                isUploadFileOpen: value,
                fileName: "",
                chatResult: "",
                selectedDocType: "",
                Chats: {},
                inputValue: "",
                isHandWritten: false,
                selectedFile: "",
                uploadFileId: undefined,
                showMsgBoxAfterUpload: false,
                batchFiles: [],
                index_list: [],
                isIndexCreated: false,
                disableAddToWorkspace: false,
                workspace_id: 0,
                workspace_item_id: 0,
                ws_count:0,
                workspace_name: "",
                isExpanded: true,
                isFromFavorites: false,
                isFromHistory: false,
                doc_help_questions: [],
            },
            () => {
                this.removeWorkspaceDetails();
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleDocTypeChange = (event) => {
        const { value } = event.target;
        this.setState(
            {
                ...ChatWithDocsInitialState,
                selectedDocType: value,
                ws_count: 0,
                selectedCanned_Ques: prompts_ddl[value + "_Canned_Ques"],
                isUploadFileOpen: true,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleEngineChange = (event) => {
        const { value } = event.target;
        console.log("Engine selected: ", value);
        this.setState(
            {
                selectedEngine: value,
            },
            () => this.props.updateChatWithDocState(this.state)
        );
    };
    handleExpandCollapse = () => {
        this.setState(
            {
                isExpanded: !this.state.isExpanded,
            },
            () => this.props.updateChatWithDocState(this.state)
        );
    };
    handleHistoryFile = (file, docType, is_hand_written, engine_id) => {
        try {
            this.setState(
                {
                    isUploadFileOpen: false,
                    isLoading: false,
                    selectedFile: file,
                    selectedEngine: engine_id,
                    selectedDocType: docType,
                    isHandWritten: is_hand_written,
                    selectedCanned_Ques: prompts_ddl[docType + "_Canned_Ques"],
                    fromDashBoard: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                    // console.log(this.state);
                    let fileDetail = {};
                    fileDetail.file = file;
                    fileDetail.error = null;
                    console.log(fileDetail);
                    this.handleChatFile(fileDetail);
                }
            );
        } catch (e) {
            console.log(`Exception ${e}`);
        }
    };

    handleLoadingFromHistory = (value, text, error) => {
        if (error) {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
            Swal.fire({
                text: "Not able to fetch file from history, please try after some time",
                icon: "error",
                confirmButtonText: "OK",
            });
        } else
            this.setState(
                {
                    isLoading: value,
                    loaderText: text,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
    };

    handleHWSwitchChange = (isHandWritten) => {
        this.setState(
            {
                isHandWritten,
            },
            () => {
                this.props.updateChatWithDocState(this.state);

                if (this.state.selectedFile !== "") {
                    this.handleChatFile(this.state.selectedFile);
                }
            }
        );
    };

    /*Favorites Component State Update things*/
    handleFetchFavoriteFilesState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        }
    };

    handleFavoriteFileState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        } else
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
    };

    // move to favorite supportive component
    handleRetrieveFileState = (stateobj, error) => {
        try {
            let docType = stateobj?.selectedDocType
                ? stateobj.selectedDocType
                : null;
            let selectedCanned_Ques = [];
            if (docType) {
                selectedCanned_Ques = prompts_ddl[docType + "_Canned_Ques"];
            }
            if (error) {
                this.setState({ ...stateobj }, () => {
                    this.props.updateChatWithDocState(this.state);
                });
            } else {
                this.setState(
                    {
                        ...stateobj,
                        disableAddToWorkspace: false,
                        selectedCanned_Ques: selectedCanned_Ques,
                        batchName: this.state.selectedFile.name,
                    },
                    () => {
                        this.removeWorkspaceDetails();
                        this.props.updateChatWithDocState(this.state);
                        if (this.state.selectedFile !== "") {
                            if (
                                this.state.selectedFile.file.length !==
                                undefined
                            ) {
                                console.log("***** I am here ");
                                this.handleBatch(
                                    this.state.selectedFile,
                                    this.state.selectedFile.file,
                                    []
                                );
                            } else this.handleChatFile(this.state.selectedFile);
                        }
                    }
                );
            }
        } catch (error) {
            console.log("Error in handleRetrieveFileState", error);
        }
    };

    // move to favoritesupportive component
    handleRemoveFavoriteState = (stateobj, error) => {
        console.log("Review Remove Favourites", stateobj);
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateChatWithDocState(this.state);
            });
        }
    };
    /*Favorites Component State Update things*/

    handleBatchPopUp = (value) => {
        this.setState(
            {
                isPopupVisible: value,
                isRenaming: value ? false : true,
                popupActivebatchFiles: this.state.batchFiles,
                popupActivebatchName: this.state.fileName,
                oldBatchName: this.state.fileName,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleBatchRename = async (value) => {
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props?.history
        );
        let data = {
            workspace_id: this?.state?.workspace_id,
            ws_item_id: this?.state?.workspace_item_id,
            name: this?.state?.fileName,
            old_name: this.state?.oldBatchName,
            batch_id:
                this.state.favdisplayval || this.state.isFromFavorites
                    ? this.state?.batchId
                    : this.state?.batchId,
            history_batch_id:
                this.state.hisdisplayval || this.state.isFromHistory
                    ? this.state.batchId
                    : this.state?.batchId,
        };

        try {
            if (data.ws_item_id || data.batch_id || data.history_batch_id) {
                let response = await axiosInstance
                    .put("/rename/batchName", data, { timeout: 180000 })
                    .then((res) => {
                        if (res.status === 200) {
                            // this.setState({...this.state, isRenaming: false},()=>{this.props.updateChatWithDocState(this.state)});
                            if (
                                this.state.favdisplayval ||
                                this.state.isFromFavorites
                            ) {
                                let favFiles = [];
                                this.state.favoriteFiles.forEach((element) => {
                                    if (
                                        element?.batch_id === this.state.batchId
                                    ) {
                                        element.file_name = data?.name;
                                    }
                                    favFiles.push(element);
                                });

                                this.setState(
                                    {
                                        ...this.state,
                                        favoriteFiles: favFiles,
                                        isFromFavorites: false,
                                        isRenaming: false,
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                            }

                            if (
                                this.state.hisdisplayval ||
                                this.state.isFromHistory
                            ) {
                                let histFiles = [];
                                this.state.historyList.forEach((element) => {
                                    if (
                                        element?.batch_id === this.state.batchId
                                    ) {
                                        element.file_name = data?.name;
                                    }
                                    histFiles.push(element);
                                });

                                this.setState(
                                    {
                                        ...this.state,
                                        historyList: histFiles,
                                        isFromHistory: false,
                                        isRenaming: false,
                                    },
                                    () => {
                                        this.props.updateChatWithDocState(
                                            this.state
                                        );
                                    }
                                );
                            }

                            return true;
                        }
                    });
                return response;
            }
        } catch {
            this.setState({ ...this.state, isRenaming: false }, () => {
                this.props.updateChatWithDocState(this.state);
            });
            return false;
        }
    };

    handleValuesChange = (e) => {
        this.setState(
            {
                fileName: e.target.value,
                popupActivebatchName: e.target.value,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    handleUpdateBlobFilesState = (blobFilesList, activeTab) => {
        this.setState(
            {
                blobFilesList: blobFilesList,
                activeTab: activeTab,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };
    handleToggleFavourites = () => {
        this.setState(
            {
                favdisplayval: !this.state.favdisplayval,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    getMessages = () => {
        try {
            if (
                this.state.module_id === 3 &&
                this.state.workspace_id &&
                this.state.workspace_item_id &&
                (this.state.workspace_id !==
                    store.getState().chatWithDoc.chatWithDocState
                        .workspace_id ||
                    this.state.workspace_item_id !==
                        store.getState().chatWithDoc.chatWithDocState
                            .workspace_item_id ||
                    store.getState().chatWithDoc.chatWithDocState.ws_count <= 0)
            ) {
                this.setState(
                    {
                        ...this.state,
                        isLoading: true,
                        isIndexCreated: true,
                        disableAddToWorkspace: true,
                        workspace_id: this.state.workspace_id,
                        workspace_item_id: this.state.workspace_item_id,
                        isUploadFileOpen: false,
                        Chats: {},
                        fileName: "",
                        uploadFileId: "",
                        selectedFile: {},
                        chatTimer: 0 + "s",
                        openCreateWorkSpacePopUp: false,
                        selectedDocType: "",
                    },
                    () => {
                        this.props.updateChatWithDocState(this.state);
                    }
                );

                const axiosInstance = getAxiosInstance(
                    this.props.token,
                    false,
                    this.props?.history
                );
                axiosInstance
                    .get(
                        `/workspace/item?ws_id=${this.state.workspace_id}&ws_item_id=${this.state.workspace_item_id}`,
                        { timeout: 180000 }
                    )
                    .then((res) => {
                        if (res?.data) {
                            var queryContent = [];
                            let chats = {};
                            let batchName = undefined;
                            res.data.messages.forEach((element, index) => {
                                let currentChatObj = JSON.parse(
                                    element.messages
                                );
                                Object.keys(currentChatObj).map((key) => {
                                    if (currentChatObj[key]?.length > 0) {
                                        currentChatObj[key].forEach((i, j) => {
                                            i.time = convertUtcToLocal(
                                                element?.created_at
                                            );
                                            if (
                                                res.data?.shared_users
                                                    ?.length === 1
                                            ) {
                                                i.chat_user_name = "";
                                            }
                                            if (
                                                res.data?.shared_users?.length >
                                                1
                                            ) {
                                                if (
                                                    i.chat_user_name ===
                                                        undefined ||
                                                    i.chat_user_name?.length ===
                                                        0
                                                ) {
                                                    i.chat_user_name =
                                                        res.data?.shared_users?.find(
                                                            (x) =>
                                                                x.user_id ===
                                                                element.created_by
                                                        )?.full_name;
                                                }
                                            }
                                        });
                                    }
                                    return key;
                                });
                                chats = { ...chats, ...currentChatObj };
                                batchName = element?.batchName
                                    ? element?.batchName
                                    : batchName;
                            });
                            let docs = [];
                            let docsContents = [];
                            res.data.documents.forEach((element, index) => {
                                const file = dataURLtoFile(
                                    element.docresponse,
                                    element.name
                                );
                                docsContents.push({
                                    content: element.docresponse,
                                    fileName: element.name,
                                    fileType: element.mime_type,
                                    index_path: element?.index_path,
                                });
                                if (!batchName) {
                                    batchName = element?.name;
                                }
                                docs.push(file);
                            });

                            let data = {
                                ...this.state,
                                workspace_created_by:
                                    res.data?.shared_users?.find(
                                        (x) => x.permissions === "owner"
                                    )?.user_id,
                                selectedEngine: res.data?.engine_type,
                                ws_shared_users: res.data?.shared_users,
                                isSharedWorkspace:
                                    res.data?.shared_users?.length > 1
                                        ? true
                                        : false,
                                isUploadFileOpen: false,
                                Chats: chats,
                                fileName: batchName,
                                uploadFileId:
                                    res.data?.documents?.length > 0 &&
                                    res.data?.documents[0].index_path,
                                batchFiles: docs.length > 1 ? docs : [],
                                isLoading: false,
                                chatTimer: res.data.messages[0].timeTaken + "s",
                                oldMessages: queryContent,
                                selectedFile:
                                    docs.length === 1
                                        ? { error: "", file: docs[0] }
                                        : {},
                                CannedQuestionsList: queryContent,
                                selectedDocType:
                                    res.data?.documents?.length > 0 &&
                                    res.data.documents[0].documentType,
                                selectedCanned_Ques:
                                    prompts_ddl[
                                        res.data?.documents?.length > 0 &&
                                            res.data.documents[0].documentType +
                                                "_Canned_Ques"
                                    ],
                                docsContent: docsContents,
                                ws_count:1,
                            };

                            this.setState({ ...data }, () => {
                                this.props.updateChatWithDocState(this.state);
                            });
                        }
                    });
            }
        } catch (exception) {
            this.setState(
                {
                    ...this.state,
                    isLoading: false,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
        }
    };
    CloseWorkspaceListPopUp = (data, res) => {
        if (this.state.addToAnotherWorkspace) {
            this.setState(
                {
                    addToAnotherWorkspace: false,
                    openCreateWorkSpacePopUp: false,
                    closeKebabMenu: true,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                }
            );
        } else {
            this.setState(
                {
                    openCreateWorkSpacePopUp:
                        !this.state.openCreateWorkSpacePopUp,
                    disableAddToWorkspace: false,
                    selectedWorkSpaces: data,
                    showAllWorkspaces: true,
                },
                () => {
                    this.props.updateChatWithDocState(this.state);
                    if (data?.length === 1 && res?.added?.length === 1) {
                        this.closeShowAllWorkspacesPopup(data);
                    }
                }
            );
        }
    };

    closeShowAllWorkspacesPopup = (data) => {
        if (data?.length > 0) {
            if (!this.state.moveToAnotherWorkspace) {
                let ob = {
                    cid: this.state.uploadFileId,
                    workspace_item_id: data[0].workspace_item_id,
                    workspace_id: data[0].id,
                };

                socket.emit("set_doc_chat_session", ob);
            }
            if (this.state.moveToAnotherWorkspace) {
                let obj = {
                    msg: {
                        map: this.state.inputValue,
                        reduce: this.state.inputValue,
                    },
                    doctype: this.state.selectedDocType,
                    engineType: this.state.selectedEngine,
                    srcname: undefined,
                    topk: undefined,
                    token: this.props.token,
                    uploadFileId: undefined,
                    index_path: this.state.index_path,
                    hwFlag: this.state.isHandWritten ? "1" : "0",
                    fileName: this.state.fileName,
                    isBatchFile: false,
                    workspace_item_id: data[0].workspace_item_id,
                    workspace_id: data[0].id,
                };
                socket.emit("doc_chat", obj);
            }
        }
        this.setState(
            {
                ...this.state,
                showAllWorkspaces: false,
                workspace_name: data[0].name,
                workspace_id: data[0].id,
                workspace_item_id: data[0].workspace_item_id,
                openCreateWorkSpacePopUp: false,
                disableAddToWorkspace: true,
                moveToAnotherWorkspace: false,
                closeKebabMenu: true,
                workspace_created_by: parseInt(
                    store?.getState()?.login?.logInState?.user_id
                ),
                isSharedWorkspace:
                    parseInt(data[0]?.shared_users) > 1 ? true : false,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    closeAllWorkspacesPopup = () => {
        this.setState(
            {
                ...this.state,
                showAllWorkspaces: false,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    addToAnotherWorkspace = () => {
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                addToAnotherWorkspace: true,
                moveToAnotherWorkspace: false,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };
    moveToAnotherWorkspace = () => {
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                moveToAnotherWorkspace: true,
                addToAnotherWorkspace: false,
            },
            () => {
                this.props.updateChatWithDocState(this.state);
            }
        );
    };

    render() {
        return (
            <Fragment>
                {this.state.showAllWorkspaces && (
                    <ShowAllWorkspacePopUp
                        selectedWorkspaces={this.state.selectedWorkSpaces}
                        closeShowAllWorkspacesPopup={
                            this.closeShowAllWorkspacesPopup
                        }
                        closepopup={this.closeAllWorkspacesPopup}
                        document_name={this.state.selectedFile?.file?.name}
                    />
                )}

                {this.state.openCreateWorkSpacePopUp && (
                    <WorkSpaceListPopUp
                        title={"Create new WorkSpace"}
                        workSpace={""}
                        module_id={3}
                        engine_type={this.state.selectedEngine}
                        buttonName={"Add"}
                        workSpaceDisabled={false}
                        state={this.state}
                        file={this.state.selectedFile.file}
                        content={JSON.stringify(this.state.Chats)}
                        baseContent={this.state.baseContent}
                        docsContent={this.state.docsContent}
                        document_type={this.state.selectedDocType}
                        document_name={this.state.fileName}
                        handwritten_text_flag={this.state.isHandWritten}
                        timeTaken={0}
                        query={this.state.chatQuery}
                        CannedQuestionsList={[]}
                        index_path={this.state.index_path}
                        batchName={
                            this.state?.batchFiles?.length > 1 &&
                            this.state.fileName
                        }
                        closepopup={(data, res) => {
                            this.CloseWorkspaceListPopUp(data, res);
                        }}
                        closeWsListpopup={() => {
                            this.setState({
                                ...this.state,
                                openCreateWorkSpacePopUp:
                                    !this.state.openCreateWorkSpacePopUp,
                                disableAddToWorkspace:
                                    this.state.addToAnotherWorkspace ||
                                    this.state.moveToAnotherWorkspace
                                        ? this.state.disableAddToWorkspace
                                        : false,
                            });
                        }}
                        workspace_id={this.state.workspace_id}
                        moveToAnotherWorkspace={
                            this.state.moveToAnotherWorkspace
                        }
                        fromWorkspaceId={this.state.workspace_id}
                        fromWorkspaceItemId={this.state.workspace_item_id}
                    />
                )}
                {this.state.isPopupVisible && (
                    <>
                        <CustomWindowPopupComponent
                            module_name={this.module_name}
                            window_name={"Batch"}
                            togglepopup={this.handleBatchPopUp}
                            favdisplayval={this.state.isPopupVisible}
                            customclass=" maxWidth_grid"
                            content={
                                <BatchComponent
                                    handleBatchRenameCallback={
                                        this.handleBatchRename
                                    }
                                    handleBatchRename={(val) => {
                                        this.setState({ isRenaming: val });
                                    }}
                                    handleBatchCallback={this.handleBatch}
                                    handleValuesChangeCallback={
                                        this.handleValuesChange
                                    }
                                    handleBatchPopUpCallback={
                                        this.handleBatchPopUp
                                    }
                                    popupActivebatchName={
                                        this.state?.popupActivebatchName
                                    }
                                    isRenaming={this.state?.isRenaming}
                                    fileName={this.state?.popupActivebatchName}
                                    batchFiles={this.state?.batchFiles}
                                />
                            }
                        />
                    </>
                )}
                <CustomWindowPopupComponent
                    module_name={this.module_name}
                    window_name={"Favorites"}
                    togglepopup={this.handleToggleFavourites}
                    favdisplayval={this.state.favdisplayval}
                    content={
                        <FavoritesComponent
                            ref={this.FavoriteComponentRef}
                            module={this.module_id}
                            favdisplayval={this.state.favdisplayval}
                            aciveTab={this.state.activeTab}
                            module_name="ChatwithDocType"
                            handleToggleFavoritesCallback={
                                this.handleToggleFavourites
                            }
                            handleRemoveFavoriteState={
                                this.handleRemoveFavoriteState
                            }
                            handleFavoriteFile={this.handleFavoriteFileState}
                            handleFetchFavoriteFilesState={
                                this.handleFetchFavoriteFilesState
                            }
                            handleRetrieveFileState={
                                this.handleRetrieveFileState
                            }
                            favoriteFiles={this.state.favoriteFiles}
                            token={this.props.token}
                            removetoken={this.props.removetoken}
                            selectedEngine={this.state.selectedEngine}
                            isBatchFile={this.state.isBatchFile}
                            blobFilesList={this.state.blobFilesList} //passing blob files list to push data on select
                            handleUpdateBlobFilesState={
                                this.handleUpdateBlobFilesState
                            }
                        />
                    }
                />
                <CustomWindowPopupComponent
                    module_name={this.module_name}
                    window_name={"History"}
                    togglepopup={this.handleToggleHistory}
                    favdisplayval={this.state.hisdisplayval}
                    content={
                        <HistoryComponent
                            module={this.module_id}
                            module_name={"ChatwithDocType"}
                            hisdisplayval={this.state.hisdisplayval}
                            isProcessed={
                                this.state.chatResult !== "" ||
                                this.state.showMsgBoxAfterUpload
                                    ? true
                                    : false
                            }
                            ref={this.HistoryComponentRef}
                            fileName={this.state.fileName}
                            fileDetails={this.state.selectedFile}
                            docType={this.state.selectedDocType}
                            isHandWritten={this.state.isHandWritten}
                            historyList={this.state.historyList}
                            isLoading={this.handleLoadingFromHistory}
                            handleHistoryFile={this.handleHistoryFile}
                            handleFileResetCallback={this.handleUploadBar}
                            fromDashBoard={this.state.fromDashBoard}
                            token={this.props.token}
                            removeToken={this.props.removeToken}
                            selectedEngine={this.state.selectedEngine}
                            handleRetrieveFileStateCallback={
                                this.handleRetrieveFileState
                            }
                            handleFavoriteFileStateCallback={
                                this.handleFavoriteFileState
                            }
                            updateStateCallback={this.handleUpdateHistoryList}
                            toggleFavorites={this.handleToggleFavourites}
                        />
                    }
                />
                <div className="rytpaneltoppart   flex-flow-wrap">
                    <div className="reviewfirstdiv">
                        <h2>chat with Docs</h2>
                        {this.state.fileName !== "" &&
                            (Object.keys(this.state.Chats)?.length !== 0 &&
                            (this.state.Chats.constructor === Object) !== 0
                                ? this.state.Chats[
                                      Object.keys(this.state.Chats)[
                                          Object.keys(this.state.Chats)
                                              ?.length - 1
                                      ]
                                  ][0]?.tresp !==
                                  this.state.Chats[
                                      Object.keys(this.state.Chats)[
                                          Object.keys(this.state.Chats)
                                              ?.length - 1
                                      ]
                                  ]?.length
                                    ? false
                                    : true
                                : true) && (
                                <>
                                    <HandleNewButtonComponent
                                        title="Chat with a new document"
                                        selectedDocType={
                                            this.state.selectedDocType
                                        }
                                        handleUploadBarCallback={
                                            this.handleUploadBar
                                        }
                                    />
                                </>
                            )}
                    </div>
                    <div className="reviewdescriptionsec">
                        <div className="reviewdescription">
                            <HandleDocTypeChangeComponent
                                handleDocTypeChangeCallback={
                                    this.handleDocTypeChange
                                }
                                selectedDocType={this.state.selectedDocType}
                                documentTypes={docTypes_ddl.ChatwithDocType}
                                fileName={this.state.fileName}
                            />

                            <HandleEngineComponent
                                fileName={this.state.fileName}
                                handleEngineChangeCallback={
                                    this.handleEngineChange
                                }
                                selectedEngine={this.state.selectedEngine}
                            />
                            {this.state.selectedDocType !== "" && (
                                <HandwrittenComponent
                                    isHandWritten={this.state.isHandWritten}
                                    handleHWSwitchChangeCallback={
                                        this.handleHWSwitchChange
                                    }
                                />
                            )}

                            {this.state.fileName !== "" &&
                                this.state.selectedFile &&
                                this.state.selectedFile?.file &&
                                this.state.selectedFile?.file?.length ===
                                    undefined && (
                                    <HandleFileNameComponent
                                        fileName={this.state.fileName}
                                        selectedFile={this.state.selectedFile}
                                        maxLengthOfFileName={15}
                                        blobFilesList={this.state.blobFilesList}
                                        favoriteFiles={this.state.favoriteFiles}
                                        module_id={this.module_id}
                                        handleUpdateBlobFilesStateCallback={
                                            this.handleUpdateBlobFilesState
                                        }
                                        handleWarnRemoveFavoriteCallback={
                                            handleWarnRemoveFavorite
                                        }
                                        handleRemoveFavoriteStateCallback={
                                            this.handleRemoveFavoriteState
                                        }
                                        selectedDocType={
                                            this.state.selectedDocType
                                        }
                                        isHandWritten={this.state.isHandWritten}
                                        selectedEngine={
                                            this.state.selectedEngine
                                        }
                                        RemoveFavouriteCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.RemoveFavorite
                                        }
                                        handleFavoriteFileCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.handleFavoriteFile
                                        }
                                        handleFavoriteFileStateCallback={
                                            this.handleFavoriteFileState
                                        }
                                    />
                                )}

                            {this.state.fileName !== "" &&
                                this.state.batchFiles?.length > 1 && (
                                    <>
                                        {" "}
                                        <HandleBatchFileNameComponent
                                            fileName={this.state.fileName}
                                            favoriteFiles={
                                                this.state.favoriteFiles
                                            }
                                            handleBatchPopUpCallback={
                                                this.handleBatchPopUp
                                            }
                                            handleWarnRemoveFavoriteCallback={
                                                handleWarnRemoveFavorite
                                            }
                                            RemoveFavouriteCallback={
                                                this.FavoriteComponentRef
                                                    ?.current?.RemoveFavorite
                                            }
                                            isHovered={this.state.isHovered}
                                            module_id={this.module_id}
                                            batchId={this.state.batchId}
                                            handleRemoveFavoriteStateCallback={
                                                this.handleRemoveFavoriteState
                                            }
                                            handleFavoriteFileCallback={
                                                this.FavoriteComponentRef
                                                    ?.current
                                                    ?.handleFavoriteFile
                                            }
                                            handleFavoriteFileStateCallback={
                                                this.handleFavoriteFileState
                                            }
                                            batchFiles={this.state.batchFiles}
                                            selectedFile={
                                                this.state.selectedFile
                                            }
                                            selectedDocType={
                                                this.state.selectedDocType
                                            }
                                            selectedEngine={
                                                this.state.selectedEngine
                                            }
                                            isHandWritten={
                                                this.state.isHandWritten
                                            }
                                        />
                                    </>
                                )}
                            {(this.state.isLoading ||
                                this.state?.fileName?.length > 0) &&
                                !this.state.isSocketRequestProgress && (
                                    <>
                                        <HandleRefreshButtonComponent
                                            handleRefreshFunc={() => {
                                                this.setState(
                                                    {
                                                        uploadFileId: undefined,
                                                        index_path:
                                                            this.state
                                                                .module_id ===
                                                                3 &&
                                                            this.state
                                                                .workspace_id >
                                                                0 &&
                                                            this.state
                                                                .workspace_item_id >
                                                                0
                                                                ? this.state
                                                                      .index_path
                                                                : undefined,
                                                    },
                                                    () => {
                                                        this.props.updateChatWithDocState(
                                                            this.state
                                                        );
                                                    }
                                                );
                                                this.getMessages();
                                                if (
                                                    this.state.selectedFile !==
                                                    ""
                                                ) {
                                                    if (
                                                        this.state.selectedFile
                                                            .file.length !==
                                                        undefined
                                                    ) {
                                                        console.log(
                                                            "***** I am here "
                                                        );
                                                        this.handleBatch(
                                                            this.state
                                                                .selectedFile,
                                                            this.state
                                                                .selectedFile
                                                                .file,
                                                            []
                                                        );
                                                    } else
                                                        this.handleChatFile(
                                                            this.state
                                                                .selectedFile
                                                        );
                                                }
                                            }}
                                        />
                                    </>
                                )}

                            {(this.state.isLoading ||
                                this.state?.fileName?.length > 0) &&
                                this.state.isSocketRequestProgress && (
                                    <>
                                        <HandleCancelRequestButtonComponent
                                            handleRefreshFunc={() => {
                                                console.log(
                                                    `stoping the request`
                                                );
                                            }}
                                        />
                                    </>
                                )}
                        </div>
                    </div>
                    {
                        <>
                            <CustomHistoryFavoriteLinks
                                toggleFavoritescallback={
                                    this.handleToggleFavourites
                                }
                                toggleHistoryCallback={this.handleToggleHistory}
                            />
                        </>
                    }
                </div>
                {this.state.selectedDocType !== "" &&
                    this.state.isUploadFileOpen && (
                        <FileProcessing
                            handleFile={this.handleChatFile}
                            inProgress={
                                this.state.chatResult !== "" ? false : true
                            }
                            module={this.module_id}
                            docType={this.state.selectedDocType}
                            handleBatch={this.handleBatch}
                        ></FileProcessing>
                    )}
                <div
                    ref={this.secondDivRef}
                    id="midPanel"
                    className={
                        this.state.isBottomPanelOpen &&
                        (this.state.isIndexCreated || this.state?.index_path)
                            ? "rytpanelmidpartWithBottom"
                            : " rytpanelmidpart"
                    }
                >
                    <div className="midPanel_Container">
                        <div data-scroll className="midPanel_Item1">
                            {Object.keys(this.state.Chats)?.length !== 0 &&
                            (this.state.Chats.constructor === Object) !== 0 ? (
                                <>
                                    <TitleOfComponent
                                        title={""}
                                        description={null}
                                        isExpanded={this.state.isExpanded}
                                        exapandfncallback={
                                            this.handleExpandCollapse
                                        }
                                        buttonTitle={"Add to Workspace"}
                                        fileName={this.state.fileName}
                                        CannedQuestionsList={this.state.Chats}
                                        hideAddToWorkspace={
                                            this.state.Chats[
                                                Object.keys(this.state.Chats)[
                                                    Object.keys(
                                                        this.state.Chats
                                                    )?.length - 1
                                                ]
                                            ][0]?.tresp !==
                                            this.state.Chats[
                                                Object.keys(this.state.Chats)[
                                                    Object.keys(
                                                        this.state.Chats
                                                    )?.length - 1
                                                ]
                                            ]?.length
                                                ? true
                                                : false
                                        }
                                        loadinghtml={
                                            this.state.Chats[
                                                Object.keys(this.state.Chats)[
                                                    Object.keys(
                                                        this.state.Chats
                                                    )?.length - 1
                                                ]
                                            ][0]?.tresp !==
                                            this.state.Chats[
                                                Object.keys(this.state.Chats)[
                                                    Object.keys(
                                                        this.state.Chats
                                                    )?.length - 1
                                                ]
                                            ]?.length ? (
                                                <>
                                                    <span>
                                                        <Spinning /> Processing{" "}
                                                        {this.state.Chats[
                                                            Object.keys(
                                                                this.state.Chats
                                                            )[
                                                                Object.keys(
                                                                    this.state
                                                                        .Chats
                                                                )?.length - 1
                                                            ]
                                                        ]?.length +
                                                            1 +
                                                            " of " +
                                                            this.state.Chats[
                                                                Object.keys(
                                                                    this.state
                                                                        .Chats
                                                                )[
                                                                    Object.keys(
                                                                        this
                                                                            .state
                                                                            .Chats
                                                                    )?.length -
                                                                        1
                                                                ]
                                                            ][0]?.tresp}
                                                    </span>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        componenttitle="ChatDocs"
                                        setStateCallback={() => {
                                            this.setState(
                                                {
                                                    openCreateWorkSpacePopUp: true,
                                                },
                                                () => {
                                                    this.props.updateChatWithDocState(
                                                        this.state
                                                    );
                                                }
                                            );
                                        }}
                                        disableAddToWorkspace={
                                            this.state.disableAddToWorkspace
                                        }
                                        workspace_name={
                                            this.state.workspace_name
                                        }
                                        workspace_id={this.state.workspace_id}
                                        workspace_created_by={
                                            this.state.workspace_created_by
                                        }
                                        users={this.state.ws_shared_users}
                                        AddToAnotherWorkspace={
                                            this.addToAnotherWorkspace
                                        }
                                        MoveToAnotherWorkspace={
                                            this.moveToAnotherWorkspace
                                        }
                                        closeKebabMenu={
                                            this.state.closeKebabMenu
                                        }
                                    />
                                    <div className="reviewdatasec">
                                        <CWDReviewTextComponent
                                            ref={this.TextComponentRef}
                                            Chats={this.state.Chats}
                                            fileName={this.state.fileName}
                                            isExpanded={this.state.isExpanded}
                                            componenttitle="ChatDoc"
                                            engine_id={
                                                this.state.selectedEngine
                                            }
                                        />
                                        <div
                                            style={{
                                                float: "left",
                                                clear: "both",
                                            }}
                                            ref={(el) => {
                                                this.messagesEnd = el;
                                            }}
                                        ></div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {this.state.showMsgBoxAfterUpload && (
                                        <DocumentUploadedHelperDisplayComponent
                                            count={
                                                this.state.batchFiles?.length >
                                                0
                                                    ? this.state.batchFiles
                                                          .length
                                                    : 1
                                            }
                                            isIndexCreated={
                                                this.state.isIndexCreated
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {this.state.isLoading &&
                            this.state.isIndexCreated &&
                            this.getUploadFileIdBool() ? (
                                <LoadingSpinnerSticky
                                    text={this.state.loaderText}
                                    className="position-absolute"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        {this.state.fileName?.length > 0 &&
                        (this.state.batchFiles?.length > 0 ||
                            Object.keys(this.state.selectedFile)?.length !==
                                0) ? (
                            <>
                                <CustomExpandableDocComponent
                                    isDocumentOpen={this.state.isDocumentOpen}
                                    toggleDocumentVisibleStateCallback={
                                        this.handleToggleDocumentViewer
                                    }
                                    customclass="midPanel_Item2"
                                    childComponent={
                                        <>
                                            {this.state.batchFiles?.length >
                                            0 ? (
                                                <>
                                                    <CustomMultiDOcViewer
                                                        files={
                                                            this.state
                                                                .batchFiles
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <CustomDocViewer
                                                    name={
                                                        this.state.selectedFile
                                                            ?.file?.name
                                                    }
                                                    opendocdate={""}
                                                    mimetype={
                                                        this.state.selectedFile
                                                            ?.file?.type
                                                    }
                                                    file={
                                                        this.state.selectedFile
                                                            ?.file
                                                    }
                                                ></CustomDocViewer>
                                            )}
                                        </>
                                    }
                                    activedocname={this.state.fileName}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <ErrorBoundary>
                    {(this.state.isIndexCreated || this.state?.index_path) && (
                        <ChatComponent
                            ref={this.chatComponentRef}
                            module={this.module_id}
                            module_name="ChatwithDocType"
                            activeTab={this.state.activeTab}
                            toggleTab={this.toggleTab}
                            selectedDocType={this.state.selectedDocType}
                            handleCannedQuestion={this.handleCannedQuestion}
                            handleBottomPanel={this.handleBottomPanel}
                            isBottomPanelOpen={this.state.isBottomPanelOpen}
                            selectedCanned_Ques={this.state.selectedCanned_Ques}
                            isReview={false}
                            isSummary={false}
                            isProcessed={
                                this.state.Chats?.length !== 0 ||
                                this.state.showMsgBoxAfterUpload
                                    ? true
                                    : false
                            }
                            inputValue={this.state.inputValue}
                            promptDisplayed={this.state.promptDisplayed}
                            uploadFileId={this.state.uploadFileId}
                            isBatchFile={
                                this.state.batchFiles?.length > 0 ? true : false
                            }
                            fileName={
                                this.state.selectedDocType +
                                "__" +
                                this.state.uploadFileId
                            }
                            chatType="docchat"
                            hwFlag={this.state.isHandWritten}
                            handleResponse={this.handleResponse}
                            setLoaderStatus={this.setLoaderStatus}
                            isLoading={this.state.isLoading}
                            token={this.props.token}
                            removeToken={this.props.removeToken}
                            selectedEngine={this.state.selectedEngine}
                            setReqStartTime={this.setReqStartTime}
                            blobFilesList={this.state.blobFilesList}
                            handleUpdateBlobFilesState={
                                this.handleUpdateBlobFilesState
                            }
                            handleBatchPopUp={this.handleBatchPopUp}
                            workspace_item_id={this.state.workspace_item_id}
                            workspace_id={this.state.workspace_id}
                            index_path={this.state.index_path}
                            index_list={this.state.index_list}
                            reqStartTime={this.state.reqStartTime}
                            doc_help_questions={this.state.doc_help_questions}
                        />
                    )}
                </ErrorBoundary>

                <Toaster />
            </Fragment>
        );
    }
}
// ChatWithDocComponent.contextType = WorkspaceContext

const mapStateToProps = (state) => ({
  chatWithDocState: state.chatWithDoc.chatWithDocState, // Use the appropriate reducer key
  dashBoardState: state.dashBoard.dashBoardState,
});

const mapDispatchToProps = {
  updateChatWithDocState, // This maps your action directly to props
  updateDashBoardState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatWithDocComponent);
