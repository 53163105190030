import { Formik } from "formik";
import { Fragment, useState } from "react";
import * as Yup from "yup";
import HelperMessages from "../../../Helpers/HelperMessages";

const CreateDocTypePopUp = (props) => {
  const [state, setState] = useState({
    error: "",
    event: undefined,
    docName: "",
  });

  const handleSubmit = (values) => {
    if (props?.documentTypes?.length > 0) {
      props.documentTypes?.push({
        documentName: state?.event?.target?.value,
        documentID: state?.event?.target?.value,
      });
    }
    props.handleDocTypeChangeCallback(state.event, props.documentTypes);
    props.closepopup();
  };

  const handleValidation = Yup.object().shape({
    docTypeName: Yup.string().required("Doc Type Name is required."),
  });

  return (
    <Fragment>
      <div
        id="popup-overlay"
        className={`custom-popup-overlay view-fav-popup-overlay  show
            }`}
        // onClick={() => props.closepopup()}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          style={{ height: "35%" }}
          className={`custom-popup-content create-new-popup`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom-popup-header">
            <h2>{"Create Document Type"}</h2>
            <h2>
              <span
                className="close-btn"
                onClick={() => props.closepopup()}
                title="Close"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                  alt="close"
                />
              </span>
            </h2>
          </div>
          <div className="custom-popup-body">
            <Formik
              initialValues={{ docName: "" }}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={handleValidation}
            >
              {({ errors, values, setFieldValue }) => {
                const handleChange = (e) => {
                  const { value } = e.target;
                  if (
                    props?.documentTypes?.length > 0 &&
                    props.documentTypes.filter((x) => x.documentName === value)
                      .length === 0
                  ) {
                    setState({
                      ...state,
                      docName: value,
                      event: e,
                      error: "",
                    });
                  } else {
                    setState({
                      ...state,
                      docName: "",
                      error: HelperMessages.DOC_TYPE_ALREADY_EXIST,
                    });
                  }
                };
                return (
                  <div>
                    <div
                      style={{ padding: "0 3% 0 3%", height: "100px" }}
                      className="mt-3 mb-2"
                    >
                      <div className="align mt-3 fulldrapdropsec">
                        <h4> Doc Type: </h4>
                        <input
                          id="docTypeName"
                          type="text"
                          name="docTypeName"
                          value={values.workSpace}
                          placeholder="Document Type"
                          className="inputfield"
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <input id="workSpace" name="workSpace" type="text" value={values.workSpace} onChange={(e) => handleChange(e)}></input> */}
                      </div>
                      <p className="error-color mt-2 text-center mb-3">
                        {errors && errors.docTypeName
                          ? errors.docTypeName
                          : state.error}
                      </p>
                    </div>

                    <div
                      className={`mt-5 button-container`}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        title={"Save"}
                        className="continue"
                        onClick={() => handleSubmit(values)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CreateDocTypePopUp;
