import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Popup } from "../../SupportiveComponents/CustomPopup";
import { UserDetailsForm } from "./Helpers/UserForm";
import toast from "react-hot-toast";
import useTheme from "../../../contexts/theme";
export const UserOperations = (props) => {
    const gridRef = useRef();
 const { themeMode } = useTheme();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const getRowClass = (params) => {
        if (params.data.locked) {
            return "custom-grid-row-error";
        }
    };
    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        {
            field: "user_id",
            headerName: "User ID",
            maxWidth: 100,
        },
        {
            field: "full_name",
            headerName: "Full Name",
        },
        {
            field: "email",
            headerName: "Email",
        },
        {
            field: "user_name",
            headerName: "Username",
            minWidth: 100,
        },
        {
            field: "organization_name",
            headerName: "Organization",
            minWidth: 100,
        },
        {
            field: "user_type",
            headerName: "User type",
            minWidth: 100,
        },
        {
            field: "user_id",
            headerName: "Actions",
            cellRenderer: (params) => {
                return (
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                setSelectedRowData(params.data);
                                setDisplayPopup(!displayPopup);
                            }}
                        >
                            Details
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                console.log(params?.data?.user_id);
                                logoutspecificuser(params?.data?.user_id);
                            }}
                        >
                            Logout
                        </button>
                    </>
                );
            },
            pinned: "right",
            colId: "action",
            editable: false,
            maxWidth: 250,
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
        };
    }, []);

    const onGridReady = useCallback(
        (params) => {
            const axiosInstance = getAxiosInstance(props.token);

            axiosInstance
                .get("/admin/getAllUsersInfo", {
                    crossDomain: true,
                    timeout: 300000,
                })
                .then((response) => {
                    console.log(response, "Fetch All Organizations");
                    setRowData(response.data);
                })
                .catch((error) => {
                    console.error(`Something went wrong ${error}`);
                });
        },
        [props.token]
    );

    const logoutspecificuser = useCallback((user_id) => {
        const formData = new FormData();
        formData.append("user_id", user_id);

        // Set up the axios configuration

        const axiosInstance = getAxiosInstance();
        // Make the request
        axiosInstance
            .post("/logoutspecificuser", formData, {
                crossDomain: true,
                timeout: 300000,
                data: formData,
            })
            .then((response) => {
                console.log(response);
                if (response?.status === 200) {
                    toast.success("logged out user successfully");
                }
            })
            .catch((error) => {
                console.error(`Something went wrong ${error}`);
            });
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById("filter-text-box").value
        );
    }, []);

    return (
        <>
            {displayPopup ? (
                <>
                    <Popup
                        title={`User Info - ${selectedRowData?.user_name}`}
                        content={
                            <UserDetailsForm
                                seleteddata={selectedRowData}
                                closepopup={() =>
                                    setDisplayPopup(!displayPopup)
                                }
                                token={props.token}
                            />
                        }
                        closepopup={() => setDisplayPopup(!displayPopup)}
                    ></Popup>
                </>
            ) : (
                <></>
            )}
            <div className="regBody">
                <div className="header custom-grid-header">
                    <h3>All customer accounts</h3>
                </div>

                <div className="ag-grid-user-management-wrapper">
                    <div
                        className="logInfields"
                        style={{ marginBottom: "20px" }}
                    >
                        <label htmlFor="filter-text-box">Quick Filter:</label>
                        <input
                            type="search"
                            id="filter-text-box"
                            placeholder="Filter by name, email"
                            className="filtertextbox"
                            autoComplete="off"
                            onInput={onFilterTextBoxChanged}
                        />
                    </div>

                    <div
                        style={gridStyle}
                        className="ag-grid-custom ag-theme-quartz"
                    >
                        <AgGridReact
                            ref={gridRef}
                            className={`${
                                themeMode === "light"
                                    ? "ag-theme-quartz"
                                    : "ag-theme-quartz-dark"
                            } custom-ag-grid-height ${themeMode}`}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            getRowClass={getRowClass}
                            pagination={true}
                            paginationPageSize={25}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
