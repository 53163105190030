import React, { Fragment, useState } from "react";
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Switch from "react-switch";

import LoadingSpinner from "../../../SupportiveComponents/LoadingSpinner";
import getAxiosInstance from "../../../SupportiveComponents/AxiosRequest";

export const UserDetailsForm = (props) => {
    const [userdetails, setUserDetails] = useState(props.seleteddata);
    const [displayLoader, setDisplayLoader] = useState(false); //setDisplayLoader
    const axiosInstance = getAxiosInstance(props.token);
    const formik = useFormik({
        initialValues: { ...userdetails },
        onSubmit: (values) => {
            setDisplayLoader(true);
            console.log(values, " Test userFormData", userdetails);
            submitForm();
        },
    });
    const submitForm = () => {
        let formData = new FormData();
        formData.append("user_id__organization_id", formik?.values?.user_id);
        formData.append("islocked", formik?.values?.locked ? 1 : 0);
        axiosInstance
            .post("/admin/updateUserInfo", formData, {
                crossDomain: true,
                timeout: 300000,
            })
            .then((response) => {
                console.log(response, "lock User response");
                if (response.data.length > 0) {
                    switch (response.data[0]["status_code"]) {
                        case 202:
                            toast.success("lock status updated successfully!");
                            props.closepopup(); // from props
                            break;
                        case 208:
                            toast.error(
                                "request to change status made already!"
                            );
                            break;
                        case 500:
                        case 404:
                            toast.error("something went wrong!");
                            break;
                        default:
                            toast.error("something went wrong!");
                            break;
                    }
                } else {
                    toast.error("something went wrong!");
                }
            })
            .catch((error) => {
                toast.error("something went wrong!");
                console.error(`Something went wrong ${error}`);
            })
            .finally(() => {
                setDisplayLoader(false);
            });
    };

    return (
        <>
            {displayLoader ? <LoadingSpinner text={"Loading..."} /> : <></>}
            <div className="custom-form">
                <form
                    className="view-organization-details-form"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="user_id">User id</label>
                            <input
                                htmlFor="user_id"
                                type="number"
                                value={formik?.values?.user_id}
                                readOnly
                            ></input>
                        </div>
                        <div className="form-element">
                            <label htmlFor="user_name">User name</label>
                            <input
                                id="user_name"
                                name="user_name"
                                type="text"
                                value={formik?.values?.user_name}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="user_type">User Type</label>
                            <input
                                id="user_type"
                                name="user_type"
                                value={formik?.values?.user_type}
                                type="text"
                                readOnly
                            />
                        </div>
                        {/** organization_name: "Srinayan Org"*/}
                        <div className="form-element">
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                name="email"
                                value={formik?.values?.email}
                                type="email"
                                readOnly
                            ></input>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="full_name">Full name</label>
                            <input
                                id="full_name"
                                name="full_name"
                                type="text"
                                value={formik?.values?.full_name}
                                readOnly
                            ></input>
                        </div>
                        <div className="form-element justify-content-initial">
                            <label htmlFor="locked">Locked</label>
                            {/*locked*/}
                            <Fragment>
                                <Switch
                                    id="locked"
                                    name="locked"
                                    className={
                                        formik?.values?.locked
                                            ? "switchYes"
                                            : "switch"
                                    }
                                    onChange={(checked, event, id) => {
                                        formik?.setFieldValue(
                                            "locked",
                                            checked
                                        );
                                    }}
                                    checked={formik?.values?.locked}
                                ></Switch>
                            </Fragment>
                        </div>
                    </div>
                    <div className="form-action-buttons center-align">
                        <button
                            className="btn btn-secondary"
                            disabled={isEqual(formik.values, props.seleteddata)}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            disabled={isEqual(formik.values, props.seleteddata)}
                            onClick={() => {
                                setUserDetails(props.seleteddata);
                                props.closepopup();
                            }}
                            className="btn btn-secondary"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};
