import { useState, useRef } from "react";

export const HandleHelpQuestionSComponent = ({
    doc_help_questions_v,
    handlehelpquestionsChangecallback,
}) => {
    const [selectedquestion, setSelectedQuestions] = useState("");
    const selectRef = useRef(null);
     const handleSelectClick = (event) => {
         const value = selectRef.current.value;
         console.log("Select clicked. Current selected option:", value);
         // Additional logic can be added here if needed
     };
    return (
        <div className="helpquestions">
            <select
                ref={selectRef}
                name="help_questions"
                id="help_questions-select"
                value={selectedquestion.length > 0 ? selectedquestion : ""}
                onChange={(e) => {
                    if (e.target.value.length > 0) {
                        setSelectedQuestions(e.target.value);
                        handlehelpquestionsChangecallback(e.target.value);
                    }
                }}
                onClick={handleSelectClick}
            >
                <option key={`00`} value="">
                    Jumpstart your query
                </option>
                {doc_help_questions_v.map((item, i) => (
                    <option
                        key={i}
                        value={item?.question ? item?.question : item}
                        title={item?.question ? item?.question : item}
                    >
                        {item?.question ? item?.question : item}
                    </option>
                ))}
            </select>
        </div>
    );
};