import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { CustomTable } from "./AgGridTemplates/CustomAgGrid";
import { TrashCanIcon, XIcon } from "./FontAwesomeComponents/heart/filledheart";
import { EditIcon, SaveIcon } from "./FontAwesomeComponents/HelperIcons";
import LoadingSpinner from "./LoadingSpinner";
import TruncateText from "./TruncateFileName";

export const Popup = ({ title, closepopup, content }) => {
    return (
        <>
            <div
                id="popup-overlay"
                className="custom-popup-overlay view-fav-popup-overlay custom-popup-overlay"
            >
                <div className="custom-popup-content">
                    <div className="custom-popup-header">
                        <h2>{title}</h2>
                        <h2>
                            <span
                                className="close-btn"
                                onClick={() => closepopup()}
                                title="Close"
                            >
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                    alt="close"
                                />
                            </span>
                        </h2>
                    </div>
                    <div className="custom-popup-body">{content}</div>
                </div>
            </div>
        </>
    );
};

export const FavPopup = ({
    title,
    closepopup,
    content,
    favdisplayval,
    customclass,
}) => {
    return (
        <div
            id="popup-overlay"
            className={`custom-popup-overlay view-fav-popup-overlay  ${favdisplayval ? "show" : "hide"
                }`}
            onClick={() => closepopup()}
        >
            <div
                className={`custom-popup-content ${customclass}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="custom-popup-header">
                    <h2>{title}</h2>
                    <h2>
                        <span
                            className="close-btn"
                            onClick={() => closepopup()}
                            title="Close"
                        >
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                alt="close"
                            />
                        </span>
                    </h2>
                </div>
                <div className="custom-popup-body">{content}</div>
            </div>
        </div>
    );
};

export const CaseAnalysisPopup = ({
    title,
    closepopup,
    content = [],
    callback,
    removeFavoriteCallback,
}) => {
    const GridRef = useRef();

    return (
        <>
            <div id="popup-overlay"
                className={`custom-popup-overlay view-fav-popup-overlay  show `} style={{ height: '100%', width: '100%',zIndex:9 }}>
                <div style={{ height: '70%'}} className={`custom-popup-content case-popup`}
                    onClick={(e) => e.stopPropagation()} >
                    <div className="custom-popup-header">
                        <h2>{title}</h2>
                        <h2>  <span
                            className="close-btn"
                            onClick={() => closepopup()}
                            title="Close"  >
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                alt="close"
                            />
                        </span>
                        </h2>
                    </div>
                    <div className="custom-popup-body">
                        <CustomTable
                            gridRef={GridRef}
                            defaultcoldefs={{
                        flex: 1,
                        resizable: true,
                        editable: false,
                        autoHeight: true,
                        sortable: true,
                        supressSizeToFit: true,
                    }}
                            //  onFirstDataRendered={GridRef.current.api.sizeColumnsToFit()}
                            columndefs={[
                                {
                                    headerName: "",
                                    valueGetter: "node.rowIndex + 1", // Incremental number starting from 1
                                    maxWidth: 70,
                                    sortable: true,
                                    resizable: true,
                                    supressSizeToFit: true,
                                },
                                {
                                    sortable: true,
                                    resizable: true,
                                    filter: true,
                                    field: "case_name",
                                    headerName: "Case Name",
                                    suppressSizeToFit: true,
                                    // headerClass: "text-center",
                                    className: "text-color",
                                    minWidth: 500,
                                    cellRenderer: (params) => {
                                        return (
                                            <div className="text-link" onClick={() => { callback(params.data); }} >
                                                {params.data.case_name}{" "}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    field: "favorite_id",
                                    headerName: "Action",
                                    resizable: true,
                                    suppressSizeToFit: true,
                                    // width: 100,
                                    cellStyle: {
                                        textAlign: "center",
                                    },
                                    cellRenderer: (params) => {
                                        return (
                                            <button
                                                title="Remove from Favorites"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeFavoriteCallback(
                                                        params.data.case_name
                                                    );
                                                }}
                                            >
                                                <TrashCanIcon />
                                            </button>
                                        );
                                    },
                                },
                            ]}
                            rowdata={content}
                            activeTab={"01"}
                            pageSize={20}
                            fromCaseAnalysis={true}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export const BatchComponent = ({
    handleBatchRenameCallback,
    popupActivebatchName,
    handleValuesChangeCallback,
    handleBatchPopUpCallback,
    handleBatchCallback,
    isRenaming,
    fileName,
    batchFiles,
    handleBatchRename
}) => {
    const [batchFiless, setBatchFiles] = useState(batchFiles);
    const [filesbool, setfilesbool] = useState(false);
    const[loading, setLoading] = useState(false)
    const gridRef = useRef();

    useEffect(() => {
        let filesupdated = batchFiles.map((val, index) => {
            let obj = {
                serial_id: index + 1,
                url: URL.createObjectURL(val),
                fileName: val?.name,
                localdatereccreate: new Date().toLocaleString(),
            };

            return obj;
        });

        setBatchFiles(filesupdated);
        setfilesbool(true);
        return () => {
            filesupdated.map((val) => {
                return URL.revokeObjectURL(val?.url);
            });
        };
    }, [batchFiles]);

    const BatchRename =async ()=>{
        setLoading(true);
        let res = await handleBatchRenameCallback(false);
        handleBatchRename(false)
        setLoading(false);
        if(res){
            Swal.fire({
                text: "Batch name updated successfully.",
                icon: "success",
                confirmButtonText: "OK",
                customClass: {
                popup: 'custom-swal',
            },
            });
        }
    }
    
    return (
        <>
            {" "}
            <h2 className="d-flex mb-2" style={{color:'black'}}>
                {" "}
                {loading && <LoadingSpinner text="Updating the batch name" />}
                {isRenaming && (
                    <><h5 className="ml-1" style={{color:'#999'}}>BATCH NAME: </h5>
                        <input
                        style={{marginTop:'-1%',width:'70%'}}
                            id="plaintiff"
                            type="text"
                            name=""
                            placeholder="Plaintiff"
                            className="inputfield ml-1"
                            value={fileName}
                            onChange={handleValuesChangeCallback}
                            autoComplete="off"
                            required
                        />
                        <span onClick={()=>handleBatchRename(false)} className="ml-1 mr-3">
                            <XIcon  Style={{color:'black'}}/>
                        </span>
                        <span
                            onClick={() => BatchRename(false)}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                        
                        <SaveIcon/>
                            {/* ✓ */}
                        </span>

                    </>
                )}
                {!isRenaming && (
                    <div
                    style={{width:'100%'}}
                        className="custom-pop-filename d-flex ml-1"
                        onClick={() => handleBatchRename(true)}
                    >
                      <h5 className="space" style={{color:'#999'}}>BATCH NAME:</h5>  {popupActivebatchName} <EditIcon style={{cursor:'pointer',color:'#999',marginLeft:'2%'}} />
                    </div>
                )}
            </h2>
            {filesbool && (
                <CustomTable
                    ref={gridRef}
                    favdisplayval={true}
                    rowdata={batchFiless}
                    activeTab={1}
                    defaultcoldefs={{
                        flex: 1,
                        resizable: true,
                        editable: false,
                    }}
                    columndefs={[
                        {
                            field: "serial_id",
                            headerName: "",
                            width: 50,
                            maxWidth: 50,
                            suppressSizeToFit: true,
                            headerClass: "text-center",
                            cellStyle: {
                                textAlign: "center",
                            },
                        },
                        {
                            field: "fileName",
                            headerName: "File name",

                            suppressSizeToFit: true,
                            cellRenderer: (params) => {
                                return (
                                    <>
                                        <TruncateText
                                            text={params?.value}
                                            maxLength={params?.value.length}
                                            fileURL={params?.data?.url}
                                            isBatch={false}
                                        />
                                    </>
                                );
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};
