import moment from "moment-timezone";
import toast from "react-hot-toast";
export const ToastAlert=(props)=>{
    toast.success(props);
}

export const ToastErrorAlert=(message)=>{
  toast.error(message);
}

export  const convertUtcToEst=(utcTime)=> {
    return moment.utc(utcTime).tz('America/New_York').format('MM/DD/YYYY h:mm A');
  }

export const convertUtcToLocal = (utcTime) => {
    return moment
        .utc(utcTime)
        .tz(
            Intl.DateTimeFormat().resolvedOptions().timeZone
                ? Intl.DateTimeFormat().resolvedOptions().timeZone
                : "America/New_York"
        )
        .format("MM/DD/YYYY h:mm A");
};

export const getCurrentTime=()=>{
    const currentDateTime = moment();

  // Format the current date and time into US format (MM/DD/YYYY hh:mm:ss A)
  const formattedDateTime = currentDateTime.format('MM/DD/YYYY h:mm A')
  return formattedDateTime;
}

export const formatTime=(date)=>{
  return moment(date, "M/D/YY").format("YYYY-MM-DD")
}


export const convertGMTToLocal=(gmtTime)=>{
  return moment.utc(gmtTime).local().format('MM/DD/YYYY h:mm A');
}


