import { createContext, useContext } from "react";
import { Manager } from "socket.io-client";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { jsonrepair } from "jsonrepair";
import { Createaccesslog } from "../Components/SupportiveComponents/AxiosRequest";
import { getCurrentTime } from "../Components/SupportiveComponents/CustomAlerts/ToastAlert";
import {
  updateChatWithDocState,
  updateChatWithInternalDocsState,
  updateReviewState,
  updateSummaryState,
} from "../redux/actions";
import store from "../redux/store";
import { SIOEVENTS } from "../Enums/EventNames";
import { LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT, OPENAI_ENGINE_HELP_QUESTION_PROMPT } from "../js/constants";

const token = localStorage.getItem("token");

const manager = new Manager(window.location.origin);
//using localhost url when we are in debug mode i.e running react on a seperate port

//Main Namespace
export const socket = manager.socket("/", { auth: { token: token } });

//admin Namespace
export const adminSocket = manager.socket("/workspace", {
    auth: { token: token },
});

//subscribing connection event from server
socket.on("connection", () => {
    console.log(`websockets `, socket);
    if (token) socket.emit("auth", { token: token });

    //emit message event to server
});
socket.on("auth", (data) => {
    if (data["authenticated"]) {
        let accessToken = localStorage.getItem("token");
        socket.emit("joinUserRoom", accessToken);
    } else {

        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("organization");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("usertypeid");
        localStorage.removeItem("userType");
        localStorage.removeItem("org_id");

        // Broadcast logout event to other tabs
        localStorage.setItem("logout", Date.now().toString());
    }
});

socket.on("message", (obj) => {
    console.log(`a test message emitted to detect server connectivity`, obj);
});
socket.io.on("error", (error) => {
    console.error(`connection error occured in this event => ${error}`);
});
socket.io.on("reconnect", (attempt) => {
    console.log(`reconnect event triggered => ${attempt}`); 
});
socket.io.on("reconnect_attempt", (attempt) => {
    console.log(`reconnect_attempt event triggered => ${attempt}`); 
});
socket.io.on("reconnect_error", (err) => {
    console.log(`reconnect_error event occured ${err}`); 
});
socket.io.on("reconnect_failed", (err) => {
    console.log(`reconnection failed ${err}`); 
});
socket.on(SIOEVENTS.SUMMARIZE.RESPONSE, (data) => {
    let currentState = store.getState().summary.summarizeState;
    if (data?.error) {
        store.dispatch(
            updateSummaryState({
                ...currentState,
                isLoading: false,
                isSocketRequestProgress: false,
            })
        );
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    } else {
          store.dispatch(
              updateSummaryState({
                  ...store.getState().summary.summarizeState,
                  isLoading: false,
                  isSocketRequestProgress: false,
              })
          );
    }
    if (
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        let end_time = new Date().getTime();
        let startTime = currentState?.reqStartTime;
        let processtimeinsec = (
            (end_time - (data?.workspace_id ? data.reqStartTime : startTime)) /
            1000
        ).toFixed(2);
        if (data?.error_Msg) {
            store.dispatch(
                updateSummaryState({
                    ...currentState,
                    isLoading: false,
                })
            );
        } else {
            let currentState = store.getState().summary.summarizeState;
            if (data?.Concise_Summary || data?.Intermediate_Summary) {
                store.dispatch(
                    updateSummaryState({
                        ...currentState,
                        summaryResult: data,
                        isUploadFileOpen: false,
                        isLoading: false,
                        prevSelectedFile: currentState?.selectedFile,
                        summaryTimer: processtimeinsec + "s",
                        summaryTime: getCurrentTime(),
                        index_path: data?.index_path,
                        isIndexCreated: data?.index_path ? true : false,
                    })
                );
                let prompt =
                    +store.getState().summary.summarizeState.selectedEngine === 1
                        ? OPENAI_ENGINE_HELP_QUESTION_PROMPT
                        : LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT;
                let ques = {
                    msg: {
                        map: prompt,
                        reduce: prompt,
                    },
                    ishelpquestionrequest: true,
                    doctype:
                        store.getState().summary.summarizeState.selectedDocType,
                    engineType:
                        store.getState().summary.summarizeState.selectedEngine,
                    srcname: undefined,
                    topk: undefined,
                    token: token,
                    uploadFileId:
                        store.getState().summary.summarizeState.uploadFileId,
                    hwFlag: store.getState().summary.summarizeState
                        .isHandWritten
                        ? "1"
                        : "0",
                    fileName: store.getState().summary.summarizeState.fileName,
                    isBatchFile: false,
                    index_path: data?.index_path,
                    workspace_item_id:
                        store.getState().summary.summarizeState
                            .workspace_item_id,
                    workspace_id:
                        store.getState().summary.summarizeState.workspace_id,
                };
                socket.emit("summarize_chat", ques);
                const formData = new FormData();

                formData.append("file", currentState?.selectedFile.file);
                formData.append("document_type", currentState?.selectedDocType);
                formData.append("is_hand_written", currentState?.isHandWritten);
                formData.append("module_id", 2);
                formData.append("engine_id", currentState?.selectedEngine);
                const formDataForaccesslogs = formData;
                formDataForaccesslogs.append(
                    "doc_name",
                    currentState?.selectedFile.file.name
                );
                Createaccesslog(formDataForaccesslogs, processtimeinsec);
            }

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;
                if (data?.q) {
                    let obj = {
                        question: data?.q?.msg?.short ? data?.q?.msg?.short : data?.q?.msg,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                        review_by_name: currentState?.isSharedWorkspace
                            ? data?.review_by_name
                            : "",
                    };
                    currentState = store.getState().summary.summarizeState;
                    store.dispatch(
                        updateSummaryState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                }
            }
        }
    }
});

//part of multi module processing implementation
socket.on(SIOEVENTS.REPO_CHAT.RESPONSE, (data) => {

    const currentState =
        store.getState().chatWithInternalDocs.chatWithInternalDocsState;
    let now = new Date().getTime();
    let startTime = currentState.reqStartTime;
    let processtimeinsec = ((now - startTime) / 1000).toFixed(2);
    let para = "";
    if (Array.isArray(data.msg)) {
        data.msg.forEach((element) => {
            para += element + " \n ";
        });
    } else {
        para = data.msg;
    }
    let links = "";
    let metas = data?.metas?.slice(0, 2);
    metas?.map(
        (link) => (links += link.link + " - Pages " + link.pages + " \n ")
    );
    metas?.forEach((element) => {
        const lastDotIndex = element.link.lastIndexOf("/");
        if (lastDotIndex !== -1) {
            element.label = element.link.substring(lastDotIndex + 1);
        } else {
            element.label = element.link;
        }
    });
    let obj = {
        response: para + " \n Sources \n " + links,
        question: currentState.selectedPrompt.id,
        message: para,
        sources: metas,
        time: new Date().toLocaleString(),
        timetaken: processtimeinsec + "s",
    };
    const { Chats } = currentState;

    store.dispatch(
        updateChatWithInternalDocsState({
            ...currentState,
            Chats: [...Chats, obj],
            isLoading: false,
            inputValue: "",
        })
    );
});

//Chat WITH DOCS
socket.on(SIOEVENTS.CHAT_WITH_DOCS.RESPONSE, (data) => {
    store.dispatch(
        updateChatWithDocState({
            ...store.getState().chatWithDoc.chatWithDocState,
            isLoading: false,
            isSocketRequestProgress: false,
        })
    );
    if (data?.error) {  
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    } 
    let currentState = store.getState().chatWithDoc.chatWithDocState;

    if (
        data?.loader_off ||
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        const userState = store.getState().login.logInState;
       
        if (data.loader_off) {
            let docs = [];
            if (data?.index_list?.length > 0) {
                data?.index_list?.length > 0 &&
                    data.index_list.forEach((e, i) => {
                        currentState?.docsContent?.length > 0 &&
                            currentState.docsContent.forEach(
                                (element, index) => {
                                    if (
                                        element.fileName ===
                                        e.name.substring(
                                            e.name.indexOf("_") + 1
                                        )
                                    ) {
                                        element.index_path = e.index_path;
                                        docs.push(element);
                                    }
                                }
                            );
                    });
                 
            } else {
                docs = currentState.docsContent;
            }
            currentState = store.getState().chatWithDoc.chatWithDocState;
            store.dispatch(
                updateChatWithDocState({
                    ...currentState,
                    isLoading: false,
                    isIndexCreated: true,
                    index_list: data?.index_list,
                    docsContent: docs,
                })
            );
            let message =
                currentState?.batchFiles?.length > 1
                    ? `Your batch of files, <b>${currentState?.fileName}</b> is now ready for chat.`
                    : `Your document, <b>${currentState?.fileName}</b> is now ready for chat.`;
            let msg = message;
            let msgObj = {
                id: uuidv4(),
                message: message,
                msg: msg,
                fromuserid: userState?.user_id,
                touserid: userState?.user_id,
                timeread: null,
                displaystatus: "a",
                created_by: "self",
                created_at: new Date(),
                updated_by: null,
                updated_at: null,
            };
            socket.emit("in_app_notification", msgObj);
            return;
        }

        try {

            if (data === undefined) {
                currentState = store.getState().chatWithDoc.chatWithDocState;
                store.dispatch(
                    updateChatWithDocState({
                        ...currentState,
                        isLoading: false,
                        isUploadFileOpen: true,
                        selectedFile: "",
                        fileName: "",
                        selectedDocType: "",
                        showMsgBoxAfterUpload: false,
                    })
                );
            } else {
                let now = new Date().getTime();
                let processtimeinsec = (
                    (now - data?.reqStartTime) /
                    1000
                ).toFixed(2);
                let formDataForAccessLog = new FormData();
                formDataForAccessLog.append("module_id", 3);
                formDataForAccessLog.append(
                    "engine_id",
                    currentState.selectedEngine
                );
                formDataForAccessLog.append(
                    "document_type",
                    currentState.selectedDocType
                );
                formDataForAccessLog.append(
                    "is_hand_written",
                    currentState.isHandWritten
                );
                formDataForAccessLog.append("doc_name", currentState.fileName);
                Createaccesslog(formDataForAccessLog, processtimeinsec);
                let res = "";

                if (Array.isArray(data.msg) && data.msg.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                let obj = {
                    question: currentState.selectedPrompt.id,
                    response: res,
                    time: getCurrentTime(),
                    timetaken: processtimeinsec + "s",
                    qid: data?.qid,
                    fname: data["fname"],
                    tresp: data?.tresp,
                    embeddings: data?.embeddings,
                };

                if (currentState.workspace_id > 0) {
                    obj.question = data?.q?.msg?.short;
                    obj.fname = data?.index_name;
                    obj.chat_user_name = currentState?.isSharedWorkspace
                        ? data?.chat_user_name
                        : "";
                }
                const { Chats } = currentState;
                Chats[obj.qid] = Chats.hasOwnProperty(obj.qid)
                    ? [...Chats[obj.qid], obj]
                    : [obj];

                let userId = parseInt(localStorage.getItem("user_id"));
                if (data?.workspace_id && data?.workspace_id > 0) {
                    if (
                        data?.user_id === userId ||
                        (data?.user_id !== userId &&
                            Chats[
                                Object.keys(Chats)[
                                    Object.keys(Chats)?.length - 1
                                ]
                            ][0]?.tresp ===
                                Chats[
                                    Object.keys(Chats)[
                                        Object.keys(Chats)?.length - 1
                                    ]
                                ]?.length)
                    ) {
                        currentState =
                            store.getState().chatWithDoc.chatWithDocState;
                        store.dispatch(
                            updateChatWithDocState({
                                ...currentState,
                                Chats: { ...Chats },
                                isUploadFileOpen: false,
                                isLoading: false,
                                inputValue: "",
                                showMsgBoxAfterUpload: false,
                            })
                        );
                    }
                } else {
                    currentState =
                        store.getState().chatWithDoc.chatWithDocState;
                    store.dispatch(
                        updateChatWithDocState({
                            ...currentState,
                            Chats: { ...Chats },
                            isUploadFileOpen: false,
                            isLoading: false,
                            inputValue: "",
                            showMsgBoxAfterUpload: false,
                        })
                    );
                }
            }
        } catch (error) {
            console.error("ErrorHandleResponse:", error);
            currentState = store.getState().chatWithDoc.chatWithDocState;
            store.dispatch(
                updateChatWithDocState({
                    ...currentState,
                    isUploadFileOpen: true,
                    isLoading: false,
                    selectedFile: "",
                    fileName: "",
                    selectedDocType: "",
                    showMsgBoxAfterUpload: false,
                })
            );
        }
    }
});

//Review Component
socket.on(SIOEVENTS.REVIEW.RESPONSE, (data) => {
    if (data?.cancel) {
        let currentState = store.getState().review.reviewState;
        store.dispatch(
            updateReviewState({
                ...currentState,
                isLoading: false,
                isSocketRequestProgress: false,
            })
        );
    } else {
         let currentState = store.getState().review.reviewState;
         store.dispatch(
             updateReviewState({
                 ...currentState,
                 isLoading: false,
                 isSocketRequestProgress: false,
             })
         );
    }
    if (data?.error) {
        let currentState = store.getState().review.reviewState;
        store.dispatch(
            updateReviewState({
                ...currentState,
                isLoading: false,
            })
        );
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }

    let currentState = store.getState().review.reviewState;
    if (
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        if (data === undefined) {
            return;
        }
        if (data?.index_path) {
            store.dispatch(
                updateReviewState({
                    ...currentState,
                    index_path: data?.index_path,
                    isIndexCreated: true,
                })
            );
            let prompt = +(store.getState().review.reviewState.selectedEngine) === 1 ? OPENAI_ENGINE_HELP_QUESTION_PROMPT : LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT;
            let ques = {
                msg: {
                    map: prompt,
                    reduce: prompt,
                },
                ishelpquestionrequest: true,
                doctype: store.getState().review.reviewState.selectedDocType,
                engineType: store.getState().review.reviewState.selectedEngine,
                srcname: undefined,
                topk: undefined,
                token: token,
                uploadFileId: store.getState().review.reviewState.uploadFileId,
                hwFlag: store.getState().review.reviewState.isHandWritten
                    ? "1"
                    : "0",
                fileName: store.getState().review.reviewState.fileName,
                isBatchFile: false,
                index_path: data?.index_path,
                workspace_item_id:
                    store.getState().review.reviewState.workspace_item_id,
                workspace_id: store.getState().review.reviewState.workspace_id,
            };
            socket.emit("review_doc_chat", ques);
        }
        currentState = store.getState().review.reviewState;
        /**
         * handling response
         */

        try {
            let now = new Date().getTime();
            let processtimeinsec = (
                (now - currentState.reqStartTime) /
                1000
            ).toFixed(2);

            let formDataForAccessLog = new FormData();
            formDataForAccessLog.append("module_id", 1);
            formDataForAccessLog.append(
                "engine_id",
                currentState.selectedEngine
            );
            formDataForAccessLog.append(
                "document_type",
                currentState.selectedDocType
            );
            formDataForAccessLog.append(
                "is_hand_written",
                currentState.isHandWritten
            );
            formDataForAccessLog.append(
                "doc_name",
                currentState.selectedFile.name
            );
            Createaccesslog(formDataForAccessLog, processtimeinsec);

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;

                if (data?.q) {
                    let obj = {
                        question: currentState.selectedPrompt.id,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                    };
                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                } else {
                    let obj = {};

                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            ...obj,
                            reviewTime: getCurrentTime(),
                            reviewResult: res,
                            isUploadFileOpen: false,
                            isLoading: false,
                            prevSelectedFile: currentState.selectedFile,
                            reviewTimer: processtimeinsec + "s",
                            isIndexCreated: true,
                        })
                    );
                }
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
        }
        if (
            data.q?.workspace_id > 0 &&
            data.q?.workspace_id === currentState.workspace_id &&
            data.q?.workspace_item_id === currentState.workspace_item_id
        ) {
            let now = new Date().getTime();
            let processtimeinsec = ((now - data.reqStartTime) / 1000).toFixed(
                2
            );

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;

                if (data.q) {
                    let obj = {
                        question: data?.q?.msg?.short
                            ? data?.q?.msg?.short
                            : data?.q?.msg,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                        review_by_name: currentState?.isSharedWorkspace
                            ? data?.review_by_name
                            : "",
                    };
                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                }
            }
        }
    }
});

socket.on("summary_chat_questions", (data) => {
    if (data?.msg) {
        let responsetext = data?.msg.replace('```json', '');
        responsetext = responsetext.replace('```', '');
        responsetext = responsetext.replace('`','');
        let jsonText = responsetext.trim();

             try {
                 // Parse the extracted JSON string into a JavaScript array
                 const jsonArray = JSON.parse(extractJsonArray(jsonText));

                 console.log(jsonArray);
                 let builtjsonArray = []
                 jsonArray.forEach(element => {
                    builtjsonArray.push(toLowerCaseKeys(element));
                 });
                 console.log(jsonText, `before parsing`, jsonArray);
                 //const jsonObject = JSON.parse(jsonText);

                 let currentState = store.getState().summary.summarizeState;

                 store.dispatch(
                     updateSummaryState({
                         ...currentState,
                         doc_help_questions: builtjsonArray,
                     })
                 );
             } catch {
                 console.error(`something went wrong`);
             }
        }
      
    
});

socket.on("review_doc_chat_questions", (data) => {
    if (data?.msg) {
        let responsetext = data?.msg.replace('```json', '');
        responsetext = responsetext.replace('```', '');
        responsetext = responsetext.replace('`','');
        let jsonText = responsetext.trim();

             try {
                 const jsonArray = JSON.parse(extractJsonArray(jsonText));

                 console.log(jsonArray);
                  let builtjsonArray = [];
                  jsonArray.forEach((element) => {
                      builtjsonArray.push(toLowerCaseKeys(element));
                  });
                 console.log(jsonText, `before parsing`, jsonArray);
                 //const jsonObject = JSON.parse(jsonText);

                 let currentState = store.getState().review.reviewState;

                 store.dispatch(
                     updateReviewState({
                         ...currentState,
                         doc_help_questions: builtjsonArray,
                     })
                 );
             } catch {
                 console.error(`something went wrong`);
             }
        }
      

 
});


socket.on("doc_chat_questions", (data) => {
    if (data?.msg) {
        let responsetext = data?.msg.replace("```json", "");
        responsetext = responsetext.replace("```", "");
        responsetext = responsetext.replace("`", "");
        let jsonText = responsetext.trim();

        try {
            // Parse the extracted JSON string into a JavaScript array
            console.log(
                extractJsonArray(jsonText),
                `<= jsonText`,
                typeof extractJsonArray(jsonText)
            );
            const jsonArray = JSON.parse(jsonrepair(extractJsonArray(jsonText)));
            console.log(jsonArray);
            let builtjsonArray = [];
            jsonArray.forEach((element) => {
                builtjsonArray.push(toLowerCaseKeys(element));
            });
            console.log(jsonText, `before parsing`, jsonArray);
            //const jsonObject = JSON.parse(jsonText);
            let currentState = store.getState().chatWithDoc.chatWithDocState;

            store.dispatch(
                updateChatWithDocState({
                    ...currentState,
                    doc_help_questions: builtjsonArray,
                })
            );
        } catch(e) {
            console.error(`something went wrong `, e);
        }
    }
});
export const socketContext = createContext(socket);
export default function useSocket() {
  return useContext(socketContext);
}


export function extractJsonArray(inputString) {
    // Regular expression to match a JSON array
    const regex = /\[\s*({[^}]*})\s*(?:,\s*({[^}]*}))*\s*\]/g;
    const match = regex.exec(inputString);

    if (match) {
        return match[0]; // The full matched JSON array string
    } else {
        return null; // No array found
    }
}


function toLowerCaseKeys(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key.toLowerCase()] = obj[key];
        }
    }
    return result;
}