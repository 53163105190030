import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import CaseAssessmentFields from "../../dropDowns/CaseAssessmentFields.json";
import { MdtoDocx } from "./CustomContentOperations/PureContentOperations";
import {
    CollapseAllIcon,
    CopyIcon,
    ExpandIcon,
    ExportIcon,
} from "./FontAwesomeComponents/HelperIcons";
/**
 * 
 * @param {  Content={this.state.caseAnalysisResult}
                                isSummary={false}
                                component="CaseAnalysis"
                                filename={undefined}
                                question={undefined}
                                isFromCaseAnalysis={true}
                                docType={"Case Analysis"}
                                isPrompts={
                                    this.state.sortedArray
                                    // .length !== 0
                                    // ? true
                                    // : null
                                }
                                state={this.state}} param0 
 * @returns 
 */


export default function ContentOperations({
    Content,
    isSummary,
    component,
    filename,
    question,
    isFromCaseAnalysis,
    docType,
    isPrompts,
    state,
    isGenerateDoc,
    handleToggleCallback,
    isExpanded,
    enableCollpase,
    index,
    isOpened,
}) {
    const [isExpandedState, setIsExpandedState] = useState(isExpanded);
    const [element, setElement] = useState({ element: "", isOpened: false });

    useEffect(() => {
        setIsExpandedState(isExpanded);
    }, [isExpanded]);

    const format_content = (text) => {
        if (text) {
           
           return text;
        }
        return "";
    };

    const handle_content = () => {
        const value = Content;
        console.log(`handle_content ${value}`)
        if (isSummary) {
            let text =
                "Concise Summary: \n\n" +
                format_content(value.Concise_Summary) +
                "\n\n Intermediate Summary: \n\n" +
                format_content(value.Intermediate_Summary);

            return text;
        } else {
            return format_content(value);
        }
    };

    const handleCopySection = () => {
        let content = handle_content();
        
        if (isFromCaseAnalysis === true) {
            var con = "Questionnaire\n\n";
            for (const [key, value] of Object.entries(state.Case_Details)) {
                con = con + `${key.replace("_", " ")}: ${value}\n`;
            }
            for (const [key, value] of Object.entries(state.FollowUp_Details)) {
                con = con + `${key.replace("_", " ")}: ${value}\n`;
            }
            content = con + "\n\nCase Assessment\n\n" + Content?.trimStart();
            let followUp_context = isPrompts;
            followUp_context?.forEach((element) => {
                content =
                    content +
                    "\n\n" +
                    element.question +
                    "\n\n" +
                    element.response?.trimStart();
            });
        }
      
        copy(content);
        toast.success("Copied");
    };

   

   

    function generateWordDocument() {
     generateMdText();        
    }

    /*function generateFixedLengthHash() {
        // Get the current date and time as a string
        const currentDateTime = new Date().toISOString();

        // Generate a hash using SHA-256
        const hash = CryptoJS.SHA256(currentDateTime);

        // Convert the hash to a hexadecimal string
        const hashHex = hash.toString(CryptoJS.enc.Hex);

        // Trim the hash to your desired fixed length
        const fixedLengthHash = hashHex.slice(0, 6); // Change 10 to your desired length

        return fixedLengthHash;
    }*/

    function generateMdText() {
       let final_text = `# ${component} \n\n ## ${filename} \n\n ### ${question}`;
       
        let content_temp = `\n\n ${final_text} \n\n` + handle_content();
        if (isFromCaseAnalysis === true) {
            var con = " ## Questionnaire \n\n";
            CaseAssessmentFields?.Case_Details?.forEach((e,i)=>{
                con = con + ` - ${e.headerName}: ${state?.Case_Details[e.fieldName].replace("\n\n","")}\n\n`;
            })
            CaseAssessmentFields?.FollowUp_Details?.forEach((ele, ind)=>{
                con = con + ` - ${ele.headerName}: ${state.FollowUp_Details[ele.fieldName]?.replace("\n\n","")}\n\n`;
            })
           content_temp =  `# ${component}\n\n` + con +`## Assessment: \n\n ###`+ handle_content();
           
            let followUp_context = isPrompts;
            followUp_context.forEach((element) => {
                content_temp =
                    content_temp +
                    "\n\n" +
                    ` ### ${element.question}` +
                    "\n\n" +
                    element.response;
            });
        }
      
        let filenamesplitarr = filename?.split('.');
        
          MdtoDocx(content_temp, filenamesplitarr?.length>0 ? (filenamesplitarr[0]+`.docx`):"");
   }

    return (
        <div
            className={`${
                isFromCaseAnalysis || enableCollpase
                    ? "elementwithcollapse"
                    : "elementlinkdiv"
            }`}
        >
            <ul>
                {handleToggleCallback && (
                    <li onClick={() => handleToggleCallback(!isExpandedState)}>
                        {isExpandedState ? <CollapseAllIcon /> : <ExpandIcon />}
                    </li>
                )}
                <li onClick={() => {handleCopySection()}}>
                    <CopyIcon />
                    {/* copy */}
                </li>
                <li onClick={generateWordDocument}>
                    <ExportIcon />
                    {/* export as word */}
                </li>
                {enableCollpase && (
                    <li>
                      
                        <label
                            for={`handle2${index}`}
                            onClick={() => {
                                setElement({
                                    ...element,
                                    element: index,
                                    isOpened: !element.isOpened,
                                });
                            }}
                        >
                            {element.element === index && element.isOpened ? (
                                <CollapseAllIcon />
                            ) : (
                                <ExpandIcon />
                            )}
                        </label>
                    </li>
                )}
            </ul>
        </div>
    );
}
