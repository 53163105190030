import { FavPopup } from "./CustomPopup";

const CustomWindowPopupComponent = ({
    window_name,
    module_name,
    togglepopup,
    content,
    favdisplayval,
    customclass,
}) => {
    return (
        <FavPopup
            title={window_name + " - " + module_name}
            closepopup={togglepopup}
            content={content}
            favdisplayval={favdisplayval}
            customclass={customclass ? customclass : ""}
        />
    );
};

export default CustomWindowPopupComponent;
