const initialState = {
    dashBoardState: {
        source: "",
        moduleId: "",
        fileName: "",
        activeTab: 0,
        selectedDocType: "",
        linkType: "",
        batchId: null,
    }, // Initial state for the dashBoard
    // Add other initial states as needed for different components
};

export const dashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_DASHBOARD_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, dashBoardState: action.payload.data };
        // Add other cases for different components' states
        case 'RESET_DASHBOARD_STATE':
            return initialState;
        default:
        return state;
    }
};
