// ErrorPage.js
import React, { useState } from "react";

const NotFoundError = () => {
  const [theme] = useState(localStorage.getItem("theme"));
 
  return (
    <div className="custom_error_boundary">
      <pre style={{ fontSize: "18px", color: theme === "dark" ? "#BCBCBC":"" }}>
        {`
    <div>
      <h1>`}
        <strong
          style={{ fontSize: "24px", fontWeight: "bold", color: theme === "dark" ? "#FAF9F6" :""}}
        >
       Not Found
        </strong>
        {`</h1>
      <p>`}
        <strong
          style={{ fontSize: "20px", color: (theme === "dark" ? "#FAF9F6":""), fontWeight: "initial" }}
        >{`
        Page Not Found. Looks like you've stumbled upon uncharted territory.<br/>
        Please check the URL or navigate back to our homepage..
     `}
        </strong>
    {`</p>
    </div>`}
      </pre>
    </div>
  );
};

export default NotFoundError;
