import React, { Component, Fragment, useRef, useState } from "react";
import "../../../css/Login.css";
import "../../../css/CaseAnalysis.css";
import Tabs from "@mui/material/Tabs";
import toast, { Toaster } from "react-hot-toast";
import Tab from "@mui/material/Tab";
import "reactjs-popup/dist/index.css";
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import { handleCustomPrompt } from "../Admin/Helpers/HelperFunctions";
function CustomTabPanel(props) {
    const { value, index, ...other } = props;

    const GridRef = useRef();

    return (
        <div
            role="tabpanel"
            style={{
                height: "75%",
            }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <CustomTable
                gridRef={GridRef}
                columndefs={props.columndefs}
                defaultcoldefs={props.defaultcoldefs}
                rowdata={props.rowdata}
            ></CustomTable>
        </div>
    );
}

CustomTabPanel.propTypes = {};

class KavayahAdminOperations extends Component {
    state = {
        isContinue: false,
        isCaptchaAuthenticated: true,
        isLogIn: false,
        orgDetails: this.props.orgState,
        activeTab: 0,
        content: [],
    };

    updateStatus = ({ organization_id, status_text, reason, plan_id }) => {
        let formData = new FormData();
        formData.append("organization_id", organization_id);
        formData.append("status_text", status_text);
        formData.append("reason", reason);
        formData.append("plan_id", plan_id);
        console.log("update status", formData);
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props?.history
        );
        axiosInstance
            .post("/admin/updateOrganizationStatus", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                crossDomain: true,
                timeout: 300000,
            })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success("status updated successfully!");
                    this.fetchOrganizationData();
                }
            });
    };

    fetchOrganizationData = () => {
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props?.history
        );

        axiosInstance
            .get("/admin/getAllOrganizations", {
                crossDomain: true,
                timeout: 300000,
            })
            .then((response) => {
                console.log(response, "Fetch All Organizations");

                this.setState({ content: response.data });
            })
            .catch((error) => {
                console.error(`Something went wrong ${error}`);
            });
    };
    componentDidMount() {
        this.fetchOrganizationData();
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    handleTabChange = (event, newValue) => {
        this.setState({
            activeTab: newValue,
        });
    };
    onColorChange = (plan) => {
        console.log("Plan  Change", plan);
     
        // Optionally, update any other params properties or perform other actions here
        console.log(`Updated value to: ${plan}`);
    };
 
    render() {
        return (
            <>
                <Toaster />
                <div className="regBody">
                    <div className="header">
                        <h3>Organizational Approvals</h3>
                    </div>
                    <div className="adminTabs">
                        <div
                            style={{
                                borderBottom: "1px solid #4d5a7a",
                            }}
                        >
                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.handleTabChange}
                            >
                                <Tab label="Pending" {...this.a11yProps(0)} />
                                <Tab label="Approved" {...this.a11yProps(1)} />
                                <Tab label="Rejected" {...this.a11yProps(2)} />
                            </Tabs>
                        </div>
                        <>
                            <CustomTabPanel
                                rowdata={this.state.content.filter(
                                    (x) => x.status_id === 2
                                )}
                                value={this.state.activeTab}
                                index={0}
                                columndefs={[
                                    {
                                        field: "organization_name",
                                        headerName: "Organization Name",
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_full_name",
                                        headerName: "Contact Full Name",
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_email",
                                        headerName: "Contact Email",
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_phone",
                                        headerName: "Contact Phone",
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "datereccreate",
                                        headerName: "Requested Date",
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "plan_id",
                                        headerName: "Plan",
                                        cellRenderer: CustomPlansRenderer,
                                        cellRendererParams: {
                                            onColorChange: this.onColorChange,
                                        },
                                    },

                                    {
                                        field: "organization_id",
                                        headerName: "Actions",
                                        cellStyle: {
                                            textAlign: "center",
                                        },
                                        cellRenderer: (params) => {
                                            console.log(params, "Test params");
                                            return (
                                                <Fragment>
                                                    <button
                                                        title="Approve Organization"
                                                        className="success-btn"
                                                        onClick={() =>
                                                            this.updateStatus({
                                                                organization_id:
                                                                    params.data
                                                                        .organization_id,
                                                                status_text:
                                                                    "approved",
                                                                reason: "",
                                                                plan_id: 7,
                                                            })
                                                        }
                                                    >
                                                        Approve
                                                    </button>
                                                    <button
                                                        title="Reject Organization"
                                                        className="reject-btn"
                                                        onClick={() => {
                                                            handleCustomPrompt({
                                                                confirm_button_text:
                                                                    "Reject",
                                                                cancel_button_text:
                                                                    "Cancel",
                                                                callback:
                                                                    this
                                                                        .updateStatus,
                                                                callbackparamsObj:
                                                                    {
                                                                        organization_id:
                                                                            params
                                                                                .data
                                                                                .organization_id,
                                                                        status_text:
                                                                            "rejected",
                                                                        reason: "",
                                                                        plan_id: 7,
                                                                    },
                                                            });
                                                        }}
                                                    >
                                                        Reject
                                                    </button>
                                                </Fragment>
                                            );
                                        },
                                    },
                                ]}
                                defaultcoldefs={{
                                    flex: 1,
                                    resizable: true,
                                    editable: true,
                                }}
                            ></CustomTabPanel>
                            <CustomTabPanel
                                rowdata={this.state.content.filter(
                                    (x) => x.status_id === 1
                                )}
                                value={this.state.activeTab}
                                index={1}
                                columndefs={[
                                    {
                                        field: "organization_name",
                                        headerName: "Organization Name",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_full_name",
                                        headerName: "Contact Full Name",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_email",
                                        headerName: "Contact Email",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "plan_id",
                                        headerName: "Plan",
                                        cellRenderer: CustomPlansRenderer,
                                        cellRendererParams: {
                                            onColorChange: this.onColorChange,
                                        },
                                    },
                                    {
                                        field: "co_admin_phone",
                                        headerName: "Contact Phone",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "datereccreate",
                                        headerName: "Requested Date",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "approve_rej_on",
                                        headerName: "Approved On",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "approve_rej_by",
                                        headerName: "Approved By",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                ]}
                            ></CustomTabPanel>
                            <CustomTabPanel
                                rowdata={this.state.content.filter(
                                    (x) => x.status_id === 4
                                )}
                                value={this.state.activeTab}
                                index={2}
                                columndefs={[
                                    {
                                        field: "organization_name",
                                        headerName: "Organization Name",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_full_name",
                                        headerName: "Contact Full Name",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_email",
                                        headerName: "Contact Email",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "co_admin_phone",
                                        headerName: "Contact Phone",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "datereccreate",
                                        headerName: "Requested Date",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "approve_rej_on",
                                        headerName: "Rejected On",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        field: "approve_rej_by",
                                        headerName: "Rejected By",
                                        supressSizeToFit: true,
                                        sortable: true,
                                        filter: true,
                                    },
                                ]}
                            ></CustomTabPanel>
                        </>
                    </div>
                </div>
            </>
        );
    }
}

function CustomPlansRenderer(props) {
    const [plan, setPlan] = useState(props.value);

    const onPlanUpdate = (event) => {
        props.onColorChange(event.target.value);
        setPlan(event.target.value);
    };
    return (
        <div>
            <select
                value={plan}
                className="selectfield"
                onChange={onPlanUpdate}
            >
                <option>please choose</option>
                <option value="1"> Trial_30 </option>
                <option value="2"> PostTrial_30 </option>
                <option value="3"> Saas_500 </option>
                <option value="4"> Saas_Unlimited </option>
                <option value="5"> Private_Cloud </option>
                <option value="6"> Hybrid </option>
                <option value="7"> SaaS_LawBotica_Unlimited </option>
                <option value="8"> Kavayah_Internal </option>
            </select>
        </div>
    );
}

export default KavayahAdminOperations;
