import React, { useState } from "react";
import { useSelector } from "react-redux";

function FileProcessing(props) {
    // drag state
    const myReduxState = useSelector((state) => state.login.logInState);

    const [dragActive, setDragActive] = useState(false);

    const [selectedFile, setSelectedFile] = useState(
        props.selectedFile === null ? {} : props.selectedFile
    );

    // handle drag events
    const handleDrag = function (e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
            } else if (e.type === "dragleave") {
                setDragActive(false);
            }
        } catch (error) {
            console.log("Error in handleDrag", error);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                if (e.dataTransfer.files.length > 1) {
                    if (e.dataTransfer.files.length <= 20) {
                        console.info(myReduxState);
                        let selectedFile = {
                            name: `${myReduxState.user_name}_${Date.now()}_${
                                e.dataTransfer.files.length
                            }`,
                            file: e.dataTransfer.files,
                        };
                        setSelectedFile(selectedFile);
                        // setSelectedBatch(e.dataTransfer.files);
                        console.log(e.dataTransfer.files);
                        handleBatchReview(selectedFile);
                        // handleReviewing()
                    } else {
                        let fileDetail = {
                            file: null,
                            error: "Only 20 files can be uploaded at once!",
                        };
                        props.handleFile(fileDetail);
                    }
                } else {
                    const file = e.dataTransfer.files[0];
                    setSelectedFile(file);
                    // console.log("Dragged Files", file);
                    handleReviewing(file);
                }
            }
        } catch (error) {
            console.log("Error in handleDrop", error);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        try {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                console.info(myReduxState);
                if (e.target.files.length > 1) {
                    if (e.target.files.length <= 20) {
                        let selectedFile = {
                            name: `${myReduxState.user_name}_${Date.now()}_${
                                e.target.files.length
                            }`,
                            file: e.target.files,
                        };
                        setSelectedFile(selectedFile);
                        // setSelectedBatch(e.dataTransfer.files);
                        console.log(e.target.files);
                        handleBatchReview(selectedFile);
                    } else {
                        let fileDetail = {
                            file: null,
                            error: "Only 20 files can be uploaded at once!",
                        };
                        props.handleFile(fileDetail);
                    }
                } else {
                    const file = e.target.files[0];

                    setSelectedFile(file);
                    // console.log("Input Files", file);
                    handleReviewing(file);
                }
            }
        } catch (error) {
            console.log("Error in handle Change", error);
        }
    };
    const handleBatchReview = (batch) => {
        let validFiles = [];
        let invalidFiles = [];
        console.log(batch.file);
        Object.keys(batch.file).forEach((file) => {
            if (isValidFileUploaded(batch.file[file]))
                validFiles.push(batch.file[file]);
            else
                invalidFiles.push({
                    file: batch.file[file],
                    error: null,
                });
        });
        console.log("Before sending");
        props.handleBatch(batch, validFiles, invalidFiles);
    };

    const handleReviewing = (file) => {
        // setIsReviewing(true);
        let fileDetail = {
            file: file,
            error: null,
        };
        if (isValidFileUploaded(file))
           
            props.handleFile(fileDetail);
        else {
            fileDetail = {
                file: null,
                error: "Only a pdf, doc, docx, gif, png or jpeg file may be used!",
            };
            props.handleFile(fileDetail);
           
        }
    };



    const isValidFileUploaded = (file) => {
        let isValid = false;

        const validExtensions = [
            "png",
            "jpeg",
            "jpg",
            "pdf",
            "doc",
            "docx",
            "vnd.openxmlformats-officedocument.wordprocessingml.document",
            "gif",
        ];
        const fileExtension = file.type.split("/")[1];
        if (validExtensions.includes(fileExtension)) {
            const fileSizeInBytes = file.size;
            const fileSizeInKB = fileSizeInBytes / 1024;
            const fileSizeInMB = fileSizeInKB / 1024;

            console.log(`File size: ${fileSizeInBytes} bytes`);
            console.log(`File size: ${fileSizeInKB.toFixed(2)} KB`);
            console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);

            // Check if the file size is within a certain limit
            const maxFileSizeInMB = 50; // Set your maximum file size limit in MB
            if (fileSizeInMB > maxFileSizeInMB) {
                console.log("File size exceeds the maximum limit.");
                // You can handle this case, e.g., show an error message to the user.
                isValid = false;
            } else {
                console.log("File size is within the limit.");
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid;
    };

    return (
        <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
        >
            <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className="drop-container"
            >
                <div>
                    <p className="drop-title">
                        {!dragActive ? (
                            <>
                                Drag & Drop your file here or{" "}
                                <span className="drop-span">Choose File</span>
                            </>
                        ) : (
                            "Drop your files here"
                        )}{" "}
                        {props.module !== undefined ? (
                            <input
                                type="file"
                                id="input-file-upload"
                                accept="*"
                                //ref={inputRef}
                                onInput={handleChange}
                                style={{ display: "none" }}
                                required
                                multiple
                            ></input>
                        ) : (
                            <input
                                type="file"
                                id="input-file-upload"
                                accept="*"
                                //ref={inputRef}
                                onInput={handleChange}
                                style={{ display: "none" }}
                                required
                            ></input>
                        )}
                    </p>
                    {selectedFile && (
                        <p style={{ color: "#2eaadb", fontWeight: "bold" }}>
                            {selectedFile.name}
                        </p>
                    )}
                    {props.module === 3 ? (
                        <p className="drop-title">
                            50 MB max file size, up to 20 files
                        </p>
                    ) : (
                        <p className="drop-title">50 MB max file size</p>
                    )}
                </div>
            </label>

            {dragActive && (
                <>
                    <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    />
                </>
            )}
        </form>
    );
}

export default FileProcessing;
