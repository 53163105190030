import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
//import PopupCellRenderer from "../../SupportiveComponents/AgGridHelpers/PopupCellRenderer";
import { Popup } from "../../SupportiveComponents/CustomPopup";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { OrgForm } from "./Helpers/OrgForm";
import useTheme from "../../../contexts/theme";

export const OrgOperations = (props) => {
    const gridRef = useRef();
const { themeMode } = useTheme();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [rowData, setRowData] = useState([]);
    const rowClassRules = useMemo(() => {
        return {
            "custom-grid-row-error": "params.data.locked",
        };
    }, []);
    const getRowClass = (params) => {
        if (params.data.locked) {
            return "custom-grid-row-error";
        }
    };
    const [columnDefs] = useState([
        {
            field: "organization_id",
            headerName: "ID",
            suppressSizeToFit: false,
            width: 50,
            maxWidth: 100,
        },
        { field: "organization_name", headerName: "Organization Name" },
        {
            field: "co_admin_full_name",
            headerName: "Org. Admin Name",
            minWidth: 100,
        },
        { field: "co_admin_email", headerName: "Org. Admin Email" },
        { field: "nooflicense", headerName: "No. of Licenses" },
        { field: "status", headerName: "Status" },
        { field: "locked", headerName: "Locked" },
        {
            field: "organization_id",
            headerName: "Actions",
            cellRenderer: (params) => {
                return (
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                setSelectedRowData(params.data);
                                setDisplayPopup(!displayPopup);
                            }}
                        >
                            Details
                        </button>
                     {" "}
                    </>
                );
            },
            pinned: "right",
            colId: "action",
            editable: false,
            maxWidth: 150,
        },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
        };
    }, []);

    const onGridReady = useCallback(
        (params) => {
            const axiosInstance = getAxiosInstance(props.token);

            axiosInstance
                .get("/admin/getAllOrganizations", {
                    crossDomain: true,
                    timeout: 300000,
                })
                .then((response) => {
                    console.log(response, "Fetch All Organizations");
                    setRowData(response.data);
                })
                .catch((error) => {
                    console.error(`Something went wrong ${error}`);
                });
        },
        [props.token]
    );

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById("filter-text-box").value
        );
    }, []);

    return (
        <>
            <div className="regBody">
                <div className="header custom-grid-header">
                    <h3>All Organizations</h3>
                </div>
                {displayPopup ? (
                    <>
                        <Popup
                            title={`Organization Info - ${selectedRowData?.organization_name}`}
                            content={
                                <OrgForm
                                    seleteddata={selectedRowData}
                                    closepopup={() =>
                                        setDisplayPopup(!displayPopup)
                                    }
                                    token={props.token}
                                />
                            }
                            closepopup={() => setDisplayPopup(!displayPopup)}
                        ></Popup>
                    </>
                ) : (
                    <></>
                )}
                <div className="ag-grid-user-management-wrapper">
                    <div
                        className="logInfields"
                        style={{ marginBottom: "20px" }}
                    >
                        <label htmlFor="filter-text-box">Quick Filter:</label>
                        <input
                            type="search"
                            id="filter-text-box"
                            placeholder="Filter by name, email"
                            className="filtertextbox"
                            autoComplete="off"
                            onInput={onFilterTextBoxChanged}
                        />
                    </div>

                    <div
                        style={gridStyle}
                        className="ag-grid-custom ag-theme-quartz"
                    >
                        <AgGridReact
                            className={`${
                                themeMode === "light"
                                    ? "ag-theme-quartz"
                                    : "ag-theme-quartz-dark"
                            } custom-ag-grid-height ${themeMode}`}
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            pagination={true}
                            getRowClass={getRowClass}
                            rowClassRules={rowClassRules}
                            paginationPageSize={25}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
