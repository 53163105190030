import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import useTheme from "../../../contexts/theme";
import { isMobile } from "../PureHelpers";
//import { jsonrepair } from "jsonrepair";

import { repairJson } from "../../../js/HelperFunctions";
import {
    CheckIfValueMatchesInArray,
    caseInsensitiveEquals,
    parseDate,
} from "../PureComponents/Helpers";


export const FormatStringInTabularFormat = ({ rawdatastr, regpattern }) => {
    const [rawdatastrs] = useState(rawdatastr);
    const [regpatterns] = useState(regpattern);
    const[isRepaired, setIsRepaired] = useState(false)
    const { themeMode } = useTheme();
    const gridRef = useRef();


    const htmlContent = "<!-- Timeline json is repaired -->";

    //Size Columns To Fit Ag Grid
    const onFirstDataRendered = useCallback((gridRef) => {
        if (!isMobile()) {
            gridRef?.current?.api?.sizeColumnsToFit();
        }
    }, []);

    //Triggering Size Columns To Fit on Render
    useEffect(() => {
        try {
            onFirstDataRendered();
        } catch (error) {
            //empty
        }
    }, [onFirstDataRendered, gridRef]);

    const [formattedData, columnsdata, stringtobedisplayed] = useMemo(() => {
        //splitting rows with new line
        let to_json = undefined;
        try {
    
            let extractFromMarkdown = rawdatastrs?.replace("```json","")?.replace("```","")
            // to_json = convertRepairedJSontoJson(extractFromMarkdown); 
            var repaired_json = repairJson(extractFromMarkdown) 
            setIsRepaired(repaired_json?.is_repaired)
            to_json=repaired_json?.json_data
            // setComponentContent(json_break)

            to_json = to_json.filter((val) => {
                return (Object.keys(val).length >= 2)
            }).map((val) => {
                
                if (val["date"].localeCompare(val["event"]) === 0) {
                    val["date"] = "N/A";
                }
                val["dateval"] = parseDate(val["date"]);
                return val;
            }); 
           
        } catch (error) {
            console.log("Unable to parse JSON string=", rawdatastrs);
        }

        if (to_json !== undefined) {
            return [to_json, ["date", "event", "dateval"], ""];
        }
        const rows = rawdatastrs?.trim().split("\n");
        console.log("Rows", rows);
        const columns = rawdatastrs
            ?.trim()
            ?.split(regpatterns)[0]
            ?.split("|")
            ?.filter((i) => i.trim().length > 0)
            ?.map((i) => i.trim());
        console.log("Columns", columns);
        if (
            CheckIfValueMatchesInArray(columns, "date") ||
            CheckIfValueMatchesInArray(columns, "year")
        ) {
            columns.push("dateval");
        }
        const customizeddata = rows
            .slice(2)
            .filter((row) => row.includes("|"))
            .map((row, idx) => {
                let objrow = {};
                row.split("|")
                    .filter((i) => i.trim().length > 0)
                    .map((cell, idx) => {
                        if (
                            caseInsensitiveEquals(
                                columns[idx]?.trim(),
                                "date"
                            ) ||
                            caseInsensitiveEquals(columns[idx]?.trim(), "year")
                        ) {
                            objrow[columns[idx]?.trim()] = cell.trim();
                            objrow["dateval"] = cell.trim();
                            // parseDate(cell.trim());
                        } else {
                            objrow[columns[idx]?.trim()] = cell.trim();
                        }

                        return cell.trim();
                    });

                return objrow;
            });
        return [customizeddata, columns, "Following is the timeline of events"];
    }, [rawdatastrs, regpatterns]);

    const [defaultcoldef] = useState({
        resizable: true,
        flex: 1,
        sortable: true,
        wrapText: true,
        autoHeight: true,
    });
    const [updatedRowData, setUpdatedRowData] = useState(formattedData);
    const [updatedColumnsData, setUpdatedColumnsData] = useState(columnsdata);
    const [columndefsdata, setColumnDefsData] = useState(
        updatedColumnsData.map((val) => {
            //caseInsensitiveEquals(val.trim(), "date")
            if (
                (val.trim().toLowerCase().includes("date") ||
                    val.trim().toLowerCase().includes("year")) &&
                !val.trim().toLowerCase().includes("dateval")
            ) {
                return {
                    headerName: val.trim(),
                    field: "dateval",
                    width: 50,
                    minWidth: 50,
                    maxWidth: 250,
                    flex: 2,
                    wrapText: true,
                    sortable: true,
                    sort: "asc",
                    resizable: false,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: "left"
                    },
                    cellRenderer: (params) => {
                        return <span>{params?.data[`${val}`]}</span>;
                    },
                    headerClass:"custom-header",
                };
            } else if (val.trim().toLowerCase().includes("dateval")) {
                return {
                    field: val,
                    headerName: "Date",
                    headerClass:"custom-header",
                    resizable: true,
                    sortable: true,
                    suppressSizeToFit: true,
                    hide: true,
                    cellRenderer: (params) => {
                        return <span>{params?.data[`${val}`]}</span>;
                    },
                };
            } else {
                return {
                    field: val,
                    resizable: true,
                    sortable: true,
                    suppressSizeToFit: true,
                    cellRenderer: (params) => {
                        return <span>{params?.data[`${val}`]}</span>;
                    },
                };
            }
        })
    );
    useEffect(() => {
        setUpdatedRowData(formattedData);
    }, [formattedData]);

    useEffect(() => {
        setUpdatedColumnsData(columnsdata);
    }, [columnsdata]);

    useEffect(() => {
        setColumnDefsData(
            updatedColumnsData.map((val) => {
                if (
                    (val.trim().toLowerCase().includes("date") ||
                        val.trim().toLowerCase().includes("year")) &&
                    !val.trim().toLowerCase().includes("dateval")
                ) {
                    return {
                        field: "dateval",
                        headerName: val.trim(),
                        headerClass:"custom-header",
                        width: 50,
                        maxWidth: 200,
                        wrapText: true,
                        sortable: true,
                        sort: "asc",
                        resizable: true,
                        suppressSizeToFit: true,
                        cellStyle: {
                            textAlign: "left",
                        },
                        cellRenderer: (params) => {
                            return <p>{params?.data[`${val}`]}</p>;
                        },
                    };
                } else if (val.trim().toLowerCase().includes("dateval")) {
                    return {
                        field: val,
                        headerName:"Date",
                        headerClass:"custom-header",
                        resizable: false,
                        width: 50,
                        maxWidth: 200,
                        sortable: true,
                        suppressSizeToFit: true,
                        hide: true,
                    };
                } else {
                    return {
                        field: val,
                        resizable: false,
                        sortable: true,
                        flex: 2,
                        suppressSizeToFit: true,
                    };
                }
            })
        );
    }, [updatedColumnsData]);

    return (
        <>
            {columnsdata.length === 3 ? (
                <>
                    <div
                        className="ag-theme-quartz"
                        style={{
                            height: 800,
                            width: "100%",
                            overflowX: "scroll",
                        }}
                    >
                        {
                            <>
                                <AgGridReact
                                    ref={gridRef}
                                    className={`${
                                        themeMode === "light"
                                            ? "ag-theme-quartz"
                                            : "ag-theme-quartz-dark"
                                    } custom-ag-grid-height`}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        overflowY: "scroll",
                                    }}
                                    rowData={updatedRowData}
                                    columnDefs={columndefsdata}
                                    defaultColDef={defaultcoldef}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={25}
                                    onGridReady={onFirstDataRendered}
                                    onFirstDataRendered={onFirstDataRendered}
                                />
                                {isRepaired ?  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />:""}
                            </>
                        }
                    </div>
                    <div>
                        {stringtobedisplayed.length > 0
                            ? stringtobedisplayed
                            : ""}
                    </div>
                </>
            ) : (
                <p>Couldn't generate timeline</p>
            )}
        </>
    );
};
