import { useEffect, useState } from "react";

const Box = (props) => {

  return (
    <div
      className="box"
      style={{ backgroundColor: props.box.color }}
      draggable={props.draggable}
      onDragStart={props.onDragStart({ id: props.box.id })}
      onDragOver={props.onDragOver({ id: props.box.id })}
      onDrop={props.onDrop({ id: props.box.id })}
    >
      <div className="content">{props.box.name}</div>
    </div>
  );
}

export const BoxesGroup = (props) => {
  const [state, setState] = useState({ boxes: [] });

  useEffect(() => {
    setState({
      boxes: [
        // { id: 3, name: props.element3},
        { id: 1, name: props.element },
        { id: 2, name: props.element2 },
        { id: 3, name: props.element3 },
        { id: 4, name: props.element4 },
        // { id: 4, name: props.element2 },
      ]
    })
  }, [props.element, props.element2, props.element3, props.element4])


  const swapBoxes = (fromBox, toBox) => {
    let boxes = state.boxes.slice();
    let fromIndex = -1;
    let toIndex = -1;

    for (let i = 0; i < boxes.length; i++) {
      if (boxes[i].id === fromBox.id) {
        fromIndex = i;
      }
      if (boxes[i].id === toBox.id) {
        toIndex = i;
      }
    }

    if (fromIndex !== -1 && toIndex !== -1) {
      let { fromId, ...fromRest } = boxes[fromIndex];
      let { toId, ...toRest } = boxes[toIndex];
      boxes[fromIndex] = { id: fromBox.id, ...toRest };
      boxes[toIndex] = { id: toBox.id, ...fromRest };

      setState({ boxes: boxes });
    }
  };

  /* The dragstart event is fired when the user starts dragging an element or text selection */
  /* event.target is the source element : that is dragged */
  /* Firefox requires calling dataTransfer.setData for the drag to properly work */
  const handleDragStart = data => event => {
    let fromBox = JSON.stringify({ id: data.id });
    event.dataTransfer.setData("dragContent", fromBox);
  };

  /* The dragover event is fired when an element or text selection is being dragged */
  /* over a valid drop target (every few hundred milliseconds) */
  /* The event is fired on the drop target(s) */
  const handleDragOver = data => event => {
    event.preventDefault(); // Necessary. Allows us to drop.
    return false;
  };

  /* Fired when an element or text selection is dropped on a valid drop target */
  /* The event is fired on the drop target(s) */
  const handleDrop = data => event => {
    event.preventDefault();

    let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
    let toBox = { id: data.id };

    swapBoxes(fromBox, toBox);
    return false;
  };

  const makeBoxes = () => {
    return state.boxes.map(box => (

      <Box
        box={box}
        key={box.id}
        draggable="false"
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />

    ));
  };

  return <div className={`box-group`} >{makeBoxes()}</div>
}