import summarizeDocTypes from "../dropDowns/summarizeDocTypes.json";
import reviewDoctypes from "../dropDowns/documentReviewTypes.json"
import chatWithDocsTypes from "../dropDowns/ChatWithDocTypes.json"

export const mergeObjects = (obj1, obj2) => {
    const merged = { ...obj1 };
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (merged[key]) {
          merged[key] = [...merged[key], ...obj2[key]];
        } else {
          merged[key] = obj2[key];
        }
      }
    }
    return merged;
  };


  export const allCannedQuestions=()=>{
    let obj=mergeObjects(summarizeDocTypes, reviewDoctypes)
    const mergedObject = mergeObjects( obj,chatWithDocsTypes);
    return mergedObject
  }

  export const  base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  