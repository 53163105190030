import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Toggle } from "rsuite";
import "rsuite/Toggle/styles/index.css";

const ShowLogsPopUp = ({ closeWsListpopup, data }) => {
  const [isMarkdownEnabled, setIsMarkdownEnabled] = useState(false);
  return (
    <div
      id="popup-overlay"
      className={`custom-popup-overlay view-fav-popup-overlay `}
      style={{ height: "100%", width: "100%", zIndex: 8 }}
    >
      <div
        className={`custom-popup-content raw-logs z-index-1`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="custom-popup-header">
          <h2>{"Overview"}</h2>
          <h2>
            {" "}
            <span
              className="close-btn"
              onClick={() => closeWsListpopup()}
              title="Close"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                alt="close"
              />
            </span>
          </h2>
        </div>
        <div
          className="custom-popup-body log-body"
        >
          <div>
            <div className="float-right">
              <label className="strong">Pretty Print: </label>
              <Toggle
                onChange={(e) => {
                  setIsMarkdownEnabled(e);
                }}
              />
            </div>
          </div>
          <div className="log-d-flex">
            <div className="log-summary">
              <div>
                <h2 style={{ marginBottom: "1em" }}>System Prompt: </h2>
                {data?.prompt}
              </div>
              <div>
                <h2 style={{ margin: "1em 0 1em 0" }}>User Prompt: </h2>
                {isMarkdownEnabled ? (
                  <ReactMarkdown>{data?.doc_text}</ReactMarkdown>
                ) : (
                  data?.doc_text
                )}
              </div>
            </div>

            <div className="log-summary">
              <h2 style={{ marginBottom: "1em" }}> Response: </h2>
              {isMarkdownEnabled ? (
                <ReactMarkdown>{data?.response}</ReactMarkdown>
              ) : (
                data?.response
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowLogsPopUp;
