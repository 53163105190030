import React from "react";
import { useLocation } from "react-router-dom";
import { CustomVideoPlayer } from "./VideoPlayer";

const ComingSoon = () => {
  const loc = useLocation();

  return (
      <div className="coming-soon-container">
          <div className="iframe-container">
              {loc?.state?.path === "workspaces" && (  
                <CustomVideoPlayer url="https://www.youtube.com/embed/GA4kRW9-oxI?si=6kLHK3rY1i8V5nGc" />
              )}
              {loc?.state?.path === "RepositoryChat" && (
                  <CustomVideoPlayer url="https://www.youtube.com/embed/fmkL2nc_U38?si=tZcpmEDTdVXlHhPY" />
              )}
          </div>
      </div>
  );
};

export default ComingSoon;
