import React, {
    useState,
    useEffect,
    useCallback,
    Fragment,
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";
import JSZip from "jszip";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx";
import {
    convertToLocalTime,
    makefileNameShort,
} from "../../js/HelperFunctions.js";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import { CustomTable } from "./AgGridTemplates/CustomAgGrid.jsx";

export const HistoryComponent = forwardRef((props, ref) => {
    //module_id used to store history based on module
    const [module_id] = useState(props.module);
    const [token] = useState(props.token);
const [hisdisplayvals, setfavdisplayval] = useState(props.hisdisplayval);
useEffect(() => {
    setfavdisplayval(props.hisdisplayval);
}, [props.hisdisplayval]);

const [removetokencallback] = useState(() => {});

const gridRef = useRef();
//Maintains History State
const [historyList, setHistoryList] = useState(
    props.historyList ? props.historyList : []
);

const handleFetchAll = useCallback(
    (token, removetokencallback, updateStateCallback) => {
        try {
            const axiosInstance = getAxiosInstance(token);
            axiosInstance
                .get("/GetHistoryByModule", {
                    headers: {
                        "x-moduleId": `${module_id}`,
                    },
                })
                .then((response) => {
                    if (
                        response.data.result &&
                        response.data.result.length !== 0
                    ) {
                        let res = response.data.result
                            .sort(
                                (a, b) =>
                                    new Date(a.daterecupdate) -
                                    new Date(b.daterecupdate)
                            )
                            .reverse();

                        res.forEach((element) => {
                            // const jsDate = convertToLocalTime(element.daterecupdate);

                            element.displayDate = convertToLocalTime(
                                new Date(element.daterecupdate).toISOString()
                            );
                        });

                        let res_10 = res.splice(0, 10);

                        setHistoryList(res_10);
                        updateStateCallback(res_10);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        removetokencallback();
                    }
                });
        } catch (error) {
            console.error("Error:", error);
        }
    },
    [module_id]
);

useEffect(() => {
    handleFetchAll(props.token, props.removeToken, props.updateStateCallback);
}, [handleFetchAll, props.removeToken, props.updateStateCallback, props.token]);

const handleSaveFileToHistory = (obj, token) => {
    try {
        const formData = new FormData();

        formData.append("document_type", obj.selectedDocType);
        formData.append("is_hand_written", obj.isHandWritten);
        formData.append("module_id", obj.module_id);
        formData.append("engine_id", obj.selected_engine_id);

        if (obj.isBatchFile) {
            let files = obj.batchFiles;
            formData.append("isBatchFile", true);
            formData.append("batchName", obj.batchName);
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });
            formData.append("files", files);
        } else {
            formData.append("file", obj.selectedFile.file);
            console.log("History File=>", obj.selectedFile.file);
        }
        const axiosInstance = getAxiosInstance(token);
        axiosInstance
            .post("/SaveFileToHistory", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                timeout: 300000,
            })
            .then((response) => {
                // let endTime = (new Date().getTime());
                if (response.data.error_Msg) {
                    // empty
                } else {
                    // setRefresh(false);
                    console.log("Response", response.data);
                    handleFetchAll(
                        token,
                        removetokencallback,
                        props.updateStateCallback
                    );
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                let status = error.response ? error.response.status : 200;
                if (status === 401 || status === 422) {
                    //  removetokencallback();
                }
            });
    } catch (error) {
        // empty
    }
};

useImperativeHandle(ref, () => ({
    handleSaveFileToHistory,
    handleFetchAll,
    handleRetrieveHistoryFile,
}));

const handleRetrieveHistoryFile = (
    fileName,
    batchId,
    isBatch,
    handleRetrieveFileStateCallback
) => {
    try {
        let loaderText = "Retreiving file from History...";
        handleRetrieveFileStateCallback(
            {
                isLoading: true,
                loaderText: loaderText,
                isFromHistory:true,
                isFromFavorites:false,
                hisdisplayval: false,
            },
            true
        );
        props.handleFileResetCallback(true);
        const formData = new FormData();
        formData.append("fileName", fileName);
        formData.append("module_id", module_id);
        formData.append("batch_id", batchId);
        formData.append("isBatch", isBatch);
        const axiosInstance = getAxiosInstance(token, true);
        axiosInstance
            // customAxios
            .post("/FetchHistoryFile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                crossDomain: true,
                timeout: 300000,
                responseType: "blob",
            })
            .then((response) => {
                console.log(response, "Fetch History File");
                if (response.data.error_Msg) {
                } else {
                    if (isBatch) {
                        const headers = response.headers;

                        // Access specific headers by their names
                        const doctypeheader = headers["x-document-type"];

                        // Access specific headers by their names
                        const ishandwrittenheader =
                            headers["x-is-hand-written"];

                        //const mimeType = headers["x-mime-type"];
                        const engine_id = headers["x-engine-id"];
                        // You can access other response data like the response body
                        let docType = doctypeheader;
                        let is_hand_written =
                            parseInt(ishandwrittenheader) === 1 ? true : false;
                        
                        const zip = new JSZip();

                        const zipBlob = response.data;
                        let extractedFiles = [];
                        zip.loadAsync(zipBlob).then(function (loadedZip) {
                            //  console.log("loadedZip", loadedZip);
                            let promises = Object.keys(loadedZip.files).map(
                                function (relativePath) {
                                    let zipEntry =
                                        loadedZip.files[relativePath];
                                    // console.log("zipEntry", zipEntry);
                                    let mimeType = zipEntry.comment;
                                    let filename = zipEntry.name;
                                    return zipEntry
                                        .async("blob")
                                        .then(function (contentBlob) {
                                            console.log(
                                                "before Push:*******************",
                                                contentBlob
                                            );
                                            console.log(
                                                mimeType,
                                                filename,
                                                "mimetype, filename",
                                                contentBlob,
                                                URL.createObjectURL(
                                                    new File(
                                                        [contentBlob],
                                                        filename,
                                                        { type: mimeType }
                                                    )
                                                )
                                            );

                                            extractedFiles.push(
                                                new File(
                                                    [contentBlob],
                                                    filename,
                                                    { type: mimeType }
                                                )
                                            );
                                      
                                        });
                                }
                            );

                            Promise.all(promises).then(function () {
                                let selectedFile = {
                                    name: fileName,
                                    file: extractedFiles,
                                    error: null,
                                };
                                console.log(extractedFiles, 'tests');
                                handleRetrieveFileStateCallback(
                                    {
                                        isUploadFileOpen: false,
                                        isLoading: false,
                                        batchFiles: extractedFiles,
                                        selectedDocType: docType,
                                        isHandWritten: is_hand_written,
                                        fileName: fileName,
                                        selectedFile,
                                        selectedEngine:
                                            engine_id === null
                                                ? props.selectedEngine
                                                : engine_id,
                                        batchId: batchId,
                                        hisdisplayval: false,
                                        isFromHistory:true,
                                        isFromFavorites:false
                                    },
                                    false
                                );
                            });
                        });
                    } else {
                        const headers = response.headers;

                        // Access specific headers by their names
                        const doctypeheader = headers["x-document-type"];

                        // Access specific headers by their names
                        const ishandwrittenheader =
                            headers["x-is-hand-written"];

                        const mimeType = headers["x-mime-type"];
                        const engine_id = headers["x-engine-id"];

                        // You can access other response data like the response body
                        //const data = response.data;

                        let docType = doctypeheader;
                        let is_hand_written =
                            parseInt(ishandwrittenheader) === 1 ? true : false;
                        const file1 = new File([response.data], fileName, {
                            type: mimeType,
                        });

                        props.handleHistoryFile(
                            file1,
                            docType,
                            is_hand_written,
                            engine_id === null
                                ? props.selectedEngine
                                : engine_id
                        );
                    }
                }
            })
            .catch((error) => {
                console.log("Error occurred ", error);
                let status = error.response ? error.response.status : 200;
                if (status === 401 || status === 422) {
                    removetokencallback();
                }
            });
    } catch (error) {
        console.log("Error occurred ", error);
        handleRetrieveFileStateCallback(
            {
                isLoading: false,
                loaderText: "",
                hisdisplayval:false,
            },
            true
        );
    }
};

return (
    <Fragment>
        <CustomTable
            ref={gridRef}
            favdisplayval={hisdisplayvals}
            rowdata={historyList.map((element, index) => ({
                ...element,
                serial_id: index + 1,
                localdatereccreate: new Date(
                    element.datereccreate
                ).toLocaleString(),
                doc_type_name: docTypes_ddl[props.module_name].filter((ele) => {
                    return ele.documentID === element.document_type;
                }).length >0 && docTypes_ddl[props.module_name].filter((ele) => {
                    return ele.documentID === element.document_type;
                })[0]["documentName"],
            }))}
            activeTab={props.activeTab}
            defaultcoldefs={{
                flex: 1,
                resizable: true,
                editable: false,
                autoHeight: true,
            }}
            columndefs={[
                {
                    field: "serial_id",
                    headerName: "",
                    width: 50,
                    maxWidth: 50,
                    suppressSizeToFit: true,
                    headerClass: "text-center",
                    cellStyle: {
                        textAlign: "center",
                    },
                },

                {
                    field: "engine_id",
                    headerName: "Engine",
                    suppressSizeToFit: true,
                    maxWidth: 150,
                    sortable: true,
                    cellStyle: {
                        textAlign: "center",
                    },
                    cellRenderer: (params) => {
                        //   console.log("params", params);
                        return (
                            <p>
                                {params.value !== null ? (
                                    params.value === 1 ? (
                                        <span
                                            className="aicon icon-square-o"
                                            title="Open AI Engine"
                                        ></span>
                                    ) : (
                                        <span
                                            className="aicon icon-square-m"
                                            title="LawBotica Engine"
                                        ></span>
                                    )
                                ) : (
                                    ""
                                )}{" "}
                            </p>
                        );
                    },
                },
                {
                    field: "file_name",
                    headerName: "File Name",
                    suppressSizeToFit: true,
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) => {
                        return (
                            <span
                                onClick={() => {
                                    handleRetrieveHistoryFile(
                                        params?.data?.file_name,
                                        params?.data?.batch_id, //batchid assuming it as null as there are multi files upload here
                                        params?.data?.batch_id === null ? 0 : 1,
                                        props?.handleRetrieveFileStateCallback
                                    );
                                }}
                            >
                                {makefileNameShort(params.value)}
                                {/*} */}
                            </span>
                        );
                    },
                },
                {
                    field: "doc_type_name",
                    headerName: " Document Type",
                    suppressSizeToFit: true,
                    sortable: true,
                    resizable: true,
                    cellRenderer: (params) => {
                        return <span>{params.value}</span>;
                    },
                },
                {
                    field: "displayDate",
                    headerName: "",
                    suppressSizeToFit: true,
                    sortable: true,

                    cellRenderer: (params) => {
                        return (
                            <span className="historyTime">{params.value}</span>
                        );
                    },
                },
            ]}
        />
    </Fragment>
);
});
