import React, { useState, Fragment } from "react";
import { useFormik } from "formik";
import { isEqual } from "lodash";
//import { handleLockUserOrOrganization } from "./HelperFunctions";
import LoadingSpinner from "../../../SupportiveComponents/LoadingSpinner";
import getAxiosInstance from "../../../SupportiveComponents/AxiosRequest";
import Switch from "react-switch";
import toast from "react-hot-toast";
export const OrgForm = (props) => {
    const [orgdetails, setOrgDetails] = useState(props.seleteddata);
    const [displayLoader, setDisplayLoader] = useState(false); //setDisplayLoader

    const formik = useFormik({
        initialValues: { ...orgdetails },
        onSubmit: (values) => {
            console.log(values, " Test OrgFormData");
            SubmitForm();
        },
    });

    const SubmitForm = () => {
        setDisplayLoader(true);
        try {
            const customAxios = getAxiosInstance(props.token);
            const formData = new FormData();
            formData.append(
                "user_id__organization_id",
                orgdetails.organization_id
            );
            formData.append("notes", formik?.values?.notes);
            formData.append("islocked", formik?.values?.locked ? 1 : 0);
            formData.append("no_of_licenses", formik?.values?.nooflicense);

            customAxios
                .post("/admin/updateOrgInfo", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 300000,
                })
                .then((response) => {
                    if (response.status === 200 && response.data.length > 0) {
                        switch (response.data[0]["status_code"]) {
                            case 202:
                                toast.success(
                                    "organization data updated successfully"
                                );
                                break;
                            case 208:
                                toast.error(
                                    "something went wrong! please try again."
                                );
                                break;
                            case 404:
                                toast.error(
                                    "something went wrong! please try again."
                                );
                                break;
                            default:
                                toast.error(
                                    "something went wrong! please try again."
                                );
                                break;
                        }
                    } else {
                        toast.error("something went wrong! please try again.");
                    }
                })
                .catch((error) => {
                    console.log("Error occurred ", error);
                });
        } catch (e) {
            console.error("something went wrong!, Org Form");
        } finally {
            setDisplayLoader(false);
            props.closepopup();
        }
    };

    return (
        <>
            {displayLoader ? <LoadingSpinner text={"Loading..."} /> : <></>}
            <div className="custom-form">
                <form
                    className="view-organization-details-form"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="organization_name">
                                Organization name
                            </label>
                            <input
                                id="organization_name"
                                name="organization_name"
                                value={formik.values.organization_name}
                                onChange={formik.handleChange}
                                type="text"
                                readOnly
                            />
                        </div>
                        <div className="form-element">
                            <label htmlFor="nooflicense"># of licensees</label>
                            <input
                                id="nooflicense"
                                name="nooflicense"
                                value={formik.values.nooflicense}
                                onChange={formik.handleChange}
                                type="number"
                            ></input>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="co_admin_full_name">
                                Organization Admin
                            </label>
                            <input
                                id="co_admin_full_name"
                                name="co_admin_full_name"
                                value={formik.values.co_admin_full_name}
                                onChange={formik.handleChange}
                                type="text"
                                readOnly
                            ></input>
                        </div>
                        <div className="form-element justify-content-initial">
                            <label htmlFor="locked">Locked</label>

                            <Fragment>
                                <Switch
                                    id="locked"
                                    name="locked"
                                    className={
                                        formik.values.locked
                                            ? "switchYes"
                                            : "switch"
                                    }
                                    onChange={(checked, event, id) => {
                                        formik?.setFieldValue(
                                            "locked",
                                            checked
                                        );
                                    }}
                                    checked={formik?.values?.locked}
                                ></Switch>
                            </Fragment>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="co_admin_email">Admin Email</label>
                            <input
                                id="co_admin_email"
                                name="co_admin_email"
                                value={formik.values.co_admin_email}
                                onChange={formik.handleChange}
                                type="email"
                                readOnly
                            ></input>
                        </div>
                        <div className="form-element">
                            <label htmlFor="notes">Notes:</label>
                            <textarea
                                id="notes"
                                name="notes"
                                onChange={formik.handleChange}
                                value={
                                    formik.values.notes
                                        ? formik.values.notes
                                        : ""
                                }
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-element">
                            <label htmlFor="co_admin_phone">Admin Phone</label>
                            <input
                                id="co_admin_phone"
                                name="co_admin_phone"
                                value={formik.values.co_admin_phone}
                                type="text"
                                onChange={formik.handleChange}
                                readOnly
                            ></input>
                        </div>
                    </div>

                    <div className="form-action-buttons center-align">
                        <button
                            className="btn btn-secondary"
                            disabled={isEqual(formik.values, props.seleteddata)}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={isEqual(formik.values, props.seleteddata)}
                            onClick={() => {
                                setOrgDetails(props.seleteddata);
                                props.closepopup();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};
