
import React, { useState } from "react";

const UnauthorizedError = () => {
    const [theme] = useState(localStorage.getItem("theme"));

  return (
    <div className="custom_error_boundary">
      <pre style={{ fontSize: "18px", color: (theme === "dark" ? "#BCBCBC":"") }}>
        {`
    <div>
      <h1>`}
        <strong
          style={{ fontSize: "24px", fontWeight: "bold", color: (theme === "dark" ? "#FAF9F6":"") }}
        >
       Unauthorized
        </strong>
        {`</h1>
      <p>`}
        <strong
          style={{ fontSize: "20px", color: (theme === "dark" ? "#FAF9F6":""), fontWeight: "initial" }}
        >{`
        Access Denied. Please ensure you are logged in with the correct credentials.
     `}
        </strong>
    {`</p>
    </div>`}
      </pre>
    </div>
  );
};

export default UnauthorizedError;
