// import { useNavigate } from "react-router-dom";


const ShowAllWorkspacePopUp = ({ selectedWorkspaces, document_name, closeShowAllWorkspacesPopup,closepopup }) => {
    return <div>
        <div id="popup-overlay"
            className={`custom-popup-overlay view-fav-popup-overlay  show `} style={{zIndex:9}}>
            <div style={{ height: 'fit-content',zIndex:9 }} className={`custom-popup-content create-new-popup`}
                onClick={(e) => e.stopPropagation()} >
                <div className="custom-popup-header">
                    <h2>{"Workspaces"}</h2>
                    <h2>  <span
                        className="close-btn"
                        onClick={() => closepopup()}
                        title="Close"  >
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                            alt="close"
                        />
                    </span>
                    </h2>
                </div>
                <div className="custom-popup-body show-workspaces">
                    <div style={{ height: 'fit-content' }}>
                        Your document <strong>{document_name}</strong> has been successfully added to the following workspaces. Click on a workspace name to work on the document in that workspace.
                        <ul className="ul-adj"> 
                            {selectedWorkspaces?.length > 0 && selectedWorkspaces?.map((i, j) => { 
                                return <li className="li-adj" onClick={()=>closeShowAllWorkspacesPopup([i])}> <p className="text-link">{i.name} </p></li>
                            })}

                        </ul>

                       
                    </div>
                </div>
            </div>
        </div>

    </div>
}
export default ShowAllWorkspacePopUp;