import { useEffect, useState } from "react";
import useTheme from "../../../contexts/theme";

export const NotificationLogo = () => {
    const { themeMode } = useTheme();
    const [fillColor, setfillColor] = useState(
        themeMode === "dark" ? "#fff" : "#1C1B1F"
    );

    useEffect(() => {
        setfillColor(themeMode === "dark" ? "#fff" : "#1C1B1F");
    }, [themeMode]);
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1017_3397"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1017_3397)">
                <path
                    d="M4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z"
                    fill={fillColor}
                />
            </g>
        </svg>
    );
};
export const DisabledNotificationLogo = () => {
    const { themeMode } = useTheme();
    const [fillColor, setfillColor] = useState(
        themeMode === "dark" ? "#bbb3b3" : "#bbb3b3"
    );

    useEffect(() => {
        setfillColor(themeMode === "dark" ? "#bbb3b3" : "#bbb3b3");
    }, [themeMode]);
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1017_3397"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1017_3397)">
                <path
                    d="M4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z"
                    fill={fillColor}
                />
            </g>
        </svg>
    );
};

export const NewNotificationLogo = () => {
    const { themeMode } = useTheme();
    const [fillColor, setfillColor] = useState(
        themeMode === "dark" ? "#fff" : "#1C1B1F"
    );

    useEffect(() => {
        setfillColor(themeMode === "dark" ? "#fff" : "#1C1B1F");
    }, [themeMode]);
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-bell"
        >
            <mask
                id="mask0_1017_3396"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1017_3396)">
                <path
                    d="M12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V3.825C13.3167 4.19167 13.1833 4.56667 13.1 4.95C13.0167 5.33333 12.9833 5.725 13 6.125C12.8333 6.09167 12.6708 6.0625 12.5125 6.0375C12.3542 6.0125 12.1833 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17H16V10.575C16.3 10.7083 16.6208 10.8125 16.9625 10.8875C17.3042 10.9625 17.65 11 18 11V17H20V19H4ZM18 9C17.1667 9 16.4583 8.70833 15.875 8.125C15.2917 7.54167 15 6.83333 15 6C15 5.16667 15.2917 4.45833 15.875 3.875C16.4583 3.29167 17.1667 3 18 3C18.8333 3 19.5417 3.29167 20.125 3.875C20.7083 4.45833 21 5.16667 21 6C21 6.83333 20.7083 7.54167 20.125 8.125C19.5417 8.70833 18.8333 9 18 9Z"
                    fill={fillColor}
                />
                <circle cx="18" cy="6" r="3" fill="#2EB1E3" />
            </g>
        </svg>
    );
};


export const ArchivedLogo = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1054_6057"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1054_6057)">
                <path
                    d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V8.725C2.7 8.54167 2.45833 8.30417 2.275 8.0125C2.09167 7.72083 2 7.38333 2 7V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V7C22 7.38333 21.9083 7.72083 21.725 8.0125C21.5417 8.30417 21.3 8.54167 21 8.725V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 9V20H19V9H5ZM4 7H20V4H4V7ZM9 14H15V12H9V14Z"
                    fill="#1C1B1F"
                />
            </g>
        </svg>
    );
};

export const ActiveLogo = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1054_6050"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1054_6050)">
                <path
                    d="M8 16H20V13H17.45C17.1 13.6167 16.6167 14.1042 16 14.4625C15.3833 14.8208 14.7167 15 14 15C13.3 15 12.6417 14.8208 12.025 14.4625C11.4083 14.1042 10.9167 13.6167 10.55 13H8V16ZM14 13C14.5667 13 15.0417 12.8042 15.425 12.4125C15.8083 12.0208 16 11.55 16 11H20V4H8V11H12C12 11.55 12.1958 12.0208 12.5875 12.4125C12.9792 12.8042 13.45 13 14 13ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                    fill="#1C1B1F"
                />
            </g>
        </svg>
    );
};

export const DisplayLogo = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 22H2C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V17H2V20H5V22ZM17 22V20H20V17H22V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H17ZM11 17.5C9 17.5 7.1875 16.9083 5.5625 15.725C3.9375 14.5417 2.75 12.9667 2 11C2.75 9.03333 3.9375 7.45833 5.5625 6.275C7.1875 5.09167 9 4.5 11 4.5C13 4.5 14.8125 5.09167 16.4375 6.275C18.0625 7.45833 19.25 9.03333 20 11C19.25 12.9667 18.0625 14.5417 16.4375 15.725C14.8125 16.9083 13 17.5 11 17.5ZM11 15.5C12.4667 15.5 13.8083 15.1 15.025 14.3C16.2417 13.5 17.175 12.4 17.825 11C17.175 9.6 16.2417 8.5 15.025 7.7C13.8083 6.9 12.4667 6.5 11 6.5C9.53333 6.5 8.19167 6.9 6.975 7.7C5.75833 8.5 4.825 9.6 4.175 11C4.825 12.4 5.75833 13.5 6.975 14.3C8.19167 15.1 9.53333 15.5 11 15.5ZM11 14.5C11.9667 14.5 12.7917 14.1583 13.475 13.475C14.1583 12.7917 14.5 11.9667 14.5 11C14.5 10.0333 14.1583 9.20833 13.475 8.525C12.7917 7.84167 11.9667 7.5 11 7.5C10.0333 7.5 9.20833 7.84167 8.525 8.525C7.84167 9.20833 7.5 10.0333 7.5 11C7.5 11.9667 7.84167 12.7917 8.525 13.475C9.20833 14.1583 10.0333 14.5 11 14.5ZM11 12.5C10.5833 12.5 10.2292 12.3542 9.9375 12.0625C9.64583 11.7708 9.5 11.4167 9.5 11C9.5 10.5833 9.64583 10.2292 9.9375 9.9375C10.2292 9.64583 10.5833 9.5 11 9.5C11.4167 9.5 11.7708 9.64583 12.0625 9.9375C12.3542 10.2292 12.5 10.5833 12.5 11C12.5 11.4167 12.3542 11.7708 12.0625 12.0625C11.7708 12.3542 11.4167 12.5 11 12.5ZM0 5V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H5V2H2V5H0ZM20 5V2H17V0H20C20.55 0 21.0208 0.195833 21.4125 0.5875C21.8042 0.979167 22 1.45 22 2V5H20Z"
                fill="#1C1B1F"
            />
        </svg>
    );
};

export const NewNotificationIndicationLogo = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
            <circle cx="12" cy="12" r="6" fill="#2EB1E3" />
        </svg>
    );
};