import { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import '../../../css/workspace.css';
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";

const FavoritedWorkspaces = (props) => {
    const [userId] = useState(localStorage.getItem('user_id'))
    const [loading, SetLoading] = useState(false)
    const [favoritedWorkspaces, setFavoritedWorkspaces] = useState([])
    const searchParams = new URLSearchParams(window.location.search);
    const parameters = {};
    for (const [key, value] of searchParams.entries()) {
        parameters[key] = !isNaN(value) ? Number(value) : value;
    }

    useEffect(() => {
        if (parseInt(userId) && props.workspaces.length>0) {
            setFavoritedWorkspaces(props.workspaces.filter(x => x.favorites.filter(y => y.user_id === parseInt(userId)).length > 0))
            SetLoading(false)
        }
    }, [props.workspaces, userId])

    const nav = useNavigate();

    const RedirectToWorkspaces = (data) => {
        nav('/Workspaces',  {
            state: {
                workspace_id: data.id, workspace_name: data.name,data:data,
                isSharedWorkspace: data.users.length > 1 ? true : false, activeTab: props.activeTab,
                allWorkSpaces:props?.workspaces
            }
        })
    }

    return <Fragment>
        <div className="ag-theme-quartz" style={{ height: 600, width: '98%',minHeight:300, maxHeight:600 }}>
            {loading ? <LoadingSpinner></LoadingSpinner> :
                <CustomTable
                    defaultcoldefs={{
                        flex: 1,
                        resizable: true,
                        editable: false,
                        autoHeight: true,
                    }}
                    columndefs={[
                        {
                            headerName: 'Workspace Name',
                            cellRenderer: (params) => {
                                return <div
                                    className="text-link"
                                    onClick={() => RedirectToWorkspaces(params.data)}
                                >{params.data.name}</div>
                            },
                            filter: true,
                            sortable: true,
                            resizable: true,
                            field:"name",
                            tooltipField:"name",
                            headerTooltip:"Workspace Name"
                        },
                        {
                            sortable: true,
                            filter: true,
                            resizable: true,
                            field: "names",
                            // cellRenderer: (params) => { return getNames(params.data) },
                            headerName: "Shared with",
                            tooltipField:"names",
                            headerTooltip:"Shared with"
                        },
                        {
                            sortable: true,
                            filter: true,
                            field:'convertedTime',
                            // cellRenderer: (params) => { return convertUtcToEst(params.data.last_modified_at) },
                            headerName: "Last Modified On",
                            resizable: true,
                            tooltipField:"convertedTime",
                            headerTooltip:"Last Modified On"
                        },
                        {
                            sortable: true,
                            filter: true,
                            field:'modified_by_name',
                            cellRenderer: (params) => { return params.data?.users.find(x => x.user_id === params.data.last_modified_by)?.full_name },
                            headerName: "Last Modified By",
                            resizable: true,
                            tooltipField:"modified_by_name",
                            headerTooltip:"Last Modified By"
                        },
                        // {
                        //     // field: "id",
                        //     headerName: "Favorite",
                        //     cellRenderer: (params) => { return <div onClick={() => FavoriteWorkspace(params.data)}><FilledHeart></FilledHeart></div> },
                        //     resizable: true
                        // },
                        {
                            sortable: true,
                            filter: true,
                            field:'created_by_name',
                            // cellRenderer: (params) => { return params.data?.users.find(x => x.user_id === params.data.created_by)?.full_name },
                            headerName: "Owner",
                            tooltipField:"created_by_name",
                            headerTooltip:"Owner"
                        },

                    ]}
                    rowdata={favoritedWorkspaces}
                    activeTab={"01"}
                    pageSize={20}
                    loading={loading}
                ></CustomTable>
            }
        </div>
        <Toaster />
    </Fragment>
}
export default FavoritedWorkspaces;
