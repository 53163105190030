import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateCaseAnalysisState } from "../../../redux/actions.js";
import store from "../../../redux/store.js";
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid";
import { convertUtcToEst } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { getUserDetails } from "../Registration/useToken.jsx";
import { TrashCanIcon } from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";


const CaseAssessmentWorkspace = (props) => {
    
    const [sortedItems, setSortedItems] = useState(props?.value?.data?.workspace_items?.length > 0 ? [...props?.value?.data?.workspace_items].sort((a, b) => b.id - a.id):[]);
    const [workspaces, setWorkspaces] = useState([]);
    const [users] = useState(props.users)
    const { user_id } = getUserDetails();
    const [userId] = useState(user_id)

    useEffect(() => {
        if (props?.value?.data?.workspace_items?.length !== sortedItems.length) {
            setSortedItems(props?.value?.data?.workspace_items?.length > 0 && [...props?.value?.data?.workspace_items].sort((a, b) => b.id - a.id))
        }
    }, [props?.value?.data?.workspace_items, sortedItems.length])

    useEffect(() => {
        if (sortedItems?.length > 0) {
            const updatedItems = sortedItems?.map(item => {
                return { ...item, convertedTime: convertUtcToEst(item.modified_at) };
            });
            setWorkspaces(updatedItems)
        }else{
            setWorkspaces([])
        }
    }, [sortedItems])

    const nav = useNavigate();
    const RedirectToReview = (data) => {
        let currentState=store.getState().caseAnalysis.caseAnalysisState
        store.dispatch(
            updateCaseAnalysisState({
                ...currentState,
                workspace_item_id: data.id, 
                module_id: 6 ,
                workspace_id:props?.value?.workspace_id,
                workspace_name:props?.value?.workspace_name
            })
        );
        
        nav('/Case_Analysis')
    }
    // const RedirectToReviewToAddDoc = (data) => {
    //     let currentState=store.getState().caseAnalysis.caseAnalysisState
    //     store.dispatch(
    //         updateCaseAnalysisState({
    //             ...currentState,module_id: 5, isAddDoc: true
    //         })
    //     );
    //     nav('/Case_Analysis')
    // }

    return (
        <div className="ml-3 mt-3 mr-3" style={{ zIndex: 997 }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <h4 className="mb-2 w-50 d-flex">
                    Case Assessment [
                    {workspaces?.length > 0 &&
                        workspaces.filter((x) => x.module_id === 6).length}
                    ]
                    {/* <p
                        className="ml-3 mt-1 text-link disable"
                        onClick={() => RedirectToReviewToAddDoc()}
                    >
                        Add New
                    </p> */}
                </h4>
                <div className="ag-theme-quartz" style={{ height: 360, width: '100%' }}>
                    <CustomTable
                        defaultcoldefs={{
                            // flex: 1,
                            resizable: true,
                            editable: false,
                            autoHeight: true,
                        }}
                        columndefs={[
                            {
                                headerName: "Name",
                                field: "document_name",
                                cellRenderer: (params) => {
                                    return <div className="text-link" onClick={() => RedirectToReview(params.data)}  >
                                        {params.data?.document_name}
                                    </div>
                                },
                                sortable: true,
                                resizable: true,
                                supressSizeToFit: true,
                                tooltipField: "document_name",
                                headerTooltip: "Name"
                            },
                            {
                                headerName: 'Document Type',
                                field: 'document_type',
                                cellRenderer: (params) => {
                                    return props.docTypes.CaseAnalysis?.find(x => x.documentID === params.data.document_type)?.documentName
                                },
                                sortable: true,
                                resizable: true,
                                supressSizeToFit: true,
                                filter: true,
                                tooltipField: "document_type",
                                headerTooltip: "Document Type"
                            },
                            {
                                sortable: true,
                                filter: true,
                                supressSizeToFit: true,
                                // field: "owner",
                                cellRenderer: (params) => {
                                    return params.data.created_by === parseInt(userId) ? "You" : users?.find(x => x.user_id === params.data.created_by)?.full_name
                                },
                                headerName: "Owner",
                                resizable: true,
                                tooltipField: "owner",
                                headerTooltip: "Owner"
                            },
                            {
                                sortable: true,
                                supressSizeToFit: true,
                                filter: true,
                                // cellRenderer: (params) => {
                                //     return convertUtcToEst(params.data.modified_at);
                                // },
                                field: 'convertedTime',
                                headerName: "Last Modified On",
                                resizable: true,
                                tooltipField: "convertedTime",
                                headerTooltip: "Last Modified On"
                            },
                            {
                            field: "delete",
                            headerName: "",
                            suppressSizeToFit: true,
                            width: 50,
                            cellStyle: {
                                textAlign: "center",
                            },
                            tooltipField: "Delete from workspace",
                            headerTooltip: "Delete from workspace",
                            cellRenderer: (params) => { 
                                return (
                                    parseInt(userId)===params.data?.created_by?
                                    <button
                                        title="Delete from workspace"
                                        onClick={(e) => {props.DeleteWorkspaceItem(params.data)}}
                                    >
                                        <TrashCanIcon />
                                    </button>:""
                                );
                            },
                        },
                        ]}
                        rowdata={
                            workspaces?.length > 0 ?
                            workspaces.filter((x) => x.module_id === 6):[]
                        }
                        activeTab={"01"}
                        pageSize={20}
                        noItemsMessg={"No Docs"}
                    ></CustomTable>
                </div>
            </div>
        </div>
    );
}

export default CaseAssessmentWorkspace;