
import { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import '../../../css/workspace.css';
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid.jsx";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { ToastAlert } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { FilledHeart, RegularHeart } from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import ShareWorkSpacePopUp from "../../SupportiveComponents/PopUps/ShareWorkSpacePopUp.jsx";
import { EditIcon, SharIcon } from "../../SupportiveComponents/FontAwesomeComponents/HelperIcons.jsx";
const WorkspaceListComponent = (props) => {
    const [workSpaces, setWorkSpaces] = useState([])
    const [openSharePopUp, setOpenSharePopUp] = useState(false)
    const [data, SetData] = useState({});
    
    var userId = localStorage.getItem('user_id')
    const nav = useNavigate();


    useEffect(() => {
        if (parseInt(userId) && props.workspaces?.length > 0) {
            setWorkSpaces(props.workspaces.filter(x => x.created_by === parseInt(userId)));
        }
    }, [props.workspaces, userId])

    

    const closeSharedWorkspacesPopup = (added) => {
        setOpenSharePopUp(false);
        if (added) {
            props.GetWorkspaces(added);
        }
    };

    const FavoriteWorkspace = (data) => {
        const axiosInstance = getAxiosInstance(props.token, false, nav);
        axiosInstance
            .put(
                "/workspace/favorite",
                { workspace_id: data.id },
                { timeout: 180000 }
            )
            .then((res) => {
                if (res.status === 200) {
                    props.GetWorkspaces(true);
                    if (res.data.isExist) {
                        ToastAlert("Removed from favorites successfully.");
                    } else {
                        ToastAlert("Added to favorites successfully.");
                    }
                    // SetLoading(false)
                }
            });
    };


 
    const RedirectToWorkspace = (data) => {
       
        nav('/Workspaces', {
            state: {
                workspace_id: data.id, workspace_name: data.name,data:data,
                isSharedWorkspace: data.users.length > 1 ? true : false,
                allWorkSpaces:props?.workspaces
            }
        })
    }

    const BoldHeader = ({ displayName }) => {
        return <div style={{ fontWeight: 'bold' }}>{displayName}</div>;
    };


    return <Fragment>
       
        <div>
            {openSharePopUp && <ShareWorkSpacePopUp closepopup={(added) => closeSharedWorkspacesPopup(added)}
                workSpaceDisabled={true}
                data={data}
                users={props.users}
            />}

            <div className="ag-theme-quartz" style={{ height: 600, width: '98%', minHeight: 300, maxHeight: 600 }}>
                <CustomTable
                    defaultcoldefs={{
                        flex: 1,
                        resizable: true,
                        editable: false,
                        // autoHeight: true,
                    }}
                    columndefs={[
                        {
                            headerName: 'Workspace Name',
                            cellRenderer: (params) => { return<div className="d-flex"> <div className="text-link" onClick={() => RedirectToWorkspace(params.data)}>{params.data.name}</div><div className="flex-end"><EditIcon style={{cursor:'pointer',color:'#999'}} onClick={()=>{props?.setOpenCreateNewPopUp(true,params?.data)}}/></div></div> },
                            sortable: true,
                            resizable: true,
                            filter: true,
                            field: 'name',
                            headerComponentFramework: BoldHeader,
                            tooltipField: "name",
                            headerTooltip: "Workspace Name",
                            maxWidth: 400
                        },
                        {
                            sortable: true,
                            filter: true,
                            field: "names",
                            width: 200,
                            // cellRenderer: (params) => { return params.data) },
                            headerName: "Shared With",
                            resizable: true,
                            autoHeight: true,
                            wrapText: true,
                            tooltipField: "names",
                            headerTooltip: "Shared With"
                        },
                        {
                            sortable: true,
                            filter: true,
                            field: 'convertedTime',
                            headerName: "Last Modified On",
                            resizable: true,
                            width: 150,
                            tooltipField: "convertedTime",
                            headerTooltip: "Last Modified On"
                        },
                        {
                            field: 'modified_by_name',
                            sortable: true,
                            filter: true,
                            // cellRenderer: (params) => {
                            //     return params.data.users.find(x => x.user_id === params.data.last_modified_by)?.full_name
                            // },
                            resizable: true,
                            headerName: "Last Modified By",
                            maxWidth: 200,
                            tooltipField: "modified_by_name",
                            headerTooltip: "Last Modified By"
                        },
                        {
                            // field: "id",
                            headerName: "Actions",
                            resizable: true,
                            cellRenderer: (params) => {
                                return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div><SharIcon onClick={() => { setOpenSharePopUp(!openSharePopUp); SetData(params.data) }} style={{ color: "#2eb1e3" }} /></div>
                                    <div onClick={() => FavoriteWorkspace(params.data)}>
                                        {params.data.favorites.filter(x => x.user_id === parseInt(userId)).length > 0 ? <FilledHeart title="REMOVE FROM FAVORITES"></FilledHeart> :
                                            <RegularHeart title="ADD TO FAVORITES"></RegularHeart>}
                                    </div>
                                    <div></div>
                                </div>
                            },
                            width: 100,
                            // tooltipField:"owner",
                            headerTooltip: "Actions"
                        },
                        {
                            headerName: "Items",
                            resizable: true,
                            filter: true,
                            maxWidth: 100,
                            field: "workspace_items.length",
                            cellRenderer: (params) => { return params.data?.workspace_items?.length },
                            tooltipField: "workspace_items.length",
                            headerTooltip: "Items"
                        }
                    ]}
                    rowdata={workSpaces}
                    activeTab={"01"}
                    pageSize={20}
                // loading={loading}
                ></CustomTable>
            </div>

            <Toaster />
        </div>
    </Fragment>
}
export default WorkspaceListComponent;
