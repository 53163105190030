import Switch from "react-switch";
import {
  RegularHeart,
  FilledHeart,
} from "./FontAwesomeComponents/heart/filledheart";
import TruncateText, { CustomTruncatedText } from "./TruncateFileName";
import { AddBlobToBlobList } from "./DocumentComponents/DocViewer";
import ContentOperations from "./ContentOperations";
import { CustomRenderer } from "./CustomDataRendering/CustomDataRenderer";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  CustomgroupedContentOperationLinks,
} from "./CustomContentLinksComponent";
import store from "../../redux/store";
import CreateDocTypePopUp from "./PopUps/CreateDocTypePopUp";
import React, { useContext} from "react";
import EngineContext from "../../contexts/EngineContext";
import { CancelIcon, RefreshIcon } from "./FontAwesomeComponents/HelperIcons";

export const HandleDocTypeChangeComponent = ({
  handleDocTypeChangeCallback,
  documentTypes,
  selectedDocType,
  fileName,
}) => {
  const handleDocTypeChange = (e) => {
    const { value } = e.target;
    if (value?.toLowerCase() === "other") {
      setState({ createNew: true });
    }
    handleDocTypeChangeCallback(e);
  };

  const closePopUp = () => {
    setState({ createNew: false });
  };

  const [state, setState] = useState({ createNew: false });
  return (
    <div className="fullfieldwidth" data-order="1">
      {state.createNew && (
        <CreateDocTypePopUp
          documentTypes={documentTypes}
          closepopup={closePopUp}
          handleDocTypeChangeCallback={handleDocTypeChangeCallback}
        />
      )}
      <div
        className="fieldsec"
        data-toggle="tooltip"
        data-placement="top"
        title="Document type"
      >
        <select
          className="selectfield"
          onChange={(e) => handleDocTypeChange(e)}
          value={selectedDocType}
          disabled={fileName !== undefined ? fileName !== "" : false}
        >
          <option value="" disabled hidden>
            Choose Document Type
          </option>
          {documentTypes.map((item, i) => (
            <option value={item.documentID} key={i}>
              {item.documentName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const HandleEngineComponent = ({
  selectedEngine,
  handleEngineChangeCallback,
  fileName,
}) => {

  const { data, loading, error } = useContext(EngineContext);

  console.log(data, loading, error);
  return (
      <div className="fullfieldwidth" data-order="2">
      
      <div
              className="fieldsec"
              data-toggle="tooltip"
              data-placement="top"
              title="Engine type"
          >
              <select
                  className="selectfield"
                  onChange={handleEngineChangeCallback}
                  value={selectedEngine}
                  disabled={fileName !== undefined ? fileName !== "" : false}
              >
                  {data?.engines?.map((item, i) => {
                      
                          return (
                              <option value={item.engine_id} key={i}>
                                  {item.engine_name}
                              </option>
                          );
                      
                  })}
              </select>
          </div>
      </div>
  );
};

export const HandleRefreshButtonComponent = ({ handleRefreshFunc }) => {
  return (
      <button
          type="button"
          className="refresh newDoc"
          onClick={() => {
              handleRefreshFunc();
      }}
      title={"Refresh current analysis"}
      >
          <RefreshIcon />
      </button>
  );
};

export const HandleCancelRequestButtonComponent = ({ handleRefreshFunc }) => {
    return (
        <button
            type="button"
            className="refresh newDoc"
            onClick={() => {
                handleRefreshFunc();
            }}
            title={"Cancel Current request"}
        >
            <CancelIcon />
        </button>
    );
};

export const HandwrittenComponent = ({
  isHandWritten,
  handleHWSwitchChangeCallback,
}) => {
  return (
    <div className="fullfieldwidth" data-order="5">
      <div
        className="fieldsec"
        data-toggle="tooltip"
        data-placement="top"
        title="Hand written document?"
      >
        <label>Handwritten? </label>
        <Switch
          className={isHandWritten ? "switchYes" : "switch"}
          onChange={handleHWSwitchChangeCallback}
          checked={isHandWritten}
        />
      </div>
    </div>
  );
};

export const HandleNewButtonComponent = ({
  title,
  selectedDocType,
  handleUploadBarCallback,
  loadingref = undefined,
}) => {
  return (
      <>
          <button
              className="newDoc plus-square-icon"
              onClick={() =>
                  handleUploadBarCallback(selectedDocType === "" ? false : true)
              }
              data-toggle="tooltip"
              data-placement="top"
              title={title}
          >
              New
          </button>
      </>
  );
};

export const HandleFileNameComponent = ({
  fileName,
  selectedFile,
  maxLengthOfFileName,
  blobFilesList,
  favoriteFiles,
  module_id,
  handleUpdateBlobFilesStateCallback,
  handleWarnRemoveFavoriteCallback,
  handleRemoveFavoriteStateCallback,
  selectedDocType,
  isHandWritten,
  selectedEngine,
  RemoveFavouriteCallback,
  handleFavoriteFileCallback,
  handleFavoriteFileStateCallback,
}) => {
  return (
    <div className="fullfieldwidth" data-order="3">
      <div className="fieldsec">
        <label>File:</label>
        <CustomTruncatedText
          text={fileName}
          maxLength={maxLengthOfFileName}
          fileURL={
            Object.keys(selectedFile).length !== 0
              ? URL.createObjectURL(selectedFile.file)
              : "/"
          }
          file={
            Object.keys(selectedFile).length !== 0 ? selectedFile.file : null
          }
          callbackfn={() => {
            AddBlobToBlobList({
              blobFilesList: blobFilesList,
              blobobj: {
                name: fileName,
                url:
                  Object.keys(selectedFile).length !== 0
                    ? URL.createObjectURL(selectedFile.file)
                    : "/",
              },
              updatestatecallbackfn: handleUpdateBlobFilesStateCallback,
              closecallback: () => {
                console.info("close event triggered");
              },
            });
          }}
          updatestatecallbackfn={(blobFilesList, activeTab) => {
            handleUpdateBlobFilesStateCallback(blobFilesList, activeTab);
          }}
        ></CustomTruncatedText>{" "}
        {favoriteFiles.some((files) => files.file_name.includes(fileName)) ? (
          <button
            title="Favorite"
            className="space-inline"
            onClick={() =>
              handleWarnRemoveFavoriteCallback(
                fileName,
                module_id,
                null,
                RemoveFavouriteCallback,
                handleRemoveFavoriteStateCallback
              )
            }
          >
            <FilledHeart></FilledHeart>
          </button>
        ) : (
          <button
            title="Favorite"
            className="space-inline"
            onClick={() => {
              handleFavoriteFileCallback(
                {
                  module_id: module_id,
                  selectedFile: selectedFile,
                  selectedDocType: selectedDocType,
                  isHandWritten: isHandWritten,

                  selected_engine_id: selectedEngine,
                },
                handleFavoriteFileStateCallback
              );
            }}
          >
            <RegularHeart />
          </button>
        )}
      </div>
    </div>
  );
};

export const DocumentUploadedHelperDisplayComponent = ({
  count,
  isIndexCreated,
}) => {
  const [count_, setCount] = useState(count);
  const [isIndexCreated_, setIsIndexCreated] = useState(isIndexCreated);

  useEffect(() => {
    setCount(count);
  }, [count]);

  useEffect(() => {
    setIsIndexCreated(isIndexCreated);
  }, [isIndexCreated]);
  return (
    <div className="parent">
      <div className="rectangle-box">
        <div
          style={{
            fontWeight: "bold",
          }}
          className="content"
        >
          {isIndexCreated_ &&
            (count_ > 1
              ? "Your documents have been uploaded and are ready for chat. "
              : "Your document has been uploaded and is ready for chat. ")}
        </div>
        <div className="content">
          {isIndexCreated_ && (
            <>
              Use the chat box below to chat by asking questions or use the
              ready-to-use questions in the prompts section towards the right
            </>
          )}
          {!isIndexCreated_ && (
            <>
              {`${count_} ${
                count_ === 1 ? "document has" : "documents have"
              } been successfully uploaded. `}
              {count_ === 1 ? "This document is" : "These documents are"} being
              prepared for chat. We will notify you once this is complete. If
              you need to, you may navigate away from this page and return using
              the notifications page.
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const CWDReviewTextComponent = forwardRef(
    ({ Chats, fileName, isExpanded, componenttitle, engine_id }, ref) => {
        const [ChatsState, setChatState] = useState(Chats);
        const [isExpandedS, setIsExpandedS] = useState(isExpanded);
        const [engine_id_state, setEngineId] = useState(engine_id);

        useEffect(() => {
            setEngineId(engine_id);
        }, [engine_id]);
        useEffect(() => {
            setChatState(Chats);
        }, [Chats]);
        useEffect(() => {
            setIsExpandedS(isExpanded);
        }, [isExpanded]);

        useImperativeHandle(ref, () => ({
            getIsDataProcessing,
        }));

        const getIsDataProcessing = () => {
            let isDataProcessingval = false;
            Object.keys(ChatsState).map((key) => {
                isDataProcessingval =
                    ChatsState[key][0]?.tresp !== ChatsState[key].length;
                return key;
            });
            return isDataProcessingval;
        };
        return Object.keys(ChatsState).map((key, idx) => (
            <>
                <CWDSectionContent
                    key={"test" + idx}
                    keyval={key}
                    ChatsState={Chats}
                    isExpanded={
                        Chats[key][0]?.tresp !== Chats[key].length
                            ? true
                            : isExpandedS
                    }
              fileName={fileName}
                    componenttitle={componenttitle}
                    engine_id={engine_id_state}
                />
            </>
        ));
    }
);

export const CWDSectionContent = ({
    keyval,
    ChatsState,
    isExpanded,
    componenttitle,
    engine_id,
    fileName
}) => {
    const [key, setKey] = useState(keyval);
    const [Chats, setChats] = useState(ChatsState);
    const [engine_id_state, setEngineId] = useState(engine_id);
    const [isExpandedS, setIsExpanded] = useState(isExpanded);
  const [userDetials] = useState(store.getState().login.logInState);
  const [fileNameS, setFileName] = useState(fileName)
    useEffect(() => {
        setEngineId(engine_id);
    }, [engine_id]);
    useEffect(() => {
        setKey(keyval);
    }, [keyval]);
    useEffect(() => {
        setChats(ChatsState);
    }, [ChatsState]);
    useEffect(() => {
        setIsExpanded(isExpanded);
    }, [isExpanded]);
  useEffect(() => {setFileName(fileName)}, [fileName]);
    return (
        <div className="sglereviewsec" key={key + "reviewsec"}>
            <div className="cannedtitlesec">
                <div className="namenmetadiv">
                    <h4
                        className="m-p-c"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsExpanded(!isExpandedS);
                        }}
                    >
                        {Chats[key][0]?.question}
                        {"   "}
                    </h4>

                    {Chats[key][0]?.tresp !== 1 && (
                        <div className="metadata">
                            {"Responses "} | {Chats[key].length}
                            {" of "}
                            {Chats[key][0]?.tresp}
                            {Chats[key][0]?.chat_user_name?.length > 0 &&
                                " | " +
                                    (Chats[key][0]?.chat_user_name ===
                                    userDetials.userFullName
                                        ? "You"
                                        : Chats[key][0]?.chat_user_name)}
                        </div>
                    )}
                    {Chats[key][0]?.tresp === 1 && (
                        <div className="metadata">
                            {Chats[key][0]?.time} | {Chats[key][0]?.timetaken}{" "}
                            {Chats[key][0]?.chat_user_name?.length > 0 &&
                                " | " +
                                    (Chats[key][0]?.chat_user_name ===
                                    userDetials.userFullName
                                        ? "You"
                                        : Chats[key][0]?.chat_user_name)}
                        </div>
                    )}
                </div>

                <CustomgroupedContentOperationLinks
                    isExpandeds={isExpandedS}
                    expandCallback={setIsExpanded}
                    fileName={fileNameS}
                    CannedQuestionsList={Chats[key]}
                    componenttitle={componenttitle}
                />
            </div>
            <div className="sglereviewsec" key={key + "reviewsec"}>
                {ChatsState[key].length > 0 &&
                    ChatsState[key]?.map((item, i) => (
                        <>
                            <div
                                className={`reviewcontsec 
                                                                                ${
                                                                                    isExpandedS
                                                                                        ? "expanded"
                                                                                        : "collapse"
                                                                                }`}
                            >
                                <div className="readmoredata">
                                    <div className="responseCard_">
                                        {ChatsState[key][i]?.tresp !== 1 && (
                                            <div className="cannedtitlesec">
                                                <div className="namenmetadiv">
                                                    <h4>{item?.fname}</h4>
                                                    <div className="metadata">
                                                        {item?.time} |{" "}
                                                        {item?.timetaken} |{" "}
                                                        {"Response"} {i + 1}
                                                        {"/"}
                                                        {
                                                            ChatsState[key][i]
                                                                ?.tresp
                                                        }
                                                    </div>
                                                </div>

                                                <ContentOperations
                                                    Content={item?.response}
                                                    isSummary={false}
                                                    component="ChatDoc"
                                                    filename={item?.fname}
                                                    question={item?.question}
                                                />
                                            </div>
                                        )}

                                        <div className={`note `}>
                                            <CustomRenderer
                                                content={item?.response}
                                                embeddings={item?.embeddings}
                                                isExpanded={isExpanded}
                                                engine_id={engine_id_state}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
            </div>
        </div>
    );
};

export const HandleBatchFileNameComponent = ({
  fileName,
  favoriteFiles,
  handleBatchPopUpCallback,
  handleWarnRemoveFavoriteCallback,
  RemoveFavouriteCallback,
  isHovered,
  module_id,
  batchId,
  handleRemoveFavoriteStateCallback,
  handleFavoriteFileCallback,
  batchFiles,
  selectedFile,
  selectedDocType,
  selectedEngine,
  isHandWritten,
  handleFavoriteFileStateCallback,
}) => {
  return (
    <div className="fullfieldwidth" data-order="3">
      <div className={`fieldsec ${isHovered ? "hovered" : ""}`}>
        <label>Batch:</label>
        <TruncateText
          text={fileName}
          maxLength={15}
          fileURL={fileName}
          isBatch={true}
          onClick={() => handleBatchPopUpCallback(true)}
        ></TruncateText>
        {favoriteFiles.some((files) => files.file_name.includes(fileName)) ? (
          <button
            title="Favorite"
            className="space-inline"
            onClick={() =>
              handleWarnRemoveFavoriteCallback(
                fileName,
                module_id,
                batchId,
                RemoveFavouriteCallback,
                handleRemoveFavoriteStateCallback
              )
            }
          >
            <FilledHeart />
          </button>
        ) : (
          <button
            title="Favorite"
            className="space-inline"
            onClick={() => {
              handleFavoriteFileCallback(
                {
                  module_id: module_id,
                  selectedFile: selectedFile,
                  selectedDocType: selectedDocType,
                  isHandWritten: isHandWritten,
                  selected_engine_id: selectedEngine,
                  isBatchFile: batchFiles.length > 0 ? true : false,
                  batchFiles: batchFiles,
                  batchName: fileName,
                },
                handleFavoriteFileStateCallback
              );
            }}
          >
            <RegularHeart />
          </button>
        )}
      </div>
    </div>
  );
};
