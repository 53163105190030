import React, { useState, useEffect, Fragment } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
export const ReadMoreCollapseOpenAi = ({
    text,
    maxLength,
    isSummary = false,
    isPrompts = null,
}) => {
    const [isFullTextVisible, setFullTextVisible] = useState(true);
    const [prompt, setConsiderPrompts] = useState(isPrompts);

    useEffect(() => {
        setConsiderPrompts(isPrompts);
    }, [isPrompts]);

    const toggleReadMore = (value) => {
        let tempprompt = value ? null : true;
        setConsiderPrompts(tempprompt);
        setFullTextVisible(value);
    };

    const res = !isSummary
        ? text
        : text?.Concise_Summary !== ""
        ? text?.Concise_Summary
        : "";
 const summary_2 =  !isSummary
            ? text
            : text?.Concise_Summary !== ""
            ? text?.Concise_Summary
            : "";

    const truncatedText =
        isFullTextVisible && (prompt === null || prompt === false)
            ? res
            : res !== ""
            ? res.slice(0, maxLength)
            : "";
    const innerHTML = { __html: truncatedText };


    return (
        <Fragment>
          

            <div
                className={
                    isSummary
                        ? summary_2 !== ""
                            ? "summary_note"
                            : "note"
                        : "note"
                }
            >
                {isSummary && (
                    <label className="summaryHeader">Concise Summary</label>
                )}
                {innerHTML ? null : (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{truncatedText}</ReactMarkdown>
                )}
                {innerHTML && <ReactMarkdown  remarkPlugins={[remarkGfm]}>{truncatedText}</ReactMarkdown>}
                {res.length > maxLength && (
                    <span
                        onClick={() => toggleReadMore(!isFullTextVisible)}
                        style={{ cursor: "pointer", color: "#2eaadb" }}
                    >
                        {isFullTextVisible &&
                        (prompt === null || prompt === false)
                            ? " ...Read Less"
                            : " ...Read More"}
                    </span>
                )}
            </div>
        </Fragment>
    );
};
const ReadMoreCollapse = ({
    text,
    maxLength,
    isSummary = false,
    isPrompts = null,
}) => {
    const [isFullTextVisible, setFullTextVisible] = useState(true);
    const [prompt, setConsiderPrompts] = useState(isPrompts);

    useEffect(() => {
        setConsiderPrompts(isPrompts);
    }, [isPrompts]);

    const toggleReadMore = (value) => {
        let tempprompt = value ? null : true;
        setConsiderPrompts(tempprompt);
        setFullTextVisible(value);
    };



    const res = !isSummary
        ? text
        : text?.Concise_Summary !== ""
        ? text?.Concise_Summary
        : "";
    const summary_2 =     !isSummary
            ? text
            : text?.Concise_Summary !== ""
            ? text?.Concise_Summary
            : ""
    ;
    const truncatedText =
        isFullTextVisible && (prompt === null || prompt === false)
            ? res
            : res !== ""
            ? res.slice(0, maxLength)
            : "";
    const innerHTML = { __html: truncatedText };

    const truncatedText_2 =
        isFullTextVisible && (prompt === null || prompt === false)
            ? summary_2
            : summary_2 !== ""
            ? summary_2.slice(0, maxLength)
            : "";
    const innerHTML_2 = { __html: truncatedText_2 };

    return (
        <Fragment>
            {isSummary && summary_2 !== "" && (
                <>
                    <p
                        style={{ whiteSpace: "pre-wrap", marginBottom: "2%" }}
                        className={isSummary ? "summary_note" : "note"}
                    >
                        {isSummary && (
                            <label className="summaryHeader">
                                Concise Summary
                            </label>
                        )}

                        {innerHTML_2 ? null : truncatedText_2}
                        {innerHTML_2 && (
                            <div dangerouslySetInnerHTML={innerHTML_2} />
                        )}

                        {summary_2.length > maxLength && (
                            <span
                                onClick={() =>
                                    toggleReadMore(!isFullTextVisible)
                                }
                                style={{ cursor: "pointer", color: "#2eaadb" }}
                            >
                                {isFullTextVisible &&
                                (prompt === null || prompt === false)
                                    ? " ...Read Less"
                                    : " ...Read More"}
                            </span>
                        )}
                    </p>
                </>
            )}
            <p
                style={{ whiteSpace: "pre-wrap" }}
                className={
                    isSummary
                        ? summary_2 !== ""
                            ? "summary_note"
                            : "note"
                        : "note"
                }
            >
                {isSummary && (
                    <label className="summaryHeader">
                        Intermediate Summary
                    </label>
                )}
                {innerHTML ? null : truncatedText}
                {innerHTML && <div dangerouslySetInnerHTML={innerHTML} />}

                {res.length > maxLength && (
                    <span
                        onClick={() => toggleReadMore(!isFullTextVisible)}
                        style={{ cursor: "pointer", color: "#2eaadb" }}
                    >
                        {isFullTextVisible &&
                        (prompt === null || prompt === false)
                            ? " ...Read Less"
                            : " ...Read More"}
                    </span>
                )}
            </p>
        </Fragment>
    );
};

export default ReadMoreCollapse;
