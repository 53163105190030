import HelperMessages from "../../Helpers/HelperMessages";

const initialState = {
    summarizeState: {
        isBottomPanelOpen: false,
        selectedDocType: "",
        selectedEngine: 2,
        selectedFile: "",
        prevSelectedFile: "",
        summaryResult: "",
        isUploadFileOpen: true,
        promptDisplayed: HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        activeTab: 1,
        isLoading: false,
        isSocketRequestProgress: false,
        loaderText: "Analysis in progress...",
        fileName: "",
        favoriteFiles: [],
        summaryTimer: "",
        isHandWritten: false,
        historyList: [],
        blobFilesList: [], // List used to open doc in the bottom bar
        docActiveTab: "", // state used for maintaining doc active tab
        isDocumentOpen: true, // state used to expand or collapse document tab
        favdisplayval: false,
        hisdisplayval: false,
        openCreateWorkSpacePopUp: false,
        reqStartTime: new Date().getTime(),
        baseContent: "",
        uploadFileId: undefined,
        disableAddToWorkspace: false,
        workspace_id: 0,
        workspace_item_id: 0,
        module_id: 2,
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        index_path: "",
        isIndexCreated: false,
        isContentExpanded: true,
        ws_shared_users: [],
        doc_help_questions: [],
        addToAnotherWorkspace: false,
        moveToAnotherWorkspace: false,
        closeKebabMenu: false,
        ws_count:0,
    },
};
export const SummarizeInitialState = initialState.summarizeState;
export const summaryReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_SUMMARIZE_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, summarizeState: action.payload.data };
        // Add other cases for different components' states

    case 'RESET_SUMMARIZE_STATE':
        return initialState;
    default:
        return state;
    }
};
