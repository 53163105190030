export const EmbeddingsPopup = ({
    content,
    closeEmbedpopup,
    positionObject,
}) => {
    return (
        <>
            <div
                id="popup-overlay"
                className="custom-popup-overlay view-fav-popup-overlay text-clr embeddings-popup"
                style={{ ...positionObject }}
            >
                <div className="custom-popup-content">
                    {content?.length > 0 && (
                        <div className="custom-popup-body">
                            {content.length > 0 ? (
                                <dl>
                                    <h2 className="position-absolute top-right-button">
                                        <span
                                            className="close-btn"
                                            onClick={() => closeEmbedpopup()}
                                            title="Close"
                                        >
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                                alt="close"
                                            />
                                        </span>
                                    </h2>
                                    {content?.map((val, index) => {
                                        return (
                                            <>
                                                <dt className="centerText d-flex j-c-s-b">
                                                    <strong>
                                                        Reference {index + 1}
                                                    </strong>
                                                    <h2>
                                                        <span></span>
                                                    </h2>
                                                </dt>

                                                <dd>{val}</dd>
                                            </>
                                        );
                                    })}
                                </dl>
                            ) : (
                                <>No References</>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
