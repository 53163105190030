import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { socket } from "../../../contexts/socket.js";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { convertUtcToEst } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";
import CreateWorkSpacePopUp from "../../SupportiveComponents/PopUps/CreateWorkSpacePopUp";
import { CustomTabPanel, a11yProps } from "../Admin/AdminOperations";
import FavoritedWorkspaces from "./FavoritedWorkspaces";
import SharedWorkspaces from "./SharedWorkspaces";
import WorkspaceListComponent from "./WorkspaceListComponent";
import Swal from "sweetalert2";
import store from "../../../redux/store.js";

export default function WorkspaceTabs(props) {
  const loc = useLocation();
  const [value, setValue] = React.useState(
    loc?.state?.activeTab ? loc.state.activeTab : 0
  );
  const [openCreateNewPopUp, setOpenCreateNewPopUp] = React.useState(false);
  const [isAdded, setIsAdded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [workspaceDetails, setWorkspaceDetails] = React.useState({name:"",id:0});
  const [openWorkspaceItemsPopUp, setOpenWorkspaceItemsPopUp] =
    React.useState(false);
  // const [allWorkSpaces] = React.useContext(WorkspaceContext);
  const [allWorkSpaces, setAllWorkSpaces] = React.useState([]);
  const [users, Setusers] = React.useState([]);

  // var userId = localStorage.getItem('user_id')

  const GetWorkspaces = React.useCallback(
    (isload) => {
      try {
        if (!isload) {
          setLoading(true);
        }
        if(store.getState().login.logInState?.user_id){
        const axiosInstance = getAxiosInstance(props.token, false, props.nav);
        axiosInstance
          .get("/workspace/all", { timeout: 180000 })
          .then((res) => {
            if (res?.status === 200 && res.data?.length > 0) {
              const sortedItems = [...res.data].sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              );
              const updatedItems = sortedItems?.map((item) => {
                return {
                  ...item,
                  convertedTime: convertUtcToEst(item.last_modified_at),
                };
              });
              setAllWorkSpaces(updatedItems);
              // if (parseInt(userId)) {
              //     setAllWorkSpaces(sortedItems.filter(x => x.created_by === parseInt(userId)));
              // }
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log("From API: ", error?.response);
            let status = error?.response?.status;
            let error_text = "Something went wrong";
            if (status === 401 || status === 422) {
              props?.removeToken();
              error_text = error?.response?.data?.msg;
              Swal.fire({
                text: error_text,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          });

        axiosInstance
          .post("/admin/getOrgData", {}, { timeout: 180000 })
          .then((res) => {
            Setusers(res?.data);
          })
          .catch((error) => {

            setLoading(false);
            let status = error?.response?.status;
            let error_text = "Something went wrong";
            if (status === 401 || status === 422) {
              props?.removeToken();
              error_text = error?.response?.data?.msg;
              Swal.fire({
                text: error_text,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          });
        }
      } catch (error) {
        setLoading(false);
        return error;
      }
    },
    [props]
  );
  React.useEffect(() => {
    socket.on("workspaceliveupdates", (data) => {
      GetWorkspaces(true);
    });
    return () => socket.off("shareworkspace");
  }, [GetWorkspaces]);

  React.useEffect(() => {
    GetWorkspaces();
  }, [GetWorkspaces, props.isAdded]);

  const handleChange = (event, newValue) => {
    // GetWorkspaces();
    setValue(newValue);
  };
  const CloseCreateNewPopUp = () => {
    setOpenCreateNewPopUp(false);
    GetWorkspaces();
  };
  const CloseWorkspaceItemsPopup = () => {
    setOpenWorkspaceItemsPopUp(!openWorkspaceItemsPopUp);
  };

  const handleEditWorkspace=(isEdit,data)=>{
    if(isEdit){
      setOpenCreateNewPopUp(true)
      setWorkspaceDetails({name:data?.name, id:data?.id})
    }
  }
  return (
      <>
          {openCreateNewPopUp && (
              <CreateWorkSpacePopUp
                  closepopup={CloseCreateNewPopUp}
                  content={""}
                  workSpace={workspaceDetails?.name}
                  workspaceId={workspaceDetails?.id}
                  baseContent={""}
                  document_type={""}
                  document_name={""}
                  mime_type={""}
                  timeTaken={0}
                  CannedQuestionsList={[]}
                  workspaces={allWorkSpaces}
                  getNameBack={(values) => {
                      if (values.workSpace && values.workSpace !== "") {
                          setIsAdded(true);
                      }
                  }}
                  handwritten_text_flag={false}
                  closeworkspacelist={() => {
                      setOpenCreateNewPopUp(false);
                      GetWorkspaces();
                  }}
              />
          )}

          <h2 className="rytpaneltoppart pt-1 pb-1 ">
              <div
                  className={`newws ml-1 ${
                      navigator.userAgent.toLowerCase().includes("mobile")
                          ? "width-100"
                          : ""
                  }`}
              >
                  WORKSPACES
                  <div
                      className="newDoc plus-square-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add new Workspace"
                      onClick={() => {
                          setWorkspaceDetails({ name: "", id: 0 });
                          setOpenCreateNewPopUp(true);
                      }}
                  >
                      New
                  </div>
              </div>
          </h2>
          <Toaster />
          <Box sx={{ width: "100%", marginLeft: "1%" }}>
              <Box>
                  <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                  >
                      <Tab
                          label="Yours"
                          sx={
                              value === 0
                                  ? {
                                        backgroundColor:
                                            "var(--clr-sidebar-buttons-background)",
                                        borderRadius: "10px",
                                        color: "#fff !important",
                                    }
                                  : {}
                          }
                          {...a11yProps(0)}
                      />

                      <Tab
                          label="Shared with you"
                          sx={
                              value === 1
                                  ? {
                                        backgroundColor:
                                            "var(--clr-sidebar-buttons-background)",
                                        borderRadius: "10px",
                                        color: "#fff !important",
                                    }
                                  : {}
                          }
                          {...a11yProps(1)}
                      />
                      <Tab
                          label="Favorites"
                          {...a11yProps(2)}
                          sx={
                              value === 2
                                  ? {
                                        backgroundColor:
                                            "var(--clr-sidebar-buttons-background)",
                                        borderRadius: "10px",
                                        color: "#fff !important",
                                    }
                                  : {}
                          }
                      />
                  </Tabs>
              </Box>
              {loading ? (
                  <LoadingSpinner text={"Loading workspaces"}></LoadingSpinner>
              ) : (
                  <div style={{ overflow: "scroll" }}>
                      <CustomTabPanel value={value} index={0}>
                          <WorkspaceListComponent
                              token={props.token}
                              closePopUp={CloseWorkspaceItemsPopup}
                              isAdded={isAdded}
                              workspaces={allWorkSpaces}
                              users={users}
                              GetWorkspaces={GetWorkspaces}
                              activeTab={value}
                              setOpenCreateNewPopUp={handleEditWorkspace}
                          />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                          <SharedWorkspaces
                              token={props.token}
                              workspaces={allWorkSpaces}
                              GetWorkspaces={GetWorkspaces}
                              activeTab={value}
                          />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={2}>
                          <FavoritedWorkspaces
                              token={props.token}
                              workspaces={allWorkSpaces}
                              activeTab={value}
                          />
                      </CustomTabPanel>
                  </div>
              )}
          </Box>
      </>
  );
}
