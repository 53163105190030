import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import getAxiosInstance from "../AxiosRequest.jsx";
import store from "../../../redux/store.js";
import { ToastAlert } from "../CustomAlerts/ToastAlert";

function Feedback(props) {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [token] = useState(localStorage.getItem("token"));

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxFiles: 2,
    maxSize: 5 * 1024 * 1024
  });

  const handleDragStart = (e) => {
    setIsDragging(true);
  };

  const handleDragEnd = (e) => {
    setIsDragging(false);
  };

  const renderTemplate = (data, message) => {
    return (
      "<html><head><b>User Details</b></head><body><div>" +
      "<p>Name: " +
      data?.userFullName +
      "</p><p>Org Name: " +
      data?.organization +
      "</p><p>User Name: " +
      data?.user_name +
      "</p><p>User Type: " +
      data?.userType +
      "</p><p><b>Url: </b>" +
      window.location.href +
      "</p><h4>Feedback: </h4><p>" +
      message +
      "</p><br/><br/><h5>Thanks and Regards</h5><p>" +
      data?.userFullName +
      "</div></body></html>"
    );
  };

  const handleSubmit = (e) => {
    setButtonText("Sending Email");
    setButtonDisable(true);
    e.preventDefault();
    const form = new FormData();
    form.append("to", "maheshp@kavayahsolutions.com");
    form.append("subject", "Feedback");
    form.append("text", JSON.stringify(store.getState()?.login?.logInState));
    form.append(
      "html",
      renderTemplate(store.getState()?.login?.logInState, message)
    );
    files.forEach((file) => form.append("files", file));
    let axiosInstance = getAxiosInstance(token, props?.history, false);
    axiosInstance
      .post("/send-email", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((res) => {
        ToastAlert("Email sent successfully.");
        setButtonText("Submit");
        setButtonDisable(false);
        props.closepopup();
      })
      .catch((execption) => {
        setButtonText("Submit");
        setButtonDisable(false);
      });
  };

  return (

      <div
        id="popup-overlay"
        className={`custom-popup-overlay view-fav-popup-overlay  show
            }`}
        onClick={() => props.closepopup()}
      >
        <div
          className={`custom-popup-content feedback-popup`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom-popup-header">
            <h2>{"Feedback"}</h2>
            <h2>
              <span
                className="close-btn"
                onClick={() => props.closepopup()}
                title="Close"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                  alt="close"
                />
              </span>
            </h2>
          </div>
          <div className="custom-popup-body">
            <Form onSubmit={handleSubmit}>
              <div
                className="sgledragdropsec_LegaIssue"
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                style={{
                  cursor: isDragging ? "move" : "auto",
                  marginTop: "1px",
                }}
              >
                <div className="fulldrapdropsec">
                  <form action="#" style={{ height: "240px" }}>
                    <div className="formfullwidth">
                      <textarea
                        id="legal_issue"
                        className="textareafield"
                        placeholder="Describe the feedback of this application."
                        value={message}
                        rows={3}
                        onChange={(e) => setMessage(e?.target?.value)}
                        required
                      ></textarea>
                    </div>
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        border: "2px dashed #cccccc",
                        marginTop: "10px",
                        pointerEvents: files.length >= 2 ? "none" : "all",
                      }}
                    >
                      <input
                        style={{ height: "20%" }}
                        {...getInputProps()}
                        disabled={files.length >= 2}
                      />
                      <p className="text-center">
                        If you need to attach, drag and drop files here or click
                        to select files (up to 2 files).
                        <p className="text-center">
                          (images, pdf, docx accepted up to 5MB)
                        </p>
                      </p>
                    </div>
                    {files.length > 0 && (
                      <div className="mt-1 files-ht">
                        <ul>
                          {files.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div
                className=" mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "99%",
                  cursor: "pointer",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <button
                  disabled={buttonDisable}
                  title={"Submit"}
                  className="continue"
                  onClick={() => {}}
                >
                  {buttonText}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
  );
}

export default Feedback;
