import { Popup } from "../../SupportiveComponents/CustomPopup";

export const TermsAndConditions = (props) => {
    return (
        <Popup
            title={props.title}
            content={
                <>
                    <div className="termsAndConditionsList">
                        <ol>
                            <li
                                className="has-line-data"
                                data-line-start="1"
                                data-line-end="3"
                            >
                                Acceptance of Terms
                                <ol>
                                    <li className="style_none">
                                        By registering on this website, you
                                        agree to comply with and be bound by the
                                        following terms and conditions. If you
                                        do not agree to these terms, please do
                                        not register or use this website.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="3"
                                data-line-end="7"
                            >
                                User Registration
                                <ol>
                                    <li>
                                        {" "}
                                        You must provide accurate, current, and
                                        complete information during the
                                        registration process.
                                    </li>{" "}
                                    <li>
                                        {" "}
                                        You are responsible for maintaining the
                                        confidentiality of your account and
                                        password and for restricting access to
                                        your computer or device.{" "}
                                    </li>
                                    <li>
                                        You agree to accept responsibility for
                                        all activities that occur under your
                                        account.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="7"
                                data-line-end="11"
                            >
                                User Conduct
                                <ol>
                                    <li>
                                        You agree not to use the website for any
                                        unlawful purpose or in any way that
                                        could violate these terms.
                                    </li>
                                    <li>
                                        You may not engage in any activity that
                                        disrupts, interferes with, or harms the
                                        website or the experience of other
                                        users.
                                    </li>
                                    <li>
                                        {" "}
                                        Offensive, abusive, or inappropriate
                                        behavior is strictly prohibited.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="11"
                                data-line-end="14"
                            >
                                Intellectual Property
                                <ol>
                                    <li>
                                        {" "}
                                        All content on this website, including
                                        text, graphics, logos, images, and
                                        software, is the property of Kavayah
                                        Solutions Inc. and is protected by
                                        copyright and other intellectual
                                        property laws.
                                    </li>
                                    <li>
                                        {" "}
                                        You may not reproduce, distribute, or
                                        use any content from this website
                                        without the express written consent of
                                        Kavayah Solutions Inc…
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="14"
                                data-line-end="16"
                            >
                                Privacy Policy
                                <ol>
                                    <li>
                                        {" "}
                                        Your registration information and other
                                        personal data are subject to our Privacy
                                        Policy. By using this website, you
                                        consent to the collection and use of
                                        your information as described in the
                                        Privacy Policy.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="16"
                                data-line-end="18"
                            >
                                Termination
                                <ol>
                                    <li>
                                        {" "}
                                        Kavayah Solutions Inc. reserves the
                                        right to terminate or suspend your
                                        account at any time, without notice, for
                                        any reason, including a breach of these
                                        terms.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="18"
                                data-line-end="20"
                            >
                                Modifications to Terms
                                <ol>
                                    <li>
                                        {" "}
                                        Kavayah Solutions Inc. may modify these
                                        terms at any time. Your continued use of
                                        the website after such modifications
                                        constitutes acceptance of the updated
                                        terms.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="20"
                                data-line-end="22"
                            >
                                Governing Law
                                <ol>
                                    <li>
                                        These terms are governed by and
                                        construed in accordance with the laws of
                                        Princeton, New Jersey.
                                    </li>
                                </ol>
                            </li>
                            <li
                                className="has-line-data"
                                data-line-start="22"
                                data-line-end="24"
                            >
                                Contact Information
                                <ol>
                                    <li>
                                        If you have any questions or concerns
                                        about these terms, please contact us at{" "}
                                        <a href="mailto:info@lawbotica.ai">
                                            <span
                                                className="__cf_email__"
                                                data-cfemail="f1989f979eb19d9086939e85989290df9098"
                                            >
                                                info@lawbotica.ai
                                            </span>
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </>
            }
            closepopup={props.closepopup}
        ></Popup>
    );
};
