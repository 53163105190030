import { Fragment } from "react"

const EmptyWorkspaces = ({handleOpenCreateNew}) => {
    return <Fragment>
            <div className="content-center mt-3">
                You do not have any workspaces as yet.
                Get started by creating your first workspace!
            </div>
            <div className="button-container mt-3">
                <button type="submit" className="generatebut mr-2" onClick={()=>handleOpenCreateNew()}>Create new workspace  </button>

            </div>

    </Fragment>
}

export default EmptyWorkspaces;