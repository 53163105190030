
export const SIOEVENTS = {
    REVIEW: {
        REQUEST: "review_doc_chat",
        RESPONSE: "review_doc_chat_response",
    },
    SUMMARIZE: {
        REQUEST: "summarize_chat",
        RESPONSE: "summary_chat_response",
    },
    CHAT_WITH_DOCS: {
        REQUEST: "doc_chat",
        RESPONSE: "doc_chat_response",
    },
    REPO_CHAT: {
        REQUEST: "repo_chat",
        RESPONSE: "repo_chat_response",
    },
};