import { useState } from "react";
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import excelFile from '../../../sampleFiles/sample_case_assessment_file.xlsx';


const UploadCaseDetails = ({ closeWsListpopup, updateData }) => {
  const [selectedFile, setSelectedFile] = useState("");

  // triggers when file is dropped
  const handleDrop = function (e) {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        fileProcessing(e.dataTransfer.files[0]);
      }
    } catch (error) {
      console.log("Error in handleDrop", error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = async function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      fileProcessing(e.target.files[0]);
    }
  };

  const fileProcessing = (file) => {

    let labels =  {
      caseName: "Case Name",
      description: "Case Description",
      plaintiff: "Plaintiff",
      defendant: "Defendant",
      jurisdiction: "Name of the Jurisdiction",
      case_number: "Case Number",
      date: "Date",
      location: "Location",
      legal_issue: "Legal Issue",
      status: "Status",
      witness: "Witnesses",
      relevant_documents: "Relevant Documents",
      Nature_of_Incident: "Nature of Incident",
      Injury: "Injury",
      Treatment_Received: "Medical Treatment received, prognosis, permanent disabilities",
      Medical_Expense: "Medical Expenses",
      Lost_Wages: "Lost Wages",
      Downtime: "Downtime",
      Pain_Suffering: "Pain and Suffering",
      Parties_Behavior: "Behavior of Parties",
      Laws_Regulations: "Laws and Regulations",
      Expert_Testimony: "Expert Testimony",
      Analysis_Questions: "Questions for Analysis",
      Liability: "Liability",
      Insurance_Coverage: "Insurance Coverage",
      Plaintiff_Req: "Plaintiff's Requirements",
      Defendent_Position: "Defense's Position",
      Precedents: "Precedents",
      Claims: "Claims and Counterclaims",
      Evidence: "Evidence",
      Laws_Precedents: "Laws and Precedents",
      Parties: "Parties",
      Legal_Team: "Legal Team",
      Evidentiary: "Evidentiary",
      Legal_Precedents: "Legal Precedents",
      Opposing_Party: "Opposing Party",
      Plaintiff_Position: "Plaintiff's Position",
      Statute_of_Limitations: "Statute of Limitations",
    }
    if (file && (file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file?.type === "application/vnd.ms-excel")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Assuming you want to read the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON format
        const data = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw:false});
        
        const updatedObject = { ...labels }; // Create a copy of the object

        data.forEach(([key,dd, value]) => {
          key = key?.replace("\r\n", "")
          key = key?.replace("*", "")
          key = key?.replace("(MM/DD/YYYY)", "")
          for (const prop in updatedObject) {
            if ( updatedObject[prop] === key) {
              updatedObject[prop] = value?value:"";
            }
          }
        });

        // Update the state with the parsed data
        updateData(updatedObject);
      };
      reader.readAsBinaryString(file);
    }
    else{
        Swal.fire({
            text: "Please upload MS excel files only (xls or xlsx)",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
  };



  return (
    <div
      id="popup-overlay"
      className={`custom-popup-overlay view-fav-popup-overlay `}
      style={{ height: "100%", width: "100%", zIndex: 8 }}
    >
      <div
        className={`custom-popup-content upload-popup z-index-1`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="custom-popup-header">
          <h2>{"Upload Excel"}</h2>
          <h2>
            {" "}
            <span
              className="close-btn"
              onClick={() => closeWsListpopup()}
              title="Close"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                alt="close"
              />
            </span>
          </h2>
        </div>
        <div className="custom-popup-body upload-body">
          <div>
            <form
              id="form-file-upload"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onSubmit={(e) => e.preventDefault()}
            >
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className="drop-container"
              >
                <div>
                  <p className="drop-title">
                    <>
                      Drag & Drop your file here or{" "}
                      <span className="drop-span">Choose File</span>
                    </>

                    <input
                      type="file"
                      id="input-file-upload"
                      accept="xlsx"
                      onInput={handleChange}
                      style={{ display: "none" }}
                      required
                    ></input>
                  </p>
                  {selectedFile && (
                    <p style={{ color: "#2eaadb", fontWeight: "bold" }}>
                      {selectedFile.name}
                    </p>
                  )}
                </div>
              </label>
            </form>
            <p style={{ padding: "10px" }}>
              Download a sample{" "}
              <a href={excelFile} download="sample_case_assessment_file">excel file</a>
              {/* <span className="text-link" onClick={handleDownloadSample}>
                excel file
              </span> */}
            </p>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default UploadCaseDetails;
