import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    handleCopySection,
    handleCopySelectionALL,
} from "./CustomContentOperations/PureContentOperations";
import {
    generateMDtoWordDoc,
    // generateWordDocument,
    handleCopyFullChatObject,
    handleExportFullChat,
    handleExportFullChatObject,
} from "./CustomContentOperations/WordDocGenerationOperations";
import { Collapse, Expand } from "./FontAwesomeComponents/heart/filledheart";
import {
    CollapseAllIcon,
    CopyIcon,
    ExpandIcon,
    ExportIcon,
} from "./FontAwesomeComponents/HelperIcons";

export const CustomgroupedContentOperationLinks = ({
    isExpandeds,
    expandCallback,
    CannedQuestionsList,
    fileName,
    componenttitle,
}) => {
    const [isExpanded, setIsExpanded] = useState(isExpandeds);

    useEffect(() => {
        setIsExpanded(isExpandeds);
    }, [isExpandeds]);
    return (
        <div className="elementlinkdiv" style={{ maxWidth: "99%" }}>
            <ul>
                <li
                    onClick={(e) => {
                        e.stopPropagation();
                        CannedQuestionsList?.constructor === Array
                            ? handleCopySelectionALL(CannedQuestionsList)
                            : handleCopyFullChatObject(
                                  CannedQuestionsList,
                                  fileName,
                                  null,
                                  componenttitle
                              );
                    }}
                    title="COPY"
                >
                    <CopyIcon />
                </li>
                <li
                    onClick={(e) => {
                        e.stopPropagation();
                        CannedQuestionsList?.constructor === Array
                            ? handleExportFullChat(
                                  CannedQuestionsList,
                                  fileName,
                                  null,
                                  componenttitle
                              )
                            : handleExportFullChatObject(
                                  CannedQuestionsList,
                                  fileName,
                                  null,
                                  componenttitle
                              );
                    }}
                >
                    <ExportIcon />
                </li>
                <li
                    onClick={(e) => {
                        e.stopPropagation();
                        expandCallback(!isExpanded);
                    }}
                >
                    <span className="vertical_align_m">
                        {isExpanded ? <CollapseAllIcon /> : <ExpandIcon />}
                    </span>
                </li>
            </ul>
        </div>
    );
};

export const CustomContentOperationLinks = ({
    isExpandeds,
    expandCallback,
    content,
    filename,
    component,
    question,
    isSummary,
    chat_quesion
}) => {
    const [isExpanded, setIsExpanded] = useState(isExpandeds);

    useEffect(() => {
        setIsExpanded(isExpandeds);
    }, [isExpandeds]);
    return (
        <div className="elementlinkdiv">
            <ul>
              {component !== 'chatwithworkspace' &&  <li
                    onClick={(e) => {
                        e.stopPropagation();
                        content
                            ? handleCopySection(content)
                            : toast.success("Copy coming soon...");
                    }}
                    title="COPY"
                >
                    <CopyIcon />
                </li>}
                {component !== 'chatwithworkspace' &&  <li
                    onClick={(e) => {
                        e.stopPropagation();
                        content
                            ? generateMDtoWordDoc(
                                  content,
                                  filename,
                                  question,
                                  component
                              )
                            : toast.success("Export coming soon...");
                    }}
                >
                    <ExportIcon /> 
                </li> 
             
                
                }
                <li
                    onClick={(e) => {
                        e.stopPropagation();
                        expandCallback(!isExpanded);
                    }}
                >{component !== 'chatwithworkspace' ?
                    <span className="vertical_align_m " >
                        {isExpanded ? <CollapseAllIcon /> : <ExpandIcon />}
                    </span>:
                    <span className="vertical_align_m " >
                        {isExpanded ? <Collapse /> : <Expand />}
                    </span>
                }
                </li>
            </ul>
        </div>
    );
};
