import React from "react";
import "../../css/LoadingSpinner.css";
// import lawBotica_Spinner from "../images/lawBotica_Spinner.jpg";
import image from "../../images/KLabs-favicon_transparent.png";
import { Spinning } from "./FontAwesomeComponents/HelperIcons";

export default function LoadingSpinner(props) {
    return (
        <div
            id="popup-overlay"
            className="overlay custom-popup-overlay"
            style={{ position: props?.isFixed ? "fixed" : "" }}
        >
            <img src={image} alt="" className="loading-spinner" />
            <h4 className="message text-loading">{props.text}</h4>
        </div>
    );
}

export function LoadingSpinnerSticky({isFixed, text}) {
    return (
        <div
            id="popup-overlay"
            className="overlay custom-popup-overlay loading-spinner-sticky"
            style={{ position: isFixed ? "fixed" : "" }}
        >
            <img src={image} alt="" className="loading-spinner" />
            <h4 className="message text-loading">{text}</h4>
            
        </div>
    );
}



export const SectionInlineLoader = () => {
    return (
        <div className="reviewcontsec expanded">
            <div className="readmoredata">
                <div className="responseCard_  text-align-center ">
                    <Spinning />
                </div>
            </div>
        </div>
    );
};
