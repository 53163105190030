import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Toaster } from "react-hot-toast";
import { OrgOperations } from "./OrgOperations";
import { UserOperations } from "./UserOperations";
import { ModuleOperations } from "./ModuleOperations";

export const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    const [children_, setChildren] = React.useState(children);

    const [value_, setValue] = React.useState(value);
    const [index_, setIndex] = React.useState(children);
    React.useEffect(() => {
        setChildren(children);
    }, [children]);

    React.useEffect(() => {
        setValue(value);
    }, [value]);

    React.useEffect(() => {
        setIndex(index);
    }, [index]);

    return (
        <div
            role="tabpanel"
            hidden={value_ !== index_}
            id={`simple-tabpanel-${index_}`}
            aria-labelledby={`simple-tab-${index_}`}
            {...other}
        >
            {value_ === index_ && (
                <Box sx={{ div: 3 }}>
                    <React.Fragment>{children_}</React.Fragment>
                </Box>
            )}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Toaster />
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Organizations"
                            style={{}}
                            {...a11yProps(0)}
                        />
                        <Tab label="Users" {...a11yProps(1)} />
                        <Tab label="Modules" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <OrgOperations token={props.token} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <UserOperations token={props.token} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <ModuleOperations token={props.token} />
                </CustomTabPanel>
            </Box>
        </>
    );
}
