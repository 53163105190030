import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { updateErrorState } from "../../../redux/actions";
import store from "../../../redux/store";
import UnauthorizedError from "../../SupportiveComponents/ErrorPages/UnAuthorizedError";
import { VerifyTrialAccountComponent } from "../../SupportiveComponents/TrialAccountHelpers/VerifyTrialAccountComponent";
import { ThemeComponent } from "../ThemeComponent";
import CompanyAdminSignupForm, {
  CompanyUserSignupForm,
  ForgotPasswordForm,
  LawBoticaFreeTrialNavigation,
  ResetPasswordForm,
  SetUpTrailAccount,
} from "./Forms";
import LogIn from "./LogIn";
import OrgRegistration from "./OrgRegistration";
import { KavayahLogo } from "./UserHeader";
import { HelpTrialPage } from "../Trial/HelpTrialPageComponent";
function UserNavigation(props) {
    const redirectToDashboard=()=>{
        store.dispatch(
            updateErrorState({
              hasError: false,
            })
          );
        window.location.href="/"
    }
  return (
      <Fragment>
          <div className="headersec">
              <div className="headeralignpad">
                  <div className="logo" onClick={() => redirectToDashboard()}>
                      <KavayahLogo />
                  </div>
                  <div className="headerrightside">
                      <ThemeComponent></ThemeComponent>
                  </div>
              </div>
          </div>
          <Routes>
              <Route
                  path="/orgadmin/signup/:securitytoken"
                  element={<CompanyAdminSignupForm />}
              />
              <Route
                  path="/orguser/signup/:securitytoken"
                  element={<CompanyUserSignupForm />}
              />
              <Route
                  path="/resetpassword/:securitytoken"
                  element={<ResetPasswordForm />}
              />
              <Route path="/forgotpassword" element={<ForgotPasswordForm />} />
              <Route path="/forgotUsername" element={<ForgotPasswordForm />} />
              <Route path="/" element={<LogIn setToken={props.setToken} />} />
              <Route path="/ui" element={<LogIn setToken={props.setToken} />} />
              <Route
                  path="/Login"
                  element={<LogIn setToken={props.setToken} />}
              />
              <Route
                  path="/lawbotica-free-trial"
                  element={<SetUpTrailAccount />}
              />
              <Route
                  path="/link-lawbotica-free-trial"
                  element={<LawBoticaFreeTrialNavigation />}
              />
              <Route
                  path="/user/verifyaccount/:user_id"
                  element={<VerifyTrialAccountComponent />}
              />
              <Route path="/unauthorized" element={<UnauthorizedError />} />
              <Route
                  path="/SignUp"
                  element={<OrgRegistration setToken={props.setToken} />}
              />
              <Route path="/trial-help" element={<HelpTrialPage />} />

              <Route path="*" element={<LogIn setToken={props.setToken} />} />
          </Routes>
      </Fragment>
  );
}

export default UserNavigation;
