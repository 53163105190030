// src/redux/store.js
import {
    legacy_createStore as createStore,
    combineReducers,
} from "redux";
import { reviewReducer } from "./Reducers/reviewReducer";
import { summaryReducer } from "./Reducers/summarizeReducer";
import { chatWithDocReducer } from "./Reducers/chatWithDocReducer";
import { caseAnalysisReducer } from "./Reducers/caseAnalysisReducer";
import { generateDocReducer } from "./Reducers/generateDocReducer";
import { chatWithInternalDocsReducer } from "./Reducers/chatWithInternalDocsReducer";
import { dashBoardReducer } from "./Reducers/dashBoardReducer";
import { organizationReducer } from "./Reducers/organizationReducer";
import { logInReducer } from "./Reducers/logInReducer";
import { errorReducer } from "./Reducers/errorReducer";
const rootReducer = combineReducers({
    review: reviewReducer,
    summary: summaryReducer,
    caseAnalysis: caseAnalysisReducer,
    chatWithDoc: chatWithDocReducer,
    chatWithInternalDocs: chatWithInternalDocsReducer,
    generateDoc: generateDocReducer,
    dashBoard: dashBoardReducer,
    organization: organizationReducer,
    login: logInReducer,
    error:errorReducer
    // Add other reducers here
});

const store = createStore(rootReducer); //

export default store;
