export function addSpacingAroundHeaders() {
    return (tree) => {
        const newChildren = [];
        tree.children.forEach((node, index) => {
          
            switch (node.type) {
                case "heading":
                    // Add spacing before the heading
                    if (index !== 0) {
                        newChildren.push({ type: "paragraph", children: [] });
                    }

                    // Add the actual heading
                    newChildren.push(node);

                    // Add spacing after the heading
                    newChildren.push({ type: "paragraph", children: [] });
                    break;
                case "list":
                    // Add spacing before the heading
                    if (index !== 0) {
                        newChildren.push({ type: "paragraph", children: [] });
                    }

                    // Add the actual heading
                    newChildren.push(node);

                    // Add spacing after the heading
                    newChildren.push({ type: "paragraph", children: [] });
                    break;
                default:
                    newChildren.push(node);
                    break;
            }
         
        });
        tree.children = newChildren;
    };
}

