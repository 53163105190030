import axios from "axios";
import { updateErrorState, updateLogInState } from "../../redux/actions";
import store from "../../redux/store";
import { jwtDecode } from "jwt-decode";

export const baseURL = "/";

let isRefreshing = false;
let pendingRequests = [];
const getInstance = (accessToken, isHistory = false, nav) => {
  let instance = undefined;

  if (isHistory)
    instance = axios.create({
      baseURL: baseURL,
      exposeHeaders: "x-document-type, x-is-hand-written",
    });
  else
    instance = axios.create({
      baseURL: baseURL,
    });

  const JWT_HEADER_NAME = "jwtauthorization";

  const processPendingRequests = (newToken) => {
    pendingRequests.forEach((callback) => callback(newToken));
    pendingRequests = [];
  };

  const refreshToken = async (data, config) => {
    try {
      let axiosInstance = await axios.create({
        baseURL: baseURL,
      });
      data["refresh_token"] = localStorage.getItem("refresh_token");
      
      const response = await axiosInstance.post(`/refresh-token`, data);
      
      if (response) {
        
        data["refresh_token"] = response?.data?.refresh_token;
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;
        const currentState = store.getState().login.logInState;
        config.headers[JWT_HEADER_NAME] = `Bearer ${newAccessToken}`;
        accessToken = newAccessToken;

        let user_id = localStorage.getItem("user_id");
        if (currentState?.user_id && user_id ) {
          if(newAccessToken && newRefreshToken){
            localStorage.setItem("token", newAccessToken);
            localStorage.setItem("refresh_token", newRefreshToken)
          }
;
          store.dispatch(
            updateLogInState({
              ...currentState,
              token: newAccessToken,
            })
          );
        }
        isRefreshing = false;
        processPendingRequests(newAccessToken);
      } else {
        
      }

      return response?.data?.access_token;
    } catch (error) {
      
      console.error("Failed to refresh token:", error);
      return Promise.reject(error);
    }
  };

  instance.interceptors.request.use(
    async (config) => {
      if (accessToken && accessToken != null) {
        if (accessToken !== localStorage.getItem("token")) {
          accessToken = localStorage.getItem("token");
        }
        
        let token_decoded = jwtDecode(accessToken);
        if (token_decoded) {
          let currentTimestamp = Math.floor(new Date().getTime() / 1000);
          if (token_decoded["exp"] - 30 <= currentTimestamp) {
            
            config.headers[JWT_HEADER_NAME] = `Bearer ${accessToken}`;
            if (!isRefreshing) {
              isRefreshing = true;
              const newToken = await refreshToken(token_decoded, config);
              if (newToken) {
                config.headers[JWT_HEADER_NAME] = `Bearer ${newToken}`;
                return config;
              }
            } else {
              return new Promise((resolve, reject) => {
                pendingRequests.push((newToken) => {
                  if (newToken) {
                    config.headers[JWT_HEADER_NAME] = `Bearer ${newToken}`;
                    resolve(config);
                  } else {
                    reject("Failed to refresh token");
                  }
                });
              });
            }
          } else if (token_decoded["exp"] > currentTimestamp) {
            
            config.headers[JWT_HEADER_NAME] = `Bearer ${accessToken}`;
            return config;
          } else {
            
            config.headers[JWT_HEADER_NAME] = `Bearer ${accessToken}`;
            return config;
          }
        }
      } else {
        return config;
      }
    },
    (error) => {}
  );

  instance.interceptors.response.use(
    (response) => {
      console.log("Response:", response.data);
      return response;
    },
    (error) => {
      if (error?.response?.status === 422 || error?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("organization");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("usertypeid");
        localStorage.removeItem("userType");
        localStorage.removeItem("org_id");
        window.location.href="/login"
       
      } else if (
        nav &&
        !window.location.pathname.includes("internal-server-error") &&
        !window.location.pathname.includes("error") &&
        !window.location.pathname.includes("unauthorized") &&
        !window.location.pathname.includes("not-found")
      ) {
        if (error?.response?.status === 500) {
          nav("/internal-server-error");
        } else if (error?.response?.status === 503) {
          nav("/error");
        } else if (error?.response?.status === 404) {
          store.dispatch(
            updateErrorState({
              hasError: true,
            })
          );
          if(localStorage.getItem('user_id')){
            nav("/not-found");
          }
          else{
            nav("/login")
          }
        }
      }

      // console.error("Response error:", error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export const Createaccesslog = (formDataForAccessLog, processtimeinsec) => {
  const token = localStorage.getItem("token");
  const axiosInstance = getInstance(token);
  formDataForAccessLog.append("timetaken", processtimeinsec);
  axiosInstance
    .post("/logs/logaccessdata", formDataForAccessLog, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 180000,
    })
    .then((response) => {
      console.log(response);
    });
};

export default getInstance;
