import { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import '../../../css/workspace.css';
import { CustomTable } from "../../SupportiveComponents/AgGridTemplates/CustomAgGrid";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { ToastAlert } from "../../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { FilledHeart, RegularHeart } from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";

const SharedWorkspaces = (props) => {
    const [userId] = useState(localStorage.getItem('user_id'))
    const [loading, SetLoading] = useState(false)
    const [sharedWorkSpaces, setSharedWorkSpaces] = useState([]);
    // const [value, setValue] = useContext(WorkspaceContext);


    useEffect(() => {
        if (parseInt(userId) && props.workspaces.length > 0) {
            setSharedWorkSpaces(props.workspaces.filter(x => x.created_by !== parseInt(userId) && x.users.filter(x => x.user_id === parseInt(userId)).length > 0))
            SetLoading(false)
        }

    }, [props.workspaces, userId])



    const FavoriteWorkspace = (data) => {
        // SetLoading(true)
        const axiosInstance = getAxiosInstance(props.token);
        axiosInstance.put('/workspace/favorite', { workspace_id: data.id }, { timeout: 180000 }).then((res) => {
            if (res.status === 200) {
                props.GetWorkspaces(true);
                if (res.data.isExist) {
                    ToastAlert("Removed from favorites successfully.")
                } else {
                    ToastAlert("Added to favorites successfully.")
                }
            }
            // SetLoading(false)
        })
    }

    const nav = useNavigate();

    const RedirectToWorkspaces = (data) => {
        // setValue({
        //     ...value, workspace_id: data.id, workspace_item_id: 0, isSharedWorkspace: data.users.length > 1 ? true : false,
        //     workspace_name: data.name, data: data, users: props.users
        // });
        // props.closePopUp()
        nav('/Workspaces', {
            state: {
                workspace_id: data.id, workspace_name: data.name, data: data,
                isSharedWorkspace: data.users.length > 1 ? true : false, activeTab: props.activeTab,
                allWorkSpaces:props?.workspaces
            }
        })
    }

    return <Fragment>
        <div className="ag-theme-quartz" style={{ height: 600, width: '98%', minHeight: 300, maxHeight: 600 }}>
            {loading ? <LoadingSpinner></LoadingSpinner> :
                <CustomTable
                    defaultcoldefs={{
                        flex: 1,
                        resizable: true,
                        editable: false,
                        autoHeight: true,
                    }}
                    columndefs={[
                        {
                            headerName: 'Workspace Name',
                            field: 'name',
                            cellRenderer: (params) => {
                                return <div className="text-link"
                                    onClick={() => RedirectToWorkspaces(params.data)}
                                >{params.data.name}</div>
                            },
                            sortable: true,
                            resizable: true,
                            filter: true,
                            tooltipField: "name",
                            headerTooltip: "Workspace Name"
                        },
                        {
                            sortable: true,
                            filter: true,
                            resizable: true,
                            field: "names",
                            // cellRenderer: (params) => { return getNames(params.data) },
                            headerName: "Shared with",
                            tooltipField: "names",
                            headerTooltip: "Shared with"
                        },
                        {
                            field: 'convertedTime',
                            sortable: true,
                            filter: true,
                            // cellRenderer: (params) => { 
                            //      return convertUtcToEst(params.data.last_modified_at) },
                            headerName: "Last Modified On",
                            resizable: true,
                            tooltipField: "convertedTime",
                            headerTooltip: "Last Modified On"
                        },
                        {
                            sortable: true,
                            filter: true,
                            field: 'modified_by_name',
                            // cellRenderer: (params) => { return params.data?.users?.find(x => x.user_id === params.data.last_modified_by)?.full_name },
                            headerName: "Last Modified By",
                            resizable: true,
                            tooltipField: "modified_by_name",
                            headerTooltip: "Last Modified By"
                        },
                        {
                            // field: "id",
                            headerName: "Favorite",
                            cellRenderer: (params) => {
                                return <div onClick={() => FavoriteWorkspace(params.data)}>
                                    {params.data.favorites.filter(x => x.user_id === parseInt(userId)).length > 0 ? <FilledHeart></FilledHeart> :
                                        <RegularHeart title="ADD TO FAVORITES"></RegularHeart>}
                                </div>
                            },
                            resizable: true,
                            // tooltipField:"names",
                            headerTooltip: "Favorite"
                        },
                        {
                            sortable: true,
                            filter: true,
                            field: 'created_by_name',
                            // cellRenderer: (params) => { return params.data?.users?.find(x => x.user_id === params.data.created_by)?.full_name },
                            headerName: "Owner",
                            tooltipField: "created_by_name",
                            headerTooltip: "Owner"
                        },

                    ]}
                    rowdata={sharedWorkSpaces}
                    activeTab={"01"}
                    pageSize={20}
                    loading={loading}
                ></CustomTable>
            }
        </div>
        <Toaster />
    </Fragment>
}
export default SharedWorkspaces;
