import { Fragment, useEffect, useState } from "react"
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import '../../../css/workspace.css'
// import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import getAxiosInstance from '../AxiosRequest.jsx'
import { ToastAlert } from "../CustomAlerts/ToastAlert.jsx";
import LoadingSpinner from "../LoadingSpinner.jsx";

const ShareWorkSpacePopUp = (props) => {
    const [state, setState] = useState({ selectedUser: {}, selectedValue: [] });
    const [users, setUsers] = useState({ all: [], remaining: [] });
    const [token] = useState(localStorage.getItem("token"));
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const handleValidation = Yup.object().shape({
        workSpace: Yup.string().required("WorkSpace is required."),
    });

    const axiosInstance = getAxiosInstance(token);
    useEffect(() => {
        var orgUsers = [];
        var userId = localStorage.getItem("user_id");
        // var Options = props.users.map((element) => ({
        //     label: element.full_name,
        //     name: element.user_name,
        //     user_id: element.user_id,
        // }));
        if (props?.users?.length > 0) {
            props?.users.forEach((i, j) => {
                if (i.user_id !== parseInt(userId) && props.data.users?.filter((x) => x.user_id === i.user_id)?.length === 0) {
                    orgUsers.push(i);
                }
            });
            
            setUsers({ all: orgUsers, remaining: orgUsers });
            // setState({...state, allUsers:orgUsers})
        }
    }, [props.users, props.data.users]);

    // const handleChange = (selectedOptions, removedValue) => {
    //     if (removedValue.action === "select-option") {
    //         var allUsers = users.all;
    //         setUsers(
    //             allUsers.filter((x) => x.user_id !== selectedOptions.user_id)
    //         );
    //         setState({ ...state, selectedUser: selectedOptions });
    //     }
    // };

    const handleShare = () => {
        try {
            setLoading(true);
            setLoadingText(`Sharing workspace in progress.`);

            state?.selectedValue?.length > 0 && state.selectedValue.forEach((element, index) => {
                var obj = {
                    to_user_id: element.user_id,
                    workspace_name: props.data.name,
                    workspace_id: props.data.id,
                    perm_type: "editor",
                };
                axiosInstance.post("/workspace/share", JSON.stringify(obj), { headers: { "Content-Type": "application/json" }, timeout: 180000, })
                    .then((res) => {
                        if (res.status === 200 && index===state.selectedValue.length-1) {
                            ToastAlert("workspace shared successfully");
                            props.closepopup(true,state?.selectedValue);
                            setLoading(false);
                        }
                    });
            })

        } catch (exception) {
            setLoading(false);
        }
    };

    const onSelect = (selectedList, selectedItem) => {
        setState({ ...state, selectedValue: selectedList })
    }

    const onRemove = (selectedList, removedItem) => {
        setState({ ...state, selectedValue: selectedList })
    }

    return <Fragment>
        <div
            id="popup-overlay"
            className={`custom-popup-overlay view-fav-popup-overlay  show
            }`}
            onClick={() => props.closepopup()}
            style={{ height: "100%", width: "100%" }}
        >
            <div
                className={`custom-popup-content create-new-popup share-ws-ht`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="custom-popup-header">
                    <h2>Share Workspace:</h2>
                    <h2>
                        <span
                            className="close-btn"
                            onClick={() => props.closepopup()}
                            title="Close"
                        >
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                alt="close"
                            />
                        </span>
                    </h2>
                </div>
                <div className="custom-popup-body">
                    {loading && <LoadingSpinner text={loadingText} />}
                    <Formik
                        initialValues={{
                            workSpace: props.workSpace
                                ? props.workSpace
                                : "",
                        }}
                        //  onSubmit={(values) => handleSubmit(values)}
                        validationSchema={handleValidation}
                        
                    >
                        {({ errors, values, setFieldValue }) => {
                            return (
                                <Form >
                                    <div style={{ marginLeft: "3%", marginRight: "3%", height:'260px'}}>
                                    <div style={{ display: "flex",}} className="mt-3 align  bg-none" >
                                            <label className="strong"> Name: </label>
                                          <div className="strong">{props.data.name}</div>
                                        </div>
                                        <div style={{ display: "flex"}} className="mt-3 align  bg-none" >
                                            <label  className="strong"> Share: </label>
                                            <Multiselect
                                                className=""
                                                options={users.remaining} // Options to display in the dropdown
                                                selectedValues={state.selectedValue} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="full_name" // Property name to display in the dropdown options
                                                showCheckbox={true}
                                                style={{ width: '100% !important' }}
                                                placeholder="Select users"
                                            />
                                        </div>
                                    </div>
                                    <div className={`button-container margin-bottom-2`}
                                        style={{ display: "flex", justifyContent: "center" }}>
                                        <button title={"Share"} className={`continue ${state?.selectedValue.length===0?"disable":""}`} onClick={() => handleShare()} disabled={state?.selectedValue?.length===0}>
                                            Share
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>

}
export default ShareWorkSpacePopUp;