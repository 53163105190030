class HelperMessages {
  static ACCOUNT_CREATED_SUCCESSFULLY =
    "Your account has been created successfully! Please check your email for a link to set up your password and complete the account setup process.";
  
  static ACCOUNT_CREATED_SUCCESSFULLY_FOR_ORG_USER = ""
  static SOMETHING_WENT_WRONG_PLEASE_CONTACT_ADMIN =
    "Something went wrong! please contact admin";
  static PROMPT_PLACEHOLDER =
    "Ask a question about the document or use the ready-made prompts available toward the top right of this box.";
  static PASSWORD_HAS_BEEN_SET =
    "Your account has been successfully created and your password has been set! You can now log in and start using your account.";
  static DOC_TYPE_ALREADY_EXIST = "Document type already exist";
  static LOG_STATUS_SUCCESS = "SUCCESS"
  static LOG_STATUS_FAILED = "FAILED"
}

export default HelperMessages;
