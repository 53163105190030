import { useFormik } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import HelperMessages from "../../../Helpers/HelperMessages";
import { TrailAccountSchema } from "../../../Schema/UserFormsSchema";
import useTheme from "../../../contexts/theme";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import { OptionalFieldIndicator } from "../../SupportiveComponents/TrialAccountHelpers/VerifyTrialAccountComponent";
import {
  FormStatusMessage,
  LoggedInFormStatusMessage,
  TrialFormStatusMessage,
} from "./FormsHelper";
import {
  HidePassword,
  ShowPassword,
} from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart";

/**Validation Schema For a user account setup for admin of an organization */
const ValidationSchemaCompanySignUp = Yup.object({
  UserName: Yup.string()
    .required("User name is required")
    .matches(/^\S*$/, "User name should not contain spaces"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password should contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
});

/**Validation Schema for Reset password screen form
 */
const ValidationSchemaResetPassword = Yup.object({
  password_new: Yup.string()
    .required("Password is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password should contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
  confirmpassword: Yup.string().when("password", (password_new, field) => {
    if (password_new) {
      return field
        .required("The passwords do not match")
        .oneOf([Yup.ref("password_new")], "The passwords do not match");
    }
  }),
});

/**Validation Schema For Forgot Password screen form
 */
const ValidationSchemaForgotPassword = Yup.object({
  email: Yup.string()
    .required("Email address is required")
    .email("Invalid email address"),
});

/**Validation Schema For Adding a User by Company Admin
 */
const ValidationSchemaAddUser = Yup.object({
  userFullName: Yup.string().required("Full name is required"),
  userEmail: Yup.string()
    .required("Please enter your business email")
    .email("Invalid email address")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Invalid email address"
    )
    .test(
      "free-domain-email-test",
      "Please provide a business email",
      async function (value) {
        // Check user existence asynchronously
        if (value) {
          let testdomainval = value.split("@")[1];
          const free_email_domains = [
            "gmail.com",
            "yahoo.com",
            "hotmail.com",
            "outlook.com",
          ];
          return !free_email_domains.includes(testdomainval);
        } else {
          return true; // Skip check if no value provided
        }
      }
    ),
});

export const CompanyUserSignupForm = () => {
  const params = useParams();
  const [companyAdminData, setCompanyAdminData] = useState({});
  const [statuscode, setStatus] = useState(0);
  const [status_message, setStatusMessage] = useState("");

  const SubmitForm = () => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();
    formData.append("org_id", companyAdminData["organization_id"]);
    formData.append("organization_name", companyAdminData["organization_name"]);
    formData.append("organization_email", companyAdminData["email"]);
    formData.append("user_name", formik.values.UserName);
    formData.append("password", formik.values.password);

    customAxios
      .post("/admin/createUseraccountForOrganization", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        console.log(response.data, "test data");
        if (response.status === 200 && response.data.length > 0) {
          switch (response.data[0]["status_code"]) {
            case 201:
              setStatus(201);
              setStatusMessage(HelperMessages.PASSWORD_HAS_BEEN_SET);
              break;
            case 403:
              //setStatus(403);
              setStatusMessage("user already exists");
              break;
            case 500:
            default:
              //setStatus(500);
              setStatusMessage("Something went wrong! please contact admin");
              break;
          }
        } else {
          //setStatus(500);
          setStatusMessage("Something went wrong! please contact admin");
        }
      })
      .catch((error) => {
        console.log("Error occurred ", error);
      });
  };

  useEffect(() => {
    const FetchOrgInfoFromToken = (token) => {
      let formData = new FormData();
      formData.append("access_token", token);
      const axiosInstance = getAxiosInstance();
      axiosInstance
        .post("/admin/getOrganizationInfoFromToken", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          crossDomain: true,
          timeout: 300000,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setCompanyAdminData(response.data);
          }
        })
        .catch((error) => {});
    };
    FetchOrgInfoFromToken(params.securitytoken);
  }, [params.securitytoken, setCompanyAdminData]);
  const formik = useFormik({
    initialValues: {
      UserName: "",
      password: "",
    },
    validationSchema: ValidationSchemaCompanySignUp,
    onSubmit: (values) => {
      console.log(companyAdminData, " Test companyAdminData");
      SubmitForm();
    },
  });
  return (
    <Fragment>
      {statuscode === 0 ? (
        <div className="regBody">
          <div className="header">
            <h3>Complete your Registration</h3>
          </div>

          <div className="registrationsec">
            <h3>
              {Object.keys(companyAdminData).length > 0
                ? companyAdminData.hasOwnProperty("organization_name")
                  ? companyAdminData["organization_name"]
                  : ""
                : ""}{" "}
            </h3>
            <br />
            <h4>
              {Object.keys(companyAdminData).length > 0
                ? companyAdminData.hasOwnProperty("user_full_name")
                  ? companyAdminData["user_full_name"] + ","
                  : ""
                : ""}{" "}
              please complete your registration process
            </h4>
            <br />
            <form onSubmit={formik.handleSubmit}>
              <div className="logInfields">
                <label htmlFor="UserName">Username</label>
                <input
                  id="UserName"
                  name="UserName"
                  type="text"
                  className="inputfield"
                  placeholder="aUser"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.UserName}
                />
                {formik.errors.UserName &&
                (formik.touched.UserName || formik.isSubmitting) ? (
                  <span className="validationError">
                    {formik.errors.UserName}
                  </span>
                ) : null}
              </div>
              <div className="logInfields">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="*******"
                  className="inputfield"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password &&
                (formik.touched.password || formik.isSubmitting) ? (
                  <span className="validationError">
                    {formik.errors.password}
                  </span>
                ) : null}
              </div>
              <div className="captcha">
                <button className="continue" type="submit">
                  Complete Registration
                </button>
              </div>
              <div className="captcha">
                {status_message.length > 0 ? (
                  <>
                    <span className="validationError">{status_message}</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
      {statuscode === 201 ? (
        <FormStatusMessage
          heading="Complete your Registration"
          message={status_message}
        ></FormStatusMessage>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

//1. company admin account creation page - verified
const CompanyAdminSignupForm = () => {
  const params = useParams();
  const [companyAdminData, setCompanyAdminData] = useState({});
  const [statuscode, setStatus] = useState(0);
  const [status_message, setStatusMessage] = useState("");

  const SubmitForm = () => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();
    formData.append("org_id", companyAdminData["organization_id"]);
    formData.append("organization_name", companyAdminData["organization_name"]);
    formData.append("organization_email", companyAdminData["email"]);
    formData.append("user_name", formik.values.UserName);
    formData.append("password", formik.values.password);

    customAxios
      .post("/admin/createadminUserForOrganization", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          switch (response.data[0]["status_code"]) {
            case 201:
              setStatus(201);
              setStatusMessage(HelperMessages.PASSWORD_HAS_BEEN_SET);
              break;
            case 403:
              setStatus(403);
              setStatusMessage("user already exists");
              break;
            case 500:
            default:
              setStatus(500);
              setStatusMessage(
                HelperMessages.SOMETHING_WENT_WRONG_PLEASE_CONTACT_ADMIN
              );
              break;
          }
        } else {
          setStatus(500);
          setStatusMessage(
            HelperMessages.SOMETHING_WENT_WRONG_PLEASE_CONTACT_ADMIN
          );
        }
      })
      .catch((error) => {
        console.log("Error occurred ", error);
      });
  };

  useEffect(() => {
    const FetchOrgInfoFromToken = (token) => {
      let formData = new FormData();
      formData.append("access_token", token);
      const axiosInstance = getAxiosInstance();
      axiosInstance
        .post("/admin/getOrganizationInfoFromToken", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          crossDomain: true,
          timeout: 300000,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setCompanyAdminData(response.data);
          }
        })
        .catch((error) => {});
    };
    FetchOrgInfoFromToken(params.securitytoken);
  }, [params.securitytoken, setCompanyAdminData]);
  const formik = useFormik({
    initialValues: {
      UserName: "",
      password: "",
    },
    validationSchema: ValidationSchemaCompanySignUp,
    onSubmit: (values) => {
      SubmitForm();
    },
  });
  return (
    <Fragment>
      {statuscode === 0 ? (
        <div className="regBody">
          <div className="header">
            <h3>Complete your Registration</h3>
          </div>

          <div className="registrationsec">
            <h3>
              {Object.keys(companyAdminData).length > 0
                ? companyAdminData.hasOwnProperty("organization_name")
                  ? companyAdminData["organization_name"]
                  : ""
                : ""}{" "}
            </h3>
            <br />
            <h4>
              {Object.keys(companyAdminData).length > 0
                ? companyAdminData.hasOwnProperty("company_admin_name")
                  ? companyAdminData["company_admin_name"] + ","
                  : ""
                : ""}{" "}
              please complete your registration process
            </h4>
            <br />
            <form onSubmit={formik.handleSubmit}>
              <div className="logInfields">
                <label htmlFor="UserName">Username</label>
                <input
                  id="UserName"
                  name="UserName"
                  type="text"
                  className="inputfield"
                  placeholder="aUser"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.UserName}
                />
                {formik.errors.UserName &&
                (formik.touched.UserName || formik.isSubmitting) ? (
                  <span className="validationError">
                    {formik.errors.UserName}
                  </span>
                ) : null}
              </div>
              <div className="logInfields">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="*******"
                  className="inputfield"
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password &&
                (formik.touched.password || formik.isSubmitting) ? (
                  <span className="validationError">
                    {formik.errors.password}
                  </span>
                ) : null}
              </div>
              <div className="captcha">
                <button className="continue" type="submit">
                  Complete Registration
                </button>
              </div>
              <div className="captcha">
                {status_message.length > 0 ? (
                  <>
                    <span className="validationError">{status_message}</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}

      {statuscode > 0 ? (
        <FormStatusMessage
          heading="Complete your Registration"
          message={status_message}
        ></FormStatusMessage>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

/**Company Admin Signup Form End */

//2.Reset password page
export const ResetPasswordForm = () => {
  const params = useParams();
  const [statuscode, setStatus] = useState(0);
  const [status_message, setStatusMessage] = useState("");

  const [user_data, setUserData] = useState({});

  /**This function submits token and new password */
  const SubmitForm = () => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();
    formData.append("password_new", formik.values.password_new);
    formData.append("secret_token", formik.values.secret_token);
    customAxios
      .post("/resetpassword", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          if (response.data[0]["status"] === "updated") {
            setStatus(1);
            setStatusMessage("Password has been updated successfully!");
          } else {
            setStatus(2);
            setStatusMessage("Something went wrong, please try after sometime");
          }
        } else {
          setStatus(2);
          setStatusMessage("Something went wrong, please try after sometime");
        }
        console.log(response, "reset password data");
      })
      .catch((error) => {
        console.log("Error occurred ", error);
      });
  };

  useEffect(() => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();
    formData.append("secret_token", params.securitytoken);
    customAxios
      .post("/verifyresetpasswordtoken", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        console.log(response, "reset password data");
        setUserData(response.data);
      })
      .catch((error) => {
        console.log("Error occurred ", error);
      });
  }, [params.securitytoken, setUserData]);

  const formik = useFormik({
    initialValues: {
      password_new: "",
      confirmpassword: "",
      secret_token: params.securitytoken,
    },
    validationSchema: ValidationSchemaResetPassword,
    onSubmit: (values) => {
      console.log("values", values);
      SubmitForm();
    },
  });
  return (
    <Fragment>
      {statuscode === 0 ? (
        <div className="regBody">
          <div className="header">
            <h3>Password reset</h3>
          </div>
          <div className="registrationsec login_form">
            <>
              <h2>
                {Object.keys(user_data).length > 0
                  ? user_data.hasOwnProperty("email")
                    ? `Hello, ${user_data["email"]}`
                    : ""
                  : ""}
              </h2>
              <br />
              <form onSubmit={formik.handleSubmit}>
                <div className="logInfields">
                  <label htmlFor="password_new">New Password</label>
                  <input
                    id="password_new"
                    name="password_new"
                    type="password"
                    className="inputfield"
                    placeholder="********"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.password_new}
                  />
                  {formik.errors.password_new ? (
                    <span className="validationError">
                      {formik.errors.password_new}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields">
                  <label htmlFor="confirmpassword">Confirm Password</label>
                  <input
                    id="confirmpassword"
                    name="confirmpassword"
                    type="password"
                    placeholder="********"
                    className="inputfield"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.confirmpassword}
                  />
                  {formik.errors.confirmpassword ? (
                    <span className="validationError">
                      {formik.errors.confirmpassword}
                    </span>
                  ) : null}
                </div>
                <div className="captcha">
                  <button className="continue" type="submit">
                    Reset Password
                  </button>
                  <div>
                    <a title="Login" href="/LogIn">
                      Go Back
                    </a>
                  </div>
                </div>
              </form>
            </>
          </div>
        </div>
      ) : (
        <FormStatusMessage
          heading="Password reset"
          message={status_message}
        ></FormStatusMessage>
      )}
    </Fragment>
  );
};

//Forgot password page - Verified
export const ForgotPasswordForm = () => {
  const { themeMode } = useTheme();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [statuscode, setStatus] = useState(0);
  const [status_message, setStatusMessage] = useState("");
  const recaptchaVerify = (e) => {
    setRecaptchaToken(e);
    setIsCaptchaVerified(true);
    // handleRecaptchaToken(e, setIsCaptchaVerified);
  };
  const recaptchaRef = useRef(null);

  const location = useLocation();
  const state = location.state; // Access the state here
  const SubmitForm = (resetFormCallback) => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();
    formData.append("user_email", formik.values.email);
    formData.append("token", recaptchaToken);

    if (state?.page === "username") {
      customAxios
        .post("/forgotusername", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 300000,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setStatus(1);
            setStatusMessage(response.data.message);
            resetFormCallback();
            console.log(formik);
          }
        })
        .catch((error) => {
          console.log("Error occurred ", error);
        });
    } else {
      customAxios
        .post("/forgotpassword", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 300000,
        })
        .then((response) => {
          console.log(response, "forgot password response");
          if (response.data.status === 200) {
            console.log(response.data.message);
            setStatus(1);
            setStatusMessage(response.data.message);
            resetFormCallback();
            console.log(formik);
          }
        })
        .catch((error) => {
          console.log("Error occurred ", error);
          recaptchaRef?.current?.reset();
          let status = error?.response?.status;
          let error_text = "Something went wrong, please try after some time.";
          if (status === 415) {
            error_text = error?.response?.data?.msg;
          }
          Swal.fire({
            text: error_text,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ValidationSchemaForgotPassword,
    onSubmit: (values, { resetForm }) => {
      //request for a url through which user can reset password
      SubmitForm(resetForm);
    },
  });
  return (
    <Fragment>
      {statuscode === 0 ? (
        <div className="regBody">
          <div className="header">
          <h3>{state?.page === "username" ?"Request Username":"Password reset"}</h3>
          </div>
          <div className="registrationsec login_form">
            <>
              <form onSubmit={formik.handleSubmit}>
                <div className="logInfields">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="inputfield"
                    placeholder="firstname@domain.id"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email ? (
                    <span className="validationError">
                      {formik.errors.email}
                    </span>
                  ) : null}
                </div>

                {
                  <div className="captcha">
                    {
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={recaptchaVerify}
                        theme={themeMode}
                        onExpired={() => setIsCaptchaVerified(false)}
                        size="normal"
                        ref={recaptchaRef}
                      />
                    }
                  </div>
                }
                <div className="captcha">
                  <button
                    className="continue"
                    type="submit"
                    disabled={!isCaptchaVerified}
                  >
                    {state?.page === "username" ? "Send Username" : "Send Reset Instructions"}
                  </button>
                </div>
                <div className="captcha">
                  <a title="Login" href="/LogIn">
                    Go Back
                  </a>
                </div>
              </form>
            </>
          </div>
        </div>
      ) : (
        <FormStatusMessage
          heading={state?.page === "username" ?"Request Username":"Password reset"}
          message={status_message}
        ></FormStatusMessage>
      )}
    </Fragment>
  );
};

//Add Organization User
export const AddUserForm = (props) => {
  const [statuscode, setStatusCode] = useState(0);
  const [status_message, setStatusMessage] = useState("");

  const SubmitForm = () => {
    const customAxios = getAxiosInstance(props.token, false, props?.history);
    const formData = new FormData();

    formData.append("user_fullname", formik.values.userFullName);
    formData.append("user_email", formik.values.userEmail);

    customAxios
      .post("/admin/createUserForOrganization", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        console.log(response, "Response");
        if (response.status === 200 && response.data.length > 0) {
          switch (response.data[0]["statuscode"]) {
            case 403:
              //setStatusCode(403);
              setStatusMessage("user account already exists");
              break;
            case 415:
              setStatusMessage(
                "you have reached the maximum number of users allowed"
              );
              break;
            case 409:
              setStatusMessage("user account creation request already sent");
              break;
            case 201:
              setStatusCode(201);
              setStatusMessage(
                `Account successfully created for ${formik.values.userFullName}!\nAn email has been sent to ${formik.values.userFullName} at ${formik.values.userEmail} to complete the registration process`
              );
              break;

            default:
              setStatusMessage(
                "Something went wrong while processing your request"
              );
              break;
          }
        } else {
          setStatusMessage(
            "Something went wrong while processing your request"
          );
        }
      })
      .catch((error) => {
        console.log("Error occurred ", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      userFullName: "",
      userEmail: "",
    },
    validationSchema: ValidationSchemaAddUser,
    onSubmit: (values) => {
      console.log(values);

      //Adding a user
      SubmitForm();
    },
  });
  return (
    <Fragment>
      {statuscode === 0 ? (
        <div className="regBody">
          <div className="header">
            <h3>ADD A USER</h3>
          </div>
          <div className="registrationsec">
            <>
              <form onSubmit={formik.handleSubmit}>
                <div className="logInfields">
                  <label htmlFor="userFullName">Full name</label>
                  <input
                    id="userFullName"
                    name="userFullName"
                    type="text"
                    className="inputfield"
                    placeholder="aUser"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.userFullName}
                  />
                  {formik.errors.userFullName &&
                  (formik.touched.userFullName || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.userFullName}
                    </span>
                  ) : null}
                </div>

                <div className="logInfields">
                  <label htmlFor="userEmail">Email address</label>
                  <input
                    id="userEmail"
                    name="userEmail"
                    type="email"
                    className="inputfield"
                    placeholder="firstname@domain.tid"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.userEmail}
                  />
                  {formik.errors.userEmail &&
                  (formik.touched.userEmail || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.userEmail}
                    </span>
                  ) : null}
                </div>

                <div className="captcha">
                  <button className="continue" type="submit">
                    Create Account
                  </button>
                </div>
                <div className="captcha">
                  {status_message.length > 0 ? (
                    <>
                      <span className="validationError">{status_message}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            </>
          </div>
        </div>
      ) : (
        <></>
      )}
      {statuscode === 201 ? (
        <>
          <LoggedInFormStatusMessage
            message={status_message}
            heading={"Add a USER"}
          ></LoggedInFormStatusMessage>
        </>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

//Forgot password page - Verified
export const LawBoticaFreeTrialNavigation = () => {
  return (
    <Fragment>
      <div className="regBody">
        <div className="header">
          <h3>Navigate to Trial Account Page</h3>
        </div>
        <div className="registrationsec login_form">
          <>
            <div className="captcha">
              <p>
                Please click on the link below to get to the Trial Account page.
              </p>
            </div>

            <div className="captcha">
              <a title="Trial Account Signup" href="/lawbotica-free-trial">
                LawBotica Free Trial
              </a>
            </div>
          </>
        </div>
      </div>
    </Fragment>
  );
};

export const SetUpTrailAccount = (props) => {
  const phoneHandleOnChange = (value, data, event, formattedvalue) => {
    console.log("api key", process.env.REACT_APP_RECAPTCHA_SITE_KEY, value);
    formik.handleChange(event);
  };
  const [isReferrerCheckPassed, setReferrerCheck] = useState(true);
  useEffect(() => {
    const referrer = document.referrer;

    // Perform validation based on the referrer
    if (
      referrer.includes("lawbotica.ai") ||
      window.location.origin.includes("localhost")
    ) {
      setReferrerCheck(true);
    }
  }, []);
  const [status_code, setStatusCode] = useState(0);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const recaptchaRef = useRef();
  const { themeMode } = useTheme();
  const formik = useFormik({
    initialValues: {
      userFullName: "",
      bEmail: "",
      phoneNumber: "",
      jobTitle: "",
      companyName: "",
      userName: "",
      password_new: "",
      confirmpassword: "",
      termsAndConditions: false,
      captchaValue: "",
    },
    validationSchema: TrailAccountSchema,
    onSubmit: (values) => {
      console.log(values);
      SubmitForm();
      //Adding a user
    },
  });

  const SubmitForm = () => {
    const customAxios = getAxiosInstance();
    const formData = new FormData();

    formData.append("userFullName", formik.values.userFullName);
    formData.append("bEmail", formik.values.bEmail);
    formData.append("phoneNumber", formik.values.phoneNumber);
    formData.append("jobTitle", formik.values.jobTitle);
    formData.append("companyName", formik.values.companyName);
    formData.append("userName", formik.values.userName);
    formData.append("confirmpassword", formik.values.confirmpassword);
    formData.append("termsAndConditions", formik.values.termsAndConditions);
    formData.append("token", recaptchaToken);
    customAxios
      .post("/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      })
      .then((response) => {
        console.log(response, "Response");
        setStatusCode(response.status);
        if (response.status === 200 && response.data.length > 0) {
        } else {
        }
      })
      .catch((error) => {
        console.log("Error occurred ", error);
        recaptchaRef?.current?.reset();
        let error_text = "Something went wrong, please try after some time.";
        let status = error?.response?.status;
        if (status === 415) {
          error_text = error?.response?.data?.msg;
        }
        Swal.fire({
          text: error_text,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const recaptchaVerify = (e) => {
    setRecaptchaToken(e);
    setIsCaptchaVerified(true);
    // handleRecaptchaToken(e, setIsCaptchaVerified);
  };

  const asyncScriptOnLoad = () => {
    console.log("test");
  };

  const togglePasswordVisibility = (name) => {
    if (name === "password") {
      setShowPassword(!showPassword);
    }
    if (name === "cpassword") {
      setShowCPassword(!showCPassword);
    }
  };

  return (
    <>
      {isReferrerCheckPassed && status_code === 0 && (
        <div className="regBody">
          <div className="header">
            <h3>Setup your Trial Account</h3>
          </div>
          <div className="registrationsec trialform">
            <>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="logInfields">
                  <label htmlFor="userFullName">Full Name</label>
                  <input
                    id="userFullName"
                    name="userFullName"
                    type="text"
                    className={
                      formik.errors.userFullName
                        ? "error inputfield"
                        : "inputfield"
                    }
                    placeholder="John Doe"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.userFullName}
                  />
                  {formik.errors.userFullName &&
                  (formik.touched.userFullName || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.userFullName}
                    </span>
                  ) : null}
                </div>

                <div className="logInfields">
                  <label htmlFor="bEmail">Business Email</label>
                  <input
                    id="bEmail"
                    name="bEmail"
                    type="email"
                    className={
                      formik.errors.bEmail ? "error inputfield" : "inputfield"
                    }
                    placeholder="firstname@domain.tid"
                    autoComplete="off"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.bEmail}
                  />
                  {formik.errors.bEmail &&
                  (formik.touched.bEmail || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.bEmail}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields ">
                  <label htmlFor="phoneNumber">
                    Phone Number <OptionalFieldIndicator />
                  </label>
                  <PhoneInput
                    phone={formik.values.phoneNumber}
                    country={"us"}
                    id="phoneNumber"
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                      autoFocus: true,
                    }}
                    onlyCountries={["us", "ca", "in"]}
                    containerClass={
                      formik.errors.phoneNumber
                        ? "error inputfield"
                        : "inputfield"
                    }
                    placeholder="(###) - ### - ####"
                    value={formik.values.phoneNumber}
                    onChange={phoneHandleOnChange}
                    countryCodeEditable={false}
                  />
                </div>
                <div className="logInfields">
                  <label htmlFor="jobTitle">
                    Job Title <OptionalFieldIndicator />
                  </label>
                  <input
                    id="jobTitle"
                    name="jobTitle"
                    type="text"
                    className={
                      formik.errors.jobTitle ? "error inputfield" : "inputfield"
                    }
                    placeholder="Lawyer"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.jobTitle}
                  />
                  {formik.errors.jobTitle &&
                  (formik.touched.jobTitle || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.jobTitle}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    id="companyName"
                    name="companyName"
                    type="text"
                    className={
                      formik.errors.companyName
                        ? "error inputfield"
                        : "inputfield"
                    }
                    placeholder="KAVAYAH SOLUTIONS INC"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                  />
                  {formik.errors.companyName &&
                  (formik.touched.companyName || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.companyName}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields">
                  <label htmlFor="userName">User name</label>
                  <input
                    id="userName"
                    name="userName"
                    type="text"
                    className={
                      formik.errors.userName ? "error inputfield" : "inputfield"
                    }
                    placeholder="John_Doe"
                    autoComplete="off"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.userName}
                  />
                  {formik.errors.userName &&
                  (formik.touched.userName || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.userName}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields" style={{ position: "relative" }}>
                  <label htmlFor="password_new">Password</label>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="password_new"
                      name="password_new"
                      type={showPassword ? "text" : "password"}
                      className={
                        formik.errors.password_new
                          ? "error inputfield"
                          : "inputfield"
                      }
                      placeholder="********"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      value={formik.values.password_new}
                    />
                    {showPassword ? (
                      <HidePassword
                        onClick={() => togglePasswordVisibility("password")}
                      />
                    ) : (
                      <ShowPassword
                        onClick={() => togglePasswordVisibility("password")}
                      />
                    )}
                  </div>

                  {formik.errors.password_new &&
                  (formik.touched.password_new || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.password_new}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields" style={{ position: "relative" }}>
                  <label htmlFor="confirmpassword">Confirm Password</label>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="confirmpassword"
                      name="confirmpassword"
                      type={showCPassword ? "text" : "password"}
                      placeholder="********"
                      className={
                        formik.errors.confirmpassword
                          ? "error inputfield"
                          : "inputfield"
                      }
                      autoComplete="off"
                      onChange={formik.handleChange}
                      value={formik.values.confirmpassword}
                    />
                    {showCPassword ? (
                      <HidePassword
                        onClick={() => togglePasswordVisibility("cpassword")}
                      />
                    ) : (
                      <ShowPassword
                        onClick={() => togglePasswordVisibility("cpassword")}
                      />
                    )}
                  </div>

                  {formik.errors.confirmpassword &&
                  (formik.touched.confirmpassword || formik.isSubmitting) ? (
                    <span className="validationError">
                      {formik.errors.confirmpassword}
                    </span>
                  ) : null}
                </div>
                <div className="logInfields">
                  <input
                    id="termsAndConditions"
                    name="termsAndConditions"
                    type="checkbox"
                    className="inputfieldcheckbox"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.termsAndConditions}
                  />
                  I have read and understand that the documents I upload will
                  not be used for any training purposes by LawBotica.
                  {formik.errors.termsAndConditions &&
                  (formik.touched.termsAndConditions || formik.isSubmitting) ? (
                    <p>
                      <span className="validationError">
                        {formik.errors.termsAndConditions}
                      </span>
                    </p>
                  ) : null}
                </div>
                <div className="logInfields">
                  <ReCAPTCHA
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    theme={themeMode}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={recaptchaVerify}
                    asyncScriptOnLoad={asyncScriptOnLoad}
                  />
                </div>
                <div className="captcha">
                  <button
                    className="continue"
                    type="submit"
                    disabled={!isCaptchaVerified}
                  >
                    Create Account
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      )}
      {isReferrerCheckPassed && status_code === 201 && (
        <TrialFormStatusMessage
          heading="Thank you for registering!"
          message=" We have sent a verification email to
the address you provided. Please check
your inbox and if necessary, spam/junk
folders and follow the instructions to
complete your registration."
        />
      )}

      {isReferrerCheckPassed && status_code === 200 && (
        <TrialFormStatusMessage
          heading="USER Exists!"
          message="User Exists with provided details"
        />
      )}

      {isReferrerCheckPassed && ![200, 201, 0].includes(status_code) && (
        <TrialFormStatusMessage
          heading="Something Went wrong!"
          message="Couldn't process your request"
        />
      )}

      {!isReferrerCheckPassed && (
        <TrialFormStatusMessage
          heading="Something Went wrong!"
          message=" Sorry, this form is accesible to authorized users only."
        />
      )}
    </>
  );
};

export default CompanyAdminSignupForm;
