import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import useTheme from "../../../contexts/theme";
import { isMobile } from "../PureHelpers";

export const CustomTable = forwardRef(
  (
    {
      favdisplayval,
      rowdata,
      columndefs,
      defaultcoldefs,
      pageSize,
      noItemsMessg,
      onRowClicked,
      selectedFilter,
      fieldName,
    },
    ref
  ) => {
    const gridRef = useRef();

    const { themeMode } = useTheme();
    const { containerStyle } = useMemo(() => ({ width: "100%" }), []);
    const [rowData, setRowData] = useState(rowdata);
    const [columnDefss, setColumnDefs] = useState(columndefs);
    const [defaultcoldefss, setDefaultCOlDefs] = useState(defaultcoldefs);
    const [pageSizes, setPageSize] = useState(pageSize);
    const [noItemsMsg] = useState(noItemsMessg);
    const [selectedFilter_, setSelectedFilter] = useState(selectedFilter);
    const [fieldName_, setFieldName] = useState(fieldName);

    const [animateRowss] = useState(true);
    // const[dataLoading]=useState(loading)
    //This Hook Exposes Methods when using ref
    useImperativeHandle(ref, () => ({
      onFirstDataRendered,
    }));
    const onFirstDataRendered = useCallback(
      (params) => {
        if (!isMobile()) {
          gridRef.current.api.sizeColumnsToFit();
          if (!rowData) {
            gridRef.current.api.showNoRowsOverlay();
          }
        }
      },
      [gridRef, rowData]
    );
    useEffect(() => {
      try {
        onFirstDataRendered();
      } catch (error) {
        //empty
      }
    }, [onFirstDataRendered, favdisplayval]);

    useEffect(() => {
      setRowData(rowdata);
    }, [rowdata]);

    useEffect(() => {
      setColumnDefs(columndefs);
    }, [columndefs]);

    useEffect(() => {
      setDefaultCOlDefs(defaultcoldefs);
    }, [defaultcoldefs]);

    useEffect(() => {
      setPageSize(pageSize);
    }, [pageSize]);

    const autoSizeStrategy = useMemo(() => {
      return {
        type: "fitGridWidth",
        defaultMinWidth: 100,
      };
    }, []);
    useEffect(() => {
      setFieldName(fieldName);
      setSelectedFilter(selectedFilter);
      if(!selectedFilter || selectedFilter === ""){
        gridRef?.current?.api?.setFilterModel(null);
      }
    }, [fieldName, selectedFilter]);

    useEffect(() => {
      if (fieldName_ && selectedFilter_) {
        const filterInstance = gridRef?.current?.api?.getFilterInstance(fieldName_);
        filterInstance?.setModel({
          type: "contains",
          filter: selectedFilter_,
        });
        gridRef.current.api.onFilterChanged();
      }
    },[fieldName_, selectedFilter_]);

    return (
        <>
         
            <AgGridReact
                ref={gridRef}
                className={`${
                    themeMode === "light"
                        ? "ag-theme-quartz"
                        : "ag-theme-quartz-dark"
                } custom-ag-grid-height ${themeMode}`}
                rowStyle={containerStyle}
                rowData={rowData}
                columnDefs={columnDefss}
                defaultColDef={defaultcoldefss}
                animateRows={animateRowss}
                pagination={true}
                // pagination={ true}
                paginationPageSize={pageSizes ? pageSizes : 20}
                autoSizeStrategy={autoSizeStrategy}
                onGridReady={onFirstDataRendered}
                onFirstDataRendered={onFirstDataRendered}
                onRowClicked={onRowClicked}
                overlayNoRowsTemplate={
                    noItemsMsg
                        ? '<span aria-live="polite" aria-atomic="true";pos>' +
                          noItemsMsg +
                          "</span>"
                        : '<span aria-live="polite" aria-atomic="true";pos>No items to show</span>'
                }
            ></AgGridReact>
        </>
    );
  }
);
