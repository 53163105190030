import { useEffect, useState } from "react";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import {
    TrialFormStatusMessage,
    TrialFormStatusMessageJSX,
} from "../../MainComponents/Registration/FormsHelper";
export const VerifyTrialAccountComponent = () => {
    const [status_code, setStatusCode] = useState(0);
    const [isDataProcessing, setIsDataProcessing] = useState(true);


    useEffect(() => {
        let pathvaluesarr = window.location.pathname.split("/");
        let val =
            pathvaluesarr?.length > 0
                ? pathvaluesarr[pathvaluesarr?.length - 1]
                : 0;
        const axiosInstance = getAxiosInstance();
        axiosInstance
            .post("/user/verifyuseraccount/" + val, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                crossDomain: true,
                timeout: 300000,
            })
            .then((response) => {
                console.log(response, "test response");
                setStatusCode(response.status);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsDataProcessing(false);
            });
    }, []);

    return (
        <>
            {isDataProcessing && status_code === 0 && (
                <TrialFormStatusMessageJSX
                    heading={"Processing your request"}
                    message={`<p>We are verifying your request.</p>`}
                />
            )}
            {!isDataProcessing && status_code === 0 && (
                <TrialFormStatusMessage />
            )}
            {!isDataProcessing && status_code === 208 && (
                <TrialFormStatusMessageJSX
                    heading={"Verification Failed"}
                    message={`<p>We’re sorry, but we could not verify
your email through the link provided. This may have happened for several
reasons:</p> <ol className="margin_1em"><li>The verification link has expired.
<li> There may have been an error in
processing your request.</ol>`}
                />
            )}
            {!isDataProcessing && status_code === 200 && (
                <TrialFormStatusMessageJSX
                    heading={"Verification Successful"}
                    message={`<p>Congratulations! Your email address has been successfully verified, and  your trial account for [${window.location.origin}] is now active.</p>\n <p><b>Log In to Your Account:</b> <a href='/'>${window.location.origin}</a></p>`}
                />
            )}
            {!isDataProcessing && status_code === 500 && (
                <TrialFormStatusMessage />
            )}
        </>
    );
};

export const RequiredFieldIndicator = () => {
    return <span className="required_field">{` (required)`}</span>;
};

export const OptionalFieldIndicator = () => {
    return <span className="optional_field"> {` (optional)`}</span>;
};
