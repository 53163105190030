import React, { useEffect, useRef, useState } from 'react';
import Loader from 'rsuite/Loader';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/Loader/styles/index.css';
import { socket } from '../../../contexts/socket';
import store from '../../../redux/store';
const KebabMenu = ({getChats, id, chat, isOpen, toggleMenu,updateChat,workspace_name }) => {

  const[loading, setLoading]=useState(false)

  React.useEffect(() => {
    socket.on("workspace_chat_delete_response", (data)=>{
      if(data?.data?.id){
        getChats()
        toggleMenu(data?.data?.id)
        setLoading(false)
      }

      })
    return () => socket.off("workspace_chat_delete_response");
  }, [getChats,toggleMenu]);
    // useEffect(()=>{
      // socket.on("workspace_chat_delete", (data)=>{
      //   getChats()
      //   toggleMenu(data?.data.id)
      //   setLoading(false)
      // })
    // },[ getChats,toggleMenu])

    const deleteChat=(id)=>{
      setLoading(true)
      let data = {
        chat_id: id,
        chat_name: chat,
        workspace_name: workspace_name,
        user_name: store?.getState()?.login?.logInState?.user_name
      }
      socket.emit("workspace_chat_delete", data)
    }
    const menuRef = useRef(null)
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          toggleMenu();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [toggleMenu]);
  return (
    <div className="kebab-menu-container">
    {loading ?<Loader/>:<>
      <div className="kebab-icon" onClick={toggleMenu}>
        ⋮
      </div>
      {isOpen && (
        <div className="menu1" ref={menuRef}>
          <div className="menu-item1" onClick={()=>updateChat(id,chat)}>Rename</div>
          <div className="menu-item1" onClick={()=>deleteChat(id)}>Delete</div>
        </div>
      )}</>}
    </div>
  );
};

export default KebabMenu;
