import { createContext, useState, useEffect } from "react";
// Create the context
const ModuleContext = createContext();


export const ModuleProvider = ({ children }) => {
    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     useEffect(() => {
         const fetchData = async () => {

             try {
                 const response = await fetch("http://localhost:5005/globalmodules");
                 const result = await response.json();
                 setModules(result);

             } catch (error) {
                 setError(error);
             } finally {
                 setLoading(false);
             }
         };

         fetchData();
     }, []);
    
    return (
        <ModuleContext.Provider value={{ modules, loading, error }}>
            {children}
        </ModuleContext.Provider>
    );
}


