import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { renderToString } from "react-dom/server";
import { Fragment, useEffect, useState } from "react";
import { renderTruncatedText } from "../TruncateFileName";
import { handlePromptWithList } from "../PureHelpers";
import { Resizable } from "re-resizable";
import { docxfileTypes } from "../TruncateFileName";
import { renderAsync } from "docx-preview";
import ScrollableTabsButtonForce from "../CustomTabs/ScrollableTabs";
//import ScrollableTabsButtonForce from "../CustomTabs/ScrollableTabs";

export const CustomDocViewer = ({ opendocdate, name, mimetype, file }) => {
    const [docurl] = useState(URL.createObjectURL(file));
    const [docopendocdate] = useState(opendocdate);
    const [docname] = useState(name);
    const [docmimeType] = useState(mimetype);
    const [documents] = useState([
        {
            uri: docurl,
            fileType: docmimeType,
            fileName: docname,
        },
    ]);
   

    return (
        <>
            <ReactFileSwitch
                docs={documents}
                opendocdate={docopendocdate}
                name={docname}
                mimetype={docmimeType}
                file={file}
                url={docurl}
            />
        </>
    );
};
export const CustomMultiDOcViewer = ({ files }) => {
    const [filess, setfiles] = useState(files);
    const [updatedfiles, setUpdatedfiles] = useState(null);
    useEffect(() => {
        setfiles(files);
    }, [files]);

    useEffect(() => {
        let filesupdated = filess.map((val) => {
            let obj = { url: URL.createObjectURL(val), file: val };

            return obj;
        });
        setUpdatedfiles(filesupdated);

        return () => {
            filesupdated.map((val) => {
                return URL.revokeObjectURL(val?.url);
            });
        };
    }, [filess]);
    return (
        <>
            <ScrollableTabsButtonForce updatedfiles={updatedfiles} />
        </>
    );
};
export const ReactFileSwitch = ({
    mimetype,
    url,
    opendocdate,
    name,
    docs,
    file,
}) => {
    switch (mimetype) {
        case "application/pdf":
            return (
                <ReactAdobeViewer
                    url={url}
                    opendocdate={opendocdate}
                    name={name}
                />
            );
        case docxfileTypes[0]:
        case docxfileTypes[1]:
            return (
                <>
                    <ReactDocxPreview file={file}></ReactDocxPreview>
                </>
            );
        default:
            return (
                <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                />
            );
    }
};

export const ReactDocxPreview = ({ file }) => {
    const [file1] = useState(file);
    function onGetFile(file1) {
        //el.preventDefault();
        //console.log(el.target.files, "files");
        //var file = el.target.files[0];

        let options = {
            inWrapper: true,
            ignoreWidth: false,
            ignoreHeight: false,
            ignoreFonts: false,
            breakPages: true,
            debug: true,
            experimental: true,
            trimXmlDeclaration: true,
            renderHeaders: true,
            renderFooters: true,
            renderFootnotes: true,
            renderEndnotes: true,
            ignoreLastRenderedPageBreak: true,
        }; // https://github.com/VolodymyrBaydalka/docxjs#api
        renderAsync(
            file1,
            document.getElementById("docx_container"),
            null,
            options
        ).then((x) => console.log("docx: finished", x));
    }

    useEffect(() => {
        // renderDocx();
        onGetFile(file1);
    }, [file1]);

    return (
        file1 != null &&
        file1 !== undefined && (
            <>
                <div id="docx_container"></div>
            </>
        )
    );
};
export const ReactAdobeViewer = ({ url, opendocdate, name }) => {
    const [urlS] = useState(url);
    const [nameS] = useState(name);
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        let adobeDCView = new window.AdobeDC.View({
            clientId: process.env.REACT_APP_ADOBE_DOC_KEY,
            divId: "adobe-dc-view",
        });
        adobeDCView
            .previewFile({
                content: { location: { url: urlS } },
                metaData: { fileName: nameS },
            })
            .catch((error) => {
                console.error("Error in previewing PDF:", error);
                setCount(count + 1);
            });
    }, [urlS, nameS, count]);

    return (
        urlS?.length > 0 && (
            <>
                <div id="adobe-dc-view"></div>
            </>
        )
    );
};
export const CustomTabsComponent = ({
    blobFilesList,
    activeTab,
    toggleTab,
    handleUpdateBlobFilesStateCallback,
}) => {
    return (
        <>
            <div className="tabarea customtabarea_ ">
                <div className="tabsection">
                    <section className="accordion-tabs">
                        {blobFilesList && (
                            <CustomTabHelper
                                togglecallback={(togval) => toggleTab(togval)}
                                activeTab={activeTab}
                                blobFilesList={blobFilesList}
                                closecallback={(activeTab, blobFilesList) => {
                                    console.log(
                                        activeTab,
                                        blobFilesList,
                                        "passing to files"
                                    );
                                    handleUpdateBlobFilesStateCallback(
                                        blobFilesList,
                                        activeTab
                                    );
                                }}
                            />
                        )}
                    </section>
                </div>
            </div>
            <section className="accordion-content">
                {blobFilesList &&
                    blobFilesList.map((val, idx) => {
                        return (
                            <article
                                key={idx}
                                className={`accordion-item ${
                                    activeTab === `doc${idx}`
                                        ? "accordion-active"
                                        : ""
                                }`}
                            >
                                {blobFilesList?.length ? (
                                    <CustomDocViewer {...val} />
                                ) : (
                                    <></>
                                )}
                            </article>
                        );
                    })}
            </section>
        </>
    );
};

export const SvgIconButton = ({ isexpanded, togglecallback }) => {
    return (
        <>
            <button
                className={`svgBtn ${isexpanded ? "collapse" : "expand"}`}
                onClick={() => {
                    togglecallback(!isexpanded);
                }}
                title={isexpanded ? "Collapse" : "Expand"}
                type="button"
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="presentation"
                >
                    <path
                        d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                        fill="currentColor"
                        fillRule="evenodd"
                    ></path>
                </svg>
            </button>
        </>
    );
};
export const CustomExpandableDocComponent = ({
    isDocumentOpen,
    toggleDocumentVisibleStateCallback,
    customclass,
    childComponent,
    activedocname,
}) => {
    return (
        <Resizable
            className={`${customclass} ${
                isDocumentOpen
                    ? "expandable-border-left expandable-bar-box-shadow"
                    : "collapse"
            } `}
            handleClasses={{
                top: "pointer-events-none",
                bottom: "pointer-events-none",
                left: isDocumentOpen
                    ? document.documentElement.clientWidth <= 1024
                        ? "pointer-events-none"
                        : ""
                    : "pointer-events-none",
                topRight: "pointer-events-none",
                bottomRight: "pointer-events-none",
                bottomLeft: "pointer-events-none",
                topLeft: "pointer-events-none",
            }}
            defaultSize={{ width: "45%", height: "auto" }}
            key={"test1"}
        >
            <SvgIconButton
                isexpanded={isDocumentOpen}
                togglecallback={toggleDocumentVisibleStateCallback}
            />
            {childComponent}
            <p className=" active_side_doc_name">{activedocname}</p>
        </Resizable>
    );
};
export const CustomTabHelper = ({
    blobFilesList,
    togglecallback,
    activeTab,
    closecallback,
}) => {
    return (
        <Fragment>
            {blobFilesList && (
                <ul className="custom_tab_list_docs">
                    {blobFilesList.map((val, idx) => {
                        return (
                            val?.name && (
                                <li
                                    className={`accordion-tab ${
                                        activeTab === "doc" + idx
                                            ? "accordion-active"
                                            : ""
                                    }`}
                                    key={idx}
                                    onClick={() => togglecallback(`doc${idx}`)}
                                >
                                    {val?.name &&
                                        renderTruncatedText(val?.name, 15)}
                                    <sup
                                        title="close"
                                        className="closebtn left-padding"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            closecallback(
                                                blobFilesList?.length === 0
                                                    ? 0
                                                    : idx > 0
                                                    ? `doc${idx - 1}`
                                                    : 0,
                                                blobFilesList.filter(
                                                    (file) =>
                                                        file?.name !== val?.name
                                                )
                                            );
                                        }}
                                    >
                                        {"X"}
                                    </sup>
                                </li>
                            )
                        );
                    })}
                </ul>
            )}
        </Fragment>
    );
};
export const blobFilesListPrompt = ({ blobFilesList, closefilecallback }) => {
    return `you need to close any of the following to open current file`;
};

const AddDataToBlobList = ({
    blobFilesList,
    blobobj,
    updatestatecallbackfn,
}) => {
    blobFilesList.push({ ...blobobj, opendocdate: new Date() });
    updatestatecallbackfn(
        blobFilesList.sort((a, b) => {
            if (a.opendocdate < b.opendocdate) return 1;
            if (a.opendocdate > b.opendocdate) return -1;
            return 0;
        }),
        `doc${blobFilesList.length - 1}`
    );
};
const FilesLimitExceededHtmlStr = (blobFilesList, fileObj) => {
    return (
        <>
            <p>
                please close one of the file to open the file:{" "}
                <b>{fileObj?.name}</b>
            </p>
            <br />
            {blobFilesList && (
                <form id="blobfileslistform">
                    <ul className="swal_list">
                        {blobFilesList.map((val, idx) => (
                            <li title={val?.name} key={idx}>
                                <input
                                    type="checkbox"
                                    name="blobfileslistcheckbox"
                                    value={val?.name}
                                ></input>
                                {renderTruncatedText(val?.name, 21)}
                            </li>
                        ))}
                    </ul>
                </form>
            )}
        </>
    );
};
const AddFileToBlobList = ({
    blobFilesList,
    blobobj,
    updatestatecallbackfn,
}) => {
    if (!blobFilesList.some((elem) => elem.name === blobobj.name)) {
        console.log(blobFilesList, blobFilesList.length);
        if (blobFilesList.length >= 10) {
            console.warn(
                "blobFilesList count is greater or equal to 2",
                renderToString(
                    FilesLimitExceededHtmlStr(blobFilesList, blobobj)
                )
            );
            handlePromptWithList(
                renderToString(
                    FilesLimitExceededHtmlStr(blobFilesList, blobobj)
                ),
                RemoveFirstElementFromArray,
                AddDataToBlobList,
                blobFilesList,
                blobobj,
                updatestatecallbackfn
            );
        } else {
            AddDataToBlobList({
                blobFilesList,
                blobobj,
                updatestatecallbackfn,
            });
        }
    } else {
        updatestatecallbackfn(
            blobFilesList,
            `doc${blobFilesList.findIndex((x) => x.name === blobobj.name)}`
        );
    }
};
const RemoveFirstElementFromArray = (blobFilesList) => {
    blobFilesList.splice(0, 1);
    return blobFilesList;
};
export const AddBlobToBlobList = ({
    blobFilesList,
    blobobj,
    updatestatecallbackfn,
    closecallback,
}) => {
    if (blobobj.hasOwnProperty("url") && blobobj.hasOwnProperty("name")) {
        console.log(blobFilesList, "");
        if (blobFilesList.length >= 2) {
            AddFileToBlobList({
                blobFilesList,
                blobobj,
                updatestatecallbackfn,
            });
        } else {
            AddFileToBlobList({
                blobFilesList,
                blobobj,
                updatestatecallbackfn,
            });
        }
    }
};
