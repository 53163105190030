// src/redux/actions.js
export const updateReviewState = (data) => ({
    type: 'UPDATE_REVIEW_STATE',
    payload: {data},
});
export const updateSummaryState = (data) => ({
    type: 'UPDATE_SUMMARIZE_STATE',
    payload: {data},
});
export const updateChatWithDocState = (data) => ({
    type: 'UPDATE_CHATWITHDOC_STATE',
    payload: {data},
});
export const updateCaseAnalysisState = (data) => ({
    type: 'UPDATE_CASEANALYSIS_STATE',
    payload: {data},
});
export const updateChatWithInternalDocsState = (data) => ({
    type: 'UPDATE_CHATWITHINTERNALDOC_STATE',
    payload: {data},
});
export const updateGenerateDocState = (data) => ({
    type: 'UPDATE_GENERATEDOC_STATE',
    payload: {data},
});
export const updateDashBoardState = (data)=>({
    type: 'UPDATE_DASHBOARD_STATE',
    payload: {data},
});
export const updateLogInState = (data)=>({
    type: 'UPDATE_LOGIN_STATE',
    payload: {data},
});
export const updateErrorState = (data)=>({
    type: 'UPDATE_ERROR_STATE',
    payload: {data},
});
export const updateOrganizationState = (data)=>({
    type: 'UPDATE_ORGANIZATION_STATE',
    payload: {data},
});
export const resetReviewState={
    type:'RESET_REVIEW_STATE'
};
export const resetSummaryState = {
    type: 'RESET_SUMMARIZE_STATE'
};
export const resetChatWithDocState = {
    type: 'RESET_CHATWITHDOC_STATE'
};
export const resetCaseAnalysisState = {
    type: 'RESET_CASEANALYSIS_STATE'
};
export const resetChatWithInternalDocsState = {
    type: 'RESET_CHATWITHINTERNALDOC_STATE'
};
export const resetGenerateDocState = {
    type: 'RESET_GENERATEDOC_STATE'
};
export const resetDashBoardState = {
    type: 'RESET_DASHBOARD_STATE'
};
export const resetLogInState = {
    type: 'RESET_LOGIN_STATE'
};
export const resetErrorState = {
    type: 'RESET_ERROR_STATE'
};
export const resetOrganizationState = {
    type: 'RESET_ORGANIZATION_STATE'
};



// Create similar actions for other components if needed
