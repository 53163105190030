export const parseDate = (dateString) => {
    dateString = dateString.toLowerCase();
    console.log(dateString);
    if (new Date(dateString).toString() !== "Invalid Date") {
        return new Date(dateString);
    }
    if (dateString.includes("-")) {
        dateString = dateString.split("-")[0];
    }
    if (dateString.includes("to ") || dateString.includes("to ")) {
        dateString = dateString.split("to ")[0].trim();
        console.log(dateString.split("to "));
    }
    if (dateString.includes("onwards")) {
        dateString = dateString.replace("onwards", "");
    }
    if (!checkIfStringhasNumber(dateString)) {
        //Handle No Date Text
        console.warn(
            "*&*&*&*&*&&*&*&*&*&*&*&*&*&*INVALID DATE*&*&*&*&*&&*&*&*&*&*&*&*&*&*",
            dateString
        );
        return new Date("9999-01-01"); // considering unknown as a far future date to ensure "Unknown dates are sorted last"
    }
    //Splitting Date Ranges Picking First Date

    //Months List
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    //Seasons List
    const seasonMonths = {
        Spring: "March",
        Summer: "June",
        Fall: "September",
        Autumn: "September",
        Winter: "December",
    };

    //Early Late Months List test
    const earlyLateMonths = { Early: "January", Late: "October" };
    // Handle "in or around" by removing it from the string

   
    Object.keys(seasonMonths).forEach((season) => {
        if (dateString.includes(season.toLowerCase())) {
            dateString =
                dateString.replace(season.toLowerCase(), seasonMonths[season]) +
                " 01";
        }
    });
    Object.keys(earlyLateMonths).forEach((timeframe) => {
        console.log(
            `timeFrame ===================================================> ${timeframe}`
        );
        if (dateString.includes(timeframe.toLowerCase())) {
            console.log(
                `inner timeFrame ===================================================> ${timeframe} ${dateString}`
            );
            dateString =
                dateString.replace(
                    timeframe.toLowerCase(),
                    earlyLateMonths[timeframe]
                ) + " 01";
        }
    });
    if (dateString.split("-")) {
        dateString = dateString.split("-")[0];
    }
    if (
        dateString.split(" ").length === 2 &&
        string_exists(monthNames, dateString.split(" ")[0])
    ) {
        console.log(
            "In Month Date String" + dateString,
            string_exists(monthNames, dateString.split(" ")[0])
        );
        dateString += " 01";
    }
    if (!isNaN(dateString) && dateString.length === 4) {
        dateString = "January 01, " + dateString;
    }
    if (new Date(dateString).toString() === "Invalid Date") {
        console.info(
            "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&INVALID DATE&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& ",
            dateString
        );
        return new Date("9999-01-01");
    }
    return new Date(dateString);
};

export const byteConvertertoMb = (bytes, decimals = 2, only = "MB") => {
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if (bytes === 0) return "0 Byte";

    if (only === "MB")
        return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp =
        parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
        " " +
        SIZES[i];

    return resp;
};


//Compare Two String With Case Insensitive
export const caseInsensitiveEquals = (a, b) => {
    return typeof a === "string" && typeof b === "string"
        ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
        : a === b;
};

function string_exists(arr, val) {
    console.info("Month Val: String _exists", val);
    const result = arr.findIndex((item, idx) => {
        return val.toUpperCase() === item.toUpperCase();
    });

    if (result !== -1) {
        return true;
    }

    return false;
}

function checkIfStringhasNumber(str) {
    return /\d/.test(str);
}
//Check if array has a value with
export const CheckIfValueMatchesInArray = (array, str) => {
    return array.filter((item, idx) =>
        item.trim().toLowerCase().includes(str.toLowerCase())
    );
};
