import React, { useEffect, useState } from "react";
import "../../../css/Login.css";
import "../../../css/CaseAnalysis.css";
import modules from "../../../dropDowns/modules.json";
import ReCAPTCHA from "react-google-recaptcha";
import {
    updateOrganizationState,
    resetOrganizationState,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import help from "../../../images/help.png";
import validate from "../../SupportiveComponents/Validation";
import Swal from "sweetalert2";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import { useSelector } from "react-redux";
import { handleRecaptchaToken } from "../../SupportiveComponents/PureHelpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TermsAndConditions } from "../Registration/TermsandConditions";
import useTheme from "../../../contexts/theme";
import { setTitle } from "../../../js/setTitle";
export default function OrgRegistration() {
    const [isContinue, setIsContinue] = useState(false);
    const [orgDetails, setOrgDetails] = useState(
        useSelector((state) => state.organization.orgState)
    );
    const { themeMode } = useTheme();
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [errorList, setErrorList] = useState({});
    const [terms, setTerms] = useState(false);
    const [displayTerms, setdisplayTerms] = useState(false);
    const dispatch = useDispatch();
    const nav = useNavigate();

    useEffect(() => {
        setTitle("Organization Signup");
    }, []);

    const handleRadioBtn = (event) => {
        //empty
        event.preventDefault();
        console.log(event.target);
    };

    const handleCheckBox = (event) => {
        console.log(event);

        if (event.target.name === "terms") {
            setTerms(event.target.checked);
        } else {
            let orgDetails_temp = { ...orgDetails };
            console.log(event.target.checked, "event checked");

            orgDetails_temp.modules[event.target.name] = event.target.checked;
            console.log(orgDetails_temp, "<=org Details Temp");
            setOrgDetails(orgDetails_temp);
        }
    };
    const phoneHandleOnChange = (value, data, event, formattedvalue) => {
        console.log("api key", process.env.REACT_APP_RECAPTCHA_SITE_KEY, value);
        const { name } = event.target;
        let details = { ...orgDetails };
        details[name] = `+${value}`;
        setOrgDetails(details);
        dispatch(updateOrganizationState(orgDetails));
        console.log(orgDetails, "Test Org Details");
    };
    const handleChange = (event) => {
        console.log("api key", process.env.REACT_APP_RECAPTCHA_SITE_KEY);
        const { name, value } = event.target;
        let details = { ...orgDetails };
        details[name] = value;
        setOrgDetails(details);
        dispatch(updateOrganizationState(orgDetails));
    };

    const handleContinue = () => {
        try {
            const isValidated = handleValidation();
            console.log(orgDetails, "Org Details");
            if (isValidated) {
                setIsContinue(true);
                dispatch(updateOrganizationState(orgDetails));
            }
        } catch (error) {
            //empty
        }
    };

    const handleSubmit = () => {
        try {
            const isValidated = handleValidation();
            if (isValidated) {
                const formData = new FormData();
                let temp_modules = orgDetails.modules;
                const selected_modules = [];
                Object.keys(temp_modules).forEach((item) => {
                    if (temp_modules[item] === true) {
                        selected_modules.push(modules[item].Id);
                    }
                });

                orgDetails.modules = selected_modules;

                console.log("orgDetails", orgDetails);
                formData.append("orgDetails", JSON.stringify(orgDetails));
                const axiosInstance = getAxiosInstance(null);

                axiosInstance
                    // .post("/login", formData, {
                    .post("/admin/addorganization", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        timeout: 300000,
                    })
                    .then((response) => {
                        let data = response.data;
                        console.log("data", data);

                        if (data.status === 0) {
                            Swal.fire({
                                text: "Your request for registration has been submitted.\nYou will be notified about the status via email to the email address provided.",
                                icon: "success",
                                confirmButtonText: "OK",
                            }).then((response) => {
                                console.log("response.value", response.value);
                                if (response.value) {
                                    nav("/LogIn");
                                }
                            });
                        } else {
                            Swal.fire({
                                text: "", //"It seems you have already requested for your organization registration. Please allow us to get back to you",
                                icon: "error",
                                html: "<p>You are already registered. Please <a href='/login'>login</a>   </p>",
                                confirmButtonText: "OK",
                            }).then((response) => {
                                console.log("response.value", response.value);
                                if (response.value) {
                                    let orgState = {
                                        organizationName: "",
                                        email: "",
                                        modules: {
                                            Review: false,
                                            Summarize: false,
                                            ChatDoc: false,
                                            GenerateDoc: false,
                                            CaseAnalysis: false,
                                        },
                                        phoneNumber: "",
                                        adminName: "",
                                    };

                                    setIsContinue(false);
                                    setIsCaptchaVerified(false);
                                    setErrorList({});
                                    setTerms(false);
                                    setOrgDetails(orgState);
                                    dispatch(resetOrganizationState);

                                    nav("/SignUp");
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("From API: ", error);

                        Swal.fire({
                            text: "Error while signing up, please try after some time.",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    });
            }
        } catch (error) {
            console.log("From Try Catch: ", error);
            Swal.fire({
                text: "Error while signing up, please try after some time.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };
    const recaptchaVerify = (e) => {
        handleRecaptchaToken(e, setIsCaptchaVerified);
    };

    function handleValidation() {
        try {
            const orgDetails_temp = { ...orgDetails };
            let errorList = {};
            Object.keys(orgDetails_temp).forEach((item) => {
                if (item !== "modules") {
                    let res_error = validate(item, orgDetails_temp[item]);
                    if (res_error) errorList[item] = res_error;
                } else {
                    if (isContinue && orgDetails_temp[item].length === 0) {
                        errorList[item] = "Please select atleast one module";
                    }
                }
            });

            setErrorList(errorList);

            if (Object.keys(errorList).length === 0) return true;
            else return false;
        } catch (error) {
            console.log("From Validation: ", error);
            Swal.fire({
                text: "Error while Logging you in, please try after some time.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }

    return (
        <>
            {displayTerms ? (
                <TermsAndConditions
                    title="Terms and Conditions for Website Registration"
                    closepopup={() => {
                        setdisplayTerms(!displayTerms);
                    }}
                />
            ) : (
                ""
            )}
            <div className="organization_form">
                <div className="regBody">
                    <div className="header">
                        <h3>Request to set up your organization's account</h3>
                    </div>

                    <div className="registrationsec ">
                        {isContinue ? (
                            <>
                                <div className="centereddiv">
                                    <h3>{orgDetails.organizationName}</h3>
                                    <h4>Choose modules</h4>
                                    <div className="modules">
                                        {Object.keys(modules).map((item, i) => (
                                            <div key={i}>
                                                {" "}
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={
                                                            orgDetails.modules[
                                                                item
                                                            ]
                                                        }
                                                        name={item}
                                                        onChange={
                                                            handleCheckBox
                                                        }
                                                    ></input>
                                                    {modules[item].Name}
                                                </label>
                                            </div>
                                        ))}
                                        {errorList && errorList.modules && (
                                            <span className="validationError">
                                                {errorList.modules}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        Your request will be addressed within 2
                                        working days (or sooner),
                                    </label>
                                    <br></br>
                                    <label>
                                        You will be informed by email to{" "}
                                        {orgDetails.email}
                                    </label>
                                </div>
                                <div className="captcha">
                                    <button
                                        className="continue"
                                        onClick={handleSubmit}
                                    >
                                        Request Account Setup
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="loginForm">
                                    <div className="fields">
                                        <label>Organization Name</label>
                                        <input
                                            type="text"
                                            name="organizationName"
                                            placeholder="Roadrunner PC"
                                            className="inputfield"
                                            autoComplete="off"
                                            value={orgDetails.organizationName}
                                            onChange={handleChange}
                                        />
                                        {errorList &&
                                            errorList.organizationName && (
                                                <span className="validationError">
                                                    {errorList.organizationName}
                                                </span>
                                            )}
                                    </div>
                                    <div className="fields">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            name="adminName"
                                            placeholder="FirstName LastName"
                                            className="inputfield"
                                            autoComplete="off"
                                            value={orgDetails.adminName}
                                            onChange={handleChange}
                                        />
                                        {errorList && errorList.adminName && (
                                            <span className="validationError">
                                                {errorList.adminName}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="loginForm">
                                    <div className="fields">
                                        <label>
                                            Business Email address
                                            <img
                                                className="orgHelpIcon"
                                                src={help}
                                                alt=""
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="To receive the activation link if approved"
                                            />
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="firstname@domain.id"
                                            className="inputfield"
                                            autoComplete="off"
                                            value={orgDetails.email}
                                            onChange={handleChange}
                                        />
                                        {errorList && errorList.email && (
                                            <span className="validationError">
                                                {errorList.email}
                                            </span>
                                        )}
                                    </div>
                                    <div className="fields">
                                        <label>
                                            Phone number
                                            <img
                                                className="orgHelpIcon"
                                                src={help}
                                                alt=""
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="To contact you, when needed"
                                            />
                                        </label>

                                        <PhoneInput
                                            phone={orgDetails.phoneNumber}
                                            country={"us"}
                                            inputProps={{
                                                name: "phoneNumber",
                                                required: true,
                                                autoFocus: true,
                                            }}
                                            onlyCountries={["us", "ca", "in"]}
                                            containerClass="inputfield"
                                            placeholder="(###) - ### - ####"
                                            value={orgDetails.phoneNumber}
                                            onChange={phoneHandleOnChange}
                                            countryCodeEditable={false}
                                        />
                                        {errorList && errorList.phoneNumber && (
                                            <span className="validationError">
                                                {errorList.phoneNumber}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="loginForm">
                                    <div className="fields">
                                        <label>
                                            <input
                                                type="radio"
                                                onChange={handleRadioBtn}
                                                checked={true}
                                            ></input>
                                            Trial Accounts. 2 Users. Unlimited
                                            usage. Expires in 14 days.
                                        </label>
                                    </div>
                                </div>
                                <div className="captcha">
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={terms}
                                                name="terms"
                                                onChange={handleCheckBox}
                                            ></input>

                                            <button
                                                className="terms"
                                                type="button"
                                                onClick={() => {
                                                    setdisplayTerms(
                                                        !displayTerms
                                                    );
                                                }}
                                            >
                                                {"  "}{" "}
                                                <u>terms and conditions</u>
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <div className="captcha">
                                    {
                                        <ReCAPTCHA
                                            sitekey={
                                                process.env
                                                    .REACT_APP_RECAPTCHA_SITE_KEY
                                            }
                                            onChange={recaptchaVerify}
                                            theme={themeMode}
                                            onExpired={() =>
                                                setIsCaptchaVerified(false)
                                            }
                                            size="normal"
                                        />
                                    }
                                </div>
                                <div className="captcha">
                                    <button
                                        className={
                                            !isCaptchaVerified || !terms
                                                ? "continue_disabled"
                                                : "continue"
                                        }
                                        onClick={handleContinue}
                                        disabled={!isCaptchaVerified || !terms}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
