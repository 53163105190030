const initialState = {
    orgState: {
        organizationName: "",
        email: "",
        modules: {
            Review: false,
            Summarize: false,
            ChatDoc: false,
            GenerateDoc: false,
            CaseAnalysis: false,
        },
        phoneNumber: "",
        adminName: "",
    }, // Initial state for the dashBoard
    // Add other initial states as needed for different components
};

export const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_ORGANIZATION_STATE":
            // console.log("Redux payload",action.payload.data)
            return { ...state, orgState: action.payload.data };
        // Add other cases for different components' states
        case "RESET_ORGANIZATION_STATE":
            return initialState;
        default:
            return state;
    }
};
