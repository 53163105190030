import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import remarkToc from "remark-toc";
import remarkParse from "remark-parse";
import { unified } from "unified";
import docx from "remark-docx";
import { jsonrepair } from "jsonrepair";
import { saveAs } from "file-saver";
import disableOrderedlist from "../../../plugins/disableOrderedlist";
import { marked } from "marked";
import { htmlToText } from "html-to-text";
import tablemark from "tablemark";
import remarkGfm from "remark-gfm";
import remarkHeadingGap from "remark-heading-gap";
// import utf8 from "remark-utf8";
import {addSpacingAroundHeaders} from "../../../plugins/ignoreOrderedlist";

export const MdtoDocx = async (text, name) => {
    const processor = unified()
        // .use(utf8)
        .use(remarkHeadingGap, {
            1: { after: 3, before: 3 },
            2: { after: 2, before: 2 },
            3: { after: 2, before: 2 },
            4: { after: 2, before: 2 },
            5: { after: 2, before: 2 },
            6: { after: 2, before: 2 },
        })
        .use(remarkToc)
        .use(remarkParse)
        .use(remarkGfm)
        .use(addSpacingAroundHeaders)
        .use(disableOrderedlist)
        .use(docx, { output: "blob" }); //
    const doc = await processor.process(text);
    const blob = await doc.result;
    saveAs(blob, name);
};

const format_content = (text) => {
    if (text) {
        text = text?.replace(/^\n+|\n+$/g, "");

        text = text?.replace(/(^\w|\.\s*\w)/gm, (match) => match.toUpperCase());

        return text;
    }
    return "";
};
export const convertRepairedJSontoJson = (content) => {
    let to_json = [];
    let to_json_val = JSON.parse(jsonrepair(content.replace('""', '"')));
    try {
        to_json_val = to_json_val.filter((val, idx) => {
            console.log(typeof val);

            return typeof val !== "string";
        });

        to_json_val.map((val, idx) => {
            if (Array.isArray(val)) {
                to_json = [...val];
            } else {
                to_json.push(val);
            }
            console.log(val, Array.isArray(val), to_json);
            return val;
        });
    } catch (error) {
        to_json_val.filter((val, idx) => {
            return typeof val !== "string";
        });
        console.error(error);
    }
    return to_json;
};

export const handle_content = (content) => {
    try{
    let value = content;
   let isMdJson = false
    let isJson = false
    const regexPattern = /--*\s*\|\s*--*/;
    try {
    isMdJson =(
        content.match(/```json([\s\S]*?)```/)?.length
            ? content.match(/```json([\s\S]*?)```/)?.length > 0
            : false
    );
    const repaired = jsonrepair(content.replace('""', '"'));
        
        JSON.parse(repaired).map((val, idx) => {
            if (typeof val === "string") {
                throw new Error(`Found String Inside Json`);
            }
            return val;
        });

    isJson = true;
} catch(error) {
        isJson = false;
        console.log(error, `Test`)
    }
    if (isMdJson || isJson || regexPattern.test(content)) {
        if (isJson) {
          
           let to_json = convertRepairedJSontoJson(content);


            to_json
                .filter((val) => {
                    return Object.keys(val).length >= 2;
                })
                .map((val) => {
                    if (val?.date?.localeCompare(val["event"]) === 0) {
                        val["date"] = "N/A";
                    }

                    return val;
                }); 
            console.log(
                tablemark(to_json, {
                    columns: [{ align: "left" }, { align: "left" }],
                }),
                typeof tablemark(to_json, {
                    columns: [{ align: "left" }, { align: "left" }],
                })
            );
              return tablemark(to_json);
        }
        return value
    }else{
        return value;
        };
    }
    catch (error) {
        console.error(error);
    }
};


//copy md done
export const handleCopySection = (content) => {
    let formattedcontent = handle_content(content);
    
    copy(htmlToText(marked(formattedcontent)));
    toast.success("Copied");
};

export const handle_content_with_question = (question, content) => {
    const value = content;
    return format_content(value);
};


//copy md done
export const handleCopySelectionALL = (CannedList) => {
    let summarizedContent = "";

    CannedList?.map((val, i) => {
        summarizedContent +=
            `\n\n\n${val?.question?.toUpperCase()}\n\n` +
            handle_content(val.response);
        return val;
    });
    /************************ */
   
     copy(htmlToText(marked(summarizedContent)));
    toast.success("Copied");
};

