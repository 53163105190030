export default function validate(name, value) {
    //A function to validate each input values
    let errors = "";
    switch (name) {
        case "username":
            if (value.length === 0) {
                errors = "Username should not be empty";
            }
            break;
        case "username_reg":
            if (value.length === 0) {
                errors = "Username should not be empty";
            } else if (value.length <= 4) {
                // we will set the error state
                errors = "Username atleast have 5 letters";
            }
            break;

        case "email":
            if (
                !new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ).test(value)
            ) {
                errors = "Enter a valid email address";
            }
            break;

        case "password":
            if (value.length === 0) {
                errors = "Password should not be empty";
            }
            break;
        case "password_reg":
            if (value.length === 0) {
                errors = "Password should not be empty";
            } else if (
                !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(
                    value
                )
            ) {
                errors =
                    "Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers";
            }
            break;
        case "organizationName":
            if (value.length === 0) {
                errors = "Organization name should not be empty";
            } else if (value.length <= 4) {
                // we will set the error state
                errors = "Organization name atleast have 5 letters";
            }
            else if(!new RegExp(
                    /^[a-zA-Z0-9. ]+$/
                ).test(value)){
                errors = "Organization name can have only alphabets, numbers or . / ";
            }
            break;
        case "phoneNumber":if (
                !new RegExp(
                    /^\+(1|1\s|\d{1,3}\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/
                ).test(value)
            ) {
                errors = "Enter a valid phone number with country code";
            }
            break;
        case "adminName":if (value.length === 0) {
                errors = "Should not be empty";
            } else if (value.length <= 4) {
                // we will set the error state
                errors = "Should atleast have 5 letters";
            }
            else if (!new RegExp(/^[a-zA-Z0-9. ]+$/).test(value)) {
                errors = "Can have only alphabets, numbers or . / ";
            }
            break;

        default:
            break;
    }
    return errors;
}
