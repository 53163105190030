import { useEffect, useState } from "react"

export const CustomVideoPlayer = ({ url }) => {
    const [urlS, setUrl] = useState(url);
    useEffect(() => {
        setUrl(url)
    }, [url]);
    return (
        <iframe
            data-ux="Embed"
            allowfullscreen=""
            type="text/html"
            title="YouTube video player"
            frameBorder="0"
            src={urlS + "&rel=0&amp;showinfo=0&amp;start=0"}
            data-aid="VIDEO_IFRAME_RENDERED"
        ></iframe>
    );
}
