import { Fragment, useState } from "react"
import { ToastAlert } from "../CustomAlerts/ToastAlert"
import getAxiosInstance from '../AxiosRequest.jsx';
import LoadingSpinner from "../LoadingSpinner.jsx";

const DocumentExistPopUp = ({ closeDocExistpopup, document_name, obj, overwriteData, token,selectedWorkSpaces,existWorkSpaces }) => {
    const [loading, setLoading] = useState(false)
    const handleOverwrite = () => {
        try {
            setLoading(true)
            const axiosInstance = getAxiosInstance(token);
            // overwriteData.ws_item.documents[0].name = document_name
            overwriteData?.length>0 && overwriteData.forEach((element, index)=>{
                axiosInstance.put('/workspace/item', element, { headers: { "Content-Type": "application/json" }, timeout: 180000 }).then((res) => {
                    if (res.status === 200) {
                        ToastAlert("updated successfully");
                        setLoading(false);
                        if (selectedWorkSpaces.length >0) {
                            let wss = []
                            selectedWorkSpaces.forEach((e, i) => {
                                if(e?.id===res.data?.added[0]['ws_id']){
                                e.workspace_item_id = res.data?.added?.length > 0 ? res.data?.added[0]['ws_item_id'] : 0
                                e.shared_users = res.data?.added?.length > 0 ? res.data?.added[0]['shared_users'] : 0
                            }
                            wss.push(e)
                            })
                            closeDocExistpopup(wss, res.data);
                        }
                    }
                })
            })
            
        } catch (exception) {
            setLoading(false)
        }
    }


    const handleCreateNew = () => {
        try {
            setLoading(true);

            const axiosInstance = getAxiosInstance(token);
            existWorkSpaces?.length>0 && existWorkSpaces.forEach((element, index)=>{
            axiosInstance.post('/workspace/item', element, { headers: { "Content-Type": "application/json" }, timeout: 180000 }).then((res) => {
                if (res.status === 200) {
                    ToastAlert("added successfully");
                    setLoading(false)
                    if (selectedWorkSpaces.length > 0) {
                        let wss = []
                        selectedWorkSpaces.forEach((e, i) => {
                            if(e?.id===res.data?.added[0]['ws_id']){
                            e.workspace_item_id = res.data?.added?.length > 0 ? res.data?.added[0]['ws_item_id'] : 0
                            e.shared_users = res.data?.added?.length > 0 ? res.data?.added[0]['shared_users'] : 0
                        }
                        wss.push(e)
                        })
                        
                        
                        closeDocExistpopup(wss, res.data);
                    }
                }
            })
        })
        } catch (exception) {
            setLoading(false)
        }
    }
    return <Fragment>
        <div
            id="popup-overlay"
            className={`custom-popup-overlay view-fav-popup-overlay  show
            }`}
            // onClick={() => closeDocExistpopup()}
            style={{  zIndex: 9999999 }}
        >
            <div 
            // style={{ width: 'fit-content', height: 'fit-content' }}
                className={`custom-popup-content doc-exist-popup`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="custom-popup-header">
                    <h2>{"Workspace Item  Exists"}</h2>
                    <h2>
                        <span className="close-btn"
                            onClick={() => closeDocExistpopup()}
                            title="Close">
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                alt="close"
                            />
                        </span>
                    </h2>
                </div>
                <div className="custom-popup-body">
                    {loading && <LoadingSpinner />}
                    <div className="pop-up-msg">
                        <strong>{document_name}</strong> already exists. Do you want to overwrite the existing file or create a new file with unique name?
                    </div>
                    <div className={`mt-2 mb-2 btn-adj`}>
                        <button type="submit" className="continue mr-2" onClick={() => handleOverwrite()}>Overwrite  </button>
                        <button type="submit" className="continue ml-2" onClick={() => handleCreateNew()}>Use unique name   </button>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>
}

export default DocumentExistPopUp;