import { createContext, useState, useEffect } from "react";
import store from "../redux/store";
// Create the context
const EngineContext = createContext();

// Export the context
export default EngineContext;


export const EngineProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let user_id = store.getState().login.logInState?.user_id;
            try {
                const response = await fetch(
                    "/userplan/" + user_id
                );
                const result = await response.json();
                setData(result);
                console.log(`result`, result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <EngineContext.Provider value={{ data, loading, error }}>
            {children}
        </EngineContext.Provider>
    );
};
