import darkthemelogo from "../../images/dark-theme.svg";
import lightthemelogo from "../../images/light-theme.svg";
import useTheme from "../../contexts/theme.js";
import { useEffect, useState } from "react";

export const ThemeComponent = () => {
    //themeval false refers to dark, true refers to light theme
    const { themeMode, darktheme, lighttheme } = useTheme();
    const [theme, setTheme] = useState(themeMode);
    useEffect(() => {
        setTheme(themeMode);
    }, [themeMode]);
    return (
        <>
            {theme === "dark" && (
                <button
                    className="favorites_button"
                    onClick={lighttheme}
                    title="Switch to Light Theme"
                >
                    <img src={lightthemelogo} alt="lighttheme" />
                </button>
            )}

            {theme === "light" && (
                <button
                    className="favorites_button"
                    onClick={darktheme}
                    title="Switch to Dark Theme"
                >
                    <img src={darkthemelogo} alt="darktheme" />
                </button>
            )}
        </>
    );
};
