// ErrorPage.js
import React, { useState } from "react";

const ErrorPage = () => {
  const [theme] = useState(localStorage.getItem("theme"));


  return (
    <div className="custom_error_boundary">
      <pre
        style={{ fontSize: "18px", color: theme === "dark" ? "#BCBCBC" : "" }}
      >
        {`
    <div>
      <h1>`}
        <strong
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: theme === "dark" ? "#FAF9F6" : "",
          }}
        >
          Service Unavailable
        </strong>
        {`</h1>
      <p>`}
        <strong
          style={{
            fontSize: "20px",
            color: theme === "dark" ? "#FAF9F6" : "",
            fontWeight: "initial",
          }}
        >
          {`
          Our team is working hard to restore service. Please check back in a few minutes.
     `}
        </strong>
        {`</p>
    </div>`}
      </pre>
    </div>
  );
};

export default ErrorPage;
