const initialState = {
    logInState: {
        userFullName: "",
        job_title: "",
        organization: "",
        userType: "",
        user_name: "",
        user_id: "",
        usertypeid: "",
        org_id: 0,
    }, // Initial state for the dashBoard
    // Add other initial states as needed for different components
};

export const logInReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_LOGIN_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, logInState: action.payload.data };
        // Add other cases for different components' states
        case 'RESET_LOGIN_STATE':
            return initialState;
        default:
        return state;
    }
};
