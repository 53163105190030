import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Walkthrough = (props) => {
  const nav = useNavigate();
  useEffect(()=>{
nav("/dashboard")
  },[nav])
 return (
    <div>
      <div
        id="popup-overlay"
        className={`custom-popup-overlay view-fav-popup-overlay 
            }`}
        onClick={() => props.closepopup()}
      >
        <div style={{zIndex:'9999999999999'}}
          className={`custom-popup-content walkthrough-pop-up`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom-popup-header">
            <h2>{props?.firstText?"Welcome to Lawbotica!":"YOU ARE ALL SET!"}</h2>
            <h2>
              <span
                className="close-btn"
                onClick={() => props.closepopup()}
                title="Close"
              >
                <img hidden={true}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                  alt="close"
                />
              </span>
            </h2>
          </div>
          <div className="custom-popup-body" style={{color:'black',padding:'10px'}}>
          {props?.firstText?<p style={{padding:'1px',textAlign:'center'}}>This walkthrough will guide you through our key features.<br/>
          Click 'Next' to begin</p> :<p style={{padding:'1px',textAlign:'center'}}>You’ve completed the Lawbotica walkthrough.<br/>
            We hope you found this tour helpful</p>}
            <div
                className=" mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "99%",
                  cursor: "pointer",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
              {props?.firstText? <button
                  title={"Submit"}
                  className="continue mr-3"
                  style={{color:'#999',background:'none'}}
                  onClick={()=>props?.startwalkthrough(true)}
                >
               No Thanks
                </button>:""}
                <button
                  title={"Submit"}
                  className="continue show-button"
                  onClick={()=>props?.startwalkthrough()}
                >
               {props?.firstText?"Next":"Close"}
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Walkthrough;
