import { isEqual } from "lodash";
import { Resizable } from "re-resizable";
import React, { Component, Fragment } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { handleSubmit, handleValidation } from "../../actions/CaseAssessment.js";
import "../../css/CaseAnalysis.css";
import followup_prompts_ddl from "../../dropDowns/CaseAnalysisTypes.json";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import favlistlogo from "../../images/FavoriteList.svg";
import { setTitle } from "../../js/setTitle.js";
import { initialState } from "../../redux/Reducers/caseAnalysisReducer.js";
import {
  updateCaseAnalysisState,
  updateDashBoardState,
} from "../../redux/actions";
import store from "../../redux/store.js";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx";
import ContentOperations from "../SupportiveComponents/ContentOperations";
import { formatTime, ToastAlert } from "../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { CaseAnalysisPopup } from "../SupportiveComponents/CustomPopup.jsx";
import { SvgIconButton } from "../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import {
  CollapseAllIcon,
  ExcelIcon,
  ExpandIcon,
} from "../SupportiveComponents/FontAwesomeComponents/HelperIcons.jsx";
import {
  FilledHeart,
  RegularHeart,
} from "../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import LoadingSpinner from "../SupportiveComponents/LoadingSpinner";
import { HandleEngineComponent } from "../SupportiveComponents/ModuleHelpers.jsx";
import ShowAllWorkspacePopUp from "../SupportiveComponents/PopUps/ShowAllSelectedWorkspacePopUp.js";
import UploadCaseDetails from "../SupportiveComponents/PopUps/UploadCaseAssessmentExcelPopUp.jsx";
import WorkSpaceListPopUp from "../SupportiveComponents/PopUps/WorkspaceListPopUp.jsx";
import { isMobile } from "../SupportiveComponents/PureHelpers.jsx";
import ReadMoreCollapse from "../SupportiveComponents/ReadMoreCollapsible";

class CaseAnalysisComponent extends Component {
    state = {
        engine_type: 2,
        selectedDocType:
            docTypes_ddl.CaseAnalysis?.length > 0
                ? docTypes_ddl.CaseAnalysis[0].documentID
                : "",
        selectedLabel: "",
        isBottomPanelOpen: true,
        caseAnalysisResult: "",
        timetaken: "",
        isAnalysed: false,
        loadingText: "",
        selectedFollowup: {},
        empty_case_Details: {
            caseName: "",
            description: "",
            plaintiff: "",
            defendant: "",
            jurisdiction: "",
            case_number: "",
            date: "",
            location: "",
            legal_issue: "",
            status: "",
            witness: "",
            relevant_documents: "",
            Statute_of_Limitations: "",
        },
        empty_FollowUp_Details: {
            Nature_of_Incident: "",
            Injury: "",
            Treatment_Received: "",
            Medical_Expense: "",
            Lost_Wages: "",
            Downtime: "",
            Pain_Suffering: "",
            Parties_Behavior: "",
            Laws_Regulations: "",
            Analysis_Questions: "",
            Liability: "",
            Insurance_Coverage: "",
            Plaintiff_Req: "",
            Defendent_Position: "",
            Claims: "",
            Evidence: "",
            Laws_Precedents: "",
            Legal_Team: "",
            Additional_Information: "",
        },
        Case_Details: {
            caseName: "",
            description: "",
            plaintiff: "",
            defendant: "",
            jurisdiction: "",
            case_number: "",
            date: "",
            location: "",
            legal_issue: "",
            status: "",
            Statute_of_Limitations: "",
            witness: "",
            relevant_documents: "",
        },
        validation_errors: {
            caseName: "Case Name",
            description: "Description",
            plaintiff: "Plaintiff",
            defendant: "Defendant",
            jurisdiction: "Jurisdiction",
            case_number: "Case Number",
            date: "Date",
            location: "Location",
            legal_issue: "Legal Issue",
            status: "Status",
            witness: "Witness",
            Nature_of_Incident: "Nature of Incident",
            Injury: "Injury",
            Treatment_Received: "Medical Treatment",
            Medical_Expense: "Medical Expenses",
            Lost_Wages: "Lost Wages",
            Downtime: "Downtime",
            Pain_Suffering: "Pain Suffering",
            Parties_Behavior: "Parties Behavior",
            Laws_Regulations: "Laws Regulations",
            Analysis_Questions: "Questions for Analysis",
            Liability: "Liability",
            Insurance_Coverage: "Insurance Coverage",
            Plaintiff_Req: "Plaintiff's Requirements",
            Defendent_Position: "Defense's Position",
            Claims: "Claims and Counterclaims",
            Evidence: "Evidence",
            Laws_Precedents: "Laws and Precedents",
            Legal_Team: "Legal Team",
            Statute_of_Limitations: "Statute of Limitations",
        },
        FollowUp_Details: {
            Nature_of_Incident: "",
            Injury: "",
            Treatment_Received: "",
            Medical_Expense: "",
            Lost_Wages: "",
            Downtime: "",
            Pain_Suffering: "",
            Parties_Behavior: "",
            Laws_Regulations: "",
            Analysis_Questions: "",
            Claims: "",
            Evidence: "",
            Laws_Precedents: "",
            Liability: "",
            Insurance_Coverage: "",
            Plaintiff_Req: "",
            Defendent_Position: "",
            Legal_Team: "",
            Additional_Information: "",
        },
        FollowUpAnalysis: [],
        prevFollowUpAnalysis: [],
        activeTab: 0,
        isLoading: false,
        isFavoriteLoading: false,
        isRemoveFavoriteLoading: false,
        view: false,
        startTime: new Date(),
        totalTimetaken: "",
        sortedArray: [],
        isOpened: false,
        isFavorite: false,
        isFavoritePopUp: false,
        FavoritesData: [],
        selectedFavourite: { case_name: "" },
        openCreateWorkSpacePopUp: false,
        isMobile: false,
        element: { index: "", isOpened: false },
        selectedCase: "",
        workspace_name: "",
        workspace_id: 0,
        workspace_item_id: 0,
        ws_count: 0,
        disableAddToWorkspace: false,
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        ws_shared_users: [],
        fromDashBoard: false,
        addToAnotherWorkspace: false,
        moveToAnotherWorkspace: false,
        openKebabMenu: false,
        openUploadExcelPopUp: false,
        isDataFromExcelSheet: false,
    };

    toggleTab = (tabId) => {
        this.setState({ activeTab: tabId }, () => {
            this.props.updateCaseAnalysisState(this.state);
        });
    };
    getFavorites = () => {
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props.history
        );
        axiosInstance
            .get("/FetchCaseAnalysisFavorites", { timeout: 180000 })
            .then((res) => {
                this.setState({ ...this.state, FavoritesData: res?.data }, () =>
                    this.props.updateCaseAnalysisState(this.state)
                );
                let dashBoardState = this.props.dashBoardState;
                if (dashBoardState && dashBoardState.source) {
                    let data = res?.data?.find(
                        (x) => x.json_favorite_id === dashBoardState.favorite_id
                    );
                    if (data) {
                        this.handleCallback(data);
                    }
                    this.handleLinkFromDashBoard(dashBoardState);
                }
            });
    };

    componentDidMount() {
        setTitle("Case Analysis");
        this?.props?.setPosition({
            document: this.state?.view,
            chatComponent: false,
        });
        this.scrollToBottom();

        var mobile = isMobile();
        this.setState({ ...this.state, isMobile: mobile });
        let dashBoardState = this.props.dashBoardState;

        if (dashBoardState && dashBoardState.source) {
            this.setState(
                {
                    ...this.state,
                    isFavoritePopUp: false,
                    caseAnalysisResult: "",
                    isLoading: true,
                    workspace_id: 0,
                    workspace_item_id: 0,
                    workspace_name: "",
                    loadingText: "Loading...",
                    isDataFromExcelSheet: false,
                },
                () => this.props.updateCaseAnalysisState(this.state)
            );
            this.getFavorites();
        } else {
            this.setState({ fromDashBoard: false }, () => {
                this.props.updateCaseAnalysisState(this.state);
            });
            this.getFavorites();
            this.getMessages();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevProps.caseAnalysisState, this.props.caseAnalysisState)
        ) {
            // State has changed, trigger re-render
            this.setState({ ...this.props.caseAnalysisState });
        }
        if (this.state.prevFollowUpAnalysis !== this.state.FollowUpAnalysis)
            this.scrollToBottom();
    }

    constructor(props) {
        super(props);
        this.secondDivRef = React.createRef();
        this.state = this.props.caseAnalysisState;
    }

    handleLinkFromDashBoard(dashBoardState) {
        let stateObj = {};
        if (dashBoardState.linkType.length > 0) {
            stateObj = {
                fromDashBoardLinkType: dashBoardState.linkType,
                isLoading: false,
                isFavoritePopUp: true,
            };
        } else {
            stateObj = {
                workspace_id: 0,
                workspace_item_id: 0,
                caseAnalysisResult: "",
                workspace_name: "",
                fileName: dashBoardState.fileName,
                selectedDocType: dashBoardState?.selectedDocType.trim(),
                selectedEngine: dashBoardState?.selectedEngine
                    ? dashBoardState.selectedEngine
                    : 1,
                isLoading: false,
            };
        }
        switch (dashBoardState.source) {
            case "favorites":
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        favdisplayval:
                            stateObj.hasOwnProperty("fromDashBoardLinkType") > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                    },
                    () => {
                        dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateCaseAnalysisState(this.state);
                    }
                );
                break;

            default:
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: false,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateCaseAnalysisState(this.state);
                    }
                );
                break;
        }
    }

    scrollToBottom() {
        const secondDivElement = this.secondDivRef.current;
        secondDivElement.scrollTop = secondDivElement.scrollHeight;
        this.setState(
            {
                prevFollowUpAnalysis: this.state.FollowUpAnalysis,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    }
    handleBottomPanel = (isOpen) => {
        this.setState(
            {
                isBottomPanelOpen: isOpen,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    handleValuesChange = (e) => {
        let casedetails = this.state.Case_Details;
        casedetails[e.target.id] = e.target.value;
        let disableAddToWorkspace = this.state.disableAddToWorkspace;
        let showWorkspaceButton = this.state.showWorkspaceButton;
        if (
            this.state.selectedCase !==
            this.state.Case_Details.plaintiff +
                "-" +
                this.state.Case_Details.defendant +
                "(" +
                this.state.Case_Details.case_number +
                ")"
        ) {
            showWorkspaceButton = false;
        } else {
            showWorkspaceButton = true;
        }
        this.setState(
            {
                disableAddToWorkspace: disableAddToWorkspace,
                Case_Details: casedetails,
                showWorkspaceButton: showWorkspaceButton,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    handleFollowupValuesChange = (e) => {
        let FollowUp_Details = this.state.FollowUp_Details;
        FollowUp_Details[e.target.id] = e.target.value;

        this.setState(
            {
                FollowUp_Details,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    handleDocTypeChange = (event) => {
        const { value } = event.target;
        const selectedLabel =
            event.target.options[event.target.selectedIndex].text;
        this.setState(
            {
                selectedDocType: value,
                selectedLabel,
                isAnalysed: false,
                prevFollowUpAnalysis: [],
                FollowUpAnalysis: [],
                ws_count: 0,
                view: true,
            },
            () => {
                this?.props?.setPosition({
                    document: true,
                    chatComponent: false,
                });
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    handleNewDoc = () => {
        let initialstate = initialState.caseAnalysisState;
        var caseDetails = {};
        var followUpdetails = {};
        for (const [key] of Object.entries(this.state.Case_Details)) {
            caseDetails[key] = "";
        }
        for (const [key] of Object.entries(this.state.FollowUp_Details)) {
            followUpdetails[key] = "";
        }

        this.setState(
            {
                ...initialstate,
                Case_Details: caseDetails,
                FollowUp_Details: followUpdetails,
                selectedDocType:
                    docTypes_ddl.CaseAnalysis?.length > 0
                        ? docTypes_ddl.CaseAnalysis[0].documentID
                        : "",
                caseAnalysisResult: "",
                workspace_id: 0,
                workspace_item_id: 0,
                workspace_name: "",
                isAnalysed: false,
                startTime: new Date(),
                isDataFromExcelSheet: false,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
                this.getFavorites();
            }
        );
    };

    handleFollowUpSelect = (question) => {
        try {
            this.setState(
                {
                    activeTab: 1,
                    selectedFollowup: question,
                    isBottomPanelOpen: true,
                },
                () => {
                    this.props.updateCaseAnalysisState(this.state);
                }
            );
        } catch (error) {
            console.error("Error:", error);
        }
    };

    handleToggleDocumentViewer = (val) => {
        var mobile = isMobile();
        this.setState({
            ...this.state,
            view: !this.state.view,
            isMobile: mobile,
        });
        this?.props?.setPosition({ document: val, chatComponent: false });
    };
    handleCOllapse = () => {
        this.setState({ ...this.state, isOpened: !this.state.isOpened });
        followup_prompts_ddl.personal_injury_Canned_Ques.forEach(
            (element, index) => {
                if (this.state.isOpened) {
                    document.getElementById(`handle2${index}`).checked = false;
                } else {
                    document.getElementById(`handle2${index}`).checked = true;
                }
            }
        );
    };

    RemoveFavourite = (casename) => {
        this.setState({ ...this.state, isRemoveFavoriteLoading: true });
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props.history
        );
        let formData = new FormData();
        formData.append("case_name", casename);
        axiosInstance
            .post("/RemoveCaseAnalysisFavorite", formData, {
                timeout: 180000,
            })
            .then((response) => {
                if (response.data["status"] === 200) {
                    ToastAlert("Favorite removed successfully");
                    this.setState({
                        ...this.state,
                        isRemoveFavoriteLoading: false,
                    });
                    this.getFavorites();
                } else {
                    this.setState({
                        ...this.state,
                        isRemoveFavoriteLoading: false,
                    });
                }
            });
    };

    handleFavourite = () => {
        let res = handleValidation(this.state.Case_Details);
        if (res.isValid) {
            this.setState({ ...this.state, isFavoriteLoading: true });
            var jsonData = {
                Case_Details: this.state.Case_Details,
                FollowUp_Details: this.state.FollowUp_Details,
            };

            const axiosInstance = getAxiosInstance(
                this.props.token,
                false,
                this.props.history
            );
            let formData = new FormData();
            formData.append("favoritesstr", JSON.stringify(jsonData));
            formData.append(
                "fileName",
                JSON.stringify(
                    `${this.state.Case_Details.plaintiff}-${this.state.Case_Details.defendant}(${this.state.Case_Details.case_number})`
                )
            );
            axiosInstance
                .post("/CaseAnalysisFavorite", formData, {
                    timeout: 180000,
                })
                .then((response) => {
                    if (response.data["status"] === 200) {
                        ToastAlert("Favorited this case successfully");
                        this.setState({
                            ...this.state,
                            isFavoriteLoading: false,
                        });
                        this.getFavorites();
                    } else {
                        this.setState({
                            ...this.state,
                            isFavoriteLoading: false,
                        });
                    }
                });
        } else {
            Swal.fire({
                text: "Please make sure to fill all the fields in case assessment. ",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    FavouritesPopUp = () => {
        this.setState({ ...this.state, isFavoritePopUp: true });
    };

    closepopup = () => {
        this.setState(
            {
                ...this.state,
                isFavoritePopUp: !this.state.isFavoritePopUp,
            },
            () => this.props.updateCaseAnalysisState(this.state)
        );
        if (this.state?.fromDashBoard) {
            this.props.history("/dashboard");
        }
    };

    handleCallback = async (data) => {
        this.setState(
            {
                ...this.state,
                workspace_id: 0,
                workspace_item_id: 0,
                workspace_name: "",
                isDataFromExcelSheet: false,
            },
            () => this.props.updateCaseAnalysisState(this.state)
        );
        const result =
            this.state.caseAnalysisResult !== "" &&
            (await Swal.fire({
                html: "Do you want to clear the follow ups?",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonColor: "#2eaadb",
                cancelButtonTextColor: "#2eaadb",
                cancelButtonColor: "#2eaadb",
                confirmButtonText: "Yes",
                confirmButtonTextColor: "#2eaadb",
                focusDeny: true,
            }));

        var caseDetails = JSON.parse(data.json_data).Case_Details;
        var followUpDetails = JSON.parse(data.json_data).FollowUp_Details;
        caseDetails.caseName =
            caseDetails.caseName === undefined ? "" : caseDetails.caseName;
        caseDetails.Statute_of_Limitations =
            caseDetails.Statute_of_Limitations === undefined
                ? ""
                : caseDetails.Statute_of_Limitations;
        followUpDetails.Additional_Information =
            followUpDetails.Additional_Information === undefined
                ? ""
                : followUpDetails.Additional_Information;
        let dashBoardState = this.props.dashBoardState;
        if (result.isConfirmed || (dashBoardState && dashBoardState?.source)) {
            this.setState(
                {
                    ...this.state,
                    selectedFavourite: data,
                    caseAnalysisResult: "",
                    Case_Details: caseDetails,
                    FollowUp_Details: followUpDetails,
                    isFavoritePopUp: false,
                    selectedDocType: data?.document_type,
                    view: true,
                    isFavoriteLoading: false,
                    fromDashBoard: false,
                },
                () => {
                    this?.props?.setPosition({
                        document: true,
                        chatComponent: false,
                    });
                    this.props.updateCaseAnalysisState(this.state);
                }
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    selectedFavourite: data,
                    caseAnalysisResult: this.state.caseAnalysisResult,
                    Case_Details: caseDetails,
                    FollowUp_Details: followUpDetails,
                    isFavoritePopUp: false,
                },
                () => {
                    this.props.updateCaseAnalysisState(this.state);
                }
            );
        }
    };

    getMessages = () => {
        if (
            this.state.module_id === 6 &&
            this.state.workspace_id > 0 &&
            this.state.workspace_item_id > 0 && this.state.ws_count=== 0
        ) {
            try {
                this.setState(
                    {
                        isLoading: true,
                        disableAddToWorkspace: true,
                        openCreateWorkSpacePopUp: false,
                        selectedFile: {},
                        reviewTimer: 0 + "s",
                        selectedDocType: "",
                    },
                    () => {
                        this.props.updateCaseAnalysisState(this.state);
                    }
                );
                const axiosInstance = getAxiosInstance(
                    this.props.token,
                    false,
                    this.props.history
                );
                axiosInstance
                    .get(
                        `/workspace/item?ws_id=${this.state.workspace_id}&ws_item_id=${this.state.workspace_item_id}`,
                        { timeout: 180000 }
                    )
                    .then((res) => {
                        if (res?.data?.messages?.length > 0) {
                            let documentType =
                                res?.data.documents.length > 0
                                    ? res?.data.documents[0].documentType
                                    : "";
                            let { Case_Details, FollowUp_Details } = JSON.parse(
                                res?.data.messages[0].query
                            );
                            let { caseAnalysisResult, FollowUpAnalysis } =
                                JSON.parse(res?.data.messages[0].messages);
                            this.setState(
                                {
                                    ...this.state,
                                    ws_shared_users: res?.data?.shared_users,
                                    workspace_created_by:
                                        res?.data?.shared_users?.find(
                                            (x) => x.permissions === "owner"
                                        )?.user_id,
                                    engine_type: res?.data?.engine_type,
                                    isLoading: false,
                                    documentType: documentType,
                                    Case_Details: Case_Details,
                                    FollowUp_Details: FollowUp_Details,
                                    caseAnalysisResult: caseAnalysisResult,
                                    FollowUpAnalysis: FollowUpAnalysis,
                                    selectedDocType: documentType,
                                    sortedArray: FollowUpAnalysis,
                                    showWorkspaceButton: true,
                                    selectedCase:
                                        Case_Details.plaintiff +
                                        "-" +
                                        Case_Details.defendant +
                                        "(" +
                                        Case_Details.case_number +
                                        ")",
                                    timetaken:
                                        res?.data.messages[0].timeTaken + "s",
                                },
                                () =>
                                    this.props.updateCaseAnalysisState(
                                        this.state
                                    )
                            );
                        }
                    });
            } catch (ex) {
                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        this.props.updateCaseAnalysisState(this.state);
                    }
                );
            }
        }
    };

    closeShowAllWorkspacesPopup = (data) => {
        if (data?.length > 0) {
            this.setState(
                {
                    ...this.state,
                    showAllWorkspaces: false,
                    workspace_name: data[0].name,
                    workspace_id: data[0].id,
                    workspace_item_id: data[0].workspace_item_id,
                    openCreateWorkSpacePopUp: false,
                    disableAddToWorkspace: true,
                    moveToAnotherWorkspace: false,
                    openKebabMenu: false,
                    workspace_created_by: parseInt(
                        store?.getState()?.login?.logInState?.user_id
                    ),
                    isSharedWorkspace:
                        parseInt(data[0]?.shared_users) > 1 ? true : false,
                },
                () => {
                    this.props.updateCaseAnalysisState(this.state);
                }
            );
        }
    };

    closeAllWorkspacesPopup = () => {
        this.setState(
            {
                ...this.state,
                showAllWorkspaces: false,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    CloseWorkspaceListPopUp = (data, res) => {
        if (this.state.addToAnotherWorkspace) {
            this.setState(
                {
                    ...this.state,
                    addToAnotherWorkspace: false,
                    openCreateWorkSpacePopUp: false,
                    openKebabMenu: false,
                },
                () => {
                    this.props.updateCaseAnalysisState(this.state);
                }
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    openCreateWorkSpacePopUp:
                        !this.state.openCreateWorkSpacePopUp,
                    disableAddToWorkspace: false,
                    selectedWorkSpaces: data,
                    showAllWorkspaces: data?.length > 1 ? true : false,
                },
                () => {
                    this.props.updateCaseAnalysisState(this.state);
                    if (data?.length === 1 && res?.added?.length === 1) {
                        this.closeShowAllWorkspacesPopup(data);
                    }
                }
            );
        }
    };

    RedirectToWorkspaceItems = () => {
        const { history } = this.props;
        let state = {
            workspace_id: this.state.workspace_id,
            workspace_name: this.state.workspace_name,
            data: {
                id: this.state.workspace_id,
                name: this.state.workspace_name,
                users: this.state.ws_shared_users,
                created_by: this.state.workspace_created_by,
            },
        };
        history("/workspaces", { state: state });
    };

    addToAnotherWorkspace = () => {
        this.setState(
            {
                ...this.state,
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                addToAnotherWorkspace: true,
                moveToAnotherWorkspace: false,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };
    moveToAnotherWorkspace = () => {
        this.setState(
            {
                ...this.state,
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                moveToAnotherWorkspace: true,
                addToAnotherWorkspace: false,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };
    updateCaseDetails = (data) => {
        const {
            caseName,
            Statute_of_Limitations,
            description,
            plaintiff,
            defendant,
            jurisdiction,
            case_number,
            date,
            location,
            legal_issue,
            status,
            witness,
            relevant_documents,
            ...follow_details
        } = data;
        let caseDetails = {
            caseName: data.caseName,
            Statute_of_Limitations: data.Statute_of_Limitations,
            description: data.description,
            plaintiff: data.plaintiff,
            defendant: data.defendant,
            jurisdiction: data.jurisdiction,
            case_number: data.case_number,
            date: formatTime(data.date),
            location: data.location,
            legal_issue: data.legal_issue,
            status: data.status,
            witness: data.witness,
            relevant_documents: data.relevant_documents,
        };

        this.setState(
            {
                ...this.state,
                Case_Details: caseDetails,
                openUploadExcelPopUp: false,
                FollowUp_Details: follow_details,
                isDataFromExcelSheet: true,
            },
            () => {
                this.props.updateCaseAnalysisState(this.state);
            }
        );
    };

    render() {
        const { FollowUp_Details, activeTab } = this.state;
        return (
            <Fragment>
                {this.state.showAllWorkspaces && (
                    <ShowAllWorkspacePopUp
                        selectedWorkspaces={this.state.selectedWorkSpaces}
                        closeShowAllWorkspacesPopup={
                            this.closeShowAllWorkspacesPopup
                        }
                        closepopup={this.closeAllWorkspacesPopup}
                        document_name={this.state.selectedFile?.file?.name}
                    />
                )}

                {this.state.openCreateWorkSpacePopUp && (
                    <WorkSpaceListPopUp
                        workSpaceDisabled={false}
                        state={this.state}
                        query={JSON.stringify({
                            Case_Details: this.state.Case_Details,
                            FollowUp_Details: this.state.FollowUp_Details,
                        })}
                        content={JSON.stringify({
                            caseAnalysisResult: this.state.caseAnalysisResult,
                            FollowUpAnalysis: this.state.FollowUpAnalysis,
                        })}
                        baseContent={""}
                        docsContent={[
                            {
                                content: this.state.Case_Details.plaintiff,
                                fileName:
                                    this.state.Case_Details.plaintiff +
                                    "-" +
                                    this.state.Case_Details.defendant +
                                    "(" +
                                    this.state.Case_Details.case_number +
                                    ")",
                                fileType: "",
                            },
                        ]}
                        document_type={this.state.selectedDocType}
                        document_name={
                            this.state.Case_Details.plaintiff +
                            "-" +
                            this.state.Case_Details.defendant +
                            "(" +
                            this.state.Case_Details.case_number +
                            ")"
                        }
                        handwritten_text_flag={false}
                        timeTaken={this.state.timetaken?.replace("s", "")}
                        CannedQuestionsList={[]}
                        index_path={""}
                        closepopup={(data, res) =>
                            this.CloseWorkspaceListPopUp(data, res)
                        }
                        closeWsListpopup={() => {
                            this.setState(
                                {
                                    ...this.state,
                                    openCreateWorkSpacePopUp:
                                        !this.state.openCreateWorkSpacePopUp,
                                    disableAddToWorkspace:
                                        this.state.addToAnotherWorkspace ||
                                        this.state.moveToAnotherWorkspace
                                            ? this.state.disableAddToWorkspace
                                            : false,
                                },
                                () => {
                                    this.props.updateCaseAnalysisState(
                                        this.state
                                    );
                                }
                            );
                        }}
                        module_id={6}
                        engine_type={this.state.engine_type}
                        workspace_id={this.state.workspace_id}
                        moveToAnotherWorkspace={
                            this.state.moveToAnotherWorkspace
                        }
                        fromWorkspaceId={this.state.workspace_id}
                        fromWorkspaceItemId={this.state.workspace_item_id}
                    />
                )}
                {this.state.isFavoritePopUp && (
                    <CaseAnalysisPopup
                        title={"Case Analysis Favorites"}
                        content={this.state.FavoritesData}
                        closepopup={() => this.closepopup()}
                        callback={this.handleCallback}
                        removeFavoriteCallback={this.RemoveFavourite}
                    />
                )}

                {this.state.openUploadExcelPopUp && (
                    <UploadCaseDetails
                        closeWsListpopup={() => {
                            this.setState(
                                { openUploadExcelPopUp: false },
                                () => {
                                    this.props.updateCaseAnalysisState(
                                        this.state
                                    );
                                }
                            );
                        }}
                        updateData={this.updateCaseDetails}
                    />
                )}
                <div className="rytpaneltoppart m-b-0 p-b-2">
                    <div className="reviewfirstdiv">
                        <div className="reviewfirstdiv">
                            <h2>Case Assessment</h2>
                        </div>
                        {this.state.selectedDocType !== "" && (
                            <div className="fullfieldwidth m-t-none">
                                <button
                                    className="newDoc plus-square-icon"
                                    onClick={() => this.handleNewDoc()}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Review a new document"
                                >
                                    New
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="reviewdescriptionsec">
                        {this.state.selectedDocType !== "" && (
                            <div className="fullfieldwidth m-t-none">
                                <HandleEngineComponent
                                    selectedEngine={this.state.engine_type}
                                    handleEngineChangeCallback={(e, l) => {
                                        this.setState({
                                            ...this.state,
                                            engine_type: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        )}

                        <div className="fullfieldwidth m-t-none">
                            <div
                                className="fieldsec"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Document type"
                            >
                                <select
                                    className="selectfield"
                                    onChange={this.handleDocTypeChange}
                                    value={this.state.selectedDocType}
                                >
                                    <option
                                        selected="selected"
                                        value={""}
                                        disabled
                                        hidden
                                    >
                                        Choose Case Type
                                    </option>
                                    {docTypes_ddl.CaseAnalysis.sort((a, b) =>
                                        a.documentName.localeCompare(
                                            b.documentName
                                        )
                                    ).map((item, i) => (
                                        <option value={item.documentID} key={i}>
                                            {item.documentName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" full_screen midPanel_Container midPanel_Container_gap">
                    <div className="midPanel_ca_Item1 width-0">
                        <div
                            data-scroll
                            ref={this.secondDivRef}
                            id="midPanel"
                            className={"rytpanelmidpart overflow-scroll"}
                        >
                            {store.getState().caseAnalysis.caseAnalysisState
                                ?.caseAnalysisResult !== "" ? (
                                <div className="reviewdatasec overflow-unset mb-100 ml-3">
                                    <div className="header-sticky">
                                        <div
                                            className={`rytpaneltoppart p-r-5  titlesec title-display`}
                                        >
                                            <div style={{ display: "block" }}>
                                                <h3 className="line-height-normal">
                                                    Analysis Time
                                                </h3>
                                                <div className="contentMetadata">
                                                    {this.state.timetaken}
                                                </div>
                                            </div>
                                            {store.getState().login.logInState
                                                .usertypeid !== "4" ? (
                                                <div
                                                    className="reviewdescriptionsec"
                                                    style={{
                                                        width: "fit-content",
                                                    }}
                                                >
                                                    {" "}
                                                    {this.state
                                                        .showWorkspaceButton &&
                                                        (this.state
                                                            .disableAddToWorkspace ? (
                                                            <div className="d-flex flex-wrap">
                                                                Document auto
                                                                saved in
                                                                <div
                                                                    className="text-link m-10"
                                                                    onClick={
                                                                        this
                                                                            .RedirectToWorkspaceItems
                                                                    }
                                                                >
                                                                    {
                                                                        this
                                                                            .state
                                                                            .workspace_name
                                                                    }
                                                                </div>
                                                                Workspace{" "}
                                                                {parseInt(
                                                                    store.getState()
                                                                        .login
                                                                        .logInState
                                                                        .user_id
                                                                ) ===
                                                                    this.state
                                                                        .workspace_created_by && (
                                                                    <div className="kebab-menu-container">
                                                                        <div
                                                                            className="kebab-icon dual-space"
                                                                            onClick={() =>
                                                                                this.setState(
                                                                                    {
                                                                                        openKebabMenu:
                                                                                            !this
                                                                                                .state
                                                                                                .openKebabMenu,
                                                                                    },
                                                                                    () => {
                                                                                        this.props.updateCaseAnalysisState(
                                                                                            this
                                                                                                .state
                                                                                        );
                                                                                    }
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            ⋮
                                                                        </div>
                                                                        {this
                                                                            .state
                                                                            .openKebabMenu && (
                                                                            <div
                                                                                className="menu1"
                                                                                style={
                                                                                    isMobile()
                                                                                        ? {
                                                                                              right: "35px",
                                                                                          }
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="menu-item1"
                                                                                    onClick={
                                                                                        this
                                                                                            .addToAnotherWorkspace
                                                                                    }
                                                                                    style={{
                                                                                        padding:
                                                                                            "10px 15px",
                                                                                        cursor: "pointer",
                                                                                        whiteSpace:
                                                                                            "nowrap",
                                                                                    }}
                                                                                >
                                                                                    Add
                                                                                    item
                                                                                    to
                                                                                    another
                                                                                    workspace
                                                                                </div>
                                                                                <div
                                                                                    className="menu-item1"
                                                                                    onClick={
                                                                                        this
                                                                                            .moveToAnotherWorkspace
                                                                                    }
                                                                                    style={{
                                                                                        padding:
                                                                                            "10px 15px",
                                                                                        cursor: "pointer",
                                                                                        whiteSpace:
                                                                                            "nowrap",
                                                                                    }}
                                                                                >
                                                                                    Move
                                                                                    item
                                                                                    to
                                                                                    another
                                                                                    workspace
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className={`newDoc btn-review plus-square-icon ${
                                                                    this.state
                                                                        .disableAddToWorkspace
                                                                        ? "disable"
                                                                        : ""
                                                                }`}
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            ...this
                                                                                .state,
                                                                            openCreateWorkSpacePopUp: true,
                                                                        },
                                                                        () => {
                                                                            this.props.updateCaseAnalysisState(
                                                                                this
                                                                                    .state
                                                                            );
                                                                        }
                                                                    );
                                                                }}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={
                                                                    "Add to workspace"
                                                                }
                                                            >
                                                                Add To Workspace
                                                            </button>
                                                        ))}
                                                </div>
                                            ) : (
                                                <>
                                                    <div className=" btn-review  "></div>
                                                </>
                                            )}
                                            {followup_prompts_ddl
                                                .personal_injury_Canned_Ques
                                                .length ===
                                            this.state.FollowUpAnalysis
                                                .length ? (
                                                <div
                                                    className={`${
                                                        followup_prompts_ddl
                                                            .personal_injury_Canned_Ques
                                                            .length ===
                                                        this.state
                                                            .FollowUpAnalysis
                                                            .length
                                                            ? "display d-flex"
                                                            : ""
                                                    }`}
                                                >
                                                    <div className="d-flex flex-none">
                                                        <ContentOperations
                                                            Content={
                                                                this.state
                                                                    .caseAnalysisResult
                                                            }
                                                            isSummary={false}
                                                            component="Case Analysis"
                                                            filename={""}
                                                            question={""}
                                                            isFromCaseAnalysis={
                                                                true
                                                            }
                                                            docType={
                                                                "Case Assessment"
                                                            }
                                                            isPrompts={
                                                                this.state
                                                                    .sortedArray
                                                            }
                                                            state={this.state}
                                                        />
                                                        <div
                                                            onClick={() =>
                                                                this.handleCOllapse()
                                                            }
                                                            className="elementwithcollapse flex-none"
                                                        >
                                                            <ul>
                                                                <li>
                                                                    {" "}
                                                                    {this.state
                                                                        .isOpened ? (
                                                                        <CollapseAllIcon />
                                                                    ) : (
                                                                        <ExpandIcon />
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sglereviewsec">
                                        <div className="reviewcontsec">
                                            <div className="readmoredata">
                                                <ReadMoreCollapse
                                                    text={
                                                        store.getState()
                                                            .caseAnalysis
                                                            .caseAnalysisState
                                                            ?.caseAnalysisResult
                                                    }
                                                    maxLength={500}
                                                    isPrompts={
                                                        this.state
                                                            .FollowUpAnalysis
                                                            .length !== 0
                                                            ? true
                                                            : null
                                                    }
                                                    engine_id={
                                                        this.state
                                                            .selectedEngine
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {followup_prompts_ddl.personal_injury_Canned_Ques.map(
                                        (i, j) => {
                                            return (
                                                <div
                                                    className="sglereviewsec"
                                                    key={j}
                                                >
                                                    <div className="accordion_test">
                                                        <section className="accordion">
                                                            <input
                                                                type="checkbox"
                                                                name="collapse2"
                                                                id={`handle2${j}`}
                                                                hidden
                                                            />
                                                            <h2 className="handle">
                                                                <div className="cannedtitlesec">
                                                                    <div className="namenmetadiv">
                                                                        {this.state.FollowUpAnalysis.filter(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.question ===
                                                                                i.value
                                                                        )
                                                                            .length >
                                                                        0 ? (
                                                                            <label
                                                                                for={`handle2${j}`}
                                                                            >
                                                                                <h4>
                                                                                    {
                                                                                        i.value
                                                                                    }{" "}
                                                                                </h4>
                                                                            </label>
                                                                        ) : (
                                                                            <h4>
                                                                                {
                                                                                    i.value
                                                                                }
                                                                            </h4>
                                                                        )}
                                                                        <div className="metadata"></div>
                                                                    </div>
                                                                    {this.state.FollowUpAnalysis.filter(
                                                                        (x) =>
                                                                            x.question ===
                                                                            i.value
                                                                    ).length ===
                                                                    0 ? (
                                                                        <span className="loader"></span>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            <ContentOperations
                                                                                Content={
                                                                                    this.state.FollowUpAnalysis.filter(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.question ===
                                                                                            i.value
                                                                                    )[0]
                                                                                        .response
                                                                                }
                                                                                isSummary={
                                                                                    false
                                                                                }
                                                                                component={
                                                                                    "Case Analysis"
                                                                                }
                                                                                filename={
                                                                                    ""
                                                                                }
                                                                                question={
                                                                                    this.state.FollowUpAnalysis.filter(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.question ===
                                                                                            i.value
                                                                                    )[0]
                                                                                        .question
                                                                                }
                                                                                docType={
                                                                                    docTypes_ddl.CaseAnalysis.filter(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.documentID ===
                                                                                            this
                                                                                                .state
                                                                                                .selectedDocType
                                                                                    )[0]
                                                                                        ?.documentName
                                                                                }
                                                                                isFromCaseAnalysis={
                                                                                    false
                                                                                }
                                                                                enableCollpase={
                                                                                    true
                                                                                }
                                                                                isPrompts={
                                                                                    this
                                                                                        .state
                                                                                        .FollowUpAnalysis
                                                                                }
                                                                                index={
                                                                                    j
                                                                                }
                                                                                collapseAll={
                                                                                    this
                                                                                        .state
                                                                                        .isOpened
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </h2>
                                                            {this.state.FollowUpAnalysis.filter(
                                                                (x) =>
                                                                    x.question ===
                                                                    i.value
                                                            ).length > 0 && (
                                                                <div
                                                                    className="content"
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    <div className="reviewcontsec">
                                                                        <div className="readmoredata">
                                                                            <p
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        "pre-wrap",
                                                                                }}
                                                                                className="note f-14"
                                                                            >
                                                                                {
                                                                                    this.state.FollowUpAnalysis.filter(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.question ===
                                                                                            i.value
                                                                                    )[0]
                                                                                        .response
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            ) : (
                                this.state.selectedDocType !== "" && (
                                    <div className="parent">
                                        <div className="rectangle-box_CID">
                                            <div className="content">
                                                Please provide details to
                                                perform Case Assessment
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <Resizable
                        className={`midPanel_Item2 flex-grow-0 ${
                            this.state.view
                                ? "expandable-border-left  expandable-bar-box-shadow"
                                : "collapse"
                        }`}
                        handleClasses={{
                            top: "pointer-events-none",
                            bottom: "pointer-events-none",
                            left: this.state.view ? "" : "pointer-events-none",
                            topRight: "pointer-events-none",
                            bottomRight: "pointer-events-none",
                            bottomLeft: "pointer-events-none",
                            topLeft: "pointer-events-none",
                        }}
                        defaultSize={{ width: "47%", height: "auto" }}
                        key={"test1"}
                    >
                        {this.state.selectedDocType && (
                            <SvgIconButton
                                isexpanded={this.state.view}
                                togglecallback={this.handleToggleDocumentViewer}
                            />
                        )}

                        {this.state.selectedDocType !== "" && (
                            <div
                                id="botbar"
                                className="rightpaneloverflow mb-6"
                            >
                                <div
                                    className="tabarea tabarea-sticky"
                                    style={{ height: "45px" }}
                                >
                                    <div className="">
                                        <section className="accordion-tabs fav-tabs">
                                            <div>
                                                <button
                                                    className={`accordion-tab ${
                                                        activeTab === 0
                                                            ? "accordion-active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.toggleTab(0)
                                                    }
                                                >
                                                    User Inputs
                                                </button>
                                                {this.state.FavoritesData &&
                                                this.state.FavoritesData.filter(
                                                    (x) =>
                                                        x.case_name ===
                                                        this.state.Case_Details
                                                            .plaintiff +
                                                            "-" +
                                                            this.state
                                                                .Case_Details
                                                                .defendant +
                                                            "(" +
                                                            this.state
                                                                .Case_Details
                                                                .case_number +
                                                            ")"
                                                ).length > 0 ? (
                                                    <button
                                                        title="Favorite"
                                                        className="accordion-tab fav-icon-size"
                                                        onClick={() =>
                                                            this.handleFavourite()
                                                        }
                                                    >
                                                        {" "}
                                                        <FilledHeart></FilledHeart>
                                                    </button>
                                                ) : (
                                                    <button
                                                        title="Favorite"
                                                        className="accordion-tab fav-icon-size"
                                                        onClick={() =>
                                                            this.handleFavourite()
                                                        }
                                                    >
                                                        {" "}
                                                        <RegularHeart />
                                                    </button>
                                                )}
                                                <button className="excel-btn">
                                                    <ExcelIcon
                                                        onClick={() => {
                                                            this.setState(
                                                                {
                                                                    openUploadExcelPopUp: true,
                                                                },
                                                                () => {
                                                                    this.props.updateCaseAnalysisState(
                                                                        this
                                                                            .state
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        className={"excel-icon"}
                                                        title={"Upload Excel"}
                                                    />
                                                </button>
                                            </div>
                                            <div>
                                                {this.state.selectedCase ===
                                                    this.state.Case_Details
                                                        .plaintiff +
                                                        "-" +
                                                        this.state.Case_Details
                                                            .defendant +
                                                        "(" +
                                                        this.state.Case_Details
                                                            .case_number +
                                                        ")" ||
                                                (!this.state.FavoritesData &&
                                                    this.state.FavoritesData.filter(
                                                        (x) =>
                                                            x.case_name ===
                                                            this.state
                                                                .Case_Details
                                                                .plaintiff +
                                                                "-" +
                                                                this.state
                                                                    .Case_Details
                                                                    .defendant +
                                                                "(" +
                                                                this.state
                                                                    .Case_Details
                                                                    .case_number +
                                                                ")"
                                                    ).length > 0) ? (
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(46, 170, 219)",
                                                        }}
                                                        className="generatebut btn-align"
                                                        onClick={handleSubmit}
                                                    >
                                                        re-analyze
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(46, 170, 219)",
                                                        }}
                                                        className="generatebut btn-align"
                                                        onClick={handleSubmit}
                                                    >
                                                        analyze
                                                    </button>
                                                )}

                                                <div className="rytpanellinksec">
                                                    {this.state
                                                        .selectedDocType && (
                                                        <button
                                                            className="favorites_button"
                                                            onClick={
                                                                this
                                                                    .FavouritesPopUp
                                                            }
                                                            title="Favorites"
                                                        >
                                                            <img
                                                                src={
                                                                    favlistlogo
                                                                }
                                                                alt="Favorites"
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <section className="accordion-content">
                                    <article
                                        className={`accordion-item ${
                                            activeTab === 0
                                                ? "accordion-active"
                                                : ""
                                        }`}
                                        data-actab-group="0"
                                        data-actab-id="0"
                                    >
                                        <div className="min-width-scroll">
                                            <div className="accordion-item__container  ">
                                                <div
                                                    className=""
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div className="demandtitle max-width-0">
                                                        Case Details
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_caseDesc">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Case Name
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <input
                                                                    type="text"
                                                                    id="caseName"
                                                                    className="textareafield h-none"
                                                                    placeholder="Case Name"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .caseName
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_caseDesc">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Case Description
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="description"
                                                                    className="textareafield"
                                                                    placeholder="Briefly describe the case"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .description
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_caseDesc m-0">
                                                    <div
                                                        className={`sglecaseanalysis ${
                                                            this.state.isMobile
                                                                ? ""
                                                                : "inline-block"
                                                        }`}
                                                    >
                                                        <div className="fulldrapdropsec">
                                                            <h4>
                                                                <label className="color-red">
                                                                    *
                                                                </label>
                                                                Parties Involved
                                                            </h4>
                                                            <form action="#">
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="plaintiff"
                                                                        type="text"
                                                                        name=""
                                                                        placeholder="Plaintiff"
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .plaintiff
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="defendant"
                                                                        type="text"
                                                                        name=""
                                                                        placeholder="Defendant"
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .defendant
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`sglecaseanalysis ${
                                                            this.state.isMobile
                                                                ? ""
                                                                : "inline-block"
                                                        }`}
                                                    >
                                                        <div className="fulldrapdropsec">
                                                            <h4>
                                                                <label className="color-red">
                                                                    *
                                                                </label>
                                                                Jurisdiction &
                                                                Case Number
                                                            </h4>
                                                            <form action="#">
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="jurisdiction"
                                                                        type="text"
                                                                        name=""
                                                                        placeholder="Name of the Jurisdiction"
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .jurisdiction
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="case_number"
                                                                        type="text"
                                                                        name=""
                                                                        placeholder="Case Number"
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .case_number
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`sglecaseanalysis ${
                                                            this.state.isMobile
                                                                ? ""
                                                                : "inline-block"
                                                        }`}
                                                    >
                                                        <div className="fulldrapdropsec">
                                                            <h4>
                                                                <label className="color-red">
                                                                    *
                                                                </label>
                                                                Date & Location
                                                            </h4>
                                                            <form action="#">
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="date"
                                                                        type="date"
                                                                        name=""
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .date
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="formfullwidth">
                                                                    <input
                                                                        id="location"
                                                                        type="text"
                                                                        name=""
                                                                        placeholder="Location"
                                                                        className="inputfield"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .Case_Details
                                                                                .location
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleValuesChange
                                                                        }
                                                                        autoComplete="off"
                                                                        required
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_LegaIssue">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Legal Issue
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="legal_issue"
                                                                    className="textareafield"
                                                                    placeholder="Briefly describe the legal issues to investigate"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .legal_issue
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_LegaIssue">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Status
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="status"
                                                                    className="textareafield"
                                                                    placeholder="Briefly describe the current status or stage of the case, including any significant developments or milestones reached."
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .status
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_caseDesc">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Statute of
                                                            Limitations
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <input
                                                                    type="text"
                                                                    id="Statute_of_Limitations"
                                                                    className="textareafield h-none"
                                                                    placeholder="Statute of Limitations"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .Statute_of_Limitations
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_caseDesc">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Witnesses
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="witness"
                                                                    className="textareafield"
                                                                    placeholder="Were there any witnesses?"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .witness
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Nature of Incident
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Nature_of_Incident"
                                                                    className="textareafield"
                                                                    placeholder="Nature of Incident"
                                                                    value={
                                                                        FollowUp_Details.Nature_of_Incident
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Injury</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Injury"
                                                                    className="textareafield"
                                                                    placeholder="Describe in detail the nature and extent of the physical injuries sustained by the plaintiff"
                                                                    value={
                                                                        FollowUp_Details.Injury
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Medical Treatment
                                                            received, prognosis,
                                                            permanent
                                                            disabilities
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Treatment_Received"
                                                                    className="textareafield"
                                                                    placeholder="Describe in detail the medical treatment received, prognosis, permanent disabilities"
                                                                    value={
                                                                        FollowUp_Details.Treatment_Received
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Medical Expenses
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Medical_Expense"
                                                                    className="textareafield"
                                                                    placeholder="Medical expenses amount"
                                                                    value={
                                                                        FollowUp_Details.Medical_Expense
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Lost Wages</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Lost_Wages"
                                                                    placeholder="Amount"
                                                                    className="textareafield"
                                                                    value={
                                                                        FollowUp_Details.Lost_Wages
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Downtime</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Downtime"
                                                                    placeholder="Downtime"
                                                                    className="textareafield"
                                                                    value={
                                                                        FollowUp_Details.Downtime
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Pain and Suffering
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Pain_Suffering"
                                                                    className="textareafield"
                                                                    placeholder="Describe the plaintiff's pain and suffering, emotional distress, loss of enjoyment of life, disfigurement, or other non-economic impacts of the injury"
                                                                    value={
                                                                        FollowUp_Details.Pain_Suffering
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Behavior of Parties
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Parties_Behavior"
                                                                    className="textareafield"
                                                                    placeholder="Any information about the behavior or decisions of each party that could have contributed to the accident"
                                                                    value={
                                                                        FollowUp_Details.Parties_Behavior
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Laws and Regulations
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Laws_Regulations"
                                                                    className="textareafield"
                                                                    placeholder="If possible, provide information about the specific laws or regulations that may apply to the behavior of each party"
                                                                    value={
                                                                        FollowUp_Details.Laws_Regulations
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Questions for
                                                            Analysis
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Analysis_Questions"
                                                                    className="textareafield"
                                                                    placeholder="Specify questions or areas that you want the analysis to cover. This could include how past cases have dealt with similar injuries, similar circumstances"
                                                                    value={
                                                                        FollowUp_Details.Analysis_Questions
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Claims and
                                                            Counterclaims
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Claims"
                                                                    className="textareafield"
                                                                    placeholder="What claims are being made by both sides, and any defenses that have been raised"
                                                                    value={
                                                                        FollowUp_Details.Claims
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Evidence</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Evidence"
                                                                    className="textareafield"
                                                                    placeholder="Details about the evidence available, including medical records, accident reports, witness testimonies, photographs, etc"
                                                                    value={
                                                                        FollowUp_Details.Evidence
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Laws and Precedents
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Laws_Precedents"
                                                                    className="textareafield"
                                                                    placeholder="Information about the jurisdiction the case falls under, and any relevant laws and precedents"
                                                                    value={
                                                                        FollowUp_Details.Laws_Precedents
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Legal Team</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Legal_Team"
                                                                    className="textareafield"
                                                                    placeholder="If known, information about the legal team for the opposing side, including their typical strategies, strengths, and weaknesses"
                                                                    value={
                                                                        FollowUp_Details.Legal_Team
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>Liability</h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Liability"
                                                                    className="textareafield"
                                                                    placeholder="Information about who is at fault and the degree of fault"
                                                                    value={
                                                                        FollowUp_Details.Liability
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Insurance Coverage
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Insurance_Coverage"
                                                                    className="textareafield"
                                                                    placeholder="If available, details about the defendant's insurance coverage since insurance often determines the maximum payout"
                                                                    value={
                                                                        FollowUp_Details.Insurance_Coverage
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Plaintiff's
                                                            Requirements
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Plaintiff_Req"
                                                                    className="textareafield"
                                                                    placeholder="What the plaintiff seeks as a settlement, including any non-monetary terms"
                                                                    value={
                                                                        FollowUp_Details.Plaintiff_Req
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_fup">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Defense's Position
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Defendent_Position"
                                                                    className="textareafield"
                                                                    placeholder="The stance of the defense - are they open to settlement? Do they have any conditions?"
                                                                    value={
                                                                        FollowUp_Details.Defendent_Position
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="sgledragdropsec_caseDesc">
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            Additional
                                                            Information
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <textarea
                                                                    id="Additional_Information"
                                                                    className="textareafield"
                                                                    placeholder="Additional Information"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .FollowUp_Details
                                                                            .Additional_Information
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleFollowupValuesChange
                                                                    }
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </section>
                            </div>
                        )}
                    </Resizable>
                    {this.state.isLoading ? (
                        <LoadingSpinner
                            text={
                                this.state?.loadingText
                                    ? this.state?.loadingText
                                    : "Analysis in progress..."
                            }
                        />
                    ) : (
                        ""
                    )}
                    {this.state.isFavoriteLoading ? (
                        <LoadingSpinner text={"Favorite is in progress..."} />
                    ) : (
                        ""
                    )}
                    {this.state.isRemoveFavoriteLoading ? (
                        <LoadingSpinner
                            text={"Removing Favorite is in progress..."}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <Toaster />
            </Fragment>
        );
    }
}
// CaseAnalysisComponent.contextType = WorkspaceContext
const mapStateToProps = (state) => ({
  caseAnalysisState: state.caseAnalysis.caseAnalysisState, // Use the appropriate reducer key
  dashBoardState: state.dashBoard.dashBoardState,
});

const mapDispatchToProps = {
  updateCaseAnalysisState, // This maps your action directly to props
  updateDashBoardState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseAnalysisComponent);
