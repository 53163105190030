import Swal from "sweetalert2";
import getAxiosInstance from "../Components/SupportiveComponents/AxiosRequest.jsx";
import followup_prompts_ddl from "../dropDowns/CaseAnalysisTypes.json";
import store from "../redux/store.js";
import { updateCaseAnalysisState } from "../redux/actions.js";
import { isEqual } from "lodash";

export const handleCaseAnalysis = async () => {
  const token = localStorage.getItem("token");
  let currentState = store.getState().caseAnalysis.caseAnalysisState;
  const formData = new FormData();
  const { Case_Details } = currentState;
  
  formData.append("case_type", currentState.selectedDocType + "_Base_Case");
  formData.append("case_details", JSON.stringify(Case_Details));
  formData.append("engine_type", currentState.engine_type);
  const axiosInstance = getAxiosInstance(token, false);
  
  await axiosInstance
    .post("/performCaseAnalysis", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 180000,
    })
    .then((response) => {
      if (response.data.error_Msg) {
        store.dispatch(
          updateCaseAnalysisState({
            ...currentState,
            isLoading: false,
            caseAnalysisResult: "",
            isAnalysed: false,
          })
        );

        Swal.fire({
          text: "We are unable to analyze the give case, Please try some other case.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        store.dispatch(
          updateCaseAnalysisState({
            ...currentState,
            isLoading: false,
            caseAnalysisResult: response.data.result,
            isAnalysed: true,
            view: false,
            selectedCase:
              currentState.Case_Details.plaintiff +
              "-" +
              currentState.Case_Details.defendant +
              "(" +
              currentState.Case_Details.case_number +
              ")",
          })
        );
      }
    })
    .catch((error) => {
      store.dispatch(
        updateCaseAnalysisState({
          ...currentState,
          isLoading: false,
          caseAnalysisResult: "",
          isAnalysed: false,
        })
      );
      Swal.fire({
        text: "We are unable to analyse the given case, Please try some other case.",
        icon: "error",
        confirmButtonText: "OK",
      });
    });
};

const handleFollowUpAnalysis = (inputs) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const case_type = inputs.caseType;
  var currentState = store.getState().caseAnalysis.caseAnalysisState;
  let case_details = {
    case_details: currentState.Case_Details,
    followup_details: inputs.obj,
  };

  formData.append("case_type", case_type);
  formData.append("case_details", JSON.stringify(case_details));
  formData.append("engine_type", currentState.engine_type);

  const axiosInstance = getAxiosInstance(token, false);
  axiosInstance
    .post("/performFollowUpCaseAnalysis", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 180000,
    })
    .then((response) => {
      if (response.data.error_Msg) {
        store.dispatch(
          updateCaseAnalysisState({
            ...currentState,
            isLoading: false,
            timetaken:
              ((new Date() - currentState.startTime) / 1000).toFixed(2) + "s",
          })
        );

        Swal.fire({
          text: "We are unable to analyze the give case, Please try some other case.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        let obj = {
          question: followup_prompts_ddl.personal_injury_Canned_Ques.filter(
            (x) => x.id === case_type
          )[0].value,
          response: response.data.result,
          time: new Date().toLocaleString(),
        };
        const temp_data = currentState.FollowUpAnalysis;
        temp_data.push(obj);
       

        store.dispatch(
          updateCaseAnalysisState({
            ...currentState,
            FollowUpAnalysis: temp_data,
            timetaken:
              ((new Date() - currentState.startTime) / 1000).toFixed(2) + "s",
            totalTimetaken:
              ((new Date() - currentState.startTime) / 1000).toFixed(2) + "s",
            sortedArray: temp_data,
            showWorkspaceButton: (temp_data.length === followup_prompts_ddl.personal_injury_Canned_Ques.length ? true: currentState?.showWorkspaceButton),
          })
        );
      }
    })
    .catch((error) => {
      store.dispatch(
        updateCaseAnalysisState({
          ...currentState,
          isLoading: false,
        })
      );

      Swal.fire({
        text: "We are unable to analyse the given case, Please try some other case.",
        icon: "error",
        confirmButtonText: "OK",
      });
    });
};

export const handleFollowupSubmit = () => {
  let currentState = store.getState().caseAnalysis.caseAnalysisState;
  let { validation_errors } = currentState;
  const { FollowUp_Details } = currentState;

  let inputs = buildFollowUpInputs(FollowUp_Details);
  let res = handleValidation(inputs);
  for (let index = 0; index < inputs.length; index++) {
    const element = inputs[index];
    if (Object.keys(inputs).length !== 0) {
      if (res.isValid) {
        handleFollowUpAnalysis(element);
        if (index === inputs?.length-1 &&
          currentState.workspace_id > 0 &&
          currentState.workspace_item_id > 0 &&
          currentState.selectedCase ===
            currentState.Case_Details.plaintiff +
              "-" +
              currentState.Case_Details.defendant +
              "(" +
              currentState.Case_Details.case_number +
              ")"
        ) {
          handleWorkspace();

        }
      } else {
        store.dispatch(
          updateCaseAnalysisState({ ...currentState, activeTab: 1 })
        );

        Swal.fire({
          text:
            "Please make sure to fill all the fields in follow up analysis. Field " +
            validation_errors[res.key] +
            " is empty",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      store.dispatch(
        updateCaseAnalysisState({ ...currentState, activeTab: 1 })
      );

      Swal.fire({
        text: "Please make sure to fill all the fields in follow up analysis.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};

const buildFollowUpInputs = (details) => {
  try {
    let obj = {};
    let allDetails = [];
    obj = {
      Nature_of_Incident: details.Nature_of_Incident,
      Injury: details.Injury,
      Treatment_Received: details.Treatment_Received,
      Medical_Expense: details.Medical_Expense,
      Lost_Wages: details.Lost_Wages,
      Downtime: details.Downtime,
      Pain_Suffering: details.Pain_Suffering,
    };
    allDetails.push({ caseType: "Fact_Check", obj: obj });
    allDetails.push({ caseType: "Damage_Assessment", obj: obj });
    obj = {
      Nature_of_Incident: details.Nature_of_Incident,
      Injury: details.Injury,
      Treatment_Received: details.Treatment_Received,
      Parties_Behavior: details.Parties_Behavior,
      Laws_Regulations: details.Laws_Regulations,
      Pain_Suffering: details.Pain_Suffering,
    };
    allDetails.push({
      caseType: "Comparative_Fault_Analysis",
      obj: obj,
    });
    obj = {
      Nature_of_Incident: details.Nature_of_Incident,
      Injury: details.Injury,
      Treatment_Received: details.Treatment_Received,
      Pain_Suffering: details.Pain_Suffering,
      Expert_Testimony: details.Expert_Testimony
        ? details.Expert_Testimony
        : "",
    };
    allDetails.push({ caseType: "Medical_Record", obj: obj });

    obj = {
      Nature_of_Incident: details.Nature_of_Incident,
      Analysis_Questions: details.Analysis_Questions,
    };
    allDetails.push({ caseType: "Precedent_Research", obj: obj });

    obj = {
      Nature_of_Incident: details.Nature_of_Incident,
      Injury: details.Injury,
      Treatment_Received: details.Treatment_Received,
      Medical_Expense: details.Medical_Expense,
      Lost_Wages: details.Lost_Wages,
      Downtime: details.Downtime,
      Pain_Suffering: details.Pain_Suffering,
      Liability: details.Liability,
      Insurance_Coverage: details.Insurance_Coverage,
      Plaintiff_Req: details.Plaintiff_Req,
      Defendent_Position: details.Defendent_Position,
      Precedents: details.Precedents ? details.Precedents : "",
    };
    allDetails.push({ caseType: "Settlement_Analysis", obj: obj });

    obj = {
      Claims: details.Claims,
      Evidence: details.Evidence,
      Laws_Precedents: details.Laws_Precedents,
      Parties: details.Parties ? details.Parties : "",
      Legal_Team: details.Legal_Team,
    };
    allDetails.push({ caseType: "Litigation_Strategy", obj: obj });

    obj = {
      Liability: details.Liability,
      Evidentiary: details.Evidentiary ? details.Evidentiary : "",
      Legal_Precedents: details.Legal_Precedents
        ? details.Legal_Precedents
        : "",
      Opposing_Party: details.Opposing_Party ? details.Opposing_Party : "",
      Plaintiff_Position: details.Plaintiff_Position
        ? details.Plaintiff_Position
        : "",
    };
    allDetails.push({ caseType: "Risk_Assessment", obj: obj });

    return allDetails;
  } catch (error) {
    Swal.fire({
      text: "We are unable to analyze the give case, Please try some other case.",
      icon: "error",
      confirmButtonText: "OK",
    });
    return {};
  }
};

export const handleValidation = (details) => {
  let isValid = true;

  let empty_fields = "";

  // Object.keys(Case_Details).forEach((key) => {
  //   if (Case_Details[key] === "") {
  //     empty_fields = key;
  //     break;
  //   }
  // });
  for (const key in details) {
    if (details.hasOwnProperty(key)) {
      if (
        key !== "Statute_of_Limitations" &&
        key !== "additionalInformation" &&
        (details[key] === "" || details[key] === undefined)
      ) {
        empty_fields = key;
        break;
      }
    }
  }

  if (empty_fields !== "") {
    isValid = false;
  }
  return { isValid: isValid, key: empty_fields };
};

export const handleSubmit = async(event) => {
  event.preventDefault();
  let currentState = store.getState().caseAnalysis.caseAnalysisState;
  store.dispatch(
    updateCaseAnalysisState({
      ...currentState,
      isLoading: true,
      FollowUpAnalysis: [],
      caseAnalysisResult: "",
      startTime: new Date().getTime(),
    })
  );
  const token = localStorage.getItem("token");
  let { validation_errors } = currentState;
  const { Case_Details } = currentState;
  Case_Details.relevant_documents = " ";
  Case_Details.caseName = currentState.Case_Details.caseName;
  let res = handleValidation(Case_Details);

  // If there are no errors, you can proceed with form submission
  if (res.isValid) {
    if (
      currentState.selectedCase !==
      currentState.Case_Details.plaintiff +
        "-" +
        currentState.Case_Details.defendant +
        "(" +
        currentState.Case_Details.case_number +
        ")"
    ) {
      currentState = store.getState().caseAnalysis.caseAnalysisState;
      store.dispatch(
        updateCaseAnalysisState({
          ...currentState,
          FollowUpAnalysis: [],
          disableAddToWorkspace: false,
          workspace_id: 0,
          workspace_item_id: 0,
        })
      );
    }
    
    let favoritedData = currentState?.FavoritesData && currentState?.FavoritesData.filter((x) =>JSON.parse(x.json_data)?.Case_Details.plaintiff === 
                          currentState?.Case_Details.plaintiff && JSON.parse(x.json_data)?.Case_Details.defendant === currentState?.Case_Details.defendant 
                          &&JSON.parse(x.json_data)?.Case_Details.case_number ===  currentState?.Case_Details.case_number)

    if (!currentState?.isDataFromExcelSheet && favoritedData && favoritedData.length > 0) {
      if(!isEqual(currentState?.Case_Details, JSON.parse(favoritedData[0]?.json_data).Case_Details) || 
      !isEqual(currentState?.FollowUp_Details, JSON.parse(favoritedData[0]?.json_data).FollowUp_Details)){
        var jsonData = {
          Case_Details: currentState?.Case_Details,
          FollowUp_Details: currentState?.FollowUp_Details,
        };
  
        const axiosInstance = getAxiosInstance(token, false);
        let formData = new FormData();
        formData.append("favoritesstr", JSON.stringify(jsonData));
        formData.append(
          "fileName",
          JSON.stringify(
            `${currentState.Case_Details.plaintiff}-${currentState.Case_Details.defendant}(${currentState.Case_Details.case_number})`
          )
        );
        //This api is to update data in database if favorited case changed
        axiosInstance.post("/CaseAnalysisFavorite", formData, {timeout: 180000,}).then((response) => {
          if(response?.status === 200){
            //this is to update the changed value in favorite state.
            currentState = store.getState().caseAnalysis.caseAnalysisState;
            currentState?.FavoritesData?.forEach((x, i) => {
              if(JSON.parse(x.json_data)?.Case_Details.plaintiff === 
              currentState?.Case_Details.plaintiff && JSON.parse(x.json_data)?.Case_Details.defendant === currentState?.Case_Details.defendant 
              &&JSON.parse(x.json_data)?.Case_Details.case_number ===  currentState?.Case_Details.case_number){
                  x.json_data = JSON.stringify({
                    Case_Details: currentState?.Case_Details,
                    FollowUp_Details: currentState?.FollowUp_Details,
                  })
              }
            });
          }
        });
      }
    }

    await handleCaseAnalysis();
    handleFollowupSubmit();
  } else {
    currentState = store.getState().caseAnalysis.caseAnalysisState;
    store.dispatch(
      updateCaseAnalysisState({
        ...currentState,
        activeTab: 0,
        isLoading: false,
      })
    );
    Swal.fire({
      text:
        "Please make sure to fill all the fields. Field " +
        validation_errors[res.key] +
        " is empty",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

 const  handleWorkspace = () => {
  let currentState = store.getState().caseAnalysis.caseAnalysisState;
  var ws_item = {
    module_id: 6,
    messages: [
      {
        response: JSON.stringify({
          caseAnalysisResult: currentState?.caseAnalysisResult,
          FollowUpAnalysis: currentState?.FollowUpAnalysis,
        }),
        query: JSON.stringify({
          Case_Details: currentState?.Case_Details,
          FollowUp_Details: currentState?.FollowUp_Details,
        }),
        response_build_time: currentState?.timetaken?.replace("s", ""),
        chat_id: "",
      },
    ],
    documents: [
      {
        content: currentState?.Case_Details.plaintiff,
        name:
          currentState?.Case_Details.plaintiff +
          "-" +
          currentState?.Case_Details.defendant +
          "(" +
          currentState?.Case_Details.case_number +
          ")",
        fileType: "",
      },
    ],
    document_type: currentState?.selectedDocType,
  };

  let obj = {
    workspace_id: currentState?.workspace_id,
    workspace_item_id: currentState?.workspace_item_id,
    ws_item: ws_item,
  };
    const token = localStorage.getItem("token");
    const axiosInstance = getAxiosInstance(token, false);
    axiosInstance.put("/workspace/item", obj, { timeout: 180000 })
      .then((response) => {});
};

