import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import toast, { Toaster } from "react-hot-toast";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner";
import Switch from "react-switch";
import { handleCustomConfirm } from "./Helpers/HelperFunctions";
import useTheme from "../../../contexts/theme";
export const ModuleOperations = (props) => {
    const gridRef = useRef();
    const themeMode = useTheme();
    const [displayLoader, setDisplayLoader] = useState(false);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [rowData, setRowData] = useState([]);
    const UpdateRowData = useCallback((params) => {
        console.log(params, "updateRowData");
    }, []);
    const rowClassRules = useMemo(() => {
        return {
            "custom-grid-row-error": "params.data.isactiveyn === '0'",
        };
    }, []);
    const getRowClass = (params) => {
        return params.data.isactiveyn === "0" ? "custom-grid-row-error" : "";
    };
    const ToggleSwitchData = useCallback((value) => {
        let updatedvalue = value;
        switch (value) {
            case "1":
                updatedvalue = "0";
                break;
            case "0":
            default:
                updatedvalue = "1";
                break;
        }
        return updatedvalue;
    }, []);

    const [columnDefs] = useState([
        {
            field: "module_id",
            headerName: "ID",
            suppressSizeToFit: true,
            width: 50,
            maxWidth: 100,
            headerClass: "text-center",
        },
        { field: "module_display_name", headerName: "Module name" },
        {
            field: "isactiveyn",
            headerName: "Status",
            cellRenderer: (params) => {
                return params.value === "1" ? "Active" : "Inactive";
            },
        },
        {
            field: "isactiveyn",
            headerName: "Action",
            cellRenderer: (params) => {
                console.log(params, "isactiveyn");
                return (
                    <Fragment>
                        <Switch
                            className={
                                params.value === "1" ? "switchYes" : "switch"
                            }
                            onChange={(e) => {
                                handleCustomConfirm(
                                    params,
                                    `This will disable or enable the ${params.data.module_display_name} globally, across all organizations. Do you want to proceed?`,
                                    (e) => {
                                        params.setValue(
                                            ToggleSwitchData(params.value)
                                        );
                                        UpdateRowData(e, params);
                                    }
                                );
                            }}
                            checked={params.value === "1" ? true : false}
                        ></Switch>
                    </Fragment>
                );
            },
        },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
        };
    }, []);

    const onCellValueChanged = useCallback(
        (params) => {
            setDisplayLoader(true);
            const axiosInstance = getAxiosInstance(props.token);
            let formData = new FormData();
            formData.append("module_id", params.data?.module_id);
            formData.append("isactiveyn", params.data?.isactiveyn);
            axiosInstance
                .post("/admin/changeModuleStatus", formData, {
                    crossDomain: true,
                    timeout: 300000,
                })
                .then((response) => {
                    console.log(response, "Fetch All Organizations");
                    if (response.data.length > 0) {
                        switch (response.data[0]["status_code"]) {
                            case 202:
                                toast.success(
                                    "module active status updated successfully!"
                                );
                                break;
                            case 208:
                                toast.info(
                                    "request to change module status made already!"
                                );
                                break;
                            case 500:
                            case 404:
                                toast.error("something went wrong!");
                                break;
                            default:
                                toast.error("something went wrong!");
                                break;
                        }
                    } else {
                        toast.error("something went wrong!");
                    }
                })
                .catch((error) => {
                    console.error(`Something went wrong ${error}`);
                    toast.error("something went wrong!");
                })
                .finally(() => {
                    setDisplayLoader(false);
                });
        },
        [props.token]
    );

    const onGridReady = useCallback(
        (params) => {
            const axiosInstance = getAxiosInstance(props.token);

            axiosInstance
                .get("/admin/getAllModules", {
                    crossDomain: true,
                    timeout: 300000,
                })
                .then((response) => {
                    console.log(response, "Fetch All Organizations");
                    setRowData(response.data);
                })
                .catch((error) => {
                    console.error(`Something went wrong ${error}`);
                });
        },
        [props.token]
    );

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById("filter-text-box").value
        );
    }, []);

    return (
        <>
            <Toaster />
            {displayLoader ? <LoadingSpinner text={"Loading..."} /> : <></>}
            <div className="regBody">
                <div className="header custom-grid-header">
                    <h3>Manage Modules Globally</h3>
                </div>
                <div className="ag-grid-user-management-wrapper">
                    <div
                        className="logInfields"
                        style={{ marginBottom: "20px" }}
                    >
                        <label htmlFor="filter-text-box">Quick Filter:</label>
                        <input
                            type="search"
                            id="filter-text-box"
                            placeholder="Filter by name, email"
                            className="filtertextbox"
                            autoComplete="off"
                            onInput={onFilterTextBoxChanged}
                        />
                    </div>

                    <div
                        style={gridStyle}
                        className="ag-grid-custom ag-theme-quartz"
                    >
                        <AgGridReact
                            className={`${
                                themeMode === "light"
                                    ? "ag-theme-quartz"
                                    : "ag-theme-quartz-dark"
                            } custom-ag-grid-height ${themeMode}`}
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowClassRules={rowClassRules}
                            getRowClass={getRowClass}
                            animateRows={true}
                            pagination={true}
                            paginationPageSize={25}
                            onCellValueChanged={onCellValueChanged}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
