import React, { Fragment, useEffect, useState } from "react";

// import { Component } from "ag-grid-community";
import { useDispatch } from "react-redux";
import { updateDashBoardState } from "../../../redux/actions";
import { Link } from "react-router-dom";
import { Shimmer } from "react-shimmer";
import { inherits } from "util";

/**
 *
 * @param {Array} FilesInfo
 * @returns
 */
export default function DashBoardCards({
    FilesInfo,
    title,
    module_id,
    source,
    isLoading,
}) {
    // const dashBoardParams = useSelector((state) => state.review.dashBoardState);s
    const dispatch = useDispatch();

    const [FilesInfos, setFilesInfos] = useState(FilesInfo);

    useEffect(() => {
        setFilesInfos(FilesInfo);
    }, [FilesInfo]);
    const handleLinkClick = (
        source,
        moduleId,
        fileName,
        selectedFileDocType,
        activeTab = 1,
        linkType = "",
        batchId = null,
        favorite_id
    ) => {
        let dashBoardState = {
            source: source,
            moduleId: moduleId,
            fileName: fileName,
            activeTab: activeTab,
            selectedDocType: selectedFileDocType,
            linkType: linkType,
            batchId: batchId,
            favorite_id:favorite_id
        };
        // Dispatch the action creator to update dashBoardState
        dispatch(updateDashBoardState(dashBoardState));
    };

    return (
        <Fragment>
            {FilesInfos && FilesInfos.length > 0 && (
                <li className="li__cards__item">
                    <div className="div__card">
                        <div className="div__card__content">
                            <div className="div__card__title">
                                <span>{title}</span>
                                <span>
                                    {FilesInfos && FilesInfos.length > 3 && (
                                        <Link
                                            to={
                                                module_id === 1
                                                    ? "/Review"
                                                    : module_id === 2
                                                    ? "/Summarize"
                                                    : module_id === 3
                                                    ? "/ChatWithDocument"
                                                    : module_id === 6
                                                    ? "/Case_Analysis"
                                                    : "/"
                                            }
                                            onClick={() =>
                                                handleLinkClick(
                                                    source,
                                                    module_id,
                                                    "",
                                                    "",
                                                    1,
                                                    "seeall",
                                                    0
                                                )
                                            }
                                        >
                                            see all
                                        </Link>
                                    )}
                                </span>
                            </div>
                            <ul>
                                {FilesInfos.slice(0, 3).map((x) => (
                                    <li key={x.favorite_id}>
                                        <Link
                                            to={
                                                module_id === 1
                                                    ? "/Review"
                                                    : module_id === 2
                                                    ? "/Summarize"
                                                    : module_id === 3
                                                    ? "/ChatWithDocument"
                                                    : module_id === 6
                                                    ? "/Case_Analysis"
                                                    : "/"
                                            }
                                            // state={{ from: "favorite" }}
                                            onClick={() => {
                                                handleLinkClick(
                                                    source,
                                                    module_id,
                                                    x.file_name,
                                                    x.document_type,
                                                    module_id === 3 ? 0 : 1,
                                                    "",
                                                    x.batch_id,
                                                    x.favorite_id
                                                );
                                            }}
                                            className="card__text"
                                        >
                                            {x.file_name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </li>
            )}

            {FilesInfos && FilesInfos.length === 0 && isLoading && (
                <li className="li__cards__item">
                    <Shimmer
                        className="div__card"
                        duration={30}
                        height={100}
                        width={inherits}
                    ></Shimmer>
                </li>
            )}
        </Fragment>
    );
}

// export default FavoriteCard;
