import {
    faSpinner,
    faCaretRight,
    faCaretDown,
    faShareAlt,
    faPenToSquare,
    faFloppyDisk,
    faRefresh,
    faStop,
    faAngleUp,
    faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "../../../contexts/theme";
import ExpandIconSvg from "../../../images/Expand.svg";
import CollapseIconSvg from "../../../images/collapse.svg";
import CopyIconSvg from "../../../images/copy.svg";
import ExportIconSvg from "../../../images/Export.svg";
import ExpandIconSvgD from "../../../images/ExpandDark.svg";
import CollapseIconSvgD from "../../../images/collapseDark.svg";
import CopyIconSvgD from "../../../images/copyDark.svg";
import ExportIconSvgD from "../../../images/ExportDark.svg";
import React, { useCallback, useEffect, useRef, useState } from "react";

export const RefreshIcon = () => {
    const { themeMode } = useTheme();
     return themeMode === "light" ? (
         <FontAwesomeIcon icon={faRefresh} style={{ color: "f0da" }} />
     ) : (
         <>
             {" "}
             <FontAwesomeIcon icon={faRefresh} style={{ color: "white" }} />
         </>
     );
    
};

export const CancelIcon = () => {
    const { themeMode } = useTheme();
    return themeMode === "light" ? (
        <FontAwesomeIcon icon={faStop} style={{ color: "f0da" }} />
    ) : (
        <>
            {" "}
            <FontAwesomeIcon icon={faStop} style={{ color: "white" }} />
        </>
    );
};

export const CopyIcon = () => {
    const { themeMode } = useTheme();
    return (
        <>
            {themeMode === "light" ? (
                <img src={CopyIconSvg} alt="Copy" title="COPY" />
            ) : (
                <img src={CopyIconSvgD} alt="Copy" title="COPY" />
            )}
        </>
    );
};
export const ExportIcon = () => {
    const { themeMode } = useTheme();
    return (
        <>
            {" "}
            {themeMode === "light" ? (
                <img
                    src={ExportIconSvg}
                    alt="Export to word"
                    title="Export to word"
                />
            ) : (
                <img
                    src={ExportIconSvgD}
                    alt="Export to Word"
                    title="Export to word"
                />
            )}
        </>
    );
};
export const ExpandIcon = () => {
    const { themeMode } = useTheme();
    return (
        <>
            {" "}
            {themeMode === "light" ? (
                <img src={ExpandIconSvg} alt="Expand" title="Expand"></img>
            ) : (
                <img src={ExpandIconSvgD} alt="Expand" title="Expand" />
            )}
        </>
    );
};

export const CollapseAllIcon = () => {
    const { themeMode } = useTheme();
    return (
        <>
            {" "}
            {themeMode === "light" ? (
                <img src={CollapseIconSvg} alt="Collapse" title="Collapse" />
            ) : (
                <img src={CollapseIconSvgD} alt="Collapse" title="Collapse" />
            )}
        </>
    );
};

export const Spinning = () => {
    return <FontAwesomeIcon icon={faSpinner} spin />;
};


export const ExpandCaret = () => {
     const { themeMode } = useTheme();
    return themeMode === "light" ? (
        <FontAwesomeIcon
            icon={faCaretRight}
            style={{ color: "f0da"}}
        />

    ) : (
        <>
            {" "}
            <FontAwesomeIcon
                icon={faCaretRight}
                style={{ color: "white" }}
            />
        </>
    );
};

export const CollapseCaret = () => {
     const { themeMode } = useTheme();
    return themeMode === "light" ? (
        <>
            {" "}
            <FontAwesomeIcon icon={faCaretDown} style={{ color: "f0da" }} />
        </>
    ) : (
        <>
            {" "}
            <FontAwesomeIcon icon={faCaretDown} style={{ color: "white" }} />
        </>
    );
}

export const SharIcon=({onClick})=>{
    return <FontAwesomeIcon
    title="SHARE"
    style={{cursor:'pointer'}}
    icon={faShareAlt}
    color="#2eb1e3"
    onClick={onClick}
    size={24}
  />
}


export const FeedBackIcon = ({onClick}) => {
    const [position, setPosition] = useState({ bottom: 200, right: 50 });
    const feedbackIconRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [hasDragged, setHasDragged] = useState(false);
    const dragThreshold = 2;
  
    const handleMouseDown = (e) => {
      setIsDragging(true);
      setHasDragged(false);
      const rect = feedbackIconRef.current.getBoundingClientRect();
      setStartPos({ x: e.clientX, y: e.clientY });
      setOffset({
        x: e.clientX - rect.right,
        y: e.clientY - rect.bottom,
      });
      document.body.style.userSelect = 'none'; // Prevent text selection during drag
    };
  
    const handleMouseMove = useCallback((e) => {
      if (isDragging) {
        const deltaX = e.clientX - startPos.x;
        const deltaY = e.clientY - startPos.y;
        if (Math.abs(deltaX) > dragThreshold || Math.abs(deltaY) > dragThreshold) {
          setHasDragged(true);
          setPosition({
            bottom: window.innerHeight - e.clientY - offset.y - 50,
            right: window.innerWidth - e.clientX - offset.x - 60,
          });
        }
      }
    },[isDragging, offset, startPos])
  
    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.userSelect = ''; // Re-enable text selection
    };
  
    const handleClick = () => {
      if (!hasDragged) {
        onClick()
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging, offset, handleMouseMove]);
  
    return (
      <div
        className="feedback-icon"
        ref={feedbackIconRef}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        style={{ bottom: `${position.bottom}px`, right: `${position.right}px` }}
      >
        <button
          className="bg-brand-500 rounded-full px-3 h-12 text-white flex items-center group"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            fill="currentColor"
            viewBox="0 0 256 256"
            className="w-6 h-6"
          >
            <rect width="256" height="256" fill="none"></rect>
            <path
              d="M132,216H47.7a7.6,7.6,0,0,1-7.7-7.7V124a92,92,0,0,1,92-92h0a92,92,0,0,1,92,92h0A92,92,0,0,1,132,216Z"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="16"
            ></path>
            <circle cx="132" cy="128" r="12"></circle>
            <circle cx="84" cy="128" r="12"></circle>
            <circle cx="180" cy="128" r="12"></circle>
          </svg>
          <span className="max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-linear">
            <span className="pl-2"></span>Feedback
          </span>
        </button>
      </div>
    );
  };
  

  export const GoToTopIcon =({style, className, onClick, title})=>{
    return <FontAwesomeIcon icon={faAngleUp} style={style} className={className} onClick={onClick} title={title ? title : "Go to top"}/>
  }
  


export const EditIcon = ({onClick,className,style})=>{
    return <FontAwesomeIcon 
    className={className}
    onClick={onClick}
    size={24}
    title="EDIT"
    style={style}
    icon={faPenToSquare} />
}


export const SaveIcon =({onClick, className, style})=>{
    return <FontAwesomeIcon 
    className={className}
    onClick={onClick}
    size={24}
    title="SAVE"
    style={style}
    icon={faFloppyDisk} />
}


export const ExcelIcon =({onClick, className, style, title})=>{
  return <FontAwesomeIcon icon={faFileExcel} onClick={onClick} className={className} style={style} title={title}/>
}