import React, { Fragment, useEffect, useState } from "react";
import { CustomTable } from "../../../SupportiveComponents/AgGridTemplates/CustomAgGrid.jsx";
import getAxiosInstance from "../../../SupportiveComponents/AxiosRequest.jsx";
export const UserDeletion = (props) => {
    const [users, setUsers] = useState([]);
    const userId = localStorage.getItem("user_id");
    const [isRefreshGrid, SetIsRefreshGrid] = useState(false);
    const GridRef = React.createRef();

    const DeleteUser = (user_id) => {
        SetIsRefreshGrid(false);
        console.log("Triggered User ID", user_id);
        try {
            const formData = new FormData();
            formData.append("user_id", user_id);
            new Date().getTime();
            // FavoriteFile
            const axiosInstance = getAxiosInstance(props.token);
            axiosInstance
                .post("/admin/deleteOrgUser", formData, {
                    headers: {},
                })
                .then((response) => {
                    console.log(response);
                    SetIsRefreshGrid(true);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            console.error("Error:", error);
        }
    };
    useEffect(() => {
        const getUsersByOrganization = () => {
            let user_id = localStorage.getItem("user_id");
            try {
                new Date().getTime();
                // FavoriteFile
                const axiosInstance = getAxiosInstance(props.token);
                axiosInstance
                    .get("/admin/getOrgDatav2/" + user_id, {
                        headers: {},
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data.error_Msg) {
                            setUsers([]);
                        } else {
                            console.log(`assigning users`, response.data);
                            setUsers(response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                console.error("Error:", error);
            }
        };
        getUsersByOrganization();
    }, [props, isRefreshGrid]);

    return (
        <div className="regBody">
            <div className="header">
                <h3>Remove A USER</h3>
            </div>
            <div className="gridbody">
                <Fragment>
                    {users.length > 0 ? (
                        <Fragment>
                            <CustomTable
                                gridRef={GridRef}
                                rowdata={users}
                                columndefs={[
                                    {
                                        field: "full_name",
                                        headerName: "Full name",
                                        suppressSizeToFit: true,
                                        headerClass: "text-center",
                                    },
                                    {
                                        field: "email",
                                        headerName: "Email",
                                        sortable: true,
                                        resizable: true,
                                        filter: true,
                                        cellStyle: {},
                                        suppressSizeToFit: false,
                                    }, //document_type
                                    {
                                        field: "user_name",
                                        headerName: "user name",
                                        sortable: true,
                                        resizable: true,
                                        filter: true,
                                        suppressSizeToFit: false,
                                    },
                                    {
                                        field: "locked",
                                        headerName: "Is Account Locked",
                                        sortable: true,
                                        resizable: true,
                                        filter: true,
                                        suppressSizeToFit: false,
                                    },
                                    {
                                        field: "createdby",
                                        headerName: "created by",
                                        sortable: true,
                                        resizable: true,
                                        filter: true,
                                        suppressSizeToFit: false,
                                    },
                                    {
                                        field: "user_id",
                                        headerName: "Actions ",
                                        cellRenderer: (params) => {
                                            console.log(params, "Test params");
                                            return (
                                                <Fragment>
                                                    <button
                                                        title="Delete User"
                                                        className="reject-btn"
                                                        onClick={() => {
                                                            DeleteUser(
                                                                params.data
                                                                    .user_id
                                                            );
                                                        }}
                                                        disabled={parseInt(userId) === params?.data?.user_id} 
                                                    >
                                                        Delete
                                                    </button>
                                                </Fragment>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </Fragment>
                    ) : (
                        <></>
                    )}
                </Fragment>
            </div>
        </div>
    );
};
