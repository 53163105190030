import {
  faHeart,
  faX,
  faTrashCan,
  faExternalLinkAlt,
  faPen,
  faFolder,
  faCaretRight,
  faCaretDown,
  faEye,
  faEyeSlash,
  faDownLeftAndUpRightToCenter,
  faArrowLeft,
  faUpRightAndDownLeftFromCenter,
  // faFileExport
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import ExportIconSvg from "../../../../images/chat-export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function FilledHeart(props) {
  return (
    <FontAwesomeIcon
      title={props.title ? props.title : ""}
      icon={faHeart}
      style={{ color: "#2eaadb" }}
    ></FontAwesomeIcon>
  );
}

export function RegularHeart(props) {
  return (
    <FontAwesomeIcon
      title={props.title ? props.title : ""}
      icon={farHeart}
      style={{ color: "#2eb1e3" }}
    ></FontAwesomeIcon>
  );
}

export function XIcon({Style}) {
  return <FontAwesomeIcon icon={faX} style={Style? Style:{ color: "red" }} />;
}
export function TrashCanIcon() {
  return <FontAwesomeIcon icon={faTrashCan} style={{ color: "#d2d5da" }} />;
}

export function ExternalLinkIcon() {
  return (
    <FontAwesomeIcon
      title="click to view document"
      className="space-inline"
      icon={faExternalLinkAlt}
    />
  );
}

export function PencilIcon() {
  return <FontAwesomeIcon icon={faPen} style={{ color: "#2eb1e3" }} />;
}

export function FolderIcon() {
  return <FontAwesomeIcon icon={faFolder} style={{ color: "#2eb1e3" }} />;
}

export function ShowSubItems({ onClick, style, className }) {
  return (
    <FontAwesomeIcon
      icon={faCaretRight}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
}

export function CloseSubItems({ onClick, style, className }) {
  return (
    <FontAwesomeIcon
      icon={faCaretDown}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
}
export function ShowPassword({ onClick, title, className }) {
  return (
    <FontAwesomeIcon
      icon={faEye}
      title={title ? title : "show password"}
      onClick={onClick}
      style={{
        color: "#2eb1e3",
        position: "absolute",
        right: "10px", // Adjust as needed
        cursor: "pointer",
      }}
      className={className ? className : ""}
    />
  );
}

export function HidePassword({ onClick }) {
  return (
    <FontAwesomeIcon
      icon={faEyeSlash}
      title="hide password"
      onClick={onClick}
      style={{
        color: "#2eb1e3",
        position: "absolute",
        right: "10px", // Adjust as needed
        cursor: "pointer",
      }}
    />
  );
}

export function BackArrow({ onClick, style, className, title }) {
  return (
    <FontAwesomeIcon
      icon={faArrowLeft}
      title={title ? title : "Go Back"}
      onClick={onClick}
      style={style}
    />
  );
}


export function Collapse({title}){
  return <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className="doc-color" title={title?title:"Collapse"}/>
}

export function Expand({title}){
  return <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className="doc-color" title={title?title:"Expand"}/>
}

export const Export =({title, onClick})=>{
    return <img
        className="doc-color"
        src={ExportIconSvg}
        alt="Export to word"
        title={title?title:"Export to word"}
        onClick={onClick}
    />

  // return <FontAwesomeIcon icon={faFileExport}  className="doc-color" title="Export"/>
}