import favlistlogo from "../../images/FavoriteList.svg";
import historylogo from "../../images/HistoryList.svg";

export const CustomHistoryFavoriteLinks = ({
    toggleFavoritescallback,
    toggleHistoryCallback,
}) => {
    return (
        <div className="rytpanellinksec">
            <button
                className="favorites_button"
                onClick={toggleFavoritescallback}
                title="Favorites"
            >
                <img src={favlistlogo} alt="Favorites" />
            </button>
            <button
                className="favorites_button"
                onClick={toggleHistoryCallback}
                title="History"
            >
                <img src={historylogo} alt="History" />
            </button>
        </div>
    );
};
