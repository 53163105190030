import getAxiosInstance from "../SupportiveComponents/AxiosRequest";
import Swal from "sweetalert2";
export const isMobile = () => {
    return navigator.userAgent.toLowerCase().includes("mobile");
};

export const isWindows = () => {
    return navigator.userAgent.toLowerCase().includes("windows");
};

export const handleRecaptchaToken = (token, callbacktoUpdateVerifiedStatus) => {
      
    try {
        const formData = new FormData();
        formData.append("token", token);
        const axiosInstance = getAxiosInstance(null);
        axiosInstance
            .post("/verifyrecaptcha", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                timeout: 300000,
            })
            .then((response) => {
                console.log("response", response);
               
                callbacktoUpdateVerifiedStatus(response?.data?.success);
                
            })
            .catch((error) => {
                console.log("From API: ", error);
                let error_text =
                    "Error while verifying recaptcha, please try after some time.";

                Swal.fire({
                    text: error_text,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    } catch (error) {
        //empty
    }
   
};

export const handlePromptWithList = (
    htmlStr,
    callbacktoclose,
    AddFileToBlobListCallback,
    blobFilesList,
    blobobj,
    updatestatecallbackfn
) => {
    console.info("prompts data");
      Swal.fire({
          html: htmlStr,
          text: htmlStr,
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#2eaadb",
          cancelButtonTextColor: "#2eaadb",
          cancelButtonColor: "#2eaadb",
          confirmButtonText: "Yes",
          focusCancel: true,
          confirmButtonTextColor: "#2eaadb",
          preConfirm: () => {
              // Retrieve form values
              const blobfileslistcheckbox = document.getElementsByName(
                  "blobfileslistcheckbox"
              );
              console.log("blob files values:", blobfileslistcheckbox);
              let arrayofFileNames = [];

              for (let i = 0; i < blobfileslistcheckbox.length; i++) {
                  if (blobfileslistcheckbox[i].checked) {
                      arrayofFileNames.push(blobfileslistcheckbox[i].value);
                  }
              }
              if (arrayofFileNames.length === 0) {
                  Swal.showValidationMessage(
                      "you need to select atleast one file"
                  );
                  return;
              }
              return arrayofFileNames;
          },
      }).then((result) => {
          if (result.isConfirmed) {
              blobFilesList = blobFilesList.filter(function (el) {
                  console.log(el, result);
                  return result.value.indexOf(el.name) < 0;
              }); //callbacktoclose(blobFilesList);
              console.log(blobFilesList, blobobj, updatestatecallbackfn);
              AddFileToBlobListCallback({
                  blobFilesList,
                  blobobj,
                  updatestatecallbackfn,
              });
          }
      });
};

export const   dataURLtoFile=(dataurl, filename)=> {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}
