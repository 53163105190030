import HelperMessages from "../../Helpers/HelperMessages";

const initialState = {
    chatWithInternalDocsState: {
        isBottomPanelOpen: true,
        selectedDocType: "",
        selectedEngine: 2,
        chatResult: "",
        promptDisplayed:HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        activeTab: 0,
        isLoading: false,
        chatTimer: "",
        Chats: [],
        inputValue: "",
        loaderText: "Analysis in progress...",
        selectedCanned_Ques: [],
        docSourceMap: {},
        fileDDL: [],
        matchingOptions: [],
        inputSource: "",
        source: "",
        focusedOptionIndex: -1,
        isFileddlOpen: false,
        reqStartTime: new Date().getTime(),
        isContentExpanded: true,
        tabID: sessionStorage.getItem('tabID')
    },
};

export const chatWithInternalDocsReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_CHATWITHINTERNALDOC_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, chatWithInternalDocsState: action.payload.data };
        // Add other cases for different components' states
        case 'RESET_CHATWITHINTERNALDOC_STATE':
            return initialState;
    default:
        return state;
    }
};
