import { useState } from "react";
import { ExpandCaret, CollapseCaret } from "../../SupportiveComponents/FontAwesomeComponents/HelperIcons";
import { PropTypes } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FAQS from "../../../dropDowns/TrialFAQ.json";
import ReactMarkdown from "react-markdown";
import { CustomVideoPlayer } from "../../SupportiveComponents/VideoPlayer";
import { ReactAdobeViewer } from "../../SupportiveComponents/DocumentComponents/DocViewer";
import QuickGuid from "../../../images/LawBotica-Quick_Start_Guide.pdf"
import { saveAs } from "file-saver";

function CustomTabPanel(props) {
    const { value, index, ...other } = props;

  
    return (
        <div
            role="tabpanel"
            style={{
                height: "75%",
            }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className="trial_help_page_content"
            {...other}
        >
          
        </div>
    );
}

export const HelpTrialPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };
    const handleTabChange = (event, newValue) => {
        console.log(`new value ${newValue}`)
        setActiveTab(newValue);
    };
    
    return (
        <>
            <div className="regBody tabsBody">
                <div className="header">
                    <h3>HELP</h3>
                </div>
                <div className="tabs_page">
                    <div
                        style={{
                            borderBottom: "1px solid #4d5a7a",
                        }}
                    >
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            <Tab label="QUICK START GUIDE" {...a11yProps(0)} />
                            <Tab label="HOW-TO VIDEOS" {...a11yProps(1)} />
                            <Tab label="FAQS" {...a11yProps(2)} />
                        </Tabs>
                    </div>
                    <>
                    <CustomTabPanel value={activeTab} index={0}>
                        <HelpButtonsQuickTour/>
                        </CustomTabPanel>
                        <CustomTabPanel value={activeTab} index={1}>
                            <HelpTrialVideosPage/>
                        </CustomTabPanel>
                        <CustomTabPanel
                            value={activeTab}
                            index={2}>                            
                            <TrialFAQPage />
                        </CustomTabPanel>
                    </>
                </div>
            </div>
        </>
    );
};

export const HelpButtonsQuickTour = () => {

    const [display, setDisplayVal] = useState(true);


    return (
        <>
            <div className="flex-grid buttons-flex titlesec">
                <button
                    type="button"
                    className="help-buttons MuiTab-textColorPrimary"
                    onClick={() => {
                        saveAs(QuickGuid, `QUICK START GUIDE.pdf`);
                    }}
                >
                    {" "}
                    DOWNLOAD GUIDE
                </button>
                <button
                    type="button"
                    className="help-buttons MuiTab-textColorPrimary"
                    onClick={() => {
                        setDisplayVal(!display);
                    }}
                >
                    {" "}
                    {!display && `VIEW GUIDE`} {display && `HIDE GUIDE`}{" "}
                </button>{" "}
            </div>
            {display && (
                <div style={{ height: "100vh" }}>
                    <ReactAdobeViewer
                        url={QuickGuid}
                        name={"Quick Start Guide"}
                    />{" "}
                </div>
            )}
        </>
    );
}

export const HelpTrialVideosPage = () => {

   

    return (
        <>
            <h3>Videos</h3>
            <br />
            <p>
                Welcome to the How-To Videos section of our help page! Here,
                you'll find a collection of instructional videos designed to
                guide you through the key features of your LawBotica trial
                account. These videos provide step-by-step instructions on how
                to effectively use our platform, from uploading and reviewing
                documents to summarizing content and interacting with case
                files. Whether you're new to LawBotica or looking to refine your
                skills, these videos are here to help you get the most out of
                your trial experience.
            </p>

            <div className="trial_video_content_wrapper">
                <div className="video_item">
                    <CustomVideoPlayer
                        url="https://www.youtube.com/embed/h0KJF_tEmZ4?si=QtkceIQAgh_VuYff"
                    ></CustomVideoPlayer>

                    <p>
                        Empower your legal team with our AI-powered Document
                        Review module, built on a custom Legal Language Model
                        tailored for the legal industry. Upload your legal
                        documents to receive detailed analysis, critical
                        insights, and practical recommendations. Streamline your
                        review process and make informed decisions faster.
                    </p>
                </div>
                <div className="video_item">
                    {" "}
                    <CustomVideoPlayer url="https://www.youtube.com/embed/mDehWCwPrVM?si=TwhiVM8q2JSzIUGO"></CustomVideoPlayer>
                    <p>
                        Our Document Summarization module, built with a custom
                        Legal Language Model, offers a quick and efficient way
                        to digest complex legal documents. Whether it's court
                        opinions, testimonies, or depositions, our tool provides
                        clear, concise summaries that save you time and enhance
                        your understanding.
                    </p>
                </div>
                <div className="video_item">
                    <CustomVideoPlayer url="https://www.youtube.com/embed/lOyRbAgUCq0?si=Qr3uWIMHCCSQIYb-"></CustomVideoPlayer>

                    <p>
                        Unlock the potential of your legal documents with our
                        Document Chat module. Powered by a custom Legal Language
                        Model, this tool allows you to ask questions and get
                        precise, contextually relevant answers directly from
                        your documents.
                    </p>
                </div>
                <div className="video_item">
                    {" "}
                    <CustomVideoPlayer url="https://www.youtube.com/embed/t0vbTTc0d90?si=i1WQoxwgWPgIatAx"></CustomVideoPlayer>
                    <p>
                        Our comprehensive case assessment module, specializing
                        in personal injury cases, empowers legal professionals
                        to make informed decisions, develop robust legal
                        strategies, and achieve optimal outcomes.
                    </p>
                </div>
            </div>
        </>
    );
}


export const TrialFAQPage = () => {
    return (
        <>
            <h3>Frequently asked questions</h3>
            <br/>
            <p>
                Frequently asked questions Welcome to the FAQs section of our
                help page! Here, you'll find answers to common questions about
                using your LawBotica trial account. This section aims to provide
                you with detailed information on features, account management,
                and data security to help you make the most of your trial
                experience.
            </p>
            <CustomAccordion />
        </>
    );
};

export const CustomAccordion = () => {

    return (
        <>
            <div className="custom_accordion">
                {FAQS?.FAQS?.map((x, i) => {
                    return (
                        <CustomAccordionComponent
                            key={i}
                            heading={x?.question}
                            content={x?.markup_answer}
                        />
                    );
                })}
            </div>
        </>
    );
}
  
export const CustomAccordionComponent = ({heading,content}) => {
    const [isExpanded, setIsExpanded] = useState(false);


    return (
        <>
                    <>
                <div className={`custom_accordion_faq  ${isExpanded ? "expand" : "collapse" }`}>
                            <CustomAccordionComponentHeader heading={heading} isExpanded={isExpanded} setExanded = {setIsExpanded} />
                            <CustomAccordionComponentBody content={content} />
                        </div>
                    </>
            
        </>
    );
};

export const CustomAccordionComponentHeader = ({ heading, isExpanded, setExanded }) => {
  return (
      <>
          <h3
              className="custom_accordion_faq_header"
              onClick={() => {
                  setExanded(!isExpanded);
              }}
          >
              <span>
                  {heading} &nbsp; &nbsp; &nbsp;{" "}
                  {!isExpanded ? <ExpandCaret /> : <CollapseCaret />}
              </span>
          </h3>
      </>
  );
}

export const CustomAccordionComponentBody = ({ content }) => {
    return (
        <>
            <div className="custom_accordion_faq_body">
                <ReactMarkdown>{content}</ReactMarkdown>
            </div>
        </>
    );
}
CustomAccordionComponent.propTypes = {heading: PropTypes.string, content: PropTypes.string};
CustomAccordionComponentBody.propTypes = {content: PropTypes.string};
CustomAccordionComponentHeader.propTypes = {
    heading: PropTypes.string,
    isExpanded: PropTypes.bool,
    setExpanded: PropTypes.func,
};