import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    updateLogInState,
    resetReviewState,
    resetSummaryState,
    resetChatWithDocState,
    resetCaseAnalysisState,
    resetChatWithInternalDocsState,
    resetGenerateDocState,
    resetDashBoardState,
    resetLogInState,
    resetOrganizationState,
} from "../../../redux/actions";
import store from "../../../redux/store";

export function getUserDetails() {
    console.log(store.getState());

    return {
        ...store.getState().login.logInState,
    };
}

function useToken() {
    const dispatch = useDispatch();

    const getToken = useCallback(() => {
        const userToken = localStorage.getItem("token");
        const loginState = {
            userFullName: localStorage.getItem("user"),
            organization: localStorage.getItem("organization"),
            userType: localStorage.getItem("userType"),
            user_name: localStorage.getItem("user_name"),
            user_id: localStorage.getItem("user_id"),
            org_id: localStorage.getItem("org_id"),
            usertypeid: localStorage.getItem("usertypeid"),
            job_title: localStorage.getItem("job_title"),
        };

        dispatch(updateLogInState(loginState));
        return userToken && userToken;
    }, [dispatch]);

    const [token, setToken] = useState(getToken());

    const saveToken = useCallback((data) => {
        let userToken = data.access_token;
        // console.log("Inside Save Token", userToken);
        localStorage.setItem("token", userToken);
        localStorage.setItem("refresh_token", data?.refresh_token);
        localStorage.setItem("user", data.name);
        localStorage.setItem("organization", data.orgName);
        localStorage.setItem("userType", data.userType);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("org_id", data.org_id);
        localStorage.setItem("usertypeid", data.usertypeid);
        localStorage.setItem("job_title", data.job_title);
        setToken(userToken);
    }, []);

    const removeToken = useCallback(() => {
        // console.log("Inside remove Token");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("organization");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("usertypeid");
        localStorage.removeItem("userType");
        localStorage.removeItem("org_id");
        localStorage.removeItem("job_title");

        dispatch(resetReviewState);
        dispatch(resetSummaryState);
        dispatch(resetChatWithDocState);
        dispatch(resetCaseAnalysisState);
        dispatch(resetChatWithInternalDocsState);
        dispatch(resetGenerateDocState);
        dispatch(resetDashBoardState);
        dispatch(resetLogInState);
        dispatch(resetOrganizationState);

        // Broadcast logout event to other tabs
        localStorage.setItem("logout", Date.now().toString());

        setToken(null);
    }, [dispatch]);

    useEffect(() => {
        const onStorageChange = (event) => {
            console.log("New token", event);
            if (event.key === "token") {
                setToken(event.newValue);
            }
        };

        window.addEventListener("storage", onStorageChange);

        return () => {
            window.removeEventListener("storage", onStorageChange);
        };
    }, []);

    return {
        setToken: saveToken,
        token,
        removeToken,
    };
}

export default useToken;
